import { makeStyles } from '@material-ui/core';
import { BasicFontKR, Border2Gray, BorderGray, BoxGray, ContentBlue, InbodyRed } from 'src/Style/globalStyle';

export const useAttendanceStyles = makeStyles((theme) => ({
  capture: {
    '& td': {
      background: '#ffffff',
    },
  },
  root: {
    fontSize: '15px',
    fontWeight: 'bold',
    border: `1px solid ${Border2Gray}`,
  },
  selected: {
    background: InbodyRed,
    color: '#ffffff'
  },
  indicator: {
    backgroundColor: InbodyRed,
  },
  button: {
    borderRadius: 0,
    border: `1px solid ${BorderGray}`,
    padding: '2px',
  },
  deleteButton: {
    minWidth: 0,
    borderRadius: 0,
    border: `1px solid ${BorderGray}`,
    padding: '2px',
  },
  tableRow: {
    '&:hover': {
      backgroundColor: '#E5EFFF',
      cursor: 'pointer',
    },
    '& .MuiCheckbox-root': {
      padding: 1,
    }
  },
  container: {
    marginBottom: '20px',
  },
  table: {
    '& th': {
      border: `1px solid ${BorderGray}`,
      backgroundColor: BoxGray,
      fontWeight: 600,
      padding: '6px 10px',
      fontFamily: BasicFontKR,
      fontSize: '15px',
    },
    '& td': {
      border: `1px solid ${BorderGray}`,
      padding: '6px 10px',
      fontFamily: BasicFontKR,
            fontSize: '15px',
    },
    '& td:last-child': {
      paddingRight: '10px',
    },
  },
  datePicker: {
    marginTop: 0,
    marginBottom: '10px',
    '& input': {
      fontSize: '15px',
      paddingLeft: '20px'
    },
    '& button': {
      padding: '2px'
    },
  },
  denseDatePicker: {
    margin: '5px 0',
    '& input': {
      fontSize: '15px',
      paddingLeft: '20px'
    },
    '& button': {
      padding: '2px'
    },
  },
  timePicker: {
    '& .MuiOutlinedInput-input': {
      padding: '13px',
      paddingLeft: '20px'
    }
  },
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 2),
  },
  header: {
    fontSize: '20px',
    fontWeight: 600,
    color: ContentBlue,
    marginTop: '20px',
    marginBottom: '10px',
  },
  tree: {
    width: 400,
    height: 700,
    overflowY: 'auto',
    border: `1px solid ${BorderGray}`
  },
  modalButton: {
    marginTop: 15
  },
  cancelLine: {
    '&::after': {
      content: "''",
      position: 'absolute',
      left: 0,
      right: 0,
      top: '50%',
      borderTop: '1px solid black',
    },
    opacity : 0.5,
  },
  cancelPale: {
    opacity : 0.5,
  }
}));