import React, { useEffect, useState } from "react";
import { ErrorHandler, HREmployeeApi } from "src/system/ApiService";
import {
  CATEGORY2COLL,
  DeptInfoErp,
  HRCategory,
  WorkRecord,
} from "src/system/types";
import { DropDownList } from "@syncfusion/ej2-dropdowns";
import {
  ColumnDirective,
  ColumnsDirective,
  Edit,
  EditSettingsModel,
  Grid,
  GridComponent,
  Inject,
  ExcelExport,
  Resize,
  Toolbar,
  ToolbarItems,
} from "@syncfusion/ej2-react-grids";
import { getDisplayTime } from "src/system/Helper";
import {
  DataManager,
  ODataV4Adaptor,
  Query,
  RemoteSaveAdaptor,
} from "@syncfusion/ej2-data";
import { DatePicker } from "@syncfusion/ej2-react-calendars";
import { useUserState } from "src/system/UserContext";
import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import axios from "axios";

interface Props {
  employeeId: string;
  teamList: DeptInfoErp[];
  categoryList: HRCategory[];
}

function WorkRecordGridComponent({
  employeeId,
  teamList,
  categoryList,
}: Props) {
  const user = useUserState();
  const [data, setData] = useState<WorkRecord[]>([]);
  const [refresh, setRefresh] = useState<Boolean>(false);
  const [empId, setEmpId] = useState<string>(employeeId);

  const [groupList, setGroupList] = useState<DeptInfoErp[]>([]);
  const [departmentList, setDepartmentList] = useState<DeptInfoErp[]>([]);
  const [teamDeptList, setTeamDeptList] = useState<DeptInfoErp[]>([]);

  const template = (props: any): any => {
    if (props.column.field === "started") {
      return <>{getDisplayTime(props.started)}</>;
    } else if (props.column.field === "ended") {
      return <>{getDisplayTime(props.ended)}</>;
    } else if (props.column.field === "category1") {
      if (
        props.highlight !== undefined &&
        props.highlight[props.column.field] !== undefined
      ) {
        return (
          <div style={{ fontWeight: "bold", color: "#64b5f6" }}>
            {props.category1}
          </div>
        );
      } else {
        return <div>{props.category1}</div>;
      }
    } else if (props.column.field === "category2Id") {
      if (
        props.highlight !== undefined &&
        props.highlight["category2"] !== undefined
      ) {
        return (
          <div style={{ fontWeight: "bold", color: "#64b5f6" }}>
            {props.category2}
          </div>
        );
      } else {
        return <div>{props.category2}</div>;
      }
    } else if (props.column.field === "workplace") {
      if (
        props.highlight !== undefined &&
        props.highlight[props.column.field] !== undefined
      ) {
        return (
          <div style={{ fontWeight: "bold", color: "#64b5f6" }}>
            {props.workplace}
          </div>
        );
      } else {
        return <div>{props.workplace}</div>;
      }
    } else if (props.column.field === "groupId") {
      if (
        props.highlight !== undefined &&
        props.highlight[props.column.field] !== undefined
      ) {
        return (
          <div style={{ fontWeight: "bold", color: "#64b5f6" }}>
            {props.group}
          </div>
        );
      } else {
        return <div>{props.group}</div>;
      }
    } else if (props.column.field === "departmentId") {
      if (
        props.highlight !== undefined &&
        props.highlight[props.column.field] !== undefined
      ) {
        return (
          <div style={{ fontWeight: "bold", color: "#64b5f6" }}>
            {props.department}
          </div>
        );
      } else {
        return <div>{props.department}</div>;
      }
    } else if (props.column.field === "teamId") {
      if (
        props.highlight !== undefined &&
        props.highlight["team"] !== undefined
      ) {
        return (
          <div style={{ fontWeight: "bold", color: "#64b5f6" }}>
            {props.team}
          </div>
        );
      } else {
        return <div>{props.team}</div>;
      }
    } else if (props.column.field === "jobGroup") {
      if (
        props.highlight !== undefined &&
        props.highlight[props.column.field] !== undefined
      ) {
        return (
          <div style={{ fontWeight: "bold", color: "#64b5f6" }}>
            {props.jobGroup}
          </div>
        );
      } else {
        return <div>{props.jobGroup}</div>;
      }
    } else if (props.column.field === "jobDuty") {
      if (
        props.highlight !== undefined &&
        props.highlight[props.column.field] !== undefined
      ) {
        return (
          <div style={{ fontWeight: "bold", color: "#64b5f6" }}>
            {props.jobDuty}
          </div>
        );
      } else {
        return <div>{props.jobDuty}</div>;
      }
    } else if (props.column.field === "jobPosition") {
      if (
        props.highlight !== undefined &&
        props.highlight[props.column.field] !== undefined
      ) {
        return (
          <div style={{ fontWeight: "bold", color: "#64b5f6" }}>
            {props.jobPosition}
          </div>
        );
      } else {
        return <div>{props.jobPosition}</div>;
      }
    } else if (props.column.field === "jobPositionRank") {
      if (
        props.highlight !== undefined &&
        props.highlight[props.column.field] !== undefined
      ) {
        return (
          <div style={{ fontWeight: "bold", color: "#64b5f6" }}>
            {props.jobPositionRank}
          </div>
        );
      } else {
        return <div>{props.jobPositionRank}</div>;
      }
    } else if (props.column.field === "jobTitle") {
      if (
        props.highlight !== undefined &&
        props.highlight[props.column.field] !== undefined
      ) {
        return (
          <div style={{ fontWeight: "bold", color: "#64b5f6" }}>
            {props.jobTitle}
          </div>
        );
      } else {
        return <div>{props.jobTitle}</div>;
      }
    }
  };

  useEffect(() => {
    if (employeeId !== "") {
      // API 호출
      HREmployeeApi.GetWorkRecordList(employeeId)
        .then((res) => {
          setData(res);
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          alert(msg);
        });

      axios
        .get<DeptInfoErp[]>(
          `${window.location.origin}/api/department/grid/group`
        )
        .then((res) =>
          setGroupList([
            {
              legalEntity: "IHQ",
              year: 2024,
              name: "없음",
              erpName: "없음",
              costCenter: "",
              departmentMailNickName: "",
              seniorDeptCostCenter: "",
              seniorDeptCostCenterName: "",
              status: "",
            },
            ...res.data,
          ])
        );

      axios
        .get<DeptInfoErp[]>(
          `${window.location.origin}/api/department/grid/department`
        )
        .then((res) => setDepartmentList(res.data));

      axios
        .get<DeptInfoErp[]>(
          `${window.location.origin}/api/department/grid/team`
        )
        .then((res) => setTeamDeptList(res.data));
    }
  }, [employeeId, refresh]);

  useEffect(() => {
    setEmpId(employeeId);
  }, [employeeId]);

  const editOptions: EditSettingsModel = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
  };
  const toolbarOptions: ToolbarItems[] = ["ExcelExport"];
  const adminToolbarOptions: ToolbarItems[] = [
    "Add",
    "Edit",
    "Delete",
    "Update",
    "Cancel",
    "ExcelExport",
  ];

  // Edit 용 객체 선언
  var category1Elem: HTMLElement;
  var category1Obj: DropDownList;
  var category2IdElem: HTMLElement;
  var category2IdObj: DropDownList;
  var startedElem: HTMLElement;
  var startedObj: DatePicker;
  var endedElem: HTMLElement;
  var endedObj: DatePicker;
  var workplaceElem: HTMLElement;
  var workplaceObj: DropDownList;
  var groupIdElem: HTMLElement;
  var groupIdObj: DropDownList;
  var departmentIdElem: HTMLElement;
  var departmentIdObj: DropDownList;
  var teamIdElem: HTMLElement;
  var teamIdObj: DropDownList;
  var jobGroupElem: HTMLElement;
  var jobGroupObj: DropDownList;
  var jobDutyElem: HTMLElement;
  var jobDutyObj: DropDownList;
  var jobPositionRankElem: HTMLElement;
  var jobPositionRankObj: DropDownList;
  var jobPositionElem: HTMLElement;
  var jobPositionObj: DropDownList;
  var jobTitleElem: HTMLElement;
  var jobTitleObj: DropDownList;

  // GridComponent Customized Edit Cell
  // 대분류 (category1)
  const category1: object = {
    create: () => {
      category1Elem = document.createElement("input");
      return category1Elem;
    },
    destroy: () => {
      category1Obj.destroy();
    },
    read: () => {
      return category1Obj.text;
    },
    write: (e: any) => {
      category1Obj = new DropDownList({
        change: (data) => {
          category2IdObj.enabled = true;
          const tempQuery: Query = new Query().where(
            "parentId",
            "equal",
            category1Obj.value
          );
          category2IdObj.query = tempQuery;
          category2IdObj.dataBind();
          if (data.itemData.name === e.rowData.category1) {
            category2IdObj.value = e.rowData.category2Id;
          }
        },
        dataSource: new DataManager({
          adaptor: new ODataV4Adaptor(),
          crossDomain: true,
          url: `${window.location.origin}/api/category/list/1/WORKRECORD`,
        }),
        fields: { value: "parentId", text: "name" },
        floatLabelType: "Never",
        placeholder: "Select",
        text: e.rowData.category1,
      });
      category1Obj.appendTo(category1Elem);
    },
  };
  // 소분류 (category2)
  const category2Id: object = {
    create: () => {
      category2IdElem = document.createElement("input");
      return category2IdElem;
    },
    destroy: () => {
      category2IdObj.destroy();
    },
    read: () => {
      return category2IdObj.value;
    },
    write: (e: any) => {
      category2IdObj = new DropDownList({
        dataSource: new DataManager(CATEGORY2COLL),
        enabled: false,
        fields: { value: "id", text: "name" },
        floatLabelType: "Never",
        placeholder: "Select",
        value: e.rowData.category2Id,
      });
      category2IdObj.appendTo(category2IdElem);
    },
  };
  // 시작일 (started)
  const started: object = {
    create: () => {
      startedElem = document.createElement("input");
      return startedElem;
    },
    destroy: () => {
      startedObj.destroy();
    },
    read: () => {
      return startedObj.value;
    },
    write: (e: any) => {
      startedObj = new DatePicker({
        placeholder: "Select date",
        value: e.rowData.started,
        format: "yyyy.MM.dd",
        serverTimezoneOffset: 9,
      });
      startedObj.appendTo(startedElem);
    },
  };
  // 시작일 (started)
  const ended: object = {
    create: () => {
      endedElem = document.createElement("input");
      return endedElem;
    },
    destroy: () => {
      endedObj.destroy();
    },
    read: () => {
      return endedObj.value;
    },
    write: (e: any) => {
      endedObj = new DatePicker({
        placeholder: "Select date",
        value: e.rowData.ended,
        format: "yyyy.MM.dd",
      });
      endedObj.appendTo(endedElem);
    },
  };
  // 사업장 (workplace)
  const workplace: object = {
    create: () => {
      workplaceElem = document.createElement("input");
      return workplaceElem;
    },
    destroy: () => {
      workplaceObj.destroy();
    },
    read: () => {
      return workplaceObj.text;
    },
    write: (e: any) => {
      workplaceObj = new DropDownList({
        dataSource: new DataManager({
          adaptor: new ODataV4Adaptor(),
          crossDomain: true,
          url: `${window.location.origin}/api/constant/grid/workrecord/workplace`,
        }),
        fields: { value: "id", text: "name" },
        placeholder: "Select",
        text: e.rowData.workplace,
      });
      workplaceObj.appendTo(workplaceElem);
    },
  };
  // 그룹(groupId)
  const groupId: object = {
    create: () => {
      groupIdElem = document.createElement("input");
      return groupIdElem;
    },
    destroy: () => {
      groupIdObj.destroy();
    },
    read: () => {
      return groupIdObj.value;
    },
    write: (e: any) => {
      groupIdObj = new DropDownList({
        change: () => {
          departmentIdObj.enabled = true;
          const tempQuery: Query = new Query();
          if (groupIdObj.value === "") {
            tempQuery.where("seniorDeptCostCenter", "equal", "Root");
          } else {
            tempQuery.where("seniorDeptCostCenter", "equal", groupIdObj.value);
          }

          departmentIdObj.query = tempQuery;
          departmentIdObj.text = "";
          departmentIdObj.dataBind();
          departmentIdObj.clear();

          teamIdObj.enabled = true;
          const teamTempQuery: Query = new Query().where(
            "seniorDeptCostCenter",
            "equal",
            groupIdObj.value
          );
          teamIdObj.query = teamTempQuery;
          teamIdObj.text = "";
          teamIdObj.dataBind();
          teamIdObj.clear();
        },
        dataSource: new DataManager(groupList),
        fields: { value: "costCenter", text: "name" },
        placeholder: "Select",
        value: e.rowData.groupId,
      });
      groupIdObj.appendTo(groupIdElem);
    },
  };
  // 파트(departmentId)
  const departmentId: object = {
    create: () => {
      departmentIdElem = document.createElement("input");
      return departmentIdElem;
    },
    destroy: () => {
      departmentIdObj.destroy();
    },
    read: () => {
      return departmentIdObj.value;
    },
    write: (e: any) => {
      departmentIdObj = new DropDownList({
        change: () => {
          teamIdObj.enabled = true;
          const teamTempQuery: Query = new Query().where(
            "seniorDeptCostCenter",
            "equal",
            departmentIdObj.value
          );
          teamIdObj.query = teamTempQuery;
          teamIdObj.text = "";
          teamIdObj.dataBind();
          teamIdObj.clear();
        },
        enabled: false,
        dataSource: new DataManager(departmentList),
        fields: { value: "costCenter", text: "name" },
        placeholder: "Select",
        value: e.rowData.departmentId,
      });
      departmentIdObj.appendTo(departmentIdElem);
    },
  };
  // 팀(teamId)
  const teamId: object = {
    create: () => {
      teamIdElem = document.createElement("input");
      return teamIdElem;
    },
    destroy: () => {
      teamIdObj.destroy();
    },
    read: () => {
      return teamIdObj.value;
    },
    write: (e: any) => {
      teamIdObj = new DropDownList({
        dataSource: new DataManager(teamDeptList),
        enabled: false,
        fields: { value: "costCenter", text: "name" },
        placeholder: "Select",
        value: e.rowData.teamId,
      });
      teamIdObj.appendTo(teamIdElem);
    },
  };
  // 직군 (jobGroup)
  const jobGroup: object = {
    create: () => {
      jobGroupElem = document.createElement("input");
      return jobGroupElem;
    },
    destroy: () => {
      jobGroupObj.destroy();
    },
    read: () => {
      return jobGroupObj.text;
    },
    write: (e: any) => {
      jobGroupObj = new DropDownList({
        dataSource: new DataManager({
          adaptor: new ODataV4Adaptor(),
          crossDomain: true,
          url: `${window.location.origin}/api/constant/grid/workrecord/jobgroup`,
        }),
        fields: { value: "id", text: "name" },
        placeholder: "Select",
        text: e.rowData.jobGroup,
      });
      jobGroupObj.appendTo(jobGroupElem);
    },
  };
  // 직무 (jobDuty)
  const jobDuty: object = {
    create: () => {
      jobDutyElem = document.createElement("input");
      return jobDutyElem;
    },
    destroy: () => {
      jobDutyObj.destroy();
    },
    read: () => {
      return jobDutyObj.text;
    },
    write: (e: any) => {
      jobDutyObj = new DropDownList({
        dataSource: new DataManager({
          adaptor: new ODataV4Adaptor(),
          crossDomain: true,
          url: `${window.location.origin}/api/constant/grid/workrecord/jobduty`,
        }),
        fields: { value: "id", text: "name" },
        placeholder: "Select",
        text: e.rowData.jobDuty,
      });
      jobDutyObj.appendTo(jobDutyElem);
    },
  };
  // 직위 (jobPositionRank)
  const jobPositionRank: object = {
    create: () => {
      jobPositionRankElem = document.createElement("input");
      return jobPositionRankElem;
    },
    destroy: () => {
      jobPositionRankObj.destroy();
    },
    read: () => {
      return jobPositionRankObj.text;
    },
    write: (e: any) => {
      jobPositionRankObj = new DropDownList({
        dataSource: new DataManager({
          adaptor: new ODataV4Adaptor(),
          crossDomain: true,
          url: `${window.location.origin}/api/constant/grid/workrecord/jobpositionrank`,
        }),
        fields: { value: "id", text: "name" },
        placeholder: "Select",
        text: e.rowData.jobPositionRank,
      });
      jobPositionRankObj.appendTo(jobPositionRankElem);
    },
  };
  // 직급 (jobPosition)
  const jobPosition: object = {
    create: () => {
      jobPositionElem = document.createElement("input");
      return jobPositionElem;
    },
    destroy: () => {
      jobPositionObj.destroy();
    },
    read: () => {
      return jobPositionObj.text;
    },
    write: (e: any) => {
      jobPositionObj = new DropDownList({
        dataSource: new DataManager({
          adaptor: new ODataV4Adaptor(),
          crossDomain: true,
          url: `${window.location.origin}/api/constant/grid/workrecord/jobposition`,
        }),
        fields: { value: "id", text: "name" },
        placeholder: "Select",
        text: e.rowData.jobPosition,
      });
      jobPositionObj.appendTo(jobPositionElem);
    },
  };
  // 직책 (jobTitle)
  const jobTitle: object = {
    create: () => {
      jobTitleElem = document.createElement("input");
      return jobTitleElem;
    },
    destroy: () => {
      jobTitleObj.destroy();
    },
    read: () => {
      return jobTitleObj.text;
    },
    write: (e: any) => {
      jobTitleObj = new DropDownList({
        dataSource: new DataManager({
          adaptor: new ODataV4Adaptor(),
          crossDomain: true,
          url: `${window.location.origin}/api/constant/grid/workrecord/jobtitle`,
        }),
        fields: { value: "id", text: "name" },
        placeholder: "Select",
        text: e.rowData.jobTitle,
      });
      jobTitleObj.appendTo(jobTitleElem);
    },
  };

  // GirdObject
  var gridObj: Grid | null;
  // Toolbar Click 이벤트
  const toolbarClick = (args: ClickEventArgs | undefined) => {
    if (args !== undefined && args.item !== undefined) {
      if (args.item.id?.includes("excel") && gridObj !== null) {
        gridObj.excelExport();
      }
    }
  };

  //actionBegin
  const actionBegin = (e: any) => {
    if (e.requestType === "delete") {
      if (!window.confirm("정말로 삭제하시겠습니까?")) {
        e.cancel = true;
      }
    }
  };

  //actionComplete
  const actionComplete = (e: any) => {
    if (e.requestType === "save") {
      setRefresh(!refresh);
    }
  };

  return (
    <div>
      <GridComponent
        dataSource={
          new DataManager({
            adaptor: new RemoteSaveAdaptor(),
            json: data,
            crudUrl: `${window.location.origin}/api/hremployee/crud/workrecord`,
          })
        }
        allowResizing={true}
        editSettings={editOptions}
        toolbar={user.role === "admin" ? adminToolbarOptions : toolbarOptions}
        allowExcelExport={true}
        toolbarClick={toolbarClick}
        ref={(g) => {
          gridObj = g;
        }}
        actionBegin={actionBegin}
        actionComplete={actionComplete}
      >
        <Inject services={[Resize, Edit, Toolbar, ExcelExport]} />
        <ColumnsDirective>
          <ColumnDirective
            field="category1"
            headerText="대분류"
            editType="dropdownedit"
            edit={category1}
            template={template}
            width="140"
          />
          <ColumnDirective
            field="category2Id"
            headerText="소분류"
            editType="dropdownedit"
            edit={category2Id}
            template={template}
            width="170"
          />
          <ColumnDirective
            field="started"
            headerText="시작일"
            edit={started}
            template={template}
            width="135"
          />
          <ColumnDirective
            field="ended"
            headerText="종료일"
            edit={ended}
            template={template}
            width="135"
          />
          <ColumnDirective
            field="workplace"
            headerText="사업장"
            edit={workplace}
            template={template}
            width="130"
          />
          <ColumnDirective
            field="groupId"
            headerText="그룹"
            edit={groupId}
            template={template}
            width="250"
          />
          <ColumnDirective
            field="departmentId"
            headerText="파트"
            edit={departmentId}
            template={template}
            width="250"
          />
          <ColumnDirective
            field="teamId"
            headerText="팀"
            edit={teamId}
            template={template}
            width="250"
          />
          <ColumnDirective
            field="jobGroup"
            headerText="직군"
            edit={jobGroup}
            template={template}
            width="120"
          />
          <ColumnDirective
            field="jobDuty"
            headerText="직무"
            edit={jobDuty}
            template={template}
            width="190"
          />
          <ColumnDirective
            field="jobPosition"
            headerText="직급"
            edit={jobPosition}
            template={template}
            width="140"
          />
          <ColumnDirective
            field="jobPositionRank"
            headerText="직위"
            edit={jobPositionRank}
            template={template}
            width="140"
          />
          <ColumnDirective
            field="jobTitle"
            headerText="직책"
            edit={jobTitle}
            template={template}
            width="140"
          />
          <ColumnDirective field="remark" headerText="비고" width="120" />
          <ColumnDirective
            field="id"
            headerText="id"
            width="100"
            isPrimaryKey={true}
            visible={false}
          />
          <ColumnDirective
            field="employeeId"
            headerText="id"
            width="100"
            visible={false}
            defaultValue={empId || ""}
          />
          {/* visible false 로 teamId field 생성 후 team edit change()함수에 따라 teamId 변경되도록 코드 추가 */}
        </ColumnsDirective>
      </GridComponent>
    </div>
  );
}

export default WorkRecordGridComponent;
