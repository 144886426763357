import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { useRecoilState } from 'recoil';
import { saveLoadingModalAtom } from '../../recoils/SaveLoadingModalAtom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
);

export default function LoadingSpinnerModal() {
  const classes = useStyles();
  const [saveLoadingModalState, setSaveLoadingModalState] = useRecoilState(saveLoadingModalAtom);

  const handleClose = () => {
    setSaveLoadingModalState(pre => ({ isOpen: false, text: pre.text }));
  };

  return (
    <div>
      <Backdrop
        style={{ flexDirection: 'column' }}
        className={classes.backdrop}
        open={saveLoadingModalState.isOpen}
        onClick={handleClose}>
        <h4>{saveLoadingModalState.text}</h4>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
