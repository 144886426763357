// Font
export const BasicFontKR = "Noto sans CJK KR";
export const BasicFontEN = "Noto sans";

// Color
export const DividerGray = "#bfbfbf";
export const InbodyRed = "#7a2f2f";

export const BackgroundColorGray = "#F8F8F8";
export const BackgroundColorPale = "#F7F8FA";

export const BackgroundColor2Gray = "#F4F4F4";
export const BackgroundColor2Pale = "#F0F2F5";
export const BackgroundColor2Red = "#FFF0F3";
export const BackgroundColor2Green = "#F0FAF7";
export const BackgroundColor2Blue = "#F0F6FF";
export const BackgroundColor2Orange = "#FCF1E3";

export const BoxGray = "#EDEDED";
export const BoxPale = "#E9ECF0";
export const BoxRed = "#FFE6EB";
export const BoxGreen = "#E4F7F1";
export const BoxBlue = "#E5EFFF";
export const BoxOrange = "#FFEAD1";

export const BorderGray = "#E0E0E0";
export const BorderPale = "#DCDFE3";
export const BorderRed = "#FFD4DC";
export const BorderGreen = "#CAEDE2";
export const BorderBlue = "#D4E4FF";
export const BorderOrange = "#FFDCB8";

export const Border2Gray = "#CFD0D1";
export const Border2Pale = "#C7CBD1";
export const Border2Red = "#FCB6C3";
export const Border2Green = "#9FE0CC";
export const Border2Blue = "#B6D0FC";
export const Border2Orange = "#FFCD94";

export const DisabledGray = "#B2B4B8";
export const DisabledPale = "#B3BBC5";
export const DisabledRed = "#F78194";
export const DisabledGreen = "#70CCAF";
export const DisabledBlue = "#7CABF7";
export const DisabledOrange = "#FFA947";

export const ContentGray = "#81848B";
export const ContentPale = "#718093";
export const ContentRed = "#F25C75";
export const ContentGreen = "#47B998";
export const ContentBlue = "#4C8DF5";
export const ContentOrange = "#EF8100";

export const TitleGray = "#4B4F5A";
export const TitlePale = "#3D4861";
export const TitleRed = "#CC1F3B";
export const TitleGreen = "#0E8A65";
export const TitleBlue = "#1E66D9";
export const TitleOrange = "#D47100";

export const AccentGray = "#252933";
export const AccentPale = "#182031";
export const AccentRed = "#971B2F";
export const AccentGreen = "#0A6649";
export const AccentBlue = "#124FB3";
export const AccentOrange = "#A65800";

export const PRIMARY = "#1976d2";
export const SECONDARY = "#9c27b0";
export const ERROR = "#d32f2f";
export const WARNING = "#ed6c02";
export const INFO = "#0288d1";
export const SUCCESS = "#2e7d32";

export const DraftColor = "#585c59";
export const DirectApprovalColor = "#003cff";
export const ParallelApprovalColor = "#00C1CD";
export const DirectAgreementColor = "#4CB860";
export const ParallelAgreementColor = "#b9cd00";
export const ReferenceColor = "#E41C77";

export const FlexCenter = `
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const BoarderTopLeft = `
    border-top: 1px solid ${DividerGray};
    border-left: 1px solid ${DividerGray};
`;

export const BoarderBottomRight = `
    border-bottom: 1px solid ${DividerGray};
    border-right: 1px solid ${DividerGray};
`;
