import { Button, Paper, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React, { ReactElement } from "react";
import ReactDataGrid, { Column } from "react-data-grid";
import { HrStyle } from "src/Style/theme";
import { Education, ObjType } from "src/system/types";
import { DefaultFormatter, IData } from "./Common";
import { ExcelUploadEnum } from "./MainPage";
import PublishIcon from "@material-ui/icons/Publish";

export interface EducationUpload extends Education, IData {
  error: ObjType;
}

interface Props {
  data: EducationUpload[];
  errorCount: number;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  onInsertData: (type: ExcelUploadEnum) => void;
}

function EducationDataImportRef({
  data,
  errorCount,
  onSubmit,
  onInsertData,
}: Props): ReactElement {
  const classes = HrStyle();
  const columns = React.useMemo(() => {
    const k = [
      {
        name: "NUM",
        key: "id",
      },
      {
        name: "사번",
        key: "employeeId",
      },
      {
        name: "교육분류",
        key: "kind",
      },
      {
        name: "외부/내부",
        key: "location",
      },
      {
        name: "교육명",
        key: "name",
      },
      {
        name: "분야",
        key: "field",
      },
      {
        name: "직능구분",
        key: "ability",
      },
      {
        name: "교육시작일",
        key: "started",
      },
      {
        name: "교육종료일",
        key: "ended",
      },
      {
        name: "교육기관",
        key: "agency",
      },
      {
        name: "교육비",
        key: "cost",
      },
      {
        name: "증빙서류",
        key: "file",
      },
      {
        name: "R&R문서번호",
        key: "rnrNo",
      },
      {
        name: "교육신청서 문서번호",
        key: "applicationNo",
      },
      {
        name: "교육기록부 문서번호",
        key: "reportNo",
      },
      {
        name: "비고",
        key: "remark",
      },
    ];
    const nk: Column<EducationUpload>[] = k.map((x) => {
      if (x.key === "id") return x;
      else {
        return {
          ...x,
          formatter: DefaultFormatter,
        } as Column<EducationUpload>;
      }
    });
    return nk;
  }, []);

  const onClickUpload = () => onInsertData("education");

  return (
    <Paper variant="outlined" className={classes.paperUpload}>
      <form onSubmit={onSubmit} name="education">
        <input type="file" name="file" id="input-file" required />
        <input type="submit" />
        <input type="reset" />
      </form>
      <br />
      <Button
        onClick={onClickUpload}
        variant="contained"
        startIcon={<PublishIcon />}
      >
        데이터 업로드
      </Button>

      {errorCount > 0 && (
        <Alert severity="error" title="에러가 발생한 위치">
          <AlertTitle>에러가 발생한 위치</AlertTitle>
          <ul>
            {data
              .filter((x) => Object.keys(x.error).length >= 1)
              .map((x) => (
                <li>{x.id}</li>
              ))}
          </ul>
        </Alert>
      )}

      <div className="text-right">
        <Typography variant="body2">에러데이터 : {errorCount}건</Typography>
      </div>

      <ReactDataGrid
        rows={data}
        columns={columns}
        rowKeyGetter={(row) => {
          return row.id;
        }}
        rowClass={(row) => {
          if (Object.keys(row.error).length >= 1) {
            return "c-error-row";
          } else return undefined;
        }}
        defaultColumnOptions={{
          resizable: true,
        }}
        className={classes.reactDataGrid}
      ></ReactDataGrid>
    </Paper>
  );
}

export default EducationDataImportRef;
