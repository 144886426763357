import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import React, { useCallback, useEffect, useState } from "react";
import { AttendanceApi, ErrorHandler } from "src/system/ApiService";
import { NightShiftStatistics } from "src/system/types";
import {
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  ExcelExport,
  Filter,
  Grid,
  GridComponent,
  Inject,
  Page,
  Resize,
  Search,
  Sort,
  Toolbar,
  ToolbarItems,
} from "@syncfusion/ej2-react-grids";
import { ClickEventArgs } from "@syncfusion/ej2-react-navigations";
import { useLoadingDispatch } from "src/system/LoadingContext";

interface NightShiftInput {
  workplace: string;
  date: Date;
}

function NightShiftGrid() {
  const [data, setData] = useState<NightShiftStatistics[]>([]);
  const [input, setInput] = useState<NightShiftInput>({
    workplace: "본사",
    date: new Date(),
  });
  const LoadingDispatch = useLoadingDispatch();

  const LoadData = useCallback(() => {
    LoadingDispatch({ type: "LOADING" });

    AttendanceApi.GetMonthlyNightShift(input.workplace, input.date.getFullYear(), input.date.getMonth() + 1)
      .then((res) => {
        setData(res);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      })
      .finally(() => {
        LoadingDispatch({ type: "COMPLETE" });
      });
  }, [LoadingDispatch, input]);

  useEffect(() => {
    LoadData();
  }, [LoadData]);

  const onChange = (event: any) => {
    setInput({ ...input, workplace: event.target.value });
  };

  const onDateChange = (event: any) => {
    if (event) {
      setInput({ ...input, date: event.value });
    }
  };

  var gridObj: Grid | null;

  const toolbarOptions: ToolbarItems[] = ["Search", "ColumnChooser", "ExcelExport"];

  const toolbarClick = (args: ClickEventArgs | undefined) => {
    if (args !== undefined && args.item !== undefined) {
      if (args.item.id?.includes("excel") && gridObj !== null) {
        gridObj.excelExport();
      }
    }
  };

  return (
    <div>
      <div style={{ textAlign: "left" }}>
        <DropDownListComponent
          placeholder="사업장 선택"
          floatLabelType="Always"
          width="200px"
          value={input.workplace}
          dataSource={["본사", "벤처"]}
          onChange={onChange}
        />
        <DatePickerComponent
          width="200px"
          floatLabelType="Always"
          value={input.date}
          format="yyyy-MM"
          placeholder="기간 선택"
          start="Year"
          depth="Year"
          onChange={onDateChange}
        />
      </div>
      <br></br>
      <GridComponent
        dataSource={data}
        allowResizing={true}
        allowFiltering={true}
        allowPaging={true}
        allowSorting={true}
        allowExcelExport={true}
        toolbar={toolbarOptions}
        toolbarClick={toolbarClick}
        pageSettings={{ pageSize: 100, pageSizes: true }}
        ref={(g) => {
          gridObj = g;
        }}
      >
        <ColumnsDirective>
          <ColumnDirective field="employeeId" headerText="사번" width="150" />
          <ColumnDirective field="name" headerText="이름" width="150" />
          <ColumnDirective field="nightShiftSecom" headerText="야근(세콤)" width="150" />
          <ColumnDirective field="nightShiftV4" headerText="야근(통합근태신청서)" width="150" />
        </ColumnsDirective>
        <Inject services={[Resize, Toolbar, Filter, Page, Sort, Search, ExcelExport, ColumnChooser]} />
      </GridComponent>
    </div>
  );
}

export default NightShiftGrid;
