import DateFnsUtils from '@date-io/date-fns';
import { Grid } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { DataManager, Predicate, Query } from '@syncfusion/ej2-data';
import { AutoCompleteComponent, DropDownListComponent, FilteringEventArgs } from "@syncfusion/ej2-react-dropdowns";
import { NumericTextBoxComponent, TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { useEffect, useState } from "react";
import { GetAttendanceBalanceForAdmin } from 'src/page/attendance/attendance-request/post-attendance-request/interface/BalanceInterface';
import { AttendanceConstant } from 'src/page/attendance/attendance-request/post-attendance-request/interface/ConstantInterface';
import { AttendanceConstantApi, ErrorHandler, HREmployeeApi } from "src/system/ApiService";
import { useUserState } from 'src/system/UserContext';
import { EmployeeAutoComplete } from 'src/system/types';
import { useAttendanceBalanceStyles } from '../style/AttendanceBalanceStyle';

export interface ABDialogTemplateProps extends GetAttendanceBalanceForAdmin {
  isAdd: boolean
}

interface AttendanceBalanceGridDialogProps {
  attendanceBalance: ABDialogTemplateProps
};

export default function AttendanceBalanceGridDialog({ attendanceBalance }: AttendanceBalanceGridDialogProps) {
  const [data, setData] = useState<ABDialogTemplateProps>(attendanceBalance);
  const [employeeList, setEmployeeList] = useState<EmployeeAutoComplete[]>([]);
  const [leaveTypeList, setLeaveTypeList] = useState<AttendanceConstant[]>([]);
  const classes = useAttendanceBalanceStyles();
  const user = useUserState();

  useEffect(() => {
    HREmployeeApi.GetEmployeeList()
      .then(res => setEmployeeList(res))
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      })
    AttendanceConstantApi.GetConstant('AttendanceBalance', 'category')
      .then(res => setLeaveTypeList(res))
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      })
  }, []);

  /** input Component */
  const onChange = (args: any) => {
    setData({ ...data, [(args.target as HTMLInputElement).name]: args.target.value });
  };

  const changeEmployeeId = (data: EmployeeAutoComplete) => {
    setData(pre => {
      return (
        {
          ...pre,
          employeeId: data.employeeId,
          name: data.name
        }
      )
    })
  }


  /** AutoCompleteComponent */
  const onFiltering = (args: FilteringEventArgs) => {
    let query = new Query();
    query = query.where(new Predicate('employeeId', 'contains', args.text, true)
      .or('name', 'startswith', args.text, true))
    args.updateData(new DataManager(employeeList), query);
  }

  const itemTemplate = (data: EmployeeAutoComplete) => {
    return (
      <span>
        {`${data.name} / ${data.team} / ${data.jobPosition}`}
      </span>
    )
  }

  const changeExpiredDate = (date: MaterialUiPickersDate, value?: string | null | undefined) => {
    if (value) {
      setData(pre => {
        return (
          {
            ...pre,
            expired: value
          }
        )
      })
    }
  }


  return (
    <div style={{ width: "400px", minHeight: "200px" }}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} >
        <input type='hidden' name='id' value={data.id || 0} />
        <input type='hidden' name='name' value={data.name || ''} />
        <input type='hidden' name='created' value={String(new Date(Date.now()))} />
        <Grid container spacing={1} style={{ padding: 8 }}>
          <Grid item xs={12}>
            {leaveTypeList.length > 0 && <DropDownListComponent
              cssClass='e-outline'
              placeholder="휴가종류"
              name="category"
              dataSource={new DataManager(leaveTypeList)}
              value={data.category}
              onChange={onChange}
              fields={{ text: 'value', value: 'value' }}
              floatLabelType="Always"
            />}
          </Grid>
          <Grid item xs={12}>
            <AutoCompleteComponent
              cssClass='e-outline'
              placeholder="부여대상"
              name="employeeId"
              dataSource={new DataManager(employeeList)}
              value={data.employeeId}
              change={e => changeEmployeeId(e.itemData)}
              fields={{ text: 'name', value: 'employeeId' }}
              itemTemplate={itemTemplate}
              filterType='Contains'
              filtering={onFiltering}
              floatLabelType="Always"
            />
          </Grid>
          <Grid item xs={12}>
            <NumericTextBoxComponent
              cssClass='e-outline'
              placeholder="연차수"
              name="term"
              value={Number(data.term)}
              onChange={onChange}
              min={0}
              floatLabelType="Always"
            />
          </Grid>
          <Grid item xs={12}>
            <TextBoxComponent
              cssClass='e-outline'
              placeholder="연차부여자"
              name="createdBy"
              disabled
              value={`${user.name}(${user.employeeId})`}
              floatLabelType="Always"
            />
          </Grid>
          <Grid item xs={12}>
            <KeyboardDatePicker
              label="만료일"
              disableToolbar
              fullWidth
              name='expired'
              variant="inline"
              format="yyyy-MM-dd"
              margin="normal"
              PopoverProps={{
                style: { zIndex: 1500 }
              }}
              className={classes.datePicker}
              value={data.expired ? new Date(data.expired) : undefined}
              onChange={changeExpiredDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </div>
  );
}
