import { Divider, Grid, IconButton, TextField, Theme } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { createStyles, makeStyles } from "@material-ui/styles";
import React, { ChangeEvent, ReactElement, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { EndTextField } from "src/components/EndTextField";
import RegularColgroup from "src/components/RegularColgroup";
import TableInsaCard from "src/components/TableInsaCard";
import { ConstantApi, ErrorHandler } from "src/system/ApiService";
import { Career, InitValidationCE, ValidationCA } from "src/system/types";
import ClearIcon from "@material-ui/icons/Clear";

interface Props {
  data: Career[];
  onAddRows: (tab: string) => void;
  onRemoveRows: (tab: string, index: number) => void;
  onChangeText: (tab: string, e: ChangeEvent<HTMLInputElement>, index: number) => void;
  onChangeValue: (tab: string, name: string, value: string, index: number) => void;
  onChangeAutoComplete: (
    tab: string,
    event: React.ChangeEvent<{}>,
    value: string | null,
    name: string,
    index: number
  ) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      // backgroundColor: "#eeeeee",
      width: "1000px",
      display: "inline-block",
      textAlign: "left",
    },
    sub: {
      padding: "20px",
      width: "100%",
      "& .MuiGrid-item": {
        height: "70px",
      },
    },
  })
);

function InputCareerData({
  data,
  onAddRows,
  onRemoveRows,
  onChangeText,
  onChangeValue,
  onChangeAutoComplete,
}: Props): ReactElement {
  const classes = useStyles();
  const tab = "career";
  const [val, setVal] = useState<ValidationCA>(InitValidationCE);

  useMemo(() => {
    let employmentForm: string[] = [""];

    ConstantApi.GetConstant("CAREER", "")
      .then((res) => {
        res.result.forEach((x) => {
          if (x.columnName === "EMPLOYMENTFORM") {
            employmentForm.push(x.name);
          }
        });
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      });
    setVal({ employmentForm: employmentForm });
  }, []);

  return (
    <div className={classes.main}>
      <TableInsaCard title="경력사항" colgroup={<RegularColgroup cols={6}></RegularColgroup>}>
        <div className={classes.sub}>
          <Grid container>
            <Grid item xs={6}>
              <Button onClick={() => onAddRows(tab)}>경력 추가</Button>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right" }}></Grid>
          </Grid>
          {data.length > 0 &&
            data.map((x, index) => {
              return (
                <>
                  <Divider style={{ margin: "15px 5px 15px 5px" }}></Divider>
                  <Grid container>
                    <Grid item xs={4}>
                      <EndTextField
                        label="회사명"
                        name="company"
                        value={x.company}
                        style={{ width: "210px" }}
                        onChange={(e) => onChangeText(tab, e, index)}
                      ></EndTextField>
                    </Grid>
                    <Grid item xs={4}>
                      <EndTextField
                        label="시작일"
                        name="started"
                        type="date"
                        value={x.started !== null ? x.started.split("T")[0] : ""}
                        style={{ width: "210px" }}
                        onChange={(e) => onChangeText(tab, e, index)}
                        shrink={true}
                      ></EndTextField>
                    </Grid>
                    <Grid item xs={3}>
                      <EndTextField
                        label="종료일"
                        name="ended"
                        type="date"
                        value={x.ended !== null ? x.ended.split("T")[0] : ""}
                        style={{ width: "210px" }}
                        onChange={(e) => onChangeText(tab, e, index)}
                        shrink={true}
                      ></EndTextField>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton aria-label="delete" color="secondary" onClick={() => onRemoveRows(tab, index)}>
                        <ClearIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={4}>
                      <Autocomplete
                        value={x.employmentForm === null ? "" : x.employmentForm}
                        onChange={(event, value) => {
                          if (value !== null) {
                            onChangeAutoComplete(tab, event, value, "employmentForm", index);
                          }
                        }}
                        inputValue={x.employmentForm}
                        onInputChange={(event, newInputValue) => {
                          if (newInputValue !== null) {
                            onChangeValue(tab, "employmentForm", newInputValue, index);
                          }
                        }}
                        options={val.employmentForm}
                        style={{ width: 210 }}
                        renderInput={(params) => <TextField {...params} label="고용형태" />}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <EndTextField
                        label="부서"
                        name="department"
                        value={x.department}
                        style={{ width: "210px" }}
                        onChange={(e) => onChangeText(tab, e, index)}
                      ></EndTextField>
                    </Grid>
                    <Grid item xs={4}>
                      <EndTextField
                        label="직급"
                        name="jobPosition"
                        value={x.jobPosition}
                        style={{ width: "210px" }}
                        onChange={(e) => onChangeText(tab, e, index)}
                      ></EndTextField>
                    </Grid>
                    <Grid item xs={4}>
                      <EndTextField
                        label="직무"
                        name="jobDuty"
                        value={x.jobDuty}
                        style={{ width: "210px" }}
                        onChange={(e) => onChangeText(tab, e, index)}
                      ></EndTextField>
                    </Grid>
                    <Grid item xs={2}>
                      <EndTextField
                        label="연봉"
                        name="salary"
                        value={x.salary}
                        style={{ width: "120px" }}
                        onChange={(e) => onChangeText(tab, e, index)}
                      ></EndTextField>
                    </Grid>
                    <Grid item xs={6}>
                      <EndTextField
                        label="퇴직사유"
                        name="retirementReason"
                        value={x.retirementReason}
                        style={{ width: "370px" }}
                        onChange={(e) => onChangeText(tab, e, index)}
                      ></EndTextField>
                    </Grid>
                    <Grid item xs={12}>
                      <EndTextField
                        label="담당업무"
                        name="work"
                        value={x.work}
                        style={{ width: "850px" }}
                        onChange={(e) => onChangeText(tab, e, index)}
                      ></EndTextField>
                    </Grid>
                    <Grid item xs={12}>
                      <EndTextField
                        label="비고"
                        name="remark"
                        value={x.remark}
                        style={{ width: "850px" }}
                        onChange={(e) => onChangeText(tab, e, index)}
                      ></EndTextField>
                    </Grid>
                  </Grid>
                </>
              );
            })}
        </div>
      </TableInsaCard>
    </div>
  );
}

export default InputCareerData;
