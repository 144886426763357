import {
  ColumnDirective,
  ColumnsDirective,
  Edit,
  ExcelExport,
  Filter,
  GridComponent,
  Inject,
  Page,
  Resize,
  RowDataBoundEventArgs,
  Search,
  Sort,
  Toolbar,
  ToolbarItems
} from "@syncfusion/ej2-react-grids";
import { ClickEventArgs } from '@syncfusion/ej2-react-navigations';
import { useEffect, useRef, useState } from 'react';
import { AttendanceRequestApi, ErrorHandler } from 'src/system/ApiService';
import AttendanceRequestItemStatus from '../attendance-request/attendance-request-list/components/AttendanceRequestItemStatus';
import { getLastBelong } from '../attendance-request/post-attendance-request/hooks/getLastBelong';
import { GetAttendanceItem, GetAttendanceRequestItem } from '../attendance-request/post-attendance-request/interface/RequestInterface';
import { Button } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useDateHooks } from '../attendance-request/post-attendance-request/hooks/useDateHooks';

export default function AttendanceRequestItemListPage() {
  const gridRef = useRef<GridComponent | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [data, setData] = useState<GetAttendanceRequestItem[]>([]);

  const { getDateStringFormat } = useDateHooks();
  useEffect(() => {
    const selectYear = selectedDate.getFullYear();
    const selectMonth = selectedDate.getMonth() + 1;
    AttendanceRequestApi.GetAttendanceRequestItemList(0, selectYear, selectMonth)
      .then(res => setData(res))
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      })
  }, [selectedDate]);


  const toolbarOptions: ToolbarItems[] = [
    "Search",
    "ExcelExport"
  ];

  const toolbarClick = (args: ClickEventArgs | undefined) => {
    if (args !== undefined && args.item !== undefined) {
      if (args.item.id?.includes("excel") && gridRef.current !== null) {
        gridRef.current.excelExport();
      }
    }
  };

  const getWorkDateValue = (fields: string, data: any) => {
    const attendanceItem = data as GetAttendanceRequestItem;
    const date = new Date(attendanceItem.started);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`
  }

  const getStartTimeValue = (fields: string, data: any) => {
    const attendanceItem = data as GetAttendanceRequestItem;
    if (attendanceItem.formName.includes('연차')) {
      return '-';
    }
    const time = new Date(attendanceItem.started);
    return `${time.getHours().toString().padStart(2, '0')}:${time.getMinutes().toString().padStart(2, '0')}`
  }

  const getEndTimeValue = (fields: string, data: any) => {
    const attendanceItem = data as GetAttendanceRequestItem;
    if (attendanceItem.formName.includes('연차')) {
      return '-';
    }
    const time = new Date(attendanceItem.ended);
    return `${time.getHours().toString().padStart(2, '0')}:${time.getMinutes().toString().padStart(2, '0')}`
  }


  const getRequestStatusValue = (fields: string, data: any) => {
    const attendanceItem = data as GetAttendanceRequestItem;
    if (!attendanceItem.attendanceRequestStatus) {
      return '-';
    }
    switch (attendanceItem.attendanceRequestStatus) {
      case '1':
        return '임시저장';
      case '2':
        return '결재중';
      case '4':
        return '반려';
      case '3':
      default:
        return '결재완료';
    }
  }

  const getCancelStatusValue = (fields: string, data: any) => {
    const attendanceItem = data as GetAttendanceRequestItem;
    if (!attendanceItem.attendanceRequestCancelStatus) {
      return '-';
    }
    switch (attendanceItem.attendanceRequestCancelStatus) {
      case '1':
        return '임시저장';
      case '2':
        return '결재중';
      case '4':
        return '반려';
      case '3':
      default:
        return '취소완료';
    }
  }

  const statusTemplate = (data: GetAttendanceRequestItem) => {
    return <AttendanceRequestItemStatus isCancel={false} status={data.attendanceRequestStatus} />
  }
  const cancelStatusTemplate = (data: GetAttendanceItem) => {
    return <AttendanceRequestItemStatus isCancel={true} status={data.attendanceRequestCancelStatus} />
  }

  const rowDataBound = (args: RowDataBoundEventArgs) => {
    if (args.data &&
      (args.data as GetAttendanceItem).attendanceRequestCancelStatus === '3') {
      args.row?.classList.add('cancelLine');
    }
  }

  const handlePrevMonth = () => {
    setSelectedDate(date => {
      const newDate = new Date(date);
      newDate.setMonth(date.getMonth() - 1);
      return newDate;
    });
  };

  const handleNextMonth = () => {
    setSelectedDate(date => {
      const newDate = new Date(date);
      newDate.setMonth(date.getMonth() + 1);
      return newDate;
    });
  }

  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <Button onClick={handlePrevMonth}>
          <ArrowBackIosIcon />
        </Button>
        <span style={{ padding: '8px 16px' }}>
          {getDateStringFormat(selectedDate, 'YYYY-MM')}
        </span>
        <Button onClick={handleNextMonth}>
          <ArrowForwardIosIcon />
        </Button>
      </div>
      <br></br>
      <GridComponent
        dataSource={data}
        allowResizing={true}
        allowPaging={true}
        allowSorting={true}
        allowExcelExport={true}
        allowFiltering={true}
        filterSettings={{ type: 'CheckBox' }}
        toolbar={toolbarOptions}
        toolbarClick={toolbarClick}
        pageSettings={{ pageSize: 20, pageSizes: true }}
        rowDataBound={rowDataBound}
        ref={gridRef}
      >
        <ColumnsDirective>
          <ColumnDirective
            textAlign='Center'
            field="workDate"
            headerText="날짜"
            valueAccessor={getWorkDateValue}
            width="15%" />
          <ColumnDirective
            textAlign='Center'
            clipMode='EllipsisWithTooltip'
            field="category"
            headerText="대분류"
            width="10%" />
          <ColumnDirective
            textAlign='Center'
            clipMode='EllipsisWithTooltip'
            field="formName"
            headerText="소분류"
            width="10%" />
          <ColumnDirective
            textAlign='Center'
            clipMode='EllipsisWithTooltip'
            field="attendanceRequestStatus"
            headerText="상태"
            valueAccessor={getRequestStatusValue}
            template={statusTemplate}
            width="10%" />
          <ColumnDirective
            textAlign='Center'
            clipMode='EllipsisWithTooltip'
            field="attendanceRequestCancelStatus"
            headerText="취소상태"
            valueAccessor={getCancelStatusValue}
            template={cancelStatusTemplate}
            width="10%" />
          <ColumnDirective
            textAlign='Center'
            field="group"
            clipMode='EllipsisWithTooltip'
            headerText="그룹"
            width="15%" />
          <ColumnDirective
            textAlign='Center'
            field="parentTeam"
            clipMode='EllipsisWithTooltip'
            headerText="파트"
            width="15%" />
          <ColumnDirective
            textAlign='Center'
            field="team"
            clipMode='EllipsisWithTooltip'
            headerText="팀"
            width="15%" />
          <ColumnDirective
            textAlign='Center'
            field="jobTitle"
            clipMode='EllipsisWithTooltip'
            headerText="직책"
            width="12%" />
          <ColumnDirective
            textAlign='Center'
            field="employeeId"
            clipMode='EllipsisWithTooltip'
            headerText="사번"
            width="12%" />
          <ColumnDirective
            textAlign='Center'
            field="name"
            clipMode='EllipsisWithTooltip'
            headerText="이름"
            width="12%" />
          <ColumnDirective
            textAlign='Center'
            field="term"
            clipMode='EllipsisWithTooltip'
            headerText="기간"
            width="12%" />
          <ColumnDirective
            textAlign='Center'
            field="started"
            clipMode='EllipsisWithTooltip'
            headerText="시작시간"
            valueAccessor={getStartTimeValue}
            width="15%" />
          <ColumnDirective
            textAlign='Center'
            field="ended"
            clipMode='EllipsisWithTooltip'
            headerText="종료시간"
            valueAccessor={getEndTimeValue}
            width="15%" />
        </ColumnsDirective>
        <Inject services={[Resize, Edit, Toolbar, Page, Sort, Search, ExcelExport, Filter]} />
      </GridComponent>
    </div>
  );
}
