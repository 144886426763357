import React, { ReactNode, Fragment, ReactElement } from "react";
import { useInterviewDispatch } from "src/system/InterviewContext";
import { RouteData } from "src/system/types";
import { useUserState } from "src/system/UserContext";

interface Props {
  children?: ReactNode;
  dataType: RouteData;
}

function WriteInit({ children, dataType }: Props): ReactElement {
  const dispatch = useInterviewDispatch();
  const user = useUserState();

  React.useEffect(() => {
    if (dataType === RouteData.interview) {
      dispatch({ type: "WRITE-INIT-INTERVIEW", item: user });
    } else if (dataType === RouteData.issue) {
      dispatch({ type: "WRITE-INIT-ISSUE", item: user });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Fragment>{children}</Fragment>;
}

export default WriteInit;
