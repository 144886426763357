import { Paper, TableCell, TableContainer, TableRow } from "@material-ui/core";
import { DataManager } from '@syncfusion/ej2-data';
import { ColumnDirective, ColumnsDirective, Filter, GridComponent, Inject, Page, Resize, RowDataBoundEventArgs, Search, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { useRef } from 'react';
import { StringFormat, useDateHooks } from '../../post-attendance-request/hooks/useDateHooks';
import { GetAttendanceItem } from '../../post-attendance-request/interface/RequestInterface';
import AttendanceRequestItemStatus from './AttendanceRequestItemStatus';
import AttendanceDocumentUrlItem from './AttendanceDocumentUrlItem';

interface AttendanceRequestListProps {
  attendanceRequestItemList: GetAttendanceItem[]
}

function AttendanceRequestList({ attendanceRequestItemList }: AttendanceRequestListProps) {
  const gridRef = useRef<GridComponent | null>(null);

  const { getDateStringFormat } = useDateHooks();

  const formatDateField = (field: string, data: any, format: StringFormat) => {
    if (!data[field]) return '-';
    const date = new Date(data[field]);
    return getDateStringFormat(date, format);
  }

  const statusTemplate = (data: GetAttendanceItem) => {
    return <AttendanceRequestItemStatus isCancel={false} status={data.attendanceRequestStatus} />
  }
  const cancelStatusTemplate = (data: GetAttendanceItem) => {
    return <AttendanceRequestItemStatus isCancel={true} status={data.attendanceRequestCancelStatus} />
  }
  const documentUrlTemplate = (data: GetAttendanceItem) => {
    return <AttendanceDocumentUrlItem data={data} />
  }

  const rowDataBound = (args: RowDataBoundEventArgs) => {
    if (args.data &&
      (args.data as GetAttendanceItem).attendanceRequestCancelStatus === '3') {
      args.row?.classList.add('cancelLine');
    }
  }

  return (
    <TableContainer component={Paper} style={{ minHeight: '500px' }}>
      <GridComponent
        dataSource={new DataManager(attendanceRequestItemList)}
        allowResizing={true}
        allowPaging={true}
        allowSorting={true}
        allowFiltering={true}
        filterSettings={{ type: 'CheckBox' }}
        rowDataBound={rowDataBound}
        toolbar={["Search"]}
        pageSettings={{ pageSize: 20, pageSizes: true }}
        ref={gridRef}
      >
        <ColumnsDirective>
          <ColumnDirective field="id" isPrimaryKey={true} visible={false} />
          <ColumnDirective
            headerText='결재 문서 보기'
            textAlign='Center'
            template={documentUrlTemplate}
            width="10%" />
          <ColumnDirective
            field="workDate"
            valueAccessor={(field, data) => formatDateField(field, data, 'YYYY-MM-DD(W)')}
            headerText="사용일"
            width="15%" />
          <ColumnDirective
            field="category"
            headerText="대분류"
            width="15%" />
          <ColumnDirective
            field="formName"
            headerText="소분류"
            width="15%" />
          <ColumnDirective
            field="started"
            headerText="시작시간"
            textAlign='Center'
            valueAccessor={(field, data) => formatDateField(field, data, 'hh:mm')}
            width="10%" />
          <ColumnDirective
            field="ended"
            headerText="종료시간"
            textAlign='Center'
            valueAccessor={(field, data) => formatDateField(field, data, 'hh:mm')}
            width="10%" />
          <ColumnDirective
            field="attendanceRequestStatus"
            headerText="상태"
            textAlign='Center'
            template={statusTemplate}
            width="10%" />
          <ColumnDirective
            field="attendanceRequestCancelStatus"
            headerText="취소상태"
            textAlign='Center'
            template={cancelStatusTemplate}
            width="10%" />
        </ColumnsDirective>
        <Inject services={[Resize, Filter, Toolbar, Page, Sort, Search]} />
      </GridComponent>
    </TableContainer>
  );
}

export default AttendanceRequestList;
