import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import { EmployeeAutoComplete } from "src/system/types";
import { BalanceStatusDialogState } from "../../post-attendance-request/components/BalanceStatus";
import BalanceStatusDialog from "../../post-attendance-request/components/BalanceStatusDialog";
import {
  AnnualLeave,
  AnnualLeaveType,
} from "../../post-attendance-request/data/AnnualLeaveType";
import { GetAttendanceBalanceCount } from "../../post-attendance-request/interface/BalanceInterface";
import { useBalanceStatusStyle } from "../../post-attendance-request/style/useBalanceStatusStyle";
import { getLastBelong } from "../../post-attendance-request/hooks/getLastBelong";
import { useUserState } from "src/system/UserContext";

interface AttendanceBalanceStatusProps {
  selectedEmployee: EmployeeAutoComplete;
  attendanceBalanceStatus: GetAttendanceBalanceCount;
}
export default function AttendanceBalanceStatus({
  selectedEmployee,
  attendanceBalanceStatus,
}: AttendanceBalanceStatusProps) {
  const user = useUserState();
  const classes = useBalanceStatusStyle();
  const [balanceStatusState, setBalanceStatusState] =
    useState<BalanceStatusDialogState>();

  const changeDialogState = (type: AnnualLeaveType) => {
    if (attendanceBalanceStatus) {
      switch (type) {
        case AnnualLeave.annualLeave:
          setBalanceStatusState({
            open: true,
            totalCount: attendanceBalanceStatus.annualLeave,
            tempCount: attendanceBalanceStatus.usedAnnualLeaveTemp,
            inProgressCount: attendanceBalanceStatus.usedAnnualLeaveInProgress,
            approvedCount: attendanceBalanceStatus.usedAnnualLeaveApproved,
            text: "연차",
          });
          break;
        case AnnualLeave.alternativeLeave:
          setBalanceStatusState({
            open: true,
            totalCount: attendanceBalanceStatus.alternativeLeave,
            tempCount: attendanceBalanceStatus.usedAlternativeLeaveTemp,
            inProgressCount:
              attendanceBalanceStatus.usedAlternativeLeaveInProgress,
            approvedCount: attendanceBalanceStatus.usedAlternativeLeaveApproved,
            text: "대체휴가",
          });
          break;
        case AnnualLeave.specialLeave:
          setBalanceStatusState({
            open: true,
            totalCount: attendanceBalanceStatus.specialLeave,
            tempCount: attendanceBalanceStatus.usedSpecialLeaveTemp,
            inProgressCount: attendanceBalanceStatus.usedSpecialLeaveInProgress,
            approvedCount: attendanceBalanceStatus.usedSpecialLeaveApproved,
            text: "특별휴가",
          });
          break;
      }
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className={classes.tableCell}>
              <Grid className={classes.wrapperGrid} container>
                <Grid item xs={2} className={classes.item}>
                  {"인적사항"}
                  <Typography
                    className={classes.numberGray}
                    variant="h6"
                    style={{ fontWeight: 600 }}
                  >
                    {`${getLastBelong(
                      selectedEmployee.team,
                      selectedEmployee.department,
                      selectedEmployee.group
                    )} ${selectedEmployee.name}`}
                  </Typography>
                </Grid>
                <Grid item xs={2} className={classes.item}>
                  {user.role === "admin" ? "근속연수(연차기산일)" : "근속연수"}
                  <Typography className={classes.numberGray} variant="h6">
                    {user.role === "admin"
                      ? `${attendanceBalanceStatus.yearOfService}(${selectedEmployee.joined})`
                      : attendanceBalanceStatus.yearOfService}
                  </Typography>
                </Grid>
                <Grid item xs={1} className={classes.item}>
                  {"총계"}
                  <Typography className={classes.totalGray} variant="h6">
                    {attendanceBalanceStatus.annualLeave +
                      attendanceBalanceStatus.alternativeLeave +
                      attendanceBalanceStatus.specialLeave +
                      attendanceBalanceStatus.usedAlternativeLeaveApproved +
                      attendanceBalanceStatus.usedAlternativeLeaveInProgress +
                      attendanceBalanceStatus.usedAlternativeLeaveTemp}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={1}
                  className={classes.grid}
                  onClick={() => changeDialogState(AnnualLeave.annualLeave)}
                >
                  {"연차"}
                  <Typography className={classes.numberGray} variant="h6">
                    {attendanceBalanceStatus.annualLeave}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={1}
                  className={classes.grid}
                  onClick={() =>
                    changeDialogState(AnnualLeave.alternativeLeave)
                  }
                >
                  {"대체휴가"}
                  <Typography className={classes.numberGray} variant="h6">
                    {attendanceBalanceStatus.alternativeLeave +
                      attendanceBalanceStatus.usedAlternativeLeaveApproved +
                      attendanceBalanceStatus.usedAlternativeLeaveInProgress +
                      attendanceBalanceStatus.usedAlternativeLeaveTemp}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={1}
                  className={classes.grid}
                  onClick={() => changeDialogState(AnnualLeave.specialLeave)}
                >
                  {"특별휴가"}
                  <Typography className={classes.numberGray} variant="h6">
                    {attendanceBalanceStatus.specialLeave}
                  </Typography>
                </Grid>
                <Grid item xs={1} className={classes.item}>
                  {"총 사용계"}
                  <Typography className={classes.totalBlue} variant="h6">
                    {attendanceBalanceStatus.usedAnnualLeave +
                      attendanceBalanceStatus.usedAlternativeLeave +
                      attendanceBalanceStatus.usedSpecialLeave +
                      attendanceBalanceStatus.usedAlternativeLeaveApproved +
                      attendanceBalanceStatus.usedAlternativeLeaveInProgress +
                      attendanceBalanceStatus.usedAlternativeLeaveTemp}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={1}
                  className={classes.grid}
                  onClick={() => changeDialogState(AnnualLeave.annualLeave)}
                >
                  {"연차사용"}
                  <Typography className={classes.numberBlue} variant="h6">
                    {attendanceBalanceStatus.usedAnnualLeave}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={1}
                  className={classes.grid}
                  onClick={() =>
                    changeDialogState(AnnualLeave.alternativeLeave)
                  }
                >
                  {"대체휴가사용"}
                  <Typography className={classes.numberBlue} variant="h6">
                    {attendanceBalanceStatus.usedAlternativeLeaveApproved +
                      attendanceBalanceStatus.usedAlternativeLeaveInProgress +
                      attendanceBalanceStatus.usedAlternativeLeaveTemp}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={1}
                  className={classes.grid}
                  onClick={() => changeDialogState(AnnualLeave.specialLeave)}
                >
                  {"특별휴가사용"}
                  <Typography className={classes.numberBlue} variant="h6">
                    {attendanceBalanceStatus.usedSpecialLeave}
                  </Typography>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {balanceStatusState && (
        <BalanceStatusDialog
          dialogState={balanceStatusState}
          setDialogState={setBalanceStatusState}
        />
      )}
    </TableContainer>
  );
}
