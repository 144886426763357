import React, { ReactElement } from "react";
import { Route, Switch } from "react-router-dom";
import MainPage from "src/page/evidence/MainPage";
import PrintPage from "./PrintPage";

function Routes(): ReactElement {
  return (
    <Switch>
      <Route path="/evidence" exact>
        <MainPage></MainPage>
      </Route>
      <Route path="/evidence/print">
        <PrintPage></PrintPage>
      </Route>
    </Switch>
  );
}

export default Routes;
