import {
  Button,
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { AttendanceRequestApi, ErrorHandler } from "src/system/ApiService";
import { useUserState } from "src/system/UserContext";
import { GetAttendanceItem } from "../interface/RequestInterface";
import ErrorText from "./ErrorText";
import { useAttendanceStyles } from "../style/useAttendanceStyle";
import { AttendanceDocumentForm } from "../PostAttendanceRequest";
import AttendanceRequestItemStatus from "../../attendance-request-list/components/AttendanceRequestItemStatus";

export default function ARCancellation() {
  const classes = useAttendanceStyles();
  const user = useUserState();
  const [cancellableItem, setCancellableItem] = useState<GetAttendanceItem[]>(
    []
  );

  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<AttendanceDocumentForm>();

  useEffect(() => {
    AttendanceRequestApi.GetAttendanceItemList(2, user.employeeId)
      .then((res) => {
        setCancellableItem(res);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      });
  }, []);

  let selectedCancelIdList = watch("attendanceRequestItemCancelIdList");

  useEffect(() => {
    const selectedAttendanceItemList = cancellableItem.filter((item) =>
      selectedCancelIdList.map(Number).includes(item.id)
    );
    let stringBuilder = "";

    selectedAttendanceItemList.forEach((item, index, arr) => {
      const date = new Date(item.workDate);
      const month = date.getMonth() + 1;
      const day = date.getDate();
      if (index !== arr.length - 1) {
        stringBuilder += `${month.toString().padStart(2, "0")}/${day
          .toString()
          .padStart(2, "0")}_${item.formName}취소,`;
      } else {
        stringBuilder += `${month.toString().padStart(2, "0")}/${day
          .toString()
          .padStart(2, "0")}_${item.formName}취소`;
      }
    });
    const title = `${stringBuilder}_${user.parentTeamName}_${user.name}`;

    if (title.length > 100) {
      const result = `${stringBuilder.split(",")[0]} 등..._${
        user.parentTeamName
      }_${user.name}`;
      setValue("title", result);
    } else {
      setValue("title", title);
    }
  }, [
    selectedCancelIdList,
    setValue,
    cancellableItem,
    user.parentTeamName,
    user.name,
  ]);

  const handleClickRow = (event: any, attendanceId: number) => {
    if (event.target.type !== "checkbox") {
      const checkboxButton = document.getElementById(
        `checkbox-${attendanceId}`
      );
      if (checkboxButton) {
        checkboxButton.click();
      }
    }
  };

  const isCheckedRow = (attendanceId: number) => {
    return watch("attendanceRequestItemCancelIdList")
      .map(Number)
      .includes(attendanceId);
  };

  const renderTableRow = (attendance: GetAttendanceItem) => {
    const isCancelComplete = attendance.attendanceRequestCancelStatus === "3";
    return (
      <TableRow
        key={attendance.id}
        style={{ position: "relative" }}
        className={`${classes.tableRow} ${
          isCheckedRow(attendance.id) ? "" : "exclude"
        }`}
        onClick={(e) => handleClickRow(e, attendance.id)}
      >
        <TableCell align="center" className="exclude">
          <Grid>
            <Checkbox
              id={`checkbox-${attendance.id}`}
              className="input-checkbox"
              {...register("attendanceRequestItemCancelIdList")}
              value={attendance.id}
              disabled={!!attendance.attendanceRequestCancelStatus}
            />
          </Grid>
        </TableCell>
        <TableCell
          align="center"
          className={`${isCancelComplete ? classes.cancelPale : null}`}
        >
          {attendance.category}
        </TableCell>
        <TableCell
          align="center"
          className={`${isCancelComplete ? classes.cancelPale : null}`}
        >
          {attendance.formName}
        </TableCell>
        <TableCell
          align="center"
          className={`${isCancelComplete ? classes.cancelPale : null}`}
        >
          {attendance.workDate.substring(0, 10)}
        </TableCell>
        <TableCell
          align="center"
          className={`${isCancelComplete ? classes.cancelPale : null}`}
        >
          {`${
            attendance.started === null
              ? "-"
              : attendance.started.substring(11, 16) +
                " ~ " +
                attendance.ended.substring(11, 16)
          }`}
        </TableCell>
        <TableCell
          align="center"
          className={`${isCancelComplete ? classes.cancelPale : null}`}
        >
          {attendance.team}
        </TableCell>
        <TableCell
          align="center"
          className={`${isCancelComplete ? classes.cancelPale : null}`}
        >
          {attendance.jobPosition}
        </TableCell>
        <TableCell
          align="center"
          className={`${isCancelComplete ? classes.cancelPale : null}`}
        >
          {attendance.name}
        </TableCell>
        <TableCell
          align="center"
          className={`${isCancelComplete ? classes.cancelLine : null}`}
        >
          <AttendanceRequestItemStatus
            isCancel={true}
            status={attendance.attendanceRequestCancelStatus}
          />
        </TableCell>
      </TableRow>
    );
  };

  const checkAll = () => {
    cancellableItem.forEach((attendance) => {
      if (!attendance.attendanceRequestCancelStatus) {
        const checkboxButton = document.getElementById(
          `checkbox-${attendance.id}`
        );
        if (checkboxButton) {
          checkboxButton.click();
        }
      }
    });
  };
  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell colSpan={9} align="center">
              {"통합근태 취소"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell width={"5%"} align="center" className="exclude">
              {/* <Button
                size="small"
                variant="text"
                style={{ fontWeight: 600 }}
                onClick={checkAll}
                color="secondary"
              >
                {"모두선택"}
              </Button> */}
              <Checkbox id={`checkbox-all`} onClick={checkAll} />
            </TableCell>
            <TableCell width={"10%"} align="center">
              {"종류"}
            </TableCell>
            <TableCell width={"15%"} align="center">
              {"문서양식"}
            </TableCell>
            <TableCell width={"15%"} align="center">
              {"신청일"}
            </TableCell>
            <TableCell width={"15%"} align="center">
              {"신청시간"}
            </TableCell>
            <TableCell width={"10%"} align="center">
              {"소속"}
            </TableCell>
            <TableCell width={"10%"} align="center">
              {"직급"}
            </TableCell>
            <TableCell width={"10%"} align="center">
              {"성명"}
            </TableCell>
            <TableCell width={"10%"} align="center">
              {"취소상태"}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cancellableItem.length === 0 && (
            <TableRow className="exclude">
              <TableCell align="center" colSpan={8}>
                {"결재 완료된 통합근태가 없습니다."}
              </TableCell>
            </TableRow>
          )}
          {cancellableItem.map((attendance) => renderTableRow(attendance))}
        </TableBody>
      </Table>
      <ErrorText message={errors.attendanceRequestItemCancelIdList?.message} />
      {/* {!isViewAll && (
        <Button
          type='button'
          size="small"
          style={{ fontWeight: 600 }}
          onClick={() => setIsViewAll(true)}
          variant="contained"
          fullWidth
          className="exclude"
          color="default">
          {"전체보기"}
        </Button>
      )} */}
    </TableContainer>
  );
}
