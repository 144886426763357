import { Typography } from "@material-ui/core";
import React, { ReactElement } from "react";

interface WarnMessageProps {
  children: string;
}

function WarnMessage({ children }: WarnMessageProps): ReactElement {
  return (
    <>
      <span role="img" aria-label="Warning Sign">
        ⚠
      </span>
      <Typography variant="body2" color="error" display="inline">
        {children}
      </Typography>
    </>
  );
}

export default WarnMessage;
