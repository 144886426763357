import { Theme, Grid, Divider, TextField, FormControlLabel, Checkbox, IconButton } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { createStyles, makeStyles } from "@material-ui/styles";
import {} from "@syncfusion/ej2-grids";
import React, { ChangeEvent, ReactElement, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { EndTextField } from "src/components/EndTextField";
import RegularColgroup from "src/components/RegularColgroup";
import TableInsaCard from "src/components/TableInsaCard";
import { ConstantApi, ErrorHandler } from "src/system/ApiService";
import { EducationSchool, InitValidationES, ValidationES } from "src/system/types";
import ClearIcon from "@material-ui/icons/Clear";

interface InputEducationSchoolDataProps {
  data: EducationSchool[];
  onAddRows: (tab: string) => void;
  onRemoveRows: (tab: string, index: number) => void;
  onChangeText: (tab: string, e: ChangeEvent<HTMLInputElement>, index: number) => void;
  onChangeCheck: (tab: string, e: ChangeEvent<HTMLInputElement>, index: number) => void;
  onChangeValue: (tab: string, name: string, value: string, index: number) => void;
  onChangeAutoComplete: (
    tab: string,
    event: React.ChangeEvent<{}>,
    value: string | null,
    name: string,
    index: number
  ) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      // backgroundColor: "#eeeeee",
      width: "1000px",
      display: "inline-block",
      textAlign: "left",
    },
    sub: {
      padding: "20px",
      width: "100%",
      "& .MuiGrid-item": {
        height: "70px",
      },
    },
  })
);

function InputEducationSchoolData({
  data,
  onAddRows,
  onRemoveRows,
  onChangeText,
  onChangeCheck,
  onChangeValue,
  onChangeAutoComplete,
}: InputEducationSchoolDataProps): ReactElement {
  const classes = useStyles();
  const tab = "educationSchool";
  const [val, setVal] = useState<ValidationES>(InitValidationES);

  useMemo(() => {
    let kind: string[] = [""];
    let gs: string[] = [""];
    let ismain: string[] = [""];
    let scale: string[] = [""];

    ConstantApi.GetConstant("EDUCATIONSCHOOL", "")
      .then((res) => {
        res.result.forEach((x) => {
          if (x.columnName === "KIND") {
            kind.push(x.name);
          } else if (x.columnName === "GRADUATESTATUS") {
            gs.push(x.name);
          } else if (x.columnName === "ISMAIN") {
            ismain.push(x.name);
          } else if (x.columnName === "SCALE") {
            scale.push(x.name);
          }
        });
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      });
    setVal({ kind: kind, gs: gs, ismain: ismain, scale: scale });
  }, []);

  return (
    <div className={classes.main}>
      <TableInsaCard title="학력사항" colgroup={<RegularColgroup cols={6}></RegularColgroup>}>
        <div className={classes.sub}>
          <Grid container>
            <Grid item xs={6}>
              <Button onClick={() => onAddRows(tab)}>학력 추가</Button>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right" }}></Grid>
          </Grid>
          {data.length > 0 &&
            data.map((x, index) => {
              return (
                <>
                  <Divider style={{ margin: "15px 5px 15px 5px" }}></Divider>
                  <Grid container>
                    <Grid item xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox checked={x.isFinal} onChange={(e) => onChangeCheck(tab, e, index)} name="isFinal" />
                        }
                        label="최종학력"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Autocomplete
                        value={x.kind === null ? "" : x.kind}
                        onChange={(event, value) => {
                          if (value !== null) {
                            onChangeAutoComplete(tab, event, value, "kind", index);
                          }
                        }}
                        inputValue={x.kind}
                        onInputChange={(event, newInputValue) => {
                          if (newInputValue !== null) {
                            onChangeValue(tab, "kind", newInputValue, index);
                          }
                        }}
                        options={val.kind}
                        style={{ width: 210 }}
                        renderInput={(params) => <TextField {...params} label="학교구분" />}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <EndTextField
                        label="학교명"
                        name="name"
                        value={x.name}
                        style={{ width: "210px" }}
                        onChange={(e) => onChangeText(tab, e, index)}
                      ></EndTextField>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton aria-label="delete" color="secondary" onClick={() => onRemoveRows(tab, index)}>
                        <ClearIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={4}>
                      <Autocomplete
                        value={x.graduateStatus === null ? "" : x.graduateStatus}
                        onChange={(event, value) => {
                          if (value !== null) {
                            onChangeAutoComplete(tab, event, value, "graduateStatus", index);
                          }
                        }}
                        inputValue={x.graduateStatus}
                        onInputChange={(event, newInputValue) => {
                          if (newInputValue !== null) {
                            onChangeValue(tab, "graduateStatus", newInputValue, index);
                          }
                        }}
                        options={val.gs}
                        style={{ width: 210 }}
                        renderInput={(params) => <TextField {...params} label="졸업구분" />}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <EndTextField
                        label="입학일"
                        name="started"
                        type="date"
                        value={x.started !== null ? x.started.split("T")[0] : ""}
                        style={{ width: "210px" }}
                        onChange={(e) => onChangeText(tab, e, index)}
                        shrink={true}
                      ></EndTextField>
                    </Grid>
                    <Grid item xs={4}>
                      <EndTextField
                        label="졸업일"
                        name="ended"
                        type="date"
                        value={x.ended !== null ? x.ended.split("T")[0] : ""}
                        style={{ width: "210px" }}
                        onChange={(e) => onChangeText(tab, e, index)}
                        shrink={true}
                      ></EndTextField>
                    </Grid>
                    {x.kind !== "고등학교" && x.kind !== "중학교" ? (
                      <>
                        <Grid item xs={4}>
                          <EndTextField
                            label="주전공"
                            name="major"
                            value={x.major}
                            style={{ width: "210px" }}
                            onChange={(e) => onChangeText(tab, e, index)}
                          ></EndTextField>
                        </Grid>
                        <Grid item xs={4}>
                          <EndTextField
                            label="부전공/복수전공"
                            name="minor"
                            value={x.minor}
                            style={{ width: "210px" }}
                            onChange={(e) => onChangeText(tab, e, index)}
                          ></EndTextField>
                        </Grid>
                        <Grid item xs={2}>
                          <EndTextField
                            label="성적"
                            name="gpa"
                            value={x.gpa}
                            style={{ width: "100px" }}
                            onChange={(e) => onChangeText(tab, e, index)}
                          ></EndTextField>
                        </Grid>
                        <Grid item xs={2}>
                          <Autocomplete
                            value={x.scale === null ? "" : x.scale}
                            onChange={(event, value) => {
                              if (value !== null) {
                                onChangeAutoComplete(tab, event, value, "scale", index);
                              }
                            }}
                            inputValue={x.scale}
                            onInputChange={(event, newInputValue) => {
                              if (newInputValue !== null) {
                                onChangeValue(tab, "scale", newInputValue, index);
                              }
                            }}
                            options={val.scale}
                            style={{ width: 100 }}
                            renderInput={(params) => <TextField {...params} label="만점기준" />}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Autocomplete
                            value={x.isMain === null ? "" : x.isMain}
                            onChange={(event, value) => {
                              if (value !== null) {
                                onChangeAutoComplete(tab, event, value, "isMain", index);
                              }
                            }}
                            inputValue={x.isMain}
                            onInputChange={(event, newInputValue) => {
                              if (newInputValue !== null) {
                                onChangeValue(tab, "isMain", newInputValue, index);
                              }
                            }}
                            options={val.ismain}
                            style={{ width: 210 }}
                            renderInput={(params) => <TextField {...params} label="본교구분" />}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <EndTextField
                            label="소재지"
                            name="location"
                            value={x.location}
                            style={{ width: "100px" }}
                            onChange={(e) => onChangeText(tab, e, index)}
                          ></EndTextField>
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}
                    <Grid item xs={12}>
                      <EndTextField
                        label="비고"
                        name="remark"
                        value={x.remark}
                        style={{ width: "850px" }}
                        onChange={(e) => onChangeText(tab, e, index)}
                      ></EndTextField>
                    </Grid>
                  </Grid>
                </>
              );
            })}
        </div>
      </TableInsaCard>
    </div>
  );
}

export default InputEducationSchoolData;
