import { Button, Paper, Typography } from "@material-ui/core";
import React, { ReactElement } from "react";
import ReactDataGrid, { Column } from "react-data-grid";
import { HrStyle } from "src/Style/theme";
import { WorkRecord } from "src/system/types";
import { DefaultFormatter, IData } from "./Common";
import { ExcelUploadEnum } from "./MainPage";
import PublishIcon from "@material-ui/icons/Publish";
import { Alert, AlertTitle } from "@material-ui/lab";

interface WorkRecordDataImportProps {
  data: WorkRecordUpload[];
  errorCount: number;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  onInsertData: (type: ExcelUploadEnum) => void;
}

export interface WorkRecordUpload extends WorkRecord, IData {}

function WorkRecordDataImport({
  data,
  errorCount,
  onSubmit,
  onInsertData,
}: WorkRecordDataImportProps): ReactElement {
  const classes = HrStyle();
  const columns = React.useMemo(() => {
    const k = [
      {
        name: "NUM",
        key: "id",
      },
      {
        name: "사번",
        key: "employeeId",
      },
      {
        name: "대분류",
        key: "category1",
      },
      {
        name: "소분류",
        key: "category2",
      },
      {
        name: "시작일",
        key: "started",
      },
      {
        name: "종료일",
        key: "ended",
      },
      {
        name: "사업장명",
        key: "workplace",
      },
      {
        name: "그룹",
        key: "group",
      },
      {
        name: "파트",
        key: "department",
      },
      {
        name: "팀",
        key: "team",
      },
      {
        name: "직군",
        key: "jobGroup",
      },
      {
        name: "직무",
        key: "jobDuty",
      },
      {
        name: "직위",
        key: "jobPositionRank",
      },
      {
        name: "직급",
        key: "jobPosition",
      },
      {
        name: "직책",
        key: "jobTitle",
      },
      {
        name: "비고",
        key: "remark",
      },
    ];

    const nk: Column<WorkRecordUpload>[] = k.map((x) => {
      if (x.key === "id") return x;
      else {
        return {
          ...x,
          formatter: DefaultFormatter,
        } as Column<WorkRecordUpload>;
      }
    });

    return nk;
  }, []);

  const onClickUpload = () => onInsertData("workRecord");

  return (
    <Paper variant="outlined" className={classes.paperUpload}>
      <form onSubmit={onSubmit} name="workRecord">
        <input type="file" name="file" id="input-file" required />
        <input type="submit" />
        <input type="reset" />
      </form>
      <br />
      <Button
        onClick={onClickUpload}
        variant="contained"
        startIcon={<PublishIcon />}
      >
        데이터 업로드
      </Button>

      {errorCount > 0 && (
        <Alert severity="error" title="에러가 발생한 위치">
          <AlertTitle>에러가 발생한 위치</AlertTitle>
          <ul>
            {data
              .filter((x) => Object.keys(x.error).length >= 1)
              .map((x) => (
                <li>{x.id}</li>
              ))}
          </ul>
        </Alert>
      )}

      <div className="text-right">
        <Typography variant="body2">에러데이터 : {errorCount}건</Typography>
      </div>

      <ReactDataGrid
        rows={data}
        columns={columns}
        rowKeyGetter={(row) => {
          return row.id;
        }}
        rowClass={(row) => {
          if (Object.keys(row.error).length >= 1) {
            return "c-error-row";
          } else return undefined;
        }}
        defaultColumnOptions={{
          resizable: true,
        }}
        className={classes.reactDataGrid}
      ></ReactDataGrid>
    </Paper>
  );
}

export default WorkRecordDataImport;
