
export const getLastBelong = (team?: string, parentTeam?: string, group?: string) => {
    if (!!team && team !== "") {
        return team;
    }
    if (!!parentTeam && parentTeam !== "") {
        return parentTeam;
    }
    if (!!group && group !== "") {
      return group;
    }

    return "";
}