import { createStyles, makeStyles } from "@material-ui/core";
import React, { ReactElement } from "react";
import { Table } from "react-bootstrap";
import { getDateUsingNumber } from "src/system/Helper";
import { DetailCare } from "src/system/types";
import { MyStyle } from "src/Style/theme";

interface Props {
  data: DetailCare;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "13%",
    },
  })
);

function ViewDetailTarget({ data }: Props): ReactElement {
  const classes = useStyles();
  const myClass = MyStyle();
  const dateStr = getDateUsingNumber(data.completed);
  return (
    <Table>
      <tbody>
        <tr>
          <th>세부 완료일</th>
          <td>{dateStr === "내용 없음" ? <i>내용 없음</i> : dateStr}</td>
        </tr>
        <tr>
          <th className={classes.root}>목표</th>
          <td className={myClass.content}>{data.target}</td>
        </tr>
        <tr>
          <th className={classes.root}>내용</th>
          <td className={myClass.content}>{data.result}</td>
        </tr>
      </tbody>
    </Table>
  );
}

export default ViewDetailTarget;
