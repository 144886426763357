import { Button, Dialog, DialogContent } from "@material-ui/core";
import React, { ReactElement } from "react";
import { ComboboxItem } from "src/components/TitleOneComboBox";
import SearchIssue from "./SearchMiniIssue";

interface LinkingModalProps {
  open: boolean;
  empSelectOptions: ComboboxItem[];
  onCloseModal: () => void;
}

export default function LinkingModal({ open, empSelectOptions, onCloseModal }: LinkingModalProps): ReactElement {
  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogContent>
        <SearchIssue empSelectOptions={empSelectOptions} onCloseModal={onCloseModal}></SearchIssue>
        <Button color="secondary" variant="outlined" onClick={onCloseModal}>
          취소
        </Button>
      </DialogContent>
    </Dialog>
  );
}
