import { Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Dispatch } from 'react';
import { Table } from 'react-bootstrap';
import { AccentGray, BorderGray, BoxPale, TitleGray } from 'src/Style/globalStyle';
import { BalanceStatusDialogState, initBalanceStatusDialogState } from './BalanceStatus';

interface BalanceStatusDialogProps {
  dialogState: BalanceStatusDialogState;
  setDialogState: Dispatch<BalanceStatusDialogState>;

}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      marginBottom: 0,
      '& th': {
        padding: '3px',
      }
    },
    tableHeadLeft: {
      borderRight: `1px solid ${BorderGray}`
    },
    tableHead: {
      backgroundColor: BoxPale,
    },
    title: {
      fontWeight: 600,
      color: TitleGray,
      textAlign: 'center',
    },
    headText: {
      fontWeight: 600,
      fontSize: '15px',
    },
    bodyText: {
      fontSize: '20px',
    },
    linkText: {
      fontSize: '20px',
      cursor: 'pointer',
      '&:hover': {
        color: AccentGray,
        textDecoration: 'underline',
        textUnderlineOffset: '3px'
      }
    }
  })
);

export default function BalanceStatusDialog({ dialogState, setDialogState }: BalanceStatusDialogProps) {
  const classes = useStyles();
  const handleClose = () => setDialogState(initBalanceStatusDialogState);


  return (
    <Dialog open={dialogState.open} >
      <DialogTitle id="alert-dialog-title" style={{ paddingBottom: 5, position: 'relative' }}>
        {
          dialogState.text &&
          <Typography className={classes.title} variant="h6">
            {`${dialogState.text} 현황`}
          </Typography>
        }
        <IconButton
          onClick={handleClose}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <TableContainer component={Paper}>
            <Table className={classes.table}>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell
                    align='center'
                    width={150}
                    style={{ borderRight: `1px solid ${BorderGray}` }}>
                    <Typography className={classes.headText}>
                      {`부여`}
                    </Typography>
                  </TableCell>
                  <TableCell align='center' colSpan={3}>
                    <Typography className={classes.headText}>
                      {'사용'}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align='center'
                    width={150}
                    style={{ borderRight: `1px solid ${BorderGray}` }}>
                    <Typography className={classes.headText}>
                      {`${dialogState.text}`}
                    </Typography>
                  </TableCell>
                  <TableCell align='center' width={150}>
                    <Typography className={classes.headText}>
                      {'결재완료'}
                    </Typography>
                  </TableCell>
                  <TableCell align='center' width={150}>
                    <Typography className={classes.headText}>
                      {'결재 중'}
                    </Typography>
                  </TableCell>
                  <TableCell align='center' width={150}>
                    <Typography className={classes.headText}>
                      {'임시저장'}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody >
                <TableRow>
                  <TableCell align='center' className={classes.tableHeadLeft}>
                    <Typography className={classes.bodyText}>
                      {`${dialogState.totalCount}`}
                    </Typography>
                  </TableCell>
                  <TableCell align='center'>
                    <Typography
                      className={classes.linkText}
                      onClick={() => window.open(`${process.env.REACT_APP_GROUPWARE_URL}/document/submit-document-box`)}
                    >
                      {`${dialogState.approvedCount}`}
                    </Typography>
                  </TableCell>
                  <TableCell align='center'>
                    <Typography
                      className={classes.linkText}
                      onClick={() => window.open(`${process.env.REACT_APP_GROUPWARE_URL}/document/submit-document-box`)}
                    >
                      {`${dialogState.inProgressCount}`}
                    </Typography>
                  </TableCell>
                  <TableCell align='center'>
                    <Typography
                      className={classes.linkText}
                      onClick={() => window.open(`${process.env.REACT_APP_GROUPWARE_URL}/document/temporary-box`)}
                    >
                      {`${dialogState.tempCount}`}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
