import { makeStyles } from "@material-ui/core";
import React, { ReactElement } from "react";
import BasicUserEdit from "src/page/card/basic/BasicUserEdit";
import BasicUserView from "src/page/card/basic/BasicUserView";
import { PersonalData } from "src/system/types";

const useStyles = makeStyles((theme) => ({
  divBasic: {
    display: "flex",
    alignItems: "center",
    "& > img": {
      width: "163px",
      height: "198px",
      marginRight: theme.spacing(2),
    },
  },
}));

interface Props {
  personalData: PersonalData;
}

function BasicUser({ personalData }: Props): ReactElement {
  const classes = useStyles();
  const [state, setState] = React.useState<"edit" | "save">("save");

  function onClickEdit() {
    setState("edit");
  }
  function onClickSave() {}
  function onClickCancel() {}

  return (
    <>
      <div className={classes.divBasic}>
        {state === "save" ? (
          <BasicUserView data={personalData} onClickEdit={onClickEdit}></BasicUserView>
        ) : (
          <BasicUserEdit data={{}} onClickSave={onClickSave} onClickCancel={onClickCancel}></BasicUserEdit>
        )}
      </div>
    </>
  );
}

export default BasicUser;
