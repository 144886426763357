import { useEffect } from 'react'
import { RecoilState, useRecoilCallback } from 'recoil'

export const useResetAtomsWhenPageLeave = (atoms: RecoilState<any>[]) => {
    const resetAtoms = useRecoilCallback(({reset}) => () => {
        atoms.forEach((atom) => reset(atom));
    })

    useEffect(() => {
        return () => {resetAtoms()};
    }, []);
}

