import MaterialTable from "material-table";
import React, { ReactElement, useEffect, useState } from "react";
import { MyStyle } from "src/Style/theme";
import { AttendanceApi, ErrorHandler } from "src/system/ApiService";
import { TABLEICONS, TABLE_HEADER } from "src/system/Constants";
import { LateData } from "src/system/types";

interface MyLateExplanationProps {
  employeeNo: string;
}

function MyLateExplanation({
  employeeNo,
}: MyLateExplanationProps): ReactElement {
  const classes = MyStyle();
  const [data, setData] = useState<LateData[]>();

  useEffect(() => {
    AttendanceApi.GetLateExplanationList(employeeNo, "MY_LATE_EXPLANATION")
      .then((res) => {
        setData(res);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        console.log(msg);
      });
  }, [employeeNo]);

  return (
    <div className={classes.paperMainTitle}>
      <div>
        <MaterialTable
          icons={TABLEICONS}
          title="지각소명 신청 내역"
          data={data || []}
          columns={[
            { title: "날짜", field: "date", cellStyle: { width: "15%" } },
            {
              title: "상태",
              field: "state",
              width: 10,
              cellStyle: { width: "10%" },
            },
            { title: "사유", field: "kind", cellStyle: { width: "10%" } },
            {
              title: "소명내용",
              field: "content",
              width: 60,
              cellStyle: { width: "35%" },
            },
            {
              title: "반려사유",
              field: "reject",
              width: 20,
              cellStyle: { width: "20%" },
              render: (rowData) => {
                return rowData.reject !== "null" && rowData.reject
                  ? rowData.reject
                  : "-";
              },
            },
            {
              title: "파일",
              field: "fileName",
              width: 20,
              cellStyle: { width: "20%" },
              render: (rowData) => {
                return rowData.fileName !== "null" && rowData.fileName ? (
                  <a
                    href={`${rowData.fileName}`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    파일보기
                  </a>
                ) : (
                  "-"
                );
              },
            },
          ]}
          localization={{
            body: {
              emptyDataSourceMessage: "표시할 데이터가 없습니다.",
            },
          }}
          options={{
            search: true,
            pageSize: 10,
            padding: "dense",
            headerStyle: TABLE_HEADER,
          }}
        ></MaterialTable>
      </div>
    </div>
  );
}

export default MyLateExplanation;
