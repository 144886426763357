import React, { ReactElement } from "react";
import WarnMessage from "src/components/WarnMessage";
import { isInArray } from "src/system/Helper";
import { AuthorityCategory } from "src/system/types";

interface NoWriteAuthorityProps {
  authority: string[];
}

const NoWriteAuthority = ({ authority }: NoWriteAuthorityProps): ReactElement => {
  const couldWrite = isInArray(authority, AuthorityCategory.WRITE);

  if (couldWrite) {
    return <React.Fragment></React.Fragment>;
  } else {
    return (
      <>
        &nbsp;<WarnMessage>작성 권한이 없습니다.</WarnMessage>
      </>
    );
  }
};
export default NoWriteAuthority;
