import {
  ColumnDirective,
  ColumnsDirective,
  Edit,
  ExcelExport,
  Filter,
  GridComponent,
  Inject,
  Page,
  Resize,
  Search,
  Sort,
  Toolbar,
  ToolbarItems,
} from "@syncfusion/ej2-react-grids";
import { GetSnapshotAttendanceBalance } from '../attendance-request/post-attendance-request/interface/BalanceInterface';
import { useEffect, useRef, useState } from 'react';
import { setCulture } from '@syncfusion/ej2-base';
import { AttendanceBalanceApi, ErrorHandler } from 'src/system/ApiService';
import { ClickEventArgs } from '@syncfusion/ej2-react-navigations';
import { Button } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useLoadingDispatch } from 'src/system/LoadingContext';

export default function AttendanceDashBoardPage() {
  const [snapshotAttendanceBalanceList, setSnapshotAttendanceBalanceList] = useState<GetSnapshotAttendanceBalance[]>([]);
  const gridRef = useRef<GridComponent | null>(null);
  const LoadingDispatch = useLoadingDispatch();

  setCulture('ko-KR');

  useEffect(() => {
    AttendanceBalanceApi.GetSnapshotAttendanceBalanceList()
      .then(res => setSnapshotAttendanceBalanceList(res))
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      })
  }, []);

  const handleRefreshSnapshot = () => {
    LoadingDispatch({ type: "LOADING" });
    AttendanceBalanceApi.CreateSnapshotAttendanceBalance()
      .then(() => {
        AttendanceBalanceApi.GetSnapshotAttendanceBalanceList()
          .then(res => setSnapshotAttendanceBalanceList(res))
          .catch((err) => {
            let msg = ErrorHandler(err);
            alert(msg);
          })
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      })
      .finally(() => {
        LoadingDispatch({ type: "COMPLETE" });
      })

  }

  const get_yyyyMMdd_Format = (field: string, data: any) => {
    const date = new Date(data[field]);
    const yyyy = date.getFullYear();
    const MM = String(date.getMonth() + 1).padStart(2, '0');
    const dd = String(date.getDate()).padStart(2, '0');

    return `${yyyy}.${MM}.${dd}`;
  }

  // Toolbar Click 이벤트
  const toolbarClick = (args: ClickEventArgs | undefined) => {
    if (args !== undefined && args.item !== undefined) {
      if (args.item.id?.includes("excel") && gridRef.current !== null) {
        gridRef.current.excelExport();
      }
    }
  };

  const toolbarOptions: ToolbarItems[] = [
    "Search",
    "ExcelExport"
  ];

  return (
    <div>
      <Button
        onClick={handleRefreshSnapshot}
        style={{ marginBottom: '15px' }}
        color="primary"
        variant="contained"
        startIcon={<RefreshIcon />}
      >
        {'연차 현황 갱신'}
      </Button>
      <GridComponent
        dataSource={snapshotAttendanceBalanceList}
        allowResizing={true}
        allowPaging={true}
        allowSorting={true}
        allowExcelExport={true}
        allowFiltering={true}
        filterSettings={{ type: 'Excel' }}
        toolbar={toolbarOptions}
        toolbarClick={toolbarClick}
        pageSettings={{ pageSize: 20, pageSizes: true }}
        ref={gridRef}
      >
        <ColumnsDirective>
          <ColumnDirective field="id" headerText="id" width="0" isPrimaryKey={true} visible={false} />
          <ColumnDirective
            headerTextAlign='Center'
            textAlign='Center'
            field="employeeId"
            headerText="사번"
            width="7%" />
          <ColumnDirective
            headerTextAlign='Center'
            field="name"
            headerText="이름"
            width="7%" />
          <ColumnDirective
            headerTextAlign='Center'
            field="parentTeam"
            headerText="파트"
            width="8%" />
          <ColumnDirective
            headerTextAlign='Center'
            headerText="팀"
            field='team'
            width="8%" />
          <ColumnDirective
            headerTextAlign='Center'
            field="jobPosition"
            headerText="직위"
            visible={false}
            width="8%" />
          <ColumnDirective
            headerTextAlign='Center'
            field="jobTitle"
            headerText="직책"
            width="8%" />
          <ColumnDirective
            headerTextAlign='Center'
            field="annualLeave"
            headerText="연차"
            width="8%" />
          <ColumnDirective
            headerTextAlign='Center'
            field="alternativeLeave"
            headerText="대체휴가"
            width="9%" />
          <ColumnDirective
            headerTextAlign='Center'
            field="specialLeave"
            headerText="특별휴가"
            width="9%" />
          <ColumnDirective
            headerTextAlign='Center'
            field="usedAnnualLeave"
            headerText="연차사용"
            width="9%" />
          <ColumnDirective
            headerTextAlign='Center'
            field="usedSpecialLeave"
            headerText="특별휴가사용"
            width="10%" />
          <ColumnDirective
            headerTextAlign='Center'
            textAlign='Left'
            headerText="연차기산일"
            field='joined'
            valueAccessor={get_yyyyMMdd_Format}
            width="10%" />
        </ColumnsDirective>
        <Inject services={[Resize, Edit, Toolbar, Page, Sort, Search, ExcelExport, Filter]} />
      </GridComponent>
    </div>
  );
}
