import { makeStyles } from '@material-ui/core';

export const useAttendanceBalanceStyles = makeStyles(() => ({
  datePicker: {
    width: '100%',
    padding: '0 4px ',
    minHeight: '38px',
    margin: 0,
    fontSize: '13px',
    border: 0,
  },
  datePickerWrapper: {
    position: 'relative',
    border: '1px solid #cccccc',
    borderRadius: 2,
  },
  label: {
    fontSize: '12px',
    top: '-7px',
    left: '10px',
    transform: 'translate3d(0, 0, 0) scale(1)',
    position: 'absolute',
    backgroundColor: '#ffffff',
    zIndex: 1,
  }
}));