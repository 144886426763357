import React, { ReactElement, useEffect, useState } from "react";
import TableInsaCard from "src/components/TableInsaCard";
import { ErrorHandler, HREmployeeApi } from "src/system/ApiService";
import { EducationSchool } from "src/system/types";
import { getDisplayTime } from "src/system/Helper";
import RegularColgroup from "src/components/RegularColgroup";

interface EducationSchoolTableProps {
  employeeId: string;
}

function EducationSchoolTable({ employeeId }: EducationSchoolTableProps): ReactElement {
  const [data, setData] = useState<EducationSchool[]>([]);

  useEffect(() => {
    HREmployeeApi.GetEducationSchoolList(employeeId)
      .then((res) => {
        setData(res);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      });
  }, [employeeId]);

  return (
    <TableInsaCard title="학력사항" colgroup={<RegularColgroup cols={8}></RegularColgroup>}>
      <tr>
        <th>구분</th>
        <th colSpan={2}>정보</th>
        <th>
          전공<br></br>/ 부전공,복수전공
        </th>
        <th>캠퍼스(위치)</th>
        <th>성적(만점)</th>
        <th colSpan={2}>비고</th>
      </tr>
      {data.map((x, index) => {
        return (
          <>
            <tr>
              <td rowSpan={2}>
                {x.kind}
                <br></br>
                {x.isFinal ? "(최종학력)" : ""}
              </td>
              <td>{x.name}</td>
              <td>{x.graduateStatus}</td>
              {x.kind === "고등학교" ? (
                <>
                  <td rowSpan={2}></td>
                  <td rowSpan={2}></td>
                  <td rowSpan={2}></td>
                </>
              ) : (
                <>
                  <td rowSpan={2}>
                    {x.major}
                    <br></br>
                    {x.minor !== "" ? `/ ${x.minor}` : ""}
                  </td>
                  <td rowSpan={2}>
                    {x.isMain}
                    {x.location !== "" ? `(${x.location})` : ""}
                  </td>
                  <td rowSpan={2}>
                    {x.gpa}
                    {x.scale !== "" ? `(${x.scale})` : ""}
                  </td>
                </>
              )}

              <td colSpan={2} rowSpan={2}>
                {x.remark}
              </td>
            </tr>
            <tr>
              <td colSpan={2}>{`${getDisplayTime(x.started)} ~ ${getDisplayTime(x.ended)}`}</td>
            </tr>
          </>
        );
      })}
    </TableInsaCard>
  );
}

export default EducationSchoolTable;
