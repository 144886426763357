import { Typography } from '@material-ui/core';

interface ErrorTextProps {
  message: string | undefined
}
export default function ErrorText({ message }: ErrorTextProps) {
  return (
    <>
      {message &&
        <Typography
          className='form-error remove-column'
          variant='body2'
          style={{
            color: 'red',
            fontWeight: 'bold',
            textAlign: 'center'
          }}
        >
          {message}
        </Typography>
      }
    </>
  );
}
