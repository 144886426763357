import React, { ReactElement, useState } from "react";
import { InterviewApi } from "src/system/ApiService";
import { formatBytes } from "src/system/Helper";
import fileDownload from "js-file-download";
import { Button } from "@material-ui/core";
import { FileStatus } from "src/system/types/type";
import { FileInfo } from "src/system/types";
import { useInterviewDispatch } from "src/system/InterviewContext";

interface FileViewerProps {
  file: FileInfo | null;
  page: "view" | "write";
  onChange?: (file: File | null) => void;
}

export const FileViewer = ({ file, page, onChange }: FileViewerProps): ReactElement => {
  const [fileStatus, setFileStatus] = useState<FileStatus>(file === null ? "Empty" : "Exists");

  const dispatch = useInterviewDispatch();

  const onDownload = () => {
    //파일 다운로드
    if (file) {
      //let k = mime.getExtension(file.originName);
      let contentType: string = "application/octet-stream";

      //if (k !== null) contentType = k;

      InterviewApi.downloadFile(file.id, contentType)
        .then((response) => {
          fileDownload(response.data, file.originName);
        })
        .catch(() => {
          alert("다운로드 중 문제가 발생하였습니다.");
        });
    } else {
      alert("다운로드 받을 파일이 없습니다.");
    }
  };

  const onDelete = () => {
    if (window.confirm("파일이 삭제됩니다. 정말 지우시겠습니까?")) {
      dispatch({ type: "REMOVE-FILE" });
      setFileStatus("Empty");
      alert("저장을 누르시면, 최종 삭제됩니다.");
    }
  };

  const onSubmitFileForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange !== undefined) {
      if (event.target.files) {
        onChange(event.target.files[0]);
      } else {
        onChange(null);
      }
    }
  };

  if (fileStatus === "Exists" && file) {
    return (
      <React.Fragment>
        {file.originName} &nbsp; {formatBytes(file.size)} &nbsp; <Button onClick={onDownload}>다운로드</Button>
        {/* 파일 삭제가 있어야 한다. */}
        {/* write에서만 가능하다. */}
        {page === "write" && <Button onClick={onDelete}>파일 삭제</Button>}
      </React.Fragment>
    );
  } else {
    return (
      <form method={"post"} onSubmit={onSubmitFileForm} id="file-form">
        <input type="file" onChange={onChangeFile}></input>
      </form>
    );
  }
};
