import React, { ReactElement, useEffect, useState } from "react";
import { Alert, AlertTitle, Autocomplete } from "@material-ui/lab";
import WritePage from "src/page/manage-hr/employeeAdd/WritePage";
import { Grid, TextField, Theme, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import AccordionCustom from "src/components/AccordionCustom";
import {
  Applicant,
  Career,
  Certificate,
  EducationSchool,
  InitRecruitPersonalData,
  InitRecruitment,
  Language,
  Recruitment,
  InitApplicant,
} from "src/system/types";
import { ErrorHandler, RecruitApi } from "src/system/ApiService";
import { getDisplayTime } from "src/system/Helper";
import DataGridApplicant from "./DataGridApplicant";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      textAlign: "center",
    },
    contents: {
      display: "inline-block",
      width: "1200px",
    },
    title: {
      marginTop: "20px",
      marginBottom: "20px",
    },
  })
);

function MainPage(): ReactElement {
  const classes = useStyles();
  const [recruit, setRecruit] = useState<Recruitment>(InitRecruitment);
  const [recruitList, setRecruitList] = useState<Recruitment[]>([]);
  const [applicant, setApplicant] = useState<Applicant>(InitApplicant);
  const [applicantList, setApplicantList] = useState<Applicant[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);

  const [data, setData] = useState({
    recruit: InitRecruitment,
    personalData: InitRecruitPersonalData,
    educationSchool: new Array<EducationSchool>(),
    career: new Array<Career>(),
    certificate: new Array<Certificate>(),
    language: new Array<Language>(),
  });

  useEffect(() => {
    RecruitApi.GetRecruitmentList()
      .then((res) => {
        setRecruitList(res);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      });
  }, [refresh]);

  useEffect(() => {
    if (recruit.rcode !== "") {
      // 합격자 리스트 조회
      RecruitApi.GetApplicantList(recruit.rcode)
        .then((res) => {
          let list = res;
          // 등록한 합격자 리스트 조회
          RecruitApi.GetApplicantEnrollmentList(recruit.rcode)
            .then((enrollmentList) => {
              list.forEach((x, index) => {
                let enrollment = enrollmentList.find((y) => y.ano === x.ano)?.enrollment ? true : false;
                list[index] = { ...x, enrollment: enrollment };
              });
              setApplicantList(list);
              setData({
                ...data,
                personalData: InitRecruitPersonalData,
              });
            })
            .catch((error) => {
              let msg = ErrorHandler(error);
              alert(msg);
            });
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          alert(msg);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recruit.rcode, refresh]);

  const loadApplicant = (applicant: Applicant): void => {
    setApplicant(applicant);
    // 개인정보
    RecruitApi.GetApplicantPersonalData(applicant)
      .then((personalData) => {
        for (let data in personalData) {
          if (personalData[data] === null) {
            personalData[data] = "";
          }
        }
        // 학력사항
        RecruitApi.GetApplicantEducationSchool(applicant)
          .then((educationSchool) => {
            // 경력사항
            RecruitApi.GetApplicantCareer(applicant)
              .then((career) => {
                // 자격사항
                RecruitApi.GetApplicantCertificate(applicant)
                  .then((certificate) => {
                    // 외국어
                    RecruitApi.GetApplicantLanguage(applicant)
                      .then((language) => {
                        setData({
                          ...data,
                          personalData: personalData,
                          educationSchool: educationSchool,
                          career: career,
                          certificate: certificate,
                          language: language,
                        });
                      })
                      .catch((error) => {
                        let msg = ErrorHandler(error);
                        alert(msg);
                      });
                  })
                  .catch((error) => {
                    let msg = ErrorHandler(error);
                    alert(msg);
                  });
              })
              .catch((error) => {
                let msg = ErrorHandler(error);
                alert(msg);
              });
          })
          .catch((error) => {
            let msg = ErrorHandler(error);
            alert(msg);
          });
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      });
  };

  const onChangeSelect = (event: React.ChangeEvent<{}>, value: Recruitment | null) => {
    if (value !== null) {
      setRecruit(value);
    }
  };

  return (
    <div>
      <Alert severity="info">
        <AlertTitle>도움말</AlertTitle>
        <ul>
          <li>채용사이트 정보를 불러오거나 직접 정보를 입력하여 사원을 추가할 수 있습니다.</li>
        </ul>
      </Alert>
      <div className={classes.wrapper}>
        <div className={classes.contents}>
          {/* 제목 */}
          <Typography className={classes.title} align="center" variant="h4">
            사원 등록
          </Typography>
          {/* 채용DB 불러오기 */}
          <AccordionCustom title="채용 정보 불러오기">
            <Grid container>
              {/* 채용공고 검색 */}
              {recruitList !== null && recruitList !== undefined && recruitList.length > 0 && (
                <Grid item xs={12}>
                  <Autocomplete
                    options={recruitList}
                    onChange={onChangeSelect}
                    getOptionLabel={(option) => option.subject}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.subject}
                        <span style={{ marginLeft: "10px", fontSize: "14px", color: "#888888" }}>{`${getDisplayTime(
                          option.start_date.toString()
                        )} ~ ${getDisplayTime(option.end_date.toString())}`}</span>
                      </React.Fragment>
                    )}
                    renderInput={(params) => <TextField {...params} label="채용공고 검색" variant="outlined" />}
                    style={{ minWidth: "1000px" }}
                  ></Autocomplete>
                </Grid>
              )}
              {/* 채용공고별 합격자 리스트 */}
              <Grid item xs={12}>
                <br></br>
                <DataGridApplicant data={applicantList} loadApplicant={loadApplicant}></DataGridApplicant>
              </Grid>
            </Grid>
          </AccordionCustom>
          {/* 입력 폼 */}
          <br></br>
          <WritePage data={data} applicant={applicant} refresh={refresh} setRefresh={setRefresh}></WritePage>
        </div>
      </div>
    </div>
  );
}

export default MainPage;
