import React, { ChangeEvent, ReactElement, useState } from "react";
import { Button, createStyles, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import "./LoginTemplate.css";
import { useUserDispatch } from "src/system/UserContext";
import { useHistory } from "react-router-dom";
import { InitUser } from "src/system/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    top: {
      width: "100%",
      marginTop: theme.spacing(2),
    },
    bottom: {
      width: "100%",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    button: {
      width: "100%",
      padding: theme.spacing(1.5),
      fontSize: "1.0rem",
    },
  })
);

function LoginPage(): ReactElement {
  const dispatch = useUserDispatch();

  const [state, setState] = useState({ userId: "", userPw: "" });
  const history = useHistory();

  const onSubmit = React.useCallback(
    //임시
    function () {
      dispatch({
        type: "LOGIN",
        item: {
          ...InitUser,
          authority: ["LOGIN"],
        },
      });
      history.push("/");
    },
    [dispatch, history]

    //원본
    // function () {
    //   //send axios

    //   UserApi.Login(state.userId, state.userPw)
    //     .then((response) => {
    //       const { user, token } = response.data;
    //       UserApi.SetToken(token);
    //       dispatch({ type: "LOGIN", item: user });
    //       history.push("/");
    //       //history.push(InterviewRecordRoutes.root + "?page=1&type=all");
    //     })
    //     .catch((error: AxiosError) => {
    //       let msg = ErrorHandler(error);
    //       if (error.response) {
    //         switch (error.response.status) {
    //           case 403: //forbidden
    //           case 404:
    //             msg = "로그인 정보가 틀리거나 회원정보가 없습니다.";
    //             break;
    //         }
    //       }
    //       alert(msg);
    //     });
    // },
    // [dispatch, history, state.userId, state.userPw]
  );

  const classes = useStyles();

  const onChangeText = function (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const onKeyDownEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      onSubmit();
    }
  };

  return (
    <div className="wrapper-login-div">
      <div className="login-div">
        <img src="/images/main_logo.png" width="200px" alt="InBody"></img>
        <hr />
        {/* Login */}
        <div>
          <Typography variant="h6">인사만사</Typography>

          <TextField
            id="userId"
            label="아이디"
            name="userId"
            type="text"
            variant="outlined"
            value={state.userId}
            className={classes.top}
            onChange={onChangeText}
            onKeyDown={onKeyDownEnter}
          ></TextField>
          <TextField
            id="userPw"
            name="userPw"
            label="비밀번호"
            type="password"
            variant="outlined"
            value={state.userPw}
            className={classes.bottom}
            onChange={onChangeText}
            onKeyDown={onKeyDownEnter}
          ></TextField>
          <Button
            name="button"
            variant="contained"
            color="secondary"
            type="submit"
            className={classes.button}
            onClick={onSubmit}
          >
            로그인
          </Button>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
