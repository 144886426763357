export class SearchResult<T> {
  public list: T[];
  public page: number;
  public count: number;
  public totalPage: number;

  constructor() {
    this.list = [];
    this.page = 0;
    this.count = 0;
    this.totalPage = 0;
  }
}
