import React, { ReactElement, useEffect, useState } from "react";
import KeyboardDatePicker from "src/components/KeyboardDatePicker";
import MultilineTextField from "src/components/MultilineTextField";
import TitleMultipleComboBox from "src/components/TitleMultipleComboBox";
import { ComboboxItem, TitleOneComboBox } from "src/components/TitleOneComboBox";
import SelectCombobox from "src/components/SelectCombobox";
import { useInterviewDispatch } from "src/system/InterviewContext";
import { AutocompleteChangeDetails, AutocompleteChangeReason } from "@material-ui/lab";
import { FileInfo, initEmployee, Interview } from "src/system/types";
import { InterviewCategoryApi } from "src/system/ApiService";
import { GetComboboxList, getDateUsingNumber } from "src/system/Helper";
import { MyStyle } from "src/Style/theme";
import { useUserState } from "src/system/UserContext";
import TableWrapper from "src/components/common/TableWrapper";
import { FileViewer } from "src/ViewParts/FileViewer";
import NoWriteAuthority from "src/components/NoWriteAuthority";

interface WriteInterviewProps {
  empSelectOptions: ComboboxItem[];
  data: Interview;
  file: FileInfo | null;
  setFile: (file: File | null) => void;
  // onChange: (type: string, name: string, value: any) => void;
}

function WriteInterview2({ empSelectOptions, file, data: interview, setFile }: WriteInterviewProps): ReactElement {
  const dispatch = useInterviewDispatch();
  const [categoryOption, setCategoryOption] = useState<ComboboxItem[]>([]);

  const classes = MyStyle();

  const user = useUserState();
  const accessReaders: ComboboxItem[] = GetComboboxList(empSelectOptions, interview.accessReader);

  useEffect(() => {
    InterviewCategoryApi.GetAll()
      .then((response) => {
        let k = response.data.map((x) => ({
          key: `${x.id}`,
          value: x.comment,
        }));
        setCategoryOption(k);
      })
      .catch((error) => {});
  }, []);

  const onChangeSelect = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { name, value } = event.target;
    if (name !== undefined) {
      let obj = { ...interview, [name]: value };
      dispatch({ type: "CHANGE-INTERVIEW", item: obj });
    }
  };

  const onChangeText = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    let k = { ...interview, [name]: value };
    dispatch({ type: "CHANGE-INTERVIEW", item: k });
  };

  const onChangeDate = (date: Date | null) => {
    if (date) {
      let k: Interview = { ...interview, occurred: date.getTime() };
      dispatch({ type: "CHANGE-INTERVIEW", item: k });
    }
  };

  const onChangeCombobox = (
    type: string,
    event: React.ChangeEvent<{}>,
    value: ComboboxItem | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<ComboboxItem> | undefined
  ) => {
    if (reason === "select-option") {
      //insert
      if (value) {
        let k = { ...interview };
        switch (type) {
          case "interviewee":
            k.interviewee = {
              employeeId: value.key,
              name: value.value,
              team: k.interviewee.team,
            };
            break;
          case "interviewer":
            k.interviewer = {
              employeeId: value.key,
              name: value.value,
              team: k.interviewer.team,
            };
            break;
          default:
            throw new Error("Not Implemented");
        }
        dispatch({ type: "CHANGE-INTERVIEW", item: k });
      }
    } else if (reason === "clear") {
      let k = { ...interview };
      switch (type) {
        case "interviewee":
          k.interviewee = initEmployee;
          break;
        case "interviewer":
          k.interviewer = initEmployee;
          break;
        default:
          throw new Error("Not Implemented");
      }
      dispatch({ type: "CHANGE-INTERVIEW", item: k });
    }
  };

  const ChangeInterviewee = (
    event: React.ChangeEvent<{}>,
    value: ComboboxItem | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<ComboboxItem> | undefined
  ) => {
    onChangeCombobox("interviewee", event, value, reason, details);
  };

  const ChangeInterviewer = (
    event: React.ChangeEvent<{}>,
    value: ComboboxItem | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<ComboboxItem> | undefined
  ) => {
    onChangeCombobox("interviewer", event, value, reason, details);
  };

  const ChangeAccessReader = (
    event: React.ChangeEvent<{}>,
    value: ComboboxItem[] | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<ComboboxItem> | undefined
  ) => {
    let k = { ...interview };
    if (value) {
      k.accessReader = value.map((x) => ({
        employeeId: x.key,
        name: x.value,
        team: "",
      }));

      dispatch({ type: "CHANGE-INTERVIEW", item: k });
    }
  };

  return (
    <TableWrapper>
      <tr className={classes.RemoveThBorder}>
        <th>작성일</th>
        <th>작성자</th>
        <th>카테고리</th>
        <th>피면담자</th>
        <th>면담자</th>
        <th>면담일</th>
      </tr>
      <tr>
        <td>{getDateUsingNumber(interview.created)}</td>
        <td>{interview.writer.name}</td>
        <td>
          <SelectCombobox
            key="write-interview-categoryId"
            name="categoryId"
            value={interview.categoryId}
            options={categoryOption}
            onChange={onChangeSelect}
          ></SelectCombobox>
        </td>
        <td style={{ width: "18%" }}>
          <TitleOneComboBox
            options={empSelectOptions}
            onChange={ChangeInterviewee}
            userSelected={{
              key: interview.interviewee.employeeId,
              value: interview.interviewee.name,
            }}
          ></TitleOneComboBox>
        </td>
        <td>
          <TitleOneComboBox
            options={empSelectOptions}
            onChange={ChangeInterviewer}
            userSelected={{
              key: interview.interviewer.employeeId,
              value: interview.interviewer.name,
            }}
          ></TitleOneComboBox>
        </td>
        <td style={{ width: "160px" }}>
          <KeyboardDatePicker
            value={null}
            onChange={onChangeDate}
            selecteddate={new Date(interview.occurred)}
          ></KeyboardDatePicker>
        </td>
      </tr>
      <tr>
        <th>열람제한</th>
        <td colSpan={2}>
          <SelectCombobox
            key="write-interview-accessModifier"
            name="accessModifierId"
            value={interview.accessModifierId}
            options={[
              { key: "1", value: "작성자" },
              { key: "2", value: "작성자 및 인사파트장" },
              { key: "3", value: "파트장" },
            ]}
            onChange={onChangeSelect}
          ></SelectCombobox>
        </td>
        <th>추가열람가능자</th>
        <td colSpan={2}>
          <TitleMultipleComboBox
            userSelected={accessReaders}
            onChange={ChangeAccessReader}
            options={empSelectOptions}
          ></TitleMultipleComboBox>
        </td>
      </tr>
      <tr>
        <th colSpan={6} style={{ borderBottom: "0px" }}>
          면담 내용
          <NoWriteAuthority authority={user.authority}></NoWriteAuthority>
        </th>
      </tr>
      <tr style={{ border: "0px" }}>
        <td colSpan={6} style={{ borderTop: "0px", paddingTop: "0px" }}>
          <MultilineTextField
            style={{ marginTop: "10px" }}
            name="content"
            placeholder="면담 내용을 작성해주세요"
            value={interview.content}
            onChange={onChangeText}
          ></MultilineTextField>
        </td>
      </tr>
      <tr>
        <th>파일</th>
        <td colSpan={5}>
          <FileViewer onChange={setFile} file={file} page="write" />
        </td>
      </tr>
    </TableWrapper>
  );
}

const WriteInterview = React.memo(WriteInterview2);
export default WriteInterview;
