import React, { ReactNode } from "react";
import { MyStyle } from "src/Style/theme";

interface ContentProps {
  children: ReactNode;
  open: boolean;
}

function Content({ children, open }: ContentProps) {
  const classes = MyStyle();

  return (
    <div className={open ? classes.contentWrapper : classes.contentShift}>
      <div className={classes.contentBody}>{children}</div>
    </div>
  );
}

export default Content;
