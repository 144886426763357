import React, { PureComponent } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker as BaseDatePicker, DatePickerProps, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ko } from "date-fns/locale";

export default class DatePicker extends PureComponent<DatePickerProps> {
  render() {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ko}>
        <BaseDatePicker {...this.props} okLabel="확인" cancelLabel="취소" todayLabel="오늘" />
      </MuiPickersUtilsProvider>
    );
  }
}
