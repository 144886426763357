import { Avatar, Chip, Tooltip, Typography } from "@material-ui/core";
import React, { ReactElement, useEffect } from "react";
import { Attendance } from "src/system/types";

interface ChipCalendarProps {
  label: string;
  data?: Attendance;
}

function ChipCalendar({ label, data }: ChipCalendarProps): ReactElement {
  useEffect(() => {}, [label]);

  console.log(data);

  if (data === undefined || data === null) {
    if (label === "무단결근") {
      return (
        <Chip
          size="small"
          label="무단결근"
          style={{
            fontSize: "11px",
            fontWeight: "bold",
            marginLeft: "10px",
            boxShadow: "#999999 1px 1px 1px",
            backgroundColor: "yellow",
          }}
        ></Chip>
      );
    } else if (label === "야근식대") {
      return (
        <Chip
          size="small"
          label="야근식대"
          style={{
            fontSize: "11px",
            fontWeight: "bold",
            marginLeft: "10px",
            backgroundColor: "deepskyblue",
            color: "white",
            boxShadow: "#999999 1px 1px 1px",
          }}
        ></Chip>
      );
    } else {
      return <></>;
    }
  } else {
    if (data.gwStatus === "승인" && data.gwCancelStatus !== 3) {
      return (
        <Tooltip
          title={
            <div>
              <Typography variant="subtitle1" color="inherit">
                그룹웨어 통합근태신청서 ({data.gwStatus})
              </Typography>
              <Typography variant="body1" color="inherit">
                {data.category}
              </Typography>
              {data.gwStart && (
                <Typography
                  variant="body1"
                  color="inherit"
                >{`${data.gwStart} ~ ${data.gwEnd}`}</Typography>
              )}
            </div>
          }
        >
          <Chip
            size="small"
            avatar={<Avatar>GW</Avatar>}
            label={
              data.category.length > 10
                ? data.category.substring(0, 8) + "..."
                : data.category
            }
            clickable
            color="primary"
            style={{
              fontSize: "10px",
              fontWeight: "bold",
              marginLeft: "10px",
              boxShadow: "#999999 1px 1px 1px",
            }}
          />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip
          title={
            <div>
              <Typography variant="subtitle1" color="inherit">
                그룹웨어 통합근태신청서 ({data.gwStatus})
              </Typography>
              <Typography variant="body1" color="inherit">
                {data.category}
              </Typography>
              {data.gwStart && (
                <Typography
                  variant="body1"
                  color="inherit"
                >{`${data.gwStart} ~ ${data.gwEnd}`}</Typography>
              )}
            </div>
          }
        >
          <Chip
            size="small"
            avatar={<Avatar>GW</Avatar>}
            label={
              data.category.length > 10
                ? data.category.substring(0, 8) + "..."
                : data.category
            }
            clickable
            style={{
              fontSize: "10px",
              fontWeight: "bold",
              marginLeft: "10px",
              boxShadow: "#999999 1px 1px 1px",
              backgroundColor: "lightgray",
            }}
          />
        </Tooltip>
      );
    }
  }
}

export default ChipCalendar;
