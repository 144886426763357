import { createStyles, IconButton, makeStyles, Paper } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { ReactElement } from "react";
import { Table } from "react-bootstrap";
import KeyboardDatePicker from "src/components/KeyboardDatePicker";
import MultilineTextField from "src/components/MultilineTextField";
import { getDateUsingDate } from "src/system/Helper";
import { useInterviewDispatch } from "src/system/InterviewContext";
import { DetailCare } from "src/system/types";

interface WriteDetailTargetProps {
  //callbackDelete?: (event: MouseEvent<HTMLSpanElement>) => void;
  //onDelete?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  parentId: number;
  state: DetailCare;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      textAlign: "right",
    },
  })
);

function _WriteDetailTarget({ state, parentId }: WriteDetailTargetProps): ReactElement {
  const classes = useStyles();
  const dispatch = useInterviewDispatch();

  const onDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    dispatch({ type: "REMOVE-DETAILCARE", parentId: parentId, id: state.id });
  };

  const ChangeCompleted = (date: Date | null) => {
    if (date) {
      let k = { ...state, completed: date.getTime() };
      dispatch({ type: "CHANGE-DETAILCARE", item: k, id: state.id, parentId: parentId });
    }
  };

  const textfieldChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    let k = { ...state, [name]: value };

    dispatch({ type: "CHANGE-DETAILCARE", item: k, id: state.id, parentId: parentId });
  };

  return (
    <Paper variant="outlined">
      <div className={classes.root}>
        <IconButton aria-label="delete" onClick={onDelete} data-id={state.id}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
      <Table>
        <tr>
          <th>세부 완료일</th>
          <td>
            <KeyboardDatePicker
              value={null}
              onChange={ChangeCompleted}
              selecteddate={state.completed}
            ></KeyboardDatePicker>
          </td>
          <th>작성일</th>
          <td>{getDateUsingDate(new Date())}</td>
        </tr>
        <tr>
          <td colSpan={4}>
            <MultilineTextField
              value={state.target}
              onChange={textfieldChange}
              name={"target"}
              rows={1}
              title="세부관리 목표"
            ></MultilineTextField>
          </td>
        </tr>
        <tr>
          <td colSpan={4}>
            <MultilineTextField
              value={state.result}
              onChange={textfieldChange}
              name={"result"}
              rows={1}
              title="세부관리 내용"
            ></MultilineTextField>
          </td>
        </tr>
      </Table>
    </Paper>
  );
}
const WriteDetailTarget = React.memo(_WriteDetailTarget);
export default WriteDetailTarget;
