import {
  createMuiTheme,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { koKR } from "@material-ui/core/locale";
import { BackgroundColor2Gray } from "./globalStyle";

const INBODY_DARKGREY = "#404B50";
const LAYOUT_TEST = "ghostwhite";
const fontFamilyNoto =
  "Noto Sans KR, Malgun Gothic, -apple-system, Segoe UI, Roboto";
const fontFamilyTitle =
  "Noto Sans KR, Malgun Gothic, -apple-system, Segoe UI, Roboto";
export const PRIMARY = "#971B2F";
export const SECONDARY = "#5B6770";

export const theme = createMuiTheme(
  {
    typography: {
      fontFamily: fontFamilyNoto,
      h1: {
        fontFamily: fontFamilyTitle,
      },
      h2: {
        fontFamily: fontFamilyTitle,
      },
      h3: {
        fontFamily: fontFamilyTitle,
      },
      h4: {
        fontFamily: fontFamilyTitle,
      },
      h5: {
        fontFamily: fontFamilyTitle,
      },
      h6: {
        fontFamily: fontFamilyTitle,
      },

      button: {
        fontFamily: "맑은 고딕,-apple-system",
      },
    },
    palette: {
      primary: {
        light: "#666f73",
        main: "#404b50",
        dark: "#2c3438",
        contrastText: "#fff",
      },
      secondary: {
        light: "#a1494e",
        main: "#8a1c22",
        dark: "#601317",
        contrastText: "#fff",
      },
    },
  },
  koKR
);

export const MyStyle = makeStyles((theme: Theme) =>
  createStyles({
    tableHover: {
      "& tbody": {
        "& tr": {
          "&.header": {
            "&:hover": {
              //.tableHover, tbody, tr, .header:hover{}
              background: "#efefef",
              cursor: "pointer",
            },
          },
        },
      },
    },
    bodyWrap: {
      minHeight: "100vh",
      position: "relative",
      paddingBottom: "80px",
      width: "1720px",
    },
    // content
    contentWrapper: {
      marginTop: "80px",
      // display: "flex",
      justifyContent: "center",
      flexGrow: 1,
      padding: theme.spacing(1),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: "200ms",
      }),
      marginLeft: 240,
    },
    contentShift: {
      marginTop: "80px",
      // display: "flex",
      justifyContent: "center",
      flexGrow: 1,
      padding: theme.spacing(2),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: "200ms",
      }),
      marginLeft: 0,
    },
    contentBody: {
      width: "1880px",
      "@media (max-width: 1680px)": {
        width: "1640px",
      },
      "@media (max-width: 1440px)": {
        width: "1400px",
      },
      "@media (max-width: 1380px)": {
        width: "1340px",
      },
      "@media (max-width: 1280px)": {
        width: "1240px",
      },
    },
    footer: {
      height: "80px",
      width: "100%",
      padding: "10px",
      position: "absolute",
      marginTop: theme.spacing(3),
      textAlign: "center",
      zIndex: 0,
    },
    content: {
      whiteSpace: "pre-wrap",
    },
    RemoveThBorder: {
      "& th": {
        borderTop: "0px",
      },
    },
    MaterialTable: {
      "& td": {
        padding: theme.spacing(1),
        textAlign: "center",
      },
      "& th": {
        textAlign: "center",
        padding: theme.spacing(1.5),
      },
    },
    textRight: {
      textAlign: "right",
    },
    textLeft: {
      textAlign: "left",
    },
    textCenter: {
      textAlign: "center",
    },
    Blur: {
      filter: "blur(0.9px)",
    },
    DatepickerWithTextField: {
      "& label": {
        top: "-10px",
      },
      "& div MuiInputBase-root": {
        marginTop: "10px",
      },
    },
    mainNavbar: {
      backgroundColor: INBODY_DARKGREY,
    },
    grey: {
      color: "grey",
    },
    colorWhite: {
      color: "white",
    },
    mainNavbarGrid: {
      width: "330px",
    },
    userProfileBottom: {
      padding: "0px",
      width: "240px",
      display: "flex",
      alignItems: "stretch",
      "& button": {
        width: "50%",
      },
    },
    userProfile: {
      color: "white",
      "& div": {
        padding: "0px",
      },
      "& *::after": {
        color: "white",
      },
    },
    divMainPage: {
      textAlign: "center",
    },
    paperMain: {
      position: "relative",
      display: "inline-block",
      margin: "15px;",
      padding: "10px",
      overflow: "hidden",
      backgroundColor: LAYOUT_TEST,
    },
    paperMainWhite: {
      position: "relative",
      display: "inline-block",
      margin: "15px;",
      padding: "10px",
    },
    paperMainLinkList: {
      display: "inline-block",
      margin: "10px",
      padding: "10px",
      overflow: "hidden",
      backgroundColor: LAYOUT_TEST,
      "& .MuiPaper-root:hover": {
        cursor: "pointer",
        boxShadow: "gray 3px 3px 3px",
      },
    },
    paperLinkBox: {
      float: "left",
      width: "150px",
      height: "150px",
      borderRadius: "20px",
      backgroundColor: "#f1f1f1",
      margin: "15px",
      textAlign: "center",
      "& div:hover": {
        boxShadow: "gray 0px 0px 0px",
      },
    },
    paperMainTitle: {
      width: "100%",
      textAlign: "left",
      "& input": {
        textAlign: "center",
      },
    },
    divLinkIcon: {
      "& *": {
        width: "80px",
        height: "80px",
        marginTop: "15px",
        marginBottom: "15px",
      },
    },
    spanLeave: {
      marginLeft: "20px",
    },
    marginAuto: {
      "& *+*": {
        margin: "auto",
      },
    },
    jumbotron: {
      backgroundColor: "#e2e2e2",
      borderRadius: ".3rem",
      padding: "4rem 2rem",
      margin: "15px;",
    },
    link: {
      "& *:hover": {
        cursor: "pointer",
        backgroundColor: "#8a1c22",
        color: "white",
      },
    },
    tableAnswer: {
      borderCollapse: "collapse",
      fontFamily: fontFamilyNoto,
      border: "1px solid #aaa",
      width: "100%",
      "& th, td": {
        padding: theme.spacing(1),
        border: "1px solid #aaa",
      },
      "& th.minimum": {
        fontSize: "0.9rem",
      },
      "& th": {
        textAlign: "center",
        fontFamily: fontFamilyTitle,
        backgroundColor: "linen",
      },
      "& caption": {
        padding: "0",
        textAlign: "right",
        captionSide: "top",
        fontSize: "0.88rem",
      },
    },
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      border: "1px solid #dadde9",
    },
    // 인쇄용 테이블
    printTable: {
      width: "100%",
      fontSize: "18px",
      "& tr th": {
        height: "50px",
        border: "1px solid black",
        backgroundColor: "#cddae5",
        textAlign: "center",
        fontWeight: "bold",
      },
      "& tr td": {
        paddingLeft: "10px",
        height: "50px",
        border: "1px solid black",
      },
    },
  })
);

export const HrStyle = makeStyles((theme: Theme) =>
  createStyles({
    reactDataGrid: {
      "--row-width": "1618px !important",
    },
    divSearchBar: {
      // backgroundColor: "ghostwhite",
      width: "100%",
      display: "flex",
      alignItems: "stretch",
      padding: theme.spacing(1),
      "& > *": {
        marginLeft: theme.spacing(1),
      },
      "& .MuiInputBase-root": {
        backgroundColor: "white",
      },
    },
    scroll: {
      height: "100px",
      overflow: "scroll",
    },
    table: {
      backgroundColor: "white",
      borderCollapse: "collapse",
      fontFamily: fontFamilyNoto,
      border: "1px solid #aaa",
      width: "100%",
      "& th, td": {
        padding: theme.spacing(1),
        border: "1px solid #aaa",
      },
      "& th.minimum": {
        fontSize: "0.9rem",
      },
      "& th": {
        textAlign: "center",
        fontFamily: fontFamilyTitle,
        backgroundColor: BackgroundColor2Gray,
      },
      "& caption": {
        padding: "0",
        textAlign: "right",
        captionSide: "bottom",
        fontSize: "0.88rem",
      },
    },
    paperUpload: {
      padding: "15px",
      marginTop: "20px",
    },
  })
);
