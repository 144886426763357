import React, { Fragment, MouseEvent, ReactElement, useCallback, useEffect } from "react";
import SmallTextField from "src/components/SmallTextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { Paper, makeStyles, Theme } from "@material-ui/core";
import { Button } from "react-bootstrap";
import { AftercareApi, ErrorHandler } from "src/system/ApiService";
import { AftercareSearchItem } from "src/system/types";
import MaterialTable from "material-table";
import { TABLEICONS } from "src/system/Constants";
import { getDateUsingNumber } from "src/system/Helper";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
}));

function DetailcareList(): ReactElement {
  const classes = useStyles();
  const [state, setState] = React.useState({
    name: "",
    type: "all",
  });
  const [data, setData] = React.useState<AftercareSearchItem[]>([]);

  const SearchData = useCallback(() => {
    AftercareApi.SearchList(state)
      .then(({ data }) => {
        setData(data);
      })
      .catch((error) => {
        ErrorHandler(error);
      });
  }, [state]);

  useEffect(() => {
    SearchData();
  }, [SearchData]);

  const onChangeValue = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setState({
      ...state,
      [event.target.name]: (event.target as HTMLInputElement).value,
    });
  };

  const onSubmit = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    SearchData();
  };

  return (
    <Fragment>
      <Paper variant="outlined" className={classes.root}>
        <SmallTextField
          onChange={onChangeValue}
          name={"name"}
          placeholder={"책임자 검색"}
          value={state.name}
        ></SmallTextField>
        <br />
        <br />
        <FormControl component="fieldset">
          <FormLabel component="legend">완료 여부</FormLabel>
          <RadioGroup row aria-label="completed" name="type" value={state.type} onChange={onChangeValue}>
            <FormControlLabel value="all" control={<Radio />} label="모두" />
            <FormControlLabel value="completed" control={<Radio />} label="완료" />
            <FormControlLabel value="halfCompleted" control={<Radio />} label="일부 완료" />
            <FormControlLabel value="uncompleted" control={<Radio />} label="미완료" />
          </RadioGroup>
        </FormControl>
        <Button onClick={onSubmit}>조회</Button>
      </Paper>
      <br />
      <MaterialTable
        components={{
          Container: (props) => <Paper variant="outlined"> {props.children}</Paper>,
        }}
        icons={TABLEICONS}
        data={data}
        columns={[
          { title: "책임자", field: "name" },
          {
            title: "완료예상일",
            field: "estimated",
            render: (data) => {
              return <span>{getDateUsingNumber(data.estimated)}</span>;
            },
          },
          {
            title: "내용",
            field: "content",
            render: (data) => {
              //todo. span chage to link for linking interview detail page
              return <Link to={`../../interview/${data.interviewId}`}>{data.content}</Link>;
            },
          },
          {
            title: "완료일",
            field: "completed",
            render: (data) => {
              return <span>{getDateUsingNumber(data.completed)}</span>;
            },
          },
          {
            title: "완료/전체",
            render: (data) => {
              return (
                <span>
                  {data.dcCompleted}/{data.dcAll}
                </span>
              );
            },
          },
        ]}
        localization={{
          body: {
            emptyDataSourceMessage: "표시할 데이터가 없습니다.",
          },
        }}
        options={{
          showTitle: false,
          search: false,
          toolbar: false,
        }}
      ></MaterialTable>
    </Fragment>
  );
}

export default DetailcareList;
