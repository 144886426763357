export enum CommonRoutes {
  root = "/",
  logout = "/logout",
  login = "/login",
}
export enum InterviewRecordRoutes {
  root = "/alarm", //AftercareList
  interview = "/interview",
  issue = "/issue",
  manage = "/manage",
}
export enum HRRecordRoutes {
  card = "/card", // 인사카드
  department = "/manage-hr/department",
  employee = "/manage-hr/employee",
  category = "/manage-hr/category",
  constant = "/manage-hr/constant",
  employeeAdd = "/manage-hr/employeeAdd",
  employeeDelete = "/manage-hr/employeeDelete",
  teamsInfo = "/manage-hr/teamsInfo",
  evidence = "/manage-hr/evidence",
}

export enum QNARoutes {
  root = "/qna",
  view = "/qna/view",
  edit = "/qna/edit",
}

export enum AttendanceRoutes {
  root = "/attendance",
  admin = "/attendance/admin",
}

export enum TodayAttendanceRoutes {
  root = "/today-attendance",
}

export enum OrganizationRoutes {
  root = "/organization",
}

export enum EvidenceRoutes {
  root = "/evidence",
  print = "/evidence/print",
}

export enum TestRoutes {
  root = "/test",
}

export type FileStatus = "Exists" | "Deleted" | "Empty" | "Added";

export type ObjType = { [key: string]: any };

export type InputElement =
  | HTMLInputElement
  | HTMLSelectElement
  | HTMLTextAreaElement;
