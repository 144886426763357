import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { useFormContext } from 'react-hook-form';
import { useAttendanceStyles } from '../style/useAttendanceStyle';
import { memo } from 'react';
import { AttendanceDocumentForm } from '../PostAttendanceRequest';

const Content = memo(function Content() {
  const classes = useAttendanceStyles();
  const { register, watch, setValue } = useFormContext<AttendanceDocumentForm>();
  let textareaObj: any;

  const onInput = () => {
    textareaObj.respectiveElement.style.height = "auto";
    textareaObj.respectiveElement.style.height = (textareaObj.respectiveElement.scrollHeight) + "px";
  }

  const onCreated = () => {
    textareaObj.addAttributes({ rows: 3 });
    textareaObj.respectiveElement.style.height = "auto";
    textareaObj.respectiveElement.style.height = (textareaObj.respectiveElement.scrollHeight) + "px";
  }
  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table className={classes.table} size='small'>
        <TableBody>
          <TableRow>
            <TableCell component={'th'} align='center' width={'15%'} >
              {"사유"}
            </TableCell>
            <TableCell align='center' width={'85%'}>
              <TextBoxComponent
                cssClass='e-outline'
                multiline={true}
                input={onInput}
                created={onCreated}
                value={watch('reason')}
                change={e => setValue('reason', e.value)}
                ref={scope => { textareaObj = scope }}
              />
            </TableCell>
          </TableRow>
          <TableRow >
            <TableCell component={'th'} align='center'>
              {"비고(비상연락망)"}
            </TableCell>
            <TableCell align='center'>
              <TextBoxComponent
                cssClass='e-outline'
                {...register('call')}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
})

export default Content;