import { Button, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { DataManager } from '@syncfusion/ej2-data';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { useEffect, useState } from 'react';
import { UseFieldArrayAppend, useFormContext } from 'react-hook-form';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { AttendanceDocumentForm } from '../PostAttendanceRequest';
import { AnnualLeave } from '../data/AnnualLeaveType';
import { VehicleTypeList } from '../data/VehicleType';
import { useAttendanceAppendHooks } from '../hooks/useAttendanceAppendHooks';
import { useAttendanceHooks } from '../hooks/useAttendanceHooks';
import { GetAttendanceForm } from '../interface/RequestInterface';
import { attendanceAppendAtom } from '../recoils/AttendanceAppendAtom';
import { employeeChangeModalAtom, initEmployeeChangeModalState } from '../recoils/EmployeeChangeModalAtom';
import { useAttendanceStyles } from '../style/useAttendanceStyle';

interface AMAttendanceAppendProps {
  formList: GetAttendanceForm[];
  append: UseFieldArrayAppend<AttendanceDocumentForm, "attendanceItem">;
}

export default function AttendanceAppend({ formList, append }: AMAttendanceAppendProps) {
  const classes = useAttendanceStyles();
  // target Employee의 현재 선택한 휴가의 사용 가능 연차 수 / 만료일
  const [availableBalanceCount, setAvailableBalanceCount] = useState('-');
  const [formListData, setFormListData] = useState<GetAttendanceForm[]>(formList);

  const { watch, setValue } = useFormContext<AttendanceDocumentForm>();

  const [attendanceAppendItem, setAttendanceAppendItem] = useRecoilState(attendanceAppendAtom);
  const setEmployeeChangeModalState = useSetRecoilState(employeeChangeModalAtom);

  // Function hooks
  const {
    useSetDefaultStateData,
    changeSelectedForm,
    handleStartDateChange,
    handleEndDateChange,
    handleStartTimeChange,
    handleEndTimeChange,
    handleAppendButtonClick,
  } = useAttendanceAppendHooks();

  const { getAttendanceBalanceCountData } = useAttendanceHooks();

  useSetDefaultStateData();

  useEffect(() => {
    if (watch('attendanceItem').length > 0 && watch('category')) {
      const filteredFormListData = formList
        .filter(form => form.category === watch('category'));
      setFormListData(filteredFormListData);
    } else {
      setFormListData(formList)
    }
  }, [watch('attendanceItem'), formList, watch('category')])

  useEffect(() => {
    if (attendanceAppendItem.employeeId) {
      getAttendanceBalanceCountData(attendanceAppendItem.employeeId)
        .then((data) => {
          if (attendanceAppendItem.selectedForm && attendanceAppendItem.selectedForm.leaveType) {
            let unUsedBalanceCount = 0;
            let expiredDate = new Date('2100-01-01');

            switch (attendanceAppendItem.selectedForm.leaveType) {
              case AnnualLeave.annualLeave:
                unUsedBalanceCount = data.annualLeave - data.usedAnnualLeave;
                expiredDate = new Date(data.annualLeaveExpired ?? '2100-01-01');
                break;
              case AnnualLeave.alternativeLeave:
                unUsedBalanceCount = data.alternativeLeave - data.usedAlternativeLeave;
                expiredDate = new Date(data.alternativeLeaveExpired ?? '2100-01-01');
                break;
              case AnnualLeave.specialLeave:
                unUsedBalanceCount = data.specialLeave - data.usedSpecialLeave;
                expiredDate = new Date(data.specialLeaveExpired ?? '2100-01-01');
                break;
            }

            setAvailableBalanceCount(`${unUsedBalanceCount}`);

            // 초과 사용은 만료일 100년
            if (attendanceAppendItem.selectedForm.name.includes('초과')) {
              setAttendanceAppendItem(pre => ({
                ...pre,
                expiredAt: new Date('2100-01-01'),
              }));
            } else {
              setAttendanceAppendItem(pre => ({
                ...pre,
                expiredAt: expiredDate,
              }));
            }
            //연차 아니면 초기화
          } else {
            setAvailableBalanceCount('-');
            setAttendanceAppendItem(pre => ({
              ...pre,
              expiredAt: new Date('2100-01-01'),
            }));
          }
        })
    }
  }, [attendanceAppendItem.employeeId, attendanceAppendItem.selectedForm])

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table className={classes.table} size='small'>
        <TableHead>
          <TableRow>
            <TableCell width={'10%'} align='center'>
              {"구분"}
            </TableCell>
            <TableCell width={'15%'} align='center'>
              {"양식종류"}
            </TableCell>
            <TableCell width={'10%'} align='center'>
              {"소속"}
            </TableCell>
            <TableCell width={'15%'} align='center'>
              {"대상자"}
            </TableCell>
            <TableCell width={'15%'} align='center'>
              {"시작일"}
            </TableCell>
            <TableCell width={'15%'} align='center'>
              {"종료일"}
            </TableCell>
            <TableCell width={'10%'} align='center'>
              {"차량선택"}
            </TableCell>
            <TableCell width={'5%'} align='center'>
              {"잔여휴가"}
            </TableCell>
            <TableCell width={'5%'} align='center'>
              {"반영"}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align='center'>
              <TextBoxComponent
                id='category-input'
                style={{ textAlign: 'center' }}
                cssClass='e-outline'
                value={watch(`category`)}
                placeholder='자동 입력됩니다.'
                enabled={false} />
            </TableCell>
            <TableCell align='center'>
              {
                formListData.length > 0 &&
                <DropDownListComponent
                  id='form-drop-down-list'
                  cssClass='e-outline'
                  dataSource={new DataManager(formListData)}
                  fields={{
                    text: 'name',
                    value: 'id',
                  }}
                  placeholder='선택해주세요.'
                  value={attendanceAppendItem.formId}
                  change={(args) => changeSelectedForm(args, watch, setValue)}
                />
              }
            </TableCell>
            {/** TODO 현재 teamName, parentName인데  */}
            <TableCell align='center'>
              <TextBoxComponent
                id='employee-department-input'
                cssClass='e-outline'
                value={attendanceAppendItem.employeeDepartment}
                style={{ textAlign: 'center' }}
                enabled={false} />
            </TableCell>
            <TableCell align='center'>
              <Grid
                container
                alignItems='center'
                wrap='nowrap'
                justify='space-evenly'
                spacing={1}>
                <Grid item >
                  <TextBoxComponent
                    id='employee-name-input'
                    cssClass='e-outline'
                    value={attendanceAppendItem.employeeName}
                    style={{ textAlign: 'center' }}
                    enabled={false} />
                </Grid>
                <Grid item>
                  <IconButton
                    style={{ marginBottom: 4, fontSize: '26px', padding: '6px' }}
                    aria-label="delete"
                    type='button'
                    className={classes.button}
                    onClick={() => {
                      setEmployeeChangeModalState({
                        ...initEmployeeChangeModalState,
                        isItem: false,
                        isOpen: true,
                      })
                    }}
                  >
                    <AccountTreeOutlinedIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </TableCell>
            <TableCell align='center'>
              <KeyboardDatePicker
                id='start-date-input'
                label="시작일"
                disableToolbar
                fullWidth
                variant="dialog"
                format="yyyy-MM-dd (EE)"
                margin="normal"
                className={classes.datePicker}
                value={attendanceAppendItem.startDate ? new Date(attendanceAppendItem.startDate) : undefined}
                onChange={handleStartDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                maxDate={attendanceAppendItem.expiredAt}
              />
              <TextField
                id='start-time-input'
                label="시작시간"
                type="time"
                variant='outlined'
                fullWidth
                className={classes.timePicker}
                onChange={handleStartTimeChange}
                value={attendanceAppendItem.startTime || ''}
                disabled={(attendanceAppendItem.selectedForm && !attendanceAppendItem.selectedForm.isTimeEntered)}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 600,
                }}
              />
            </TableCell>
            <TableCell align='center'>
              <KeyboardDatePicker
                id='end-date-input'
                label="종료일"
                disableToolbar
                fullWidth
                variant="dialog"
                format="yyyy-MM-dd (EE)"
                margin="normal"
                className={classes.datePicker}
                value={attendanceAppendItem.endDate ? new Date(attendanceAppendItem.endDate) : undefined}
                onChange={handleEndDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                maxDate={attendanceAppendItem.expiredAt}
              />
              <TextField
                id='end-time-input'
                label="종료시간"
                variant='outlined'
                type="time"
                fullWidth
                value={attendanceAppendItem.endTime || ''}
                className={classes.timePicker}
                onChange={handleEndTimeChange}
                disabled={attendanceAppendItem.selectedForm &&
                  (!attendanceAppendItem.selectedForm.isTimeEntered
                    || (attendanceAppendItem.selectedForm.term === -0.25))
                }
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 600,
                }}
              />
            </TableCell>
            <TableCell align='center'>
              <DropDownListComponent
                id='vehicle-select'
                cssClass='e-outline'
                dataSource={VehicleTypeList}
                placeholder='선택해주세요.'
                value={attendanceAppendItem.vehicle}
                style={{ textAlign: 'center' }}
                change={(e) => setAttendanceAppendItem(pre => ({ ...pre, vehicle: e.value }))}
                enabled={attendanceAppendItem.selectedForm?.isCheckOwnVehicle}
              />
            </TableCell>
            <TableCell align='center'>
              {availableBalanceCount}
            </TableCell>
            <TableCell align='center'>
              <Button
                variant='contained'
                type='button'
                onClick={() => handleAppendButtonClick(setValue, watch, append)}>
                {"반영"}
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table >
    </TableContainer >
  );
}