import { Dialog, DialogContent, makeStyles, Grid, Typography, Table, DialogTitle, TextField } from "@material-ui/core";
import React, { ReactElement, useEffect, useState } from "react";
import ButtonBlue from "./ButtonBlue";
import CloseIcon from "@material-ui/icons/Close";
import { LateData, ObjType } from "src/system/types";
import { AttendanceApi, ErrorHandler } from "src/system/ApiService";
import { useUserState } from "src/system/UserContext";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
}));

interface RejectModalProps {
  open: boolean;
  late: LateData;
  onLoad: () => void;
  onClose: () => void;
}

function RejectModal({ open, late, onLoad, onClose }: RejectModalProps): ReactElement {
  const user = useUserState();
  const classes = useStyles();
  const [data, setData] = useState<LateData>(late);
  useEffect(() => {
    setData(late);
  }, [late]);

  const onclick = () => {
    const formData = new FormData();
    const obj: ObjType = { ...data, state: "반려", name: data.employeeName, approverId: user.employeeId };
    for (const key of Object.keys(data)) {
      const value = obj[key];
      if (value === undefined) continue;
      else {
        formData.append(key, value);
      }
    }
    AttendanceApi.UploadLateExplanation(formData)
      .then(() => {
        alert("반려되었습니다.");
        onClose();
        onLoad();
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      });
  };

  const onChangeReject = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setData({
      ...data,
      reject: value,
    });
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle style={{ backgroundColor: "#1769aa", color: "white" }}>
        <Typography style={{ fontWeight: "bold" }}>지각소명 신청</Typography>
        <CloseIcon className={classes.closeButton} onClick={onClose} />
      </DialogTitle>
      <Grid spacing={1}>
        <DialogContent>
          <div style={{ fontWeight: "bold" }}>반려 의견 작성</div>
          <br />
          <Table className="text-center">
            <thead style={{ backgroundColor: "#616161", color: "white" }}>
              <tr>
                <th>반려 의견</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <TextField onChange={onChangeReject} multiline style={{ width: "80%" }} />
                </td>
              </tr>
            </tbody>
          </Table>
          <br />
          <div className="text-center">
            <ButtonBlue onClick={onclick}>확인</ButtonBlue>
          </div>
        </DialogContent>
      </Grid>
    </Dialog>
  );
}
export default RejectModal;
