import React, { ReactElement } from "react";
import {
  KeyboardDatePicker as BaseKeyboardDatePicker,
  KeyboardDatePickerProps as BaseKeyboardDatePickerProps,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import nextId from "react-id-generator";
import { ko } from "date-fns/locale";

interface KeyboardDatePickerProps extends BaseKeyboardDatePickerProps {
  id?: string;
  label?: string;
  style?: React.CSSProperties | undefined;
  selecteddate?: Date | number;
  value: null; //not-used
  className?: string;
}

function KeyboardDatePicker({ id, selecteddate, ...others }: KeyboardDatePickerProps): ReactElement {
  //id 값이 있으면 해당 id 값 그대로 쓰고, 아니면 만들어주기
  const _id = id && id.length > 0 ? id : `date-picker-${nextId()}`;
  let _date = null;
  if (typeof selecteddate === "number") {
    _date = new Date(selecteddate);
  } else if (selecteddate instanceof Date) {
    _date = selecteddate;
  }
  //else is undefined;

  //others, value 위치 변경
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ko}>
      <BaseKeyboardDatePicker
        {...others}
        id={_id}
        value={_date}
        size="small"
        margin="normal"
        invalidDateMessage="잘못된 날짜 형식입니다."
        disableToolbar
        variant="inline"
        format="yyyy/MM/dd"
        KeyboardButtonProps={{
          "aria-label": "날짜 수정",
        }}
      ></BaseKeyboardDatePicker>
    </MuiPickersUtilsProvider>
  );
}

export default KeyboardDatePicker;
