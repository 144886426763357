import React, { ReactElement } from "react";
import { Paper, Theme, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { createStyles, makeStyles } from "@material-ui/styles";

const MyStyle = makeStyles((theme: Theme) =>
  createStyles({
    divLinkIcon: {
      "& *": {
        width: "60px",
        height: "60px",
        marginTop: "15px",
        marginBottom: "15px",
      },
    },
  })
);

interface IconLinkBoxProps {
  className?: string;
  title: string;
  icon: React.ReactNode;
  path?: string;
}

function IconLinkBox({ className, icon, title, path }: IconLinkBoxProps): ReactElement {
  const classes = MyStyle();

  const onClickLink = () => {
    alert("준비중입니다.");
  };

  return (
    <>
      {path ? (
        path.includes("http") ? (
          <Link className="text-decoration-none" to={{ pathname: path }} target="_blank">
            <Paper className={className}>
              <div className={classes.divLinkIcon}>{icon}</div>
              <Typography variant="button">{title}</Typography>
            </Paper>
          </Link>
        ) : (
          <Link className="text-decoration-none" to={path}>
            <Paper className={className}>
              <div className={classes.divLinkIcon}>{icon}</div>
              <Typography variant="button">{title}</Typography>
            </Paper>
          </Link>
        )
      ) : (
        <Paper className={className} onClick={onClickLink}>
          <div className={classes.divLinkIcon}>{icon}</div>
          <Typography variant="button">{title}</Typography>
        </Paper>
      )}
    </>
  );
}

export default IconLinkBox;
