import React, { ReactElement, useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import Page from "src/components/common/Page";
import { EmployeeApi, InterviewApi } from "src/system/ApiService";
import WriteInterview from "src/ViewParts/WriteInterview";
import { ComboboxItem } from "src/components/TitleOneComboBox";
import WriteAftercare from "src/ViewParts/WriteAftercare";
import { useInterviewState } from "src/system/InterviewContext";
import { InterviewRecordRoutes } from "src/system/types/type";
import { useHistory } from "react-router-dom";
import SideBar from "src/components/common/SideBar";
import ProgressModal from "src/components/ProgressModal";
import CheckIcon from "@material-ui/icons/Check";
import { green, red } from "@material-ui/core/colors";
import {
  ProgressStatus,
  PROGRESS_NAME_DELETE_RESERVED_EMAIL,
  PROGRESS_NAME_RESERVE_EMAIL,
  PROGRESS_NAME_SAVE_CONTENT,
  PROGRESS_NAME_UPLOAD_FILE,
} from "src/system/types";
import RemoveIcon from "@material-ui/icons/Remove";
import { WriteIssueWrapper } from "src/ViewParts/Issue/WriteIssue";
import { confirmIssueData } from "../issue/WritePage";

interface ProgressItem {
  status: ProgressStatus;
  name: string;
}

interface ModalInfo {
  isOpen: boolean;
  onProgressBar?: boolean;
}
const initModalInfo: ModalInfo = { isOpen: false };
const initProgressArr: ProgressItem[] = [{ status: ProgressStatus.DOING, name: PROGRESS_NAME_SAVE_CONTENT }];

function ProgressItemViewer({ status, name }: ProgressItem) {
  if (status === ProgressStatus.DOING) {
    return (
      <>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{name} 중...
        <br />
      </>
    );
  } else if (status === ProgressStatus.SUCCESS) {
    return (
      <>
        <CheckIcon fontSize="small" style={{ color: green[500] }} /> {name} 완료
        <br />
      </>
    );
  } else {
    // (status === ProgressStatus.FAIL) {
    return (
      <>
        <RemoveIcon fontSize="small" style={{ color: red[500] }} /> {name} 실패
        <br />
      </>
    );
  }
}

function WritePage(): ReactElement {
  const history = useHistory();
  const state = useInterviewState();
  const [empSelectOptions, setEmpSelectOptions] = useState<ComboboxItem[]>([]);
  const [uploadFile, setUploadFile] = useState<File | null>(null);

  //---------- for Modal ----------
  const [modalInfo, setModalInfo] = useState<ModalInfo>(initModalInfo);
  //[내용 저장 여부, 이메일 예약 삭제, 이메일 예약 여부]
  const [progressArr, setProgressArr] = useState<ProgressItem[]>(initProgressArr);

  //---------- end for Modal ----------

  //직원 목록 불러오기
  useEffect(() => {
    EmployeeApi.getEmployees()
      .then(({ data }) => {
        let ret: ComboboxItem[] = [];
        data.forEach((x) => {
          ret.push({
            key: x.employeeId,
            value: `${x.name}(${x.team})`,
          });
        });
        setEmpSelectOptions(ret);
      })
      .catch(() => alert("직원 데이터를 불러오는데 실패했습니다."));
  }, []);

  const setFile = React.useCallback((file: File | null) => {
    setUploadFile(file);
  }, []);

  //data confirm 을 위한 함수
  const confirmSaveData = function (): boolean {
    let msg = [];
    const { interview, aftercares, issue } = state;
    if (interview.categoryId === 0) {
      msg.push("카테고리");
    }
    if (interview.interviewee.employeeId === "") {
      msg.push("피면담자");
    }
    if (interview.interviewer.employeeId === "") {
      msg.push("면담자");
    }

    if (aftercares.length >= 1) {
      aftercares.forEach((x, i) => {
        let k = [];
        if (x.taskMaster.employeeId === "") {
          k.push(`책임자`);
        }
        if (x.content === "") {
          k.push(`내용`);
        }

        if (x.detailcares.length >= 1) {
          x.detailcares.forEach((y) => {
            if (y.target === "") {
              k.push("세부관리(목표)");
            }
          });
        }
        if (k.length >= 1) {
          msg.push(`사후관리 ${i + 1}번째(${k.join(",")})`);
        }
      });
    }

    if (issue !== null) {
      msg.concat(confirmIssueData(issue));
    }

    if (msg.length >= 1) {
      let error = msg.join(",") + " 의 내용을 채워주세요.";
      alert(error);
    } else {
      //위의 내용도 통과 했다면,
      if (uploadFile && uploadFile.size > 500 * 1024 * 1024) {
        alert("파일은 500MB이하로 등록 가능합니다.");
        return false;
      }
    }

    return msg.length === 0;
  };

  const moveToListPage = function () {
    history.push({
      pathname: InterviewRecordRoutes.interview + "/list",
      search: "?page=1",
    });
  };

  // warning 숨기기
  // const onModalClose = function () {
  //   //모달 초기화
  //   setModalInfo(initModalInfo);
  //   //progresssArr 내용 초기화
  //   setProgressArr(initProgressArr);
  //   //다시 저장을 눌렀을 때, 기존 값이 보존되어 있기 때문임
  // };

  const onSave = async function () {
    //저장할 때 사용자 정보를 불러와야 한다.
    let moveList = false;
    if (confirmSaveData() && window.confirm("저장하시겠습니까?\n모든 변경내역이 저장됩니다.")) {
      setModalInfo({ ...modalInfo, isOpen: true }); //modalOpen

      InterviewApi.postInterview({ ...state })
        .then((response) => {
          let nextProgressArr = progressArr.map((x) =>
            x.name === PROGRESS_NAME_SAVE_CONTENT ? { ...x, status: ProgressStatus.SUCCESS } : x
          );
          let interviewId = response.data.interview.id;
          if (response.data.isSuccess) {
            nextProgressArr.concat({
              name: PROGRESS_NAME_DELETE_RESERVED_EMAIL,
              status: response.data.isSuccess ? ProgressStatus.SUCCESS : ProgressStatus.FAIL,
            });
          }

          if (state.aftercares.length >= 1) {
            //이메일 예약 등록 중...
            setProgressArr(nextProgressArr.concat({ name: PROGRESS_NAME_RESERVE_EMAIL, status: ProgressStatus.DOING }));
            InterviewApi.reserveEmail(response.data.interview.id)
              .then(() => {
                setProgressArr(
                  progressArr.map((x) =>
                    x.name === PROGRESS_NAME_RESERVE_EMAIL ? { ...x, status: ProgressStatus.SUCCESS } : x
                  )
                );
              })
              .catch(() => {
                setProgressArr(
                  progressArr.map((x) =>
                    x.name === PROGRESS_NAME_RESERVE_EMAIL ? { ...x, status: ProgressStatus.FAIL } : x
                  )
                );
                alert(
                  "이메일 예약에 실패하였습니다. 수정, 저장을 다시하면 이메일을 예약할 수 있습니다.\n계속해서 문제 발생 시, 관리자에게 연락주세요."
                );
              });
          } else {
            setProgressArr(nextProgressArr);
          }

          //파일 전송 필요
          if (uploadFile) {
            setProgressArr(
              progressArr.concat({
                name: PROGRESS_NAME_UPLOAD_FILE,
                status: ProgressStatus.DOING,
              })
            );

            let formData = new FormData();
            formData.append("file", uploadFile);

            InterviewApi.uploadFile(interviewId, formData)
              .then(() => {
                setProgressArr(
                  progressArr.map((x) =>
                    x.name === PROGRESS_NAME_UPLOAD_FILE ? { ...x, status: ProgressStatus.SUCCESS } : x
                  )
                );
              })
              .catch(() => {
                setProgressArr(
                  progressArr.map((x) =>
                    x.name === PROGRESS_NAME_UPLOAD_FILE ? { ...x, status: ProgressStatus.FAIL } : x
                  )
                );
                alert("파일 저장에 실패하였습니다.");
                setModalInfo({ ...initModalInfo, isOpen: false });
              })
              .finally(() => {
                moveToListPage();
              });
          } else {
            moveList = true;
          }
        })
        .catch((error) => {
          alert("데이터 저장에 실패하였습니다.");
          setModalInfo({ ...initModalInfo, isOpen: false });
        })
        .finally(() => {
          if (moveList) moveToListPage();
        });
    }
  };

  // const onDelete = function () {
  //   if (state.interview.id === 0) {
  //     alert("잘못된 접근입니다.");
  //   } else if (window.confirm("삭제하시겠습니까?")) {
  //     InterviewApi.deleteInterview(state.interview.id)
  //       .then(() => {
  //         alert("삭제되었습니다.");
  //         moveToListPage();
  //       })
  //       .catch((error) => {
  //         if (error.response && error.response.status === 404) {
  //           alert("잘못된 접근입니다.");
  //         } else {
  //           alert("에러가 발생하였습니다. 재로그인 후 다시 시도해주세요.");
  //         }
  //       });
  //   }
  // };

  return (
    <Page>
      <SideBar>
        <Button onClick={onSave} color="primary" variant="contained" style={{ boxShadow: "none" }}>
          저장
        </Button>
        {/* 새로쓰기를 할때는 삭제버튼이 있으면 안된다. */}
        {/* {state.interview.id !== 0 && (
          <Button
            onClick={onDelete}
            color="secondary"
            variant="contained"
            style={{ boxShadow: "none", marginLeft: "2px" }}
          >
            삭제
          </Button>
        )} */}
      </SideBar>

      {/* Issue 영역 START */}
      <WriteIssueWrapper data={state.issue} empSelectOptions={empSelectOptions}></WriteIssueWrapper>
      {/* Issue 영역 END */}

      <WriteInterview
        setFile={setFile}
        file={state.file}
        data={state.interview}
        empSelectOptions={empSelectOptions}
      ></WriteInterview>
      <br />
      <WriteAftercare data={state.aftercares} empSelectOptions={empSelectOptions}></WriteAftercare>

      {/* Save Dialog */}
      <ProgressModal open={modalInfo.isOpen} title={"면담 내용 저장"}>
        <>
          {progressArr.map((x, i) => (
            <ProgressItemViewer name={x.name} status={x.status} key={`${x.name}-${i}`}></ProgressItemViewer>
          ))}
        </>
      </ProgressModal>

      {/* 이슈 Dialog */}
      {/* <ProgressModal open={issueModalInfo.isOpen} onProgressBar={false} title={"이슈 연결하기"}>
        <></>
      </ProgressModal> */}
    </Page>
  );
}

export default WritePage;
