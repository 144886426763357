import { ReactElement, ReactNode, useEffect } from "react";
import { Container } from "@material-ui/core";

interface Props {
  children?: ReactNode;
  title?: string;
  className?: string;
  loading?: boolean;
}

function Page({ title, children, className }: Props): ReactElement {
  useEffect(() => {
    document.title = title || "인사만사";
  }, [title]);
  return (
    <Container className={`mt-3 ${className}`}>
      <>{children}</>
    </Container>
  );
}

export default Page;
