import React, { Fragment, ReactElement } from "react";
import MainRoute from "src/Route/InterviewRecord/Routes";
import { MyStyle } from "src/Style/theme";
import NavMenuInterview from "src/page/NavMenuInterview";

function MainLayout(): ReactElement {
  const classes = MyStyle();
  return (
    <Fragment>
      <div className={classes.bodyWrap}>
        {/* NAV 위치 */}
        <NavMenuInterview></NavMenuInterview>
        {/* 내용이 들어갈 위치 */}
        <MainRoute></MainRoute>
      </div>
    </Fragment>
  );
}

export default MainLayout;
