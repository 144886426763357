import React, { ReactElement, useEffect, useState } from "react";
import { Attendance, EmployeeAutoComplete } from "src/system/types";
import CellDay from "./CellDay";

interface TableWeekProps {
  month: Attendance[];
  week: number;
  employee: EmployeeAutoComplete;
  clickCellDay: (date: Date) => void;
}

function TableWeek({ month, week, employee, clickCellDay }: TableWeekProps): ReactElement {
  const [data, setData] = useState<Attendance[]>();

  useEffect(() => {
    let temp = month.slice(week * 7, (week + 1) * 7);
    setData(temp);
  }, [month, week]);

  return (
    <tr>
      {data &&
        data.map((x) => {
          return <CellDay key={`day-cell-${x.day}`} data={x} employee={employee} clickCellDay={clickCellDay}></CellDay>;
        })}
    </tr>
  );
}

export default TableWeek;
