import { AttendanceDocumentForm } from '../PostAttendanceRequest';
import { useFormContext } from 'react-hook-form';
import { useDateHooks } from './useDateHooks';
import { useUserState } from 'src/system/UserContext';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { titleRefreshAtom } from '../recoils/TitleRefreshAtom';
import { useAttendanceHooks } from './useAttendanceHooks';
import { AnnualLeaveType } from '../data/AnnualLeaveType';
import { AnnualLeave } from '../data/AnnualLeaveType';
import { getLastBelong } from './getLastBelong';

export const useTitleHooks = () => {
  const user = useUserState();
  const [titleRefresh, setTitleRefresh] = useRecoilState(titleRefreshAtom);
  const { watch, setValue } = useFormContext<AttendanceDocumentForm>();

  const { getDateStringFormat } = useDateHooks();
  const { getAttendanceBalanceCountData } = useAttendanceHooks();

  /** @private
   * 날짜들로 통합근태문서 제목 얻는 함수
   * 날짜 string값이 20자 초과시 시작일:01/01...종료일:03/03으로 줄임
   * @returns
   */
  const getDatePeriod = () => {
    const attendanceItemList = watch('attendanceItem');

    if (attendanceItemList.length === 0) return '';

    const sortedDates = attendanceItemList.map(item => new Date(item.workDate))
      .sort((a, b) => a.getTime() - b.getTime());

    let result = [];
    let rangeStart = sortedDates[0];

    for (let i = 1; i < sortedDates.length; i++) {
      if ((sortedDates[i].getTime() - sortedDates[i - 1].getTime()) > (24 * 60 * 60 * 1000)) {
        if (rangeStart.getTime() !== sortedDates[i - 1].getTime()) {
          result.push(`${getDateStringFormat(rangeStart, 'MM/DD')}~${getDateStringFormat(sortedDates[i - 1], 'MM/DD')}`);
        } else {
          result.push(getDateStringFormat(rangeStart, 'MM/DD'));
        }
        rangeStart = sortedDates[i];
      }
    }

    // 마지막 그룹 처리
    if (rangeStart.getTime() !== sortedDates[sortedDates.length - 1].getTime()) {
      result.push(`${getDateStringFormat(rangeStart, 'MM/DD')}~${getDateStringFormat(sortedDates[sortedDates.length - 1], 'MM/DD')}`);
    } else {
      result.push(getDateStringFormat(rangeStart, 'MM/DD'));
    }

    const joinedTitle = result.join(',');
    if (joinedTitle.length > 20) {
      return `시작일_${getDateStringFormat(sortedDates[0], 'MM/DD')}…종료일_${getDateStringFormat(sortedDates[sortedDates.length - 1], 'MM/DD')}`
    }
    return joinedTitle;
  }

  /** @private
   * 양식 이름으로 통합근태문서 제목 얻는 함수
   * 20자 초과 시, 등...으로 줄임
   * @returns
   */
  const getFormNameList = () => {
    const attendanceItemList = watch('attendanceItem');

    const formNameSet = new Set<string>();

    attendanceItemList.forEach(attendance => {
      if (attendance.formName.includes('_')) {
        formNameSet.add(`${attendance.formName.replace('_','(')})`)
      } else {
        formNameSet.add(attendance.formName)
      }
    });

    const formNameArray = Array.from(formNameSet);
    const result = formNameArray.join('/');

    if (result.length > 20) {
      return formNameArray[0] + " 등...";
    }

    return result;
  }

  /** @private 연차 종류에 맞게 (사용 연차 / 총 연차) 개수 구하는 함수
   */
  const getLeftLeaveCount = async (type: AnnualLeaveType) => {
    let typeName = '';

    switch (type) {
      case AnnualLeave.annualLeave:
        typeName = '연차';
        break;
      case AnnualLeave.alternativeLeave:
        typeName = '대체휴가';
        break;
      case AnnualLeave.specialLeave:
        typeName = '특별휴가';
        break;
    }

    const data = await getAttendanceBalanceCountData(user.employeeId);

    let unUsedAttendanceCount = 0;
    let totalAttendanceCount = 0;

    switch (type) {
      case AnnualLeave.annualLeave:
        unUsedAttendanceCount = data.annualLeave - data.usedAnnualLeave;
        totalAttendanceCount = data.annualLeave;
        break;
      case AnnualLeave.specialLeave:
        unUsedAttendanceCount = data.specialLeave - data.usedSpecialLeave;
        totalAttendanceCount = data.specialLeave;
        break;
      case AnnualLeave.alternativeLeave:
        unUsedAttendanceCount = data.alternativeLeave - data.usedAlternativeLeave;
        totalAttendanceCount = data.alternativeLeave;
        break;
    }

    const totalRemainingAttendanceCount = watch('attendanceItem')
      .reduce((sum, item) => {
        if (item.employeeId === user.employeeId && item.attendanceForm.leaveType === type) {
          return sum + item.term;
        }
        return sum;
      }, unUsedAttendanceCount)

    const totalUsedAttendanceCount = totalAttendanceCount - totalRemainingAttendanceCount;
    const totalCountString = Number.isInteger(totalAttendanceCount) ? `${totalAttendanceCount}.0` : totalAttendanceCount;
    const usedCountString = Number.isInteger(totalUsedAttendanceCount) ? `${totalUsedAttendanceCount}.0` : totalUsedAttendanceCount;

    return `(${typeName}: ${usedCountString}/${totalCountString})`
  }

  /** @public Item 변경에 따른 제목 변경
   */
  const useChangeTitleWhenAttendanceItemChanged = async () => {
    useEffect(() => {
      const changeTitle = async () => {
        if (watch('category') === '휴가원') {
          const leaveTypeSet = new Set<AnnualLeaveType>();

          watch('attendanceItem').forEach((item) => {
            if (item.attendanceForm.leaveType) {
              leaveTypeSet.add(item.attendanceForm.leaveType);
            }
          })

          const leaveTypePromises = Array.from(leaveTypeSet)
            .map(leaveType => getLeftLeaveCount(leaveType));

          const leaveCounts = (await Promise.all(leaveTypePromises)).join('');

          const title = `${getDatePeriod()}_${getFormNameList()}_${getLastBelong(user.teamName, user.parentTeamName, user.group)}_${user.name}${leaveCounts}`;
          setValue('title', title);
        } else {
          const title = `${getDatePeriod()}_${getFormNameList()}_${getLastBelong(user.teamName, user.parentTeamName, user.group)}_${user.name}`;
          setValue('title', title);
        }
      }

      changeTitle();
    }, [watch('attendanceItem')])
  }

  /** @public 대상자 변경에(titleRefreshAtom) 따른 제목 변경
   */
  const useChangeTitleWhenTitleRefreshAtomChanged = () => {
    useEffect(() => {
      const changeTitle = async () => {
        if (watch('category') === '휴가원') {
          const leaveTypeSet = new Set<AnnualLeaveType>();

          watch('attendanceItem').forEach((item) => {
            if (item.attendanceForm.leaveType) {
              leaveTypeSet.add(item.attendanceForm.leaveType);
            }
          })

          const leaveTypePromises = Array.from(leaveTypeSet)
            .map(leaveType => getLeftLeaveCount(leaveType));

          const leaveCounts = (await Promise.all(leaveTypePromises)).join('');

          const title = `${getDatePeriod()}_${getFormNameList()}_${getLastBelong(user.teamName, user.parentTeamName, user.group)}_${user.name}${leaveCounts}`;
          setValue('title', title);
        } else {
          const title = `${getDatePeriod()}_${getFormNameList()}_${getLastBelong(user.teamName, user.parentTeamName, user.group)}_${user.name}`;
          setValue('title', title);
          setTitleRefresh(false);
        }
      }

      if (titleRefresh) {
        changeTitle()
      }
    }, [titleRefresh])
  }

  return {
    useChangeTitleWhenAttendanceItemChanged,
    useChangeTitleWhenTitleRefreshAtomChanged
  }
}