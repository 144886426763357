import { atom } from 'recoil';
import { GetAttendanceBalanceCount } from '../interface/BalanceInterface';

/**
 * @type Map<string, GetAttendanceBalanceCount>
 * 연차 정보 관리하는 Recoil 만료일, 총연차, 잔여연차
 */
export const attendanceBalanceCountMapAtom = atom<Map<string, GetAttendanceBalanceCount>>({
    key: 'attendanceBalanceCountMapAtom',
    default: new Map(),
})