import { ReactElement } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import MainPage from "src/page/attendance/admin/MainPage";
import AttendanceRequestPage from "src/page/attendance/attendance-request/AttendanceRequestPage";

function Routes(): ReactElement {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const documentId = searchParams.get('documentId');

  return (
    <Switch>
      <Route path="/attendance" exact>
        <AttendanceRequestPage
          mode={documentId ? 'edit' : 'create'}
          documentId={documentId ? Number(documentId) : null}
        />
      </Route>
      <Route path="/attendance/admin">
        <MainPage></MainPage>
      </Route>
    </Switch>
  );
}

export default Routes;
