import { FormControl, InputLabel, Select } from "@material-ui/core";
import React, { ReactElement } from "react";
import { ComboboxItem } from "./TitleOneComboBox";

interface SelectComboboxProps {
  label?: string;
  name: string;
  value: string | number;
  options: ComboboxItem[];
  style?: any;
  /**
   * Select 에서 선택안함을 고를때 (true) (value:"0")
   */
  empty?: boolean;
  onChange?: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
}

function SelectCombobox({ label, empty, name, value, style, options, onChange }: SelectComboboxProps): ReactElement {
  return (
    <FormControl>
      {label && <InputLabel htmlFor={`${name}-native-simple`}>{label}</InputLabel>}
      <Select
        native
        style={style}
        value={value}
        onChange={onChange}
        inputProps={{
          name: `${name}`,
          id: `${name}-native-simple`,
        }}
      >
        <option disabled={!empty} aria-label="내용 없음" value="0">
          선택안함
        </option>
        {options.map((x) => (
          <option value={x.key} key={`option-${x.key}`}>
            {x.value}
          </option>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectCombobox;
