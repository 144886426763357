import { Button } from "@material-ui/core";
import React, { ReactElement, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Page from "src/components/common/Page";
import SideBar from "src/components/common/SideBar";
import { ComboboxItem } from "src/components/TitleOneComboBox";
import { InterviewRecordRoutes } from "src/system/types/type";
import { EmployeeApi, ErrorHandler, IssueApi } from "src/system/ApiService";
import { useInterviewState } from "src/system/InterviewContext";
import { Issue } from "src/system/types";
import { WriteIssue } from "src/ViewParts/Issue/WriteIssue";

export function confirmIssueData(issue: Issue) {
  let msg = [];
  if (issue.categoryId === 0) {
    msg.push("카테고리");
  }
  if (issue.content.trim() === "") {
    msg.push("이슈 내용");
  }
  return msg;
}

function WritePage(): ReactElement {
  const history = useHistory();
  const state = useInterviewState();
  const [empSelectOptions, setEmpSelectOptions] = useState<ComboboxItem[]>([]);

  //직원 목록 불러오기
  useEffect(() => {
    EmployeeApi.getEmployees()
      .then(({ data }) => {
        let ret: ComboboxItem[] = [];
        data.forEach((x) => {
          ret.push({
            key: x.employeeId,
            value: `${x.name}(${x.team})`,
          });
        });
        setEmpSelectOptions(ret);
      })
      .catch(() => alert("직원 데이터를 불러오는데 실패했습니다."));
  }, []);

  const moveToListPage = function () {
    history.push({
      pathname: InterviewRecordRoutes.issue + "/list",
      search: "?page=1",
    });
  };

  const confirmSaveData = function (): boolean {
    const { issue } = state;
    if (issue !== null) {
      let msg = confirmIssueData(issue);

      if (msg.length >= 1) {
        let error = msg.join(",") + " 의 내용을 채워주세요.";
        alert(error);
      }

      return msg.length === 0;
    } else {
      return false;
    }
  };

  const onSave = () => {
    if (state.issue !== null) {
      //검사 해야함
      if (confirmSaveData()) {
        IssueApi.save(state.issue)
          .then((res) => {
            moveToListPage();
          })
          .catch((error) => {
            let msg = ErrorHandler(error);
            alert(msg);
          });
      }
    }
  };

  return (
    <Page>
      <h4>이슈 작성</h4>
      <SideBar>
        <Button onClick={onSave} color="primary" variant="contained" style={{ boxShadow: "none" }}>
          저장
        </Button>
      </SideBar>
      {state.issue && <WriteIssue data={state.issue} empSelectOptions={empSelectOptions}></WriteIssue>}
    </Page>
  );
}

export default WritePage;
