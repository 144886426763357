import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import CloseIcon from "@material-ui/icons/Close";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
} from "@syncfusion/ej2-react-grids";
import { useEffect, useState } from "react";
import DatePicker from "src/components/DatePicker";
import { AttendanceApi, ErrorHandler } from "src/system/ApiService";
import { PRIMARY } from "src/system/Constants";
import {
  DailyAttendance,
  EmployeeAutoComplete
} from "src/system/types";
import { useUserState } from "src/system/UserContext";
import RegularColgroup from "./RegularColgroup";
import TableInsaCard from "./TableInsaCard";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  datepickerWrapper: {
    textAlign: "center",
    "& input": {
      textAlign: "center",
    },
  },
}));

interface DailyDetailAttendanceModalProps {
  employee: EmployeeAutoComplete;
  selectedDate: Date;
  open: boolean;
  onClose: () => void;
}

function DailyDetailAttendanceModal({
  employee,
  selectedDate,
  open,
  onClose,
}: DailyDetailAttendanceModalProps) {
  const classes = useStyles();
  const [date, setDateChange] = useState(selectedDate);
  const [data, setData] = useState<DailyAttendance>();
  const now = new Date();
  const user = useUserState();
  const isAdmin = user.role === "admin";

  const onDateChange = (date: Date | null) => {
    if (date) {
      setDateChange(date);
    }
  };

  const onLeftClick = () => {
    setDateChange(
      new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)
    );
  };

  const onRightClick = () => {
    setDateChange(
      new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1)
    );
  };

  useEffect(() => {
    AttendanceApi.GetAlarmList(employee.employeeNo, employee.workplace, date)
      .then((res) => {
        AttendanceApi.GetAttendance(employee, date)
          .then((res2) => {
            AttendanceApi.GetDailyGroupwareList(employee.employeeNo, date)
              .then((res3) => {
                setData({ alarmList: res, attendance: res2, gwList: res3 });
              })
              .catch((error) => {
                let msg = ErrorHandler(error);
                alert(msg);
              });
          })
          .catch((error) => {
            let msg = ErrorHandler(error);
            alert(msg);
          });
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      });
  }, [date, employee]);

  useEffect(() => {
    setDateChange(selectedDate);
  }, [selectedDate]);

  // const display = useMemo(() => {
  //   console.log("1");
  // }, [data]);

  const template = (props: any): any => {
    if (props.column.field === "time") {
      return (
        <div>
          {props.time.replace(
            /^(\d{4})(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)$/,
            "$1-$2-$3 $4:$5:$6"
          )}
        </div>
      );
    }
  };

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
      <div style={{ height: "780px" }}>
        <DialogTitle style={{ backgroundColor: PRIMARY, color: "white" }}>
          <Typography style={{ fontWeight: "bold" }}>{`상세보기`}</Typography>
          <CloseIcon className={classes.closeButton} onClick={onClose} />
        </DialogTitle>
        <Grid spacing={1}>
          <DialogContent>
            <Grid container>
              <Grid item xs={12} className={classes.datepickerWrapper}>
                <Button
                  style={{ padding: "0px" }}
                  disabled={
                    isAdmin
                      ? false
                      : new Date(
                        now.getFullYear(),
                        now.getMonth() - 2,
                        2
                      ).getMonth() >= date.getMonth()
                  }
                  onClick={onLeftClick}
                >
                  <ArrowLeftIcon style={{ marginTop: "5px" }}></ArrowLeftIcon>
                </Button>
                <DatePicker
                  views={["year", "month", "date"]}
                  openTo="date"
                  format="yyyy.  M. d"
                  minDate={
                    isAdmin
                      ? `${new Date("2020-01-01")}`
                      : `${new Date().getFullYear()}-${new Date().getMonth()}-01`
                  }
                  maxDate={
                    isAdmin
                      ? `${new Date("2030-01-01")}`
                      : `${new Date().getFullYear() + 2
                      }-${new Date().getMonth()}-01`
                  }
                  value={date}
                  onChange={onDateChange}
                  style={{ width: "120px" }}
                />
                <Button style={{ padding: "0px" }} onClick={onRightClick}>
                  <ArrowRightIcon style={{ marginTop: "5px" }}></ArrowRightIcon>
                </Button>
              </Grid>
              {/* 요약 */}
              <Grid item xs={12}>
                <TableInsaCard
                  title="요약"
                  colgroup={<RegularColgroup cols={4}></RegularColgroup>}
                >
                  <tr>
                    <th>세콤 출근시각</th>
                    <th>세콤 퇴근시각</th>
                    <th>출근상태</th>
                    <th>야근식대</th>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "center" }}>
                      {data?.attendance.start}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {data?.attendance.end}
                    </td>
                    <td style={{ textAlign: "center" }}>{ }</td>
                    <td style={{ textAlign: "center" }}>{ }</td>
                  </tr>
                </TableInsaCard>
              </Grid>
              {/* 통합근태신청서 */}
              {data?.gwList && data?.gwList.length > 0 && (
                <Grid item xs={12}>
                  <TableInsaCard title="통합근태신청서">
                    <tr>
                      <th>대분류</th>
                      <th>소분류</th>
                      <th>시작시각</th>
                      <th>종료시각</th>
                      <th>상태</th>
                    </tr>
                    {data?.gwList.map((item) => {
                      return (
                        <>
                          <tr>
                            <td style={{ textAlign: "center" }}>{item.kind}</td>
                            <td style={{ textAlign: "center" }}>
                              {item.category}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {item.startTime !== null
                                ? item.startTime.toString().replace("T", " ")
                                : ""}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {item.endTime !== null
                                ? item.endTime.toString().replace("T", " ")
                                : ""}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {item.state}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </TableInsaCard>
                </Grid>
              )}
              {/* 출입기록 */}
              <Grid item xs={12}>
                <TableInsaCard title="출입기록"></TableInsaCard>
                <GridComponent
                  dataSource={data?.alarmList}
                  allowPaging={true}
                  pageSettings={{ pageSize: 5 }}
                >
                  <ColumnsDirective>
                    <ColumnDirective
                      field="mode"
                      headerText="구분"
                      width="30"
                      textAlign="Center"
                    />
                    <ColumnDirective
                      field="place"
                      headerText="위치"
                      width="40"
                      textAlign="Center"
                    />
                    <ColumnDirective
                      field="time"
                      headerText="시간"
                      width="100"
                      template={template}
                      textAlign="Center"
                    />
                  </ColumnsDirective>
                  <Inject services={[Page]} />
                </GridComponent>
              </Grid>
            </Grid>
          </DialogContent>
        </Grid>
      </div>
    </Dialog>
  );
}

export default DailyDetailAttendanceModal;
