import React, { ReactElement, useEffect, useState } from "react";
import ReactDataGrid, { Column } from "react-data-grid";
import { ErrorHandler, HREmployeeApi } from "src/system/ApiService";
import { getDisplayTime } from "src/system/Helper";
import { Language } from "src/system/types";

interface Props {
  employeeId: string;
}

function LanguageDataGrid({ employeeId }: Props): ReactElement {
  const [data, setData] = useState<Language[]>([]);

  useEffect(() => {
    if (employeeId !== "") {
      // API 호출
      HREmployeeApi.GetLanguageList(employeeId)
        .then((res) => {
          setData(res);
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          alert(msg);
        });
    }
  }, [employeeId]);

  const defaultColumnProperties = {};

  const columns = React.useMemo(() => {
    const k = [
      {
        name: "시험명",
        key: "certification",
      },
      {
        name: "등록번호",
        key: "registrationNo",
      },
      {
        name: "응시일",
        key: "tested",
        formatter: (props: any) => {
          return <>{getDisplayTime(props.row.tested)}</>;
        },
      },
      {
        name: "취득점수",
        key: "score",
      },
      {
        name: "만점기준",
        key: "maxScore",
      },
      {
        name: "증빙서류",
        key: "evidence",
        formatter: (props: any) => {
          if (props.row.evidence === "파일첨부") {
            return <>{props.row.fileName}</>;
          } else {
            return <>{props.row.evidence}</>;
          }
        },
      },
      {
        name: "비고",
        key: "remark",
      },
    ].map((c) => ({ ...c, ...defaultColumnProperties }));
    const nk: Column<Language>[] = k.map((x) => {
      if (x.key === "id") return x;
      else {
        return {
          ...x,
          // formatter: DefaultFormatter,
        } as Column<Language>;
      }
    });
    return nk;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ReactDataGrid
        rows={data}
        columns={columns}
        rowKeyGetter={(row) => {
          return row.id;
        }}
        defaultColumnOptions={{
          resizable: true,
        }}
        style={{ height: "200px" }}
      ></ReactDataGrid>
    </>
  );
}

export default LanguageDataGrid;
