import { Paper } from "@material-ui/core";
import React, { ReactElement } from "react";
import { Table } from "react-bootstrap";
import stringArrToJSX from "src/components/StringArrToJSX";
import { MyStyle } from "src/Style/theme";
import { getDateUsingNumber } from "src/system/Helper";
import { Issue } from "src/system/types";

interface IssueDetailProps {
  issue: Issue;
}

function IssueDetail({ issue }: IssueDetailProps): ReactElement {
  const created = getDateUsingNumber(issue.created);
  const occurred = getDateUsingNumber(issue.occurred);
  const classes = MyStyle();
  return (
    <Table>
      <tr>
        <th>작성일</th>
        <th>카테고리</th>
        <th>작성자</th>
        <th>발생일</th>
      </tr>
      <tr>
        <td>{created}</td>
        <td>{issue.category}</td>
        <td>{issue.writer.name}</td>
        <td>{occurred}</td>
      </tr>
      <tr>
        <th>관련자</th>
        <td colSpan={3}>
          {stringArrToJSX(
            issue.concerneds.map((x) => x.name),
            ","
          )}
        </td>
      </tr>
      <tr>
        <th>열람제한</th>
        <td>{issue.accessModifier}</td>
        <th>추가 열람 가능자</th>
        <td>
          {stringArrToJSX(
            issue.accessReader.map((x) => x.name),
            ","
          )}
        </td>
      </tr>
      <tr>
        <th>내용</th>
        <td colSpan={3} className={classes.content}>
          {issue.content}
        </td>
      </tr>
    </Table>
  );
}

interface ViewIssueProps {
  issue: Issue | null;
}

function ViewIssue({ issue }: ViewIssueProps): ReactElement {
  if (issue === null) {
    return <></>;
  } else {
    return (
      <Paper variant="outlined" className="p-3">
        <h4>이슈</h4>
        <IssueDetail issue={issue}></IssueDetail>
      </Paper>
    );
  }
}

export default ViewIssue;
