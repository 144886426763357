import { Button, Grid, IconButton, TableCell, TableRow, TextField } from '@material-ui/core';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import RemoveIcon from '@material-ui/icons/Remove';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { UseFieldArrayRemove, useFormContext } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { VehicleTypeList } from '../data/VehicleType';
import { useDateHooks } from '../hooks/useDateHooks';
import { employeeChangeModalAtom } from '../recoils/EmployeeChangeModalAtom';
import { useAttendanceStyles } from '../style/useAttendanceStyle';
import ErrorText from './ErrorText';
import { AttendanceDocumentForm } from '../PostAttendanceRequest';
import ToastAlert from '../hooks/ToastAlert';
import { titleRefreshAtom } from '../recoils/TitleRefreshAtom';

interface AttendanceItemProps {
  index: number;
  remove: UseFieldArrayRemove;
  remainingAnnualLeaveCount: string;
}

export default function AttendanceItem({ index, remove, remainingAnnualLeaveCount }: AttendanceItemProps) {
  const classes = useAttendanceStyles();
  const { getDateStringFormat, getEndTime_ConsiderLunchTime, hourStringParseToDate } = useDateHooks();
  const { watch, formState: { errors }, setValue } = useFormContext<AttendanceDocumentForm>();
  const setEmployeeChangeModalState = useSetRecoilState(employeeChangeModalAtom);

  const setTitleRefresh = useSetRecoilState(titleRefreshAtom);

  return (
    <TableRow>
      <TableCell align='center'>
        <TextBoxComponent
          id={`attendance-item-name-${index}`}
          style={{ textAlign: 'center' }}
          cssClass='e-outline'
          value={watch(`attendanceItem.${index}.formName`)}
          placeholder='자동 입력됩니다.'
          enabled={false} />
      </TableCell>
      <TableCell align='center'>
        <TextBoxComponent
          id={`attendance-item-department-${index}`}
          style={{ textAlign: 'center' }}
          cssClass='e-outline'
          value={watch(`attendanceItem.${index}.department`)}
          enabled={false} />
      </TableCell>
      <TableCell align='center'>
        <Grid
          container
          alignItems='center'
          wrap='nowrap'
          justify='space-evenly'
          spacing={1}>
          <Grid item >
            <TextBoxComponent
              style={{ textAlign: 'center' }}
              cssClass='e-outline'
              value={watch(`attendanceItem.${index}.employeeName`)}
              enabled={false} />
          </Grid>
          <Grid item>
            <IconButton
              style={{ marginBottom: 4, fontSize: '26px', padding: '6px' }}
              aria-label="delete"
              type='button'
              className={classes.button}
              onClick={() => {
                setEmployeeChangeModalState(pre => ({
                  ...pre,
                  isItem: true,
                  isOpen: true,
                  itemIndex: index
                }))
              }}
            >
              <AccountTreeOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell align='center'>
        <KeyboardDatePicker
          id={`work-date-picker-${index}`}
          disableToolbar
          fullWidth
          variant="dialog"
          format="yyyy-MM-dd (EE)"
          margin="normal"
          className={classes.denseDatePicker}
          value={watch(`attendanceItem.${index}.workDate`)}
          maxDate={watch(`attendanceItem.${index}.expiredAt`)}
          onChange={(date, value) => {
            if (!value) return;
            const selectedDate = new Date(value);
            const day = selectedDate.getDay();
            if (watch('category') === '휴가원' && (day === 0 || day === 6)) {
              alert(`주말 및 공휴일에는 ${watch(`attendanceItem.${index}.formName`)}를 사용할 수 없습니다.`)
              return;
            }
            setValue(`attendanceItem.${index}.workDate`, getDateStringFormat(value, 'YYYY-MM-DD'));
            setTitleRefresh(true);
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <ErrorText message={errors.attendanceItem?.[index]?.workDate?.message} />
      </TableCell>
      <TableCell align='center'>
        <Grid container justify='center' alignItems='center'>
          <TextField
            style={{ marginBottom: '4px' }}
            id={`start-time-picker-${index}`}
            type="time"
            variant='outlined'
            className={classes.timePicker}
            onChange={e => {
              if (e.target.value.localeCompare('01:00') === -1) {
                ToastAlert(`시작시간이 ${e.target.value}로 설정되었습니다.`, 'warning')
              }
              setValue(`attendanceItem.${index}.startTime`, e.target.value);
              const term = Math.abs(watch(`attendanceItem.${index}.term`));
              if (0 < term && term < 1) {
                const startTime = new Date(`1970-01-01T${e.target.value}:00`);
                const endTime = new Date(startTime.getTime() + Math.abs(term) * 60 * 60 * 1000 * 8);
                setValue(
                  `attendanceItem.${index}.endTime`,
                  getEndTime_ConsiderLunchTime(startTime, endTime)
                );
              }
            }}
            value={watch(`attendanceItem.${index}.startTime`)}
            disabled={!watch(`attendanceItem.${index}.isTimeEntered`)}
            InputLabelProps={{ shrink: true }}
            inputProps={{ step: 600, style: { padding: '10px' } }}
          />
          <span style={{ padding: 5 }}>~</span>
          <TextField
            style={{ marginBottom: '4px' }}
            id={`end-time-picker-${index}`}
            type="time"
            variant='outlined'
            className={classes.timePicker}
            onChange={e => {
              if (watch(`attendanceItem.${index}.term`) === -0.5 &&
                Math.abs(hourStringParseToDate(watch(`attendanceItem.${index}.startTime`)).getTime()
                  - hourStringParseToDate(e.target.value).getTime()) > 60 * 60 * 1000 * 5) {
                alert("반차 사용은 연속된 5시간 이내의 근무시간에만 가능합니다.\n선택하신 시간은 5시간을 초과하므로 반차 사용이 불가능합니다.\n시작시간과 종료시간을 5시간 이내로 조정해 주세요.");
                return;
              }
              setValue(`attendanceItem.${index}.endTime`, e.target.value);
            }}
            value={watch(`attendanceItem.${index}.endTime`)}
            disabled={!watch(`attendanceItem.${index}.isTimeEntered`)
              || watch(`attendanceItem.${index}.term`) === -0.25}
            InputLabelProps={{ shrink: true }}
            inputProps={{ step: 600, style: { padding: '10px' } }}
          />
        </Grid>
        <ErrorText message={errors.attendanceItem?.[index]?.startTime?.message} />
        <ErrorText message={errors.attendanceItem?.[index]?.endTime?.message} />
      </TableCell>
      <TableCell
        className={watch(`isCheckOwnVehicle`) ? '' : 'exclude'}
        align='center'>
        <DropDownListComponent
          id={`vehicle-select-item-${index}`}
          cssClass='e-outline'
          dataSource={VehicleTypeList}
          placeholder='선택해주세요.'
          value={watch(`attendanceItem.${index}.vehicle`)}
          change={(e) => setValue(`attendanceItem.${index}.vehicle`, e.value)}
          enabled={watch(`isCheckOwnVehicle`)}
        />
        <ErrorText message={errors.attendanceItem?.[index]?.vehicle?.message} />
      </TableCell>
      <TableCell align='center'>
        <div className='select-box'>
          {remainingAnnualLeaveCount}
        </div>
      </TableCell>
      <TableCell align='center' className='exclude'>
        <Button
          variant='contained'
          className={classes.deleteButton}
          type='button'
          onClick={() => remove(index)}>
          <RemoveIcon />
        </Button>
      </TableCell>
    </TableRow>
  );
}