import { Grid } from "@material-ui/core";
import { enableRipple } from "@syncfusion/ej2-base";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { FormValidator, MaskedTextBoxComponent, TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import React, { useEffect, useState } from "react";
import { DepartmentApi, ErrorHandler } from "src/system/ApiService";
import { DeptInfoErp, UserInfoErp } from "src/system/types";

interface TeamsInfoGridDialogEditProps {
  info: UserInfoErp;
}
enableRipple(true);
function TeamsInfoGridDialogEdit({ info }: TeamsInfoGridDialogEditProps) {
  const [dept, setDept] = useState<DeptInfoErp[]>([]);
  const [data, setData] = useState<UserInfoErp>({ ...info, o365Create: true, teamSite: "" });
  const options = {
    rules: {
      email: {
        required: [true, "* Please enter your email"],
      },
    },
  };

  useEffect(() => {
    // Initialize the form validator
    new FormValidator("#email", options);
    // 파트/팀 불러오기 API - NEW(DeptInfoErp)
    let deptInfoErp: DeptInfoErp[] = [];
    DepartmentApi.GetDeptInfoErp(2022, "Active")
      .then((res) => {
        res.result.forEach((dept) => {
          if (dept.costCenter.includes("C")) {
            deptInfoErp.push({ ...dept });
          }
        });
        setDept(deptInfoErp);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (args: any) => {
    if ((args.target as HTMLInputElement).name === "department") {
      var temp = dept.filter((x) => x.erpName === args.target.value);
      setData({
        ...data,
        [(args.target as HTMLInputElement).name]: args.target.value,
        costCenter: temp.length > 1 ? temp[0].costCenter + " / " + temp[1].costCenter : temp[0].costCenter,
      });
    } else if ((args.target as HTMLInputElement).name === "mobilePhone") {
      setData({
        ...data,
        [(args.target as HTMLInputElement).name]: args.target.value
          .replace(/-/g, "")
          .replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"),
      });
    } else if ((args.target as HTMLInputElement).name === "o365CreateChk") {
      if (args.target.checked) setData({ ...data, o365Create: true });
      else setData({ ...data, o365Create: false });
    } else {
      setData({ ...data, [(args.target as HTMLInputElement).name]: args.target.value });
    }
  };

  return (
    <div>
      {data.isAdd && (
        <CheckBoxComponent label="O365 계정 생성" name="o365CreateChk" checked={data.o365Create} onChange={onChange} />
      )}
      <br></br>
      <br></br>
      <input type="hidden" name="legalEntity" value="IHQ"></input>
      <input type="hidden" name="id" value={data.id || 0}></input>
      <input type="hidden" name="o365Create" value={data.o365Create ? "true" : "false"}></input>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextBoxComponent
            placeholder="Email"
            name="email"
            value={data.email}
            onBlur={onChange}
            floatLabelType="Always"
            type="email"
            data-msg-containerid="errroForEmail"
          />
          <div id="errroForEmail" />
        </Grid>
        <Grid item xs={6}>
          <TextBoxComponent
            placeholder="이름"
            name="name"
            value={data.name}
            onChange={onChange}
            floatLabelType="Always"
          />
        </Grid>
        <Grid item xs={6}>
          <MaskedTextBoxComponent
            placeholder="핸드폰"
            name="mobilePhone"
            value={data.mobilePhone}
            onChange={onChange}
            floatLabelType="Always"
          />
        </Grid>
        <Grid item xs={6}>
          <TextBoxComponent
            placeholder="회사명"
            name="companyName"
            value={"(주)인바디"}
            onChange={onChange}
            floatLabelType="Always"
            readOnly={true}
          />
        </Grid>
        <Grid item xs={6}>
          <DropDownListComponent
            placeholder="소속"
            name="department"
            value={data.department}
            onChange={onChange}
            floatLabelType="Always"
            dataSource={dept.map((x) => x.erpName)}
          />
          {/* <TextBoxComponent
            placeholder="소속 코드"
            name="department"
            value={dept.find((x) => x.costCenter === data.costCenter)?.erpName}
            floatLabelType="Always"
            readOnly={false}
          /> */}
        </Grid>
        <Grid item xs={6}>
          <TextBoxComponent
            placeholder="소속 코드"
            name="costCenter"
            value={data.costCenter}
            floatLabelType="Always"
            readOnly={false}
          />
        </Grid>
        <Grid item xs={6}>
          <TextBoxComponent
            placeholder="사번"
            name="personalNum"
            value={data.personalNum}
            onChange={onChange}
            floatLabelType="Always"
          />
        </Grid>
        <Grid item xs={6}>
          <DatePickerComponent
            placeholder="입사일"
            name="hireDate"
            format="yyyy-MM-dd"
            value={new Date(data.hireDate)}
            onChange={onchange}
            floatLabelType="Always"
          />
        </Grid>
        <Grid item xs={6}>
          <TextBoxComponent
            placeholder="직급"
            name="duties"
            value={data.duties}
            onChange={onChange}
            floatLabelType="Always"
          />
        </Grid>
        {data.isAdd && data.o365Create ? (
          <Grid item xs={6}>
            <TextBoxComponent
              placeholder="비밀번호"
              name="password"
              value={data.password}
              onChange={onChange}
              floatLabelType="Always"
            />
          </Grid>
        ) : (
          <Grid>
            <br />
            <Grid item xs={6} style={{ display: "none" }}>
              <CheckBoxComponent label="퇴사" name="isResign" checked={data.isResign} onChange={onChange} disabled />
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default TeamsInfoGridDialogEdit;
