import { Button, Dialog, DialogTitle, Grid, IconButton, TextField, Tooltip, Typography } from "@material-ui/core";
import KeyboardDatePicker from "src/components/KeyboardDatePicker";
import React, { ReactElement, useState } from "react";
import TableInsaCard from "src/components/TableInsaCard";
import { RowEdit } from "src/system/types";
import * as VP from "src/components/viewparts";
import DaumPostcode, { AddressData } from "react-daum-postcode";
import CloseIcon from "@material-ui/icons/Close";

interface Props extends RowEdit {}

// eslint-disable-next-line no-empty-pattern
function BasicUserEdit({}: Props): ReactElement {
  const [open, setOpen] = useState<boolean>(false);

  const onCompleteAddress = (data: AddressData) => {
    let fullAddress = data.address;
    let extraAddress = "";
    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress += extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    //console.log(fullAddress); // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)'
    setOpen(false);
  };
  return (
    <>
      <Grid container>
        {/* <Grid item xs={12}>
          <span>인사변동:</span>
          <select>
            <option>대분류</option>
          </select>
          <select>
            <option>소분류</option>
          </select>
        </Grid>
        <Grid item>
          <span>근무상태변동:</span>
          <select>
            <option>대분류</option>
          </select>
          <select>
            <option>소분류</option>
          </select>
        </Grid> */}
        <Grid xs={12} item>
          <TableInsaCard title="기본정보1">
            <tr>
              <th>성명</th>
              <td>
                <TextField></TextField>
              </td>
              <th>사번</th>
              <td>
                <TextField></TextField>
                <button>중복확인</button>
              </td>
              <th>성별</th>
              <td>
                <select>
                  <option value="M">남자</option>
                  <option value="F">여자</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>생일</th>
              <td>
                <KeyboardDatePicker onChange={() => {}} value={null}></KeyboardDatePicker>
              </td>
              <th>핸드폰 번호</th>
              <td>
                <TextField></TextField>
              </td>
              <th>국적</th>
              <td>
                <TextField defaultValue="대한민국"></TextField>
              </td>
            </tr>
            <tr>
              <th>주소</th>
              <td colSpan={5}>
                <TextField placeholder="우편번호"></TextField>{" "}
                <Button variant="contained" onClick={() => setOpen(true)}>
                  우편번호 찾기
                </Button>
                <br />
                <TextField placeholder="도로명 주소" disabled></TextField>&nbsp;
                <TextField placeholder="상세 주소"></TextField>
              </td>
            </tr>
          </TableInsaCard>
          <TableInsaCard title="기본정보2">
            <tr>
              <th>소속</th>
              <td>
                <select>
                  <option value="본사">본사</option>
                  <option value="천안공장">천안공장</option>
                  <option value="벤처">벤처</option>
                  <option value="강원지사">강원지사</option>
                </select>
              </td>
              <td>
                <VP.SelectDepartment></VP.SelectDepartment>
              </td>
              <td></td>
              <th>근무상태</th>
              <td>
                재직&nbsp;<button>변경</button>
              </td>
            </tr>
            <tr>
              <th>직위</th>
              <td>주임</td>
              <th>직급</th>
              <td>사원</td>
              <th>직책</th>
              <td>팀원</td>
            </tr>
            <tr>
              <th>입사일</th>
              <td>2025.11.01</td>
              <th>근속연수</th>
              <td>
                <Tooltip title="입사일이 변경되어 저장 후 확인할 수 있습니다.">
                  <span>5.1</span>
                </Tooltip>
              </td>
              <th>고용형태</th>
              <td>
                <VP.SelectEmployment></VP.SelectEmployment>
              </td>
            </tr>
          </TableInsaCard>
        </Grid>
      </Grid>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xs" fullWidth>
        <DialogTitle disableTypography style={{ padding: "8px 24px" }}>
          <Typography variant="h6" display="inline">
            주소검색
          </Typography>
          <IconButton
            disableRipple
            aria-label="close"
            style={{ position: "absolute", right: "8px", top: "-3px" }}
            onClick={() => setOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <div>
          <DaumPostcode onComplete={onCompleteAddress}></DaumPostcode>
        </div>
      </Dialog>
    </>
  );
}

export default BasicUserEdit;
