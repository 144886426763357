import React, { ReactElement } from "react";
import TableInsaCard from "src/components/TableInsaCard";
import CertificateDataGrid from "./CertificateDataGrid";
import EducationDataGrid from "./EducationDataGrid";
import LanguageDataGrid from "./LanguageDataGrid";

interface Props {
  employeeId: string;
}

export default function MainPage({ employeeId }: Props): ReactElement {
  return (
    <>
      <TableInsaCard title="교육사항">
        <EducationDataGrid employeeId={employeeId}></EducationDataGrid>
      </TableInsaCard>
      <TableInsaCard title="자격사항">
        <CertificateDataGrid employeeId={employeeId}></CertificateDataGrid>
      </TableInsaCard>
      <TableInsaCard title="어학사항">
        <LanguageDataGrid employeeId={employeeId}></LanguageDataGrid>
      </TableInsaCard>
    </>
  );
}
