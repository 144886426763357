import { atom } from 'recoil';

export interface employeeChangeModalProps {
  isOpen: boolean;
  isItem: boolean;
  itemIndex: number;
}

export const initEmployeeChangeModalState: employeeChangeModalProps = {
  isOpen: false,
  isItem: false,
  itemIndex: 0,
}
export const employeeChangeModalAtom = atom<employeeChangeModalProps>({
  key: 'employeeChangeModalAtom',
  default: initEmployeeChangeModalState
})