import React, { ReactElement } from "react";
import { MyStyle } from "src/Style/theme";
import Routes from "./Routes";

function MainLayout(): ReactElement {
  const classes = MyStyle();
  return (
    <div className={classes.bodyWrap}>
      <Routes></Routes>
    </div>
  );
}

export default MainLayout;
