import { Paper } from "@material-ui/core";
import React, { ReactElement } from "react";
import { Table } from "react-bootstrap";
import { getDateUsingNumber } from "src/system/Helper";
import { FileInfo, Interview } from "src/system/types";
import { MyStyle } from "src/Style/theme";
import stringArrToJSX from "src/components/StringArrToJSX";
import { FileViewer } from "src/ViewParts/FileViewer";

interface ViewInterviewProps {
  interview: Interview;
  file: FileInfo | null;
}

function ViewInterview({ interview, file }: ViewInterviewProps): ReactElement {
  const created = getDateUsingNumber(interview.created);
  const occurred = getDateUsingNumber(interview.occurred);
  const classes = MyStyle();

  return (
    <Paper variant="outlined" className="p-3">
      <h4>면담</h4>
      <Table>
        <thead>
          <tr>
            <th>작성일</th>
            <th>카테고리</th>
            <th>작성자</th>
            <th>면담자</th>
            <th>피면담자</th>
            <th>면담일</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{created}</td>
            <td>{interview.category}</td>
            <td>{interview.writer.name}</td>
            <td>{interview.interviewer.name}</td>
            <td>{interview.interviewee.name}</td>
            <td>{occurred}</td>
          </tr>
          <tr>
            <th>열람제한</th>
            <td>{interview.accessModifier}</td>
            <th>추가 열람 가능자</th>
            <td colSpan={3}>
              {stringArrToJSX(
                interview.accessReader.map((x) => x.name),
                ","
              )}
            </td>
          </tr>
          <tr>
            <th>내용</th>
            <td colSpan={5} className={classes.content}>
              {interview.content}
            </td>
          </tr>
          {file && (
            <tr>
              <th>파일</th>
              <td colSpan={5}>
                <FileViewer file={file} page="view" />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Paper>
  );
}

export default ViewInterview;
