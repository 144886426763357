import { Box, makeStyles } from '@material-ui/core';
import { ColumnDirective, ColumnsDirective, Filter, GridComponent, Inject, Page, Sort } from '@syncfusion/ej2-react-grids';
import { useRef } from 'react';
import { useDateHooks } from '../../post-attendance-request/hooks/useDateHooks';
import { GetAttendanceBalance } from '../../post-attendance-request/interface/BalanceInterface';

interface AttendanceBalanceHistoryProps {
  attendanceBalanceList: GetAttendanceBalance[];
}

export default function AttendanceBalanceHistory({ attendanceBalanceList }: AttendanceBalanceHistoryProps) {
  const grid = useRef<GridComponent | null>(null);
  const classes = useStyles();


  const { getDateStringFormat } = useDateHooks();

  return (
    <Box className={classes.customBox}>
      <GridComponent
        ref={grid}
        dataSource={attendanceBalanceList}
        allowPaging={true}
        allowFiltering={true}
        filterSettings={{ type: 'Excel' }}
        pageSettings={{ pageSize: 10 }}
        allowSorting={true}>
        <ColumnsDirective>
          <ColumnDirective
            field='category'
            width='100'
            textAlign='Center'
            headerTemplate={'휴가종류'} />
          <ColumnDirective
            field='term'
            width='100'
            textAlign='Center'
            headerTemplate={'발생개수'} />
          <ColumnDirective
            field='created'
            width='100'
            textAlign='Center'
            valueAccessor={(_, data) =>
              getDateStringFormat((data as GetAttendanceBalance).created, 'YYYY-MM-DD')}
            headerTemplate={'생성일'} />
          <ColumnDirective
            field='expired'
            width='100'
            textAlign='Center'
            valueAccessor={(_, data) =>
              getDateStringFormat((data as GetAttendanceBalance).expired, 'YYYY-MM-DD')}
            headerTemplate={'만료일'} />
        </ColumnsDirective>
        <Inject services={[Page, Sort, Filter]} />
      </GridComponent>
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  customBox: {
    '& .e-pager': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));