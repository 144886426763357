import React, { ReactElement, useEffect, useState } from "react";
import ReactDataGrid, { Column } from "react-data-grid";
import { RewardAndPunishment } from "src/system/types";
import { ErrorHandler, HREmployeeApi } from "src/system/ApiService";
import { getDisplayTime } from "src/system/Helper";

interface Props {
  employeeId: string;
}

function RewardAndPunishmentDataGrid({ employeeId }: Props): ReactElement {
  const [data, setData] = useState<RewardAndPunishment[]>([]);

  useEffect(() => {
    if (employeeId !== "") {
      // API 호출
      HREmployeeApi.GetRewardAndPunishmentList(employeeId)
        .then((res) => {
          setData(res);
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          alert(msg);
        });
    }
  }, [employeeId]);

  const defaultColumnProperties = {
    width: 175,
  };

  const columns = React.useMemo(() => {
    const k = [
      {
        name: "구분",
        key: "category1",
      },
      {
        name: "종류",
        key: "category2",
      },
      {
        name: "확정일",
        key: "confirmed",
        formatter: (props: any) => {
          return <>{getDisplayTime(props.row.confirmed)}</>;
        },
      },
      {
        name: "내용",
        key: "content",
      },
      {
        name: "요청 파트/팀",
        key: "requestTeam",
      },
      {
        name: "요청자",
        key: "requester",
      },
      {
        name: "경위서 작성여부",
        key: "accidentReport",
      },
      {
        name: "경위서 문서번호",
        key: "accidentReportNo",
      },
      {
        name: "인사위원회 결정",
        key: "personalCommitteeDecision",
      },
      {
        name: "최종결정",
        key: "finalDecision",
      },
      {
        name: "문서번호",
        key: "documentNo",
      },
      {
        name: "비고",
        key: "remark",
      },
    ].map((c) => ({ ...c, ...defaultColumnProperties }));
    const nk: Column<RewardAndPunishment>[] = k.map((x) => {
      if (x.key === "id") return x;
      else {
        return {
          ...x,
          // formatter: DefaultFormatter,
        } as Column<RewardAndPunishment>;
      }
    });
    return nk;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ReactDataGrid
        rows={data}
        columns={columns}
        rowKeyGetter={(row) => {
          return row.id;
        }}
        defaultColumnOptions={{
          resizable: true,
        }}
        style={{
          height: data.length === 0 ? 56 : (data.length + 1) * 36 + 20 + "px",
        }}
      ></ReactDataGrid>
    </>
  );
}

export default RewardAndPunishmentDataGrid;
