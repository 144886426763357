import MaterialTable from "material-table";
import React, { Fragment, ReactElement, useCallback, useEffect, useState } from "react";
import NumberInput from "src/components/common/NumberInput";
import { ErrorHandler, InterviewCategoryApi } from "src/system/ApiService";
import { TABLEICONS } from "src/system/Constants";
import { Category } from "src/system/types";

function InterviewCategory(): ReactElement {
  const [state, setState] = useState<Category[]>([]);

  const GetCategory = useCallback(() => {
    InterviewCategoryApi.GetWithoutCache()
      .then(({ data }) => {
        setState(data);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    GetCategory();
  }, [GetCategory]);

  const SuccessHandler = useCallback(() => {
    alert("성공했습니다.");
    GetCategory();
  }, [GetCategory]);

  return (
    <Fragment>
      <MaterialTable
        icons={TABLEICONS}
        title={"면담 카테고리 관리"}
        columns={[
          {
            title: "표시 순서",
            field: "ordering",
            editComponent: NumberInput,
          },
          {
            title: "이름",
            field: "comment",
          },
        ]}
        data={state}
        editable={{
          onRowUpdate: (newData, oldData) => {
            return InterviewCategoryApi.Edit(newData)
              .then(() => {
                SuccessHandler();
              })
              .catch((error) => {
                let msg = "업데이트가 실패하였습니다.\n";
                msg += ErrorHandler(error);
                alert(msg);
              });
          },
          onRowAdd: (newData) => {
            return InterviewCategoryApi.Post(newData)
              .then(() => {
                SuccessHandler();
              })
              .catch((error) => {
                let msg = "추가를 실패하였습니다.\n";
                msg += ErrorHandler(error);
                alert(msg);
              });
          },
          onRowDelete: (oldData) => {
            return InterviewCategoryApi.Delete(oldData.id)
              .then(() => {
                SuccessHandler();
              })
              .catch((error) => {
                let msg = "삭제 실패하였습니다.\n";
                msg += ErrorHandler(error);
                alert(msg);
              });
          },
        }}
        localization={{ body: { editRow: { deleteText: "해당 카테고리를 삭제하시겠습니까?" } } }}
        options={{
          pageSize: 5,
          filtering: false,
        }}
      ></MaterialTable>
    </Fragment>
  );
}

export default InterviewCategory;
