import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { useEffect, useState } from "react";
import { AttendanceRequestApi, ErrorHandler } from "src/system/ApiService";
import { useAttendanceHooks } from '../hooks/useAttendanceHooks';
import { GetAttendanceItem } from "../interface/RequestInterface";
import { useAttendanceStyles } from '../style/useAttendanceStyle';

interface AttendanceItem extends GetAttendanceItem {
  usedLeaveCount: number;
}
interface AMHistoryProps {
  targetEmployeeId: string;
}
export default function ARHistory({ targetEmployeeId }: AMHistoryProps) {
  const classes = useAttendanceStyles();
  const [attendanceItemList, setAttendanceItemList] = useState<AttendanceItem[]>([]);
  const [totalLeaveCount, setTotalLeaveCount] = useState<number>(0);

  const { getAttendanceBalanceCountData } = useAttendanceHooks();

  useEffect(() => {
    const calculateUsedAttendanceCount = (data: GetAttendanceItem[]): AttendanceItem[] => {
      let usedLeaveCount = 0;

      const newData = data.reverse()
        .map(item => {
          usedLeaveCount -= item.term;
          return { ...item, usedLeaveCount: usedLeaveCount };
        })

      return newData.reverse();
    }

    if (targetEmployeeId.trim() !== '') {
      getAttendanceBalanceCountData(targetEmployeeId)
        .then(attendanceBalanceCount => setTotalLeaveCount(attendanceBalanceCount.annualLeave));
    }

    AttendanceRequestApi.GetAttendanceItemList(1, targetEmployeeId)
      .then((attendanceData) => {
        const leaveAttendanceDate = attendanceData.filter(attendance => attendance.term !== 0);
        const newData = calculateUsedAttendanceCount(leaveAttendanceDate);
        setAttendanceItemList(newData);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      });
  }, [targetEmployeeId]);

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table className={classes.table} size='small'>
        <TableHead>
          <TableRow>
            <TableCell colSpan={8} align='center' className='exclude'>
              <Typography variant='body2' style={{ color: 'red', fontWeight: 'bold' }}>
                {"※ 안내 : 사유에 휴가이력을 기재하지 않아도 됩니다."}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={8} align='center'>
              {"휴가원 사용 기록"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell width={'10%'} align='center'>
              {"종류"}
            </TableCell>
            <TableCell width={'15%'} align='center'>
              {"사용일"}
            </TableCell>
            <TableCell width={'15%'} align='center'>
              {"사용시간"}
            </TableCell>
            <TableCell width={'10%'} align='center'>
              {"부서"}
            </TableCell>
            <TableCell width={'10%'} align='center'>
              {"직급"}
            </TableCell>
            <TableCell width={'10%'} align='center'>
              {"대상자"}
            </TableCell>
            <TableCell width={'15%'} align='center'>
              {"사용연차"}
            </TableCell>
            <TableCell width={'15%'} align='center'>
              {"총 연차"}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            attendanceItemList.length === 0 &&
            <TableRow>
              <TableCell align='center' colSpan={8} >
                {"연차 사용 이력이 없습니다."}
              </TableCell>
            </TableRow>
          }
          {attendanceItemList.map((attendance, index) => {
            return (
              <TableRow key={attendance.id + attendance.attendanceRequestId}>
                <TableCell align='center'>
                  {attendance.formName}
                </TableCell>
                <TableCell align='center'>
                  {attendance.workDate.substring(0, 10)}
                </TableCell>
                <TableCell align='center'>
                  {`${attendance.started === null
                    ? "-"
                    : attendance.started.substring(11, 16) +
                    " ~ " +
                    attendance.ended.substring(11, 16)
                    }`}
                </TableCell>
                <TableCell align='center'>
                  {attendance.team}
                </TableCell>
                <TableCell align='center'>
                  {attendance.jobPosition}
                </TableCell>
                <TableCell align='center'>
                  {attendance.name}
                </TableCell>
                <TableCell align='center'>
                  {
                    attendance.leaveType ?
                      attendance.usedLeaveCount
                      : '-'
                  }
                </TableCell>
                <TableCell align='center'>
                  {
                    attendance.leaveType ?
                      totalLeaveCount
                      : '-'
                  }
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}