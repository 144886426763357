import HttpClient, { HttpUrlClient } from "src/system/http-client";
import {
  BusinessPlanEvaluationRecord,
  BusinessPlanScore,
  ObjType,
  PlanEvaluationRecord,
  WorkEvaluationRecord,
} from "src/system/types";

//api 배포 안됨
const businessplanUrl = "https://localhost:5001/api";
const work2Url = "https://localhost:44369/api";

interface AverageQuaterScore {
  quarter: number;
  average: number;
}
interface AverageScore {
  teamId: number;
  array: AverageQuaterScore[];
}

class BusinessPlanApi {
  private client: HttpUrlClient;
  public constructor() {
    this.client = new HttpUrlClient(new HttpClient(businessplanUrl));
  }

  /**
   * 연도에 따른 종합 점수 가져오기
   */
  GetUserClosedEvaluation(year: number, employeeId: string) {
    const data = new FormData();
    data.append("employeeId", employeeId);
    return this.client.post<ObjType>(`closed/${year}`, data);
  }

  /**
   * 사업계획 평가결과 가져오기
   */
  GetEvaluationByUser(year: number, employeeId: string) {
    const data = new FormData();
    data.append("year", year.toString());
    data.append("employeeId", employeeId);
    return this.client.post<ObjType[]>("evaluation/check-user", data);
  }

  /**
   * 파트 별 평균 가져오기
   */
  GetAverageScoreByTeam(year: number, teamId: number): Promise<AverageScore> {
    return this.client
      .post<ObjType[]>("evaluation/avg/businessplan", {
        year: year,
        teamId: teamId,
      })
      .then((res) => {
        return {
          teamId: teamId,
          array: res.map(
            (x) =>
              ({
                quarter: x["quarter"] as number,
                average: x["avg"] as number,
              } as AverageQuaterScore)
          ),
        };
      });
  }

  /**
   * 세모직원권한으로 동그라미직원의 역량평가 결과 보기
   */
  GetCompetencyEvaluationByUser(year: number, evaluatee: string, requester: string): Promise<ObjType[]> {
    const data = new FormData();
    data.append("year", year.toString());
    data.append("evaluatee", evaluatee);
    data.append("requester", requester);
    return this.client.post<ObjType>("competency/show", data).then((res) => res["list2"]);
  }
}

class WorkApi {
  private client: HttpUrlClient;
  public constructor() {
    this.client = new HttpUrlClient(new HttpClient(work2Url));
  }

  /**
   * 과제업무2 기획리스트 가져오기
   * @param employeeId 조회 대상자
   */
  public SelectPlanningWork2List(employeeName: string) {
    const data = new FormData();
    data.append("name", employeeName);
    return this.client.post<WorkEvaluationRecord[]>("work/SelectPlanningWork2List", data).then((res) =>
      res.map((x) => {
        const ary = x.name.split(",");
        return {
          ...x,
          name: ary.length >= 1 ? ary[0] : x.name,
        };
      })
    );
  }
  /**
   * 과제업무1 완료리스트 가져오기 (employeeId ="" 불가능)
   * @param employeeId 조회 대상자
   */
  public GetCompletedWorkList(employeeId: string) {
    return this.client.post<WorkEvaluationRecord[]>("work/SelectRegisteredWorkList", {
      Sidx: "progress",
      Sord: "desc",
      Sector: "",
      League: "",
      Employee_no: employeeId,
      IsProgress: "false",
      IsComplete: "true",
      IsDrop: "false",
    });
  }
}

export class _ScoreApi {
  private planApi: BusinessPlanApi;
  private workApi: WorkApi;
  public constructor() {
    this.planApi = new BusinessPlanApi();
    this.workApi = new WorkApi();
  }
  /**
   * 연도에 따른 종합 점수 가져오기
   */
  GetPlanScoreByUser(year: number, employeeId: string) {
    return this.planApi.GetUserClosedEvaluation(year, employeeId).then((res) => {
      return {
        ...res["retval"],
      } as BusinessPlanScore;
    });
  }
  /**
   * 평가등급 가져오기
   */
  GetEvaluationRank(year: number, employeeId: string): Promise<number> {
    return new Promise((resolve, reject) => {
      resolve(Math.floor(Math.random() * 5) + 1);
    });
  }

  /**
   * 사업계획 평가결과 레코드 가져오기
   */
  GetBusinessPlanEvaluationRecord(year: number, employeeId: string): Promise<BusinessPlanEvaluationRecord[]> {
    let planArray: { planContent: ObjType[]; partId: number; quarter: number }[] = [];

    return this.planApi
      .GetEvaluationByUser(year, employeeId)
      .then((res) => {
        const promiseArray: Promise<AverageScore>[] = [];
        let teamId: number | undefined = undefined;
        res.forEach((x) => {
          const _teamId = x["teamList"]["teamId"] as number;
          if (_teamId !== teamId) {
            promiseArray.push(this.planApi.GetAverageScoreByTeam(year, _teamId));
            teamId = _teamId;
          }
        });

        planArray = res.map((x) => {
          const tempAry = x["businessPlan"]["planContent"] as ObjType[];
          return {
            //array
            planContent: tempAry.length >= 1 ? tempAry.filter((y) => !(y["isBlur"] as boolean)) : [],
            partId: x["teamList"]["teamId"],
            quarter: x["businessPlan"]["quarter"],
          };
        });

        return Promise.all(promiseArray);
      })
      .then((res) => {
        const retList: BusinessPlanEvaluationRecord[] = [];
        planArray.forEach((plan) => {
          const q = plan.quarter;
          //1. content
          plan.planContent.forEach((pc) => {
            const content = pc["businessContent"] || {};
            const evaluations = (pc["evaluation"] as ObjType[]) || [];

            let retVal: BusinessPlanEvaluationRecord = {
              quarter: q,
              title: content["dutySubject"] || "",
              ratio: content["percentage"] || 0,
              average: res.find((x) => x.teamId === plan["partId"])?.array.find((y) => y.quarter === q)?.average || 0,
              mescore: null,
              teamscore: null,
              partscore: null,
            };

            evaluations.forEach((ev, i) => {
              const code = ev["positionCode"] as string;
              const score = ev["score"] as number;
              if (i === 0) {
                //첫번째 일 경우,
                retVal.mescore = score;
              } else if (code === "P_049" || code === "P_050") {
                retVal.teamscore = score;
              } else if (code === "P_020") {
                retVal.partscore = score;
              }
            });
            retList.push(retVal);
          });
        });
        return retList;
      });
  }

  /**
   * 세모직원권한으로 동그라미직원의 역량평가 결과 보기
   */
  GetCompetencyEvaluationRecord(year: number, evaluatee: string, requester: string): Promise<PlanEvaluationRecord[]> {
    return this.planApi.GetCompetencyEvaluationByUser(year, evaluatee, requester).then((res) => {
      const result: PlanEvaluationRecord[] = [];
      //결과가 quarter 순으로 정렬되어 있다.

      let record: PlanEvaluationRecord = {
        quarter: 0,
        mescore: null,
        teamscore: null,
        partscore: null,
      };

      res.forEach((item) => {
        const q = item["quarter"] as number;
        const position = item["positionCode"] as string;
        if (q !== record.quarter) {
          //초기값 제거
          if (record.quarter !== 0) {
            //다르면 이제 새로 넣어야 한다.
            result.push({ ...record });
          }
          record.quarter = item["quarter"] as number;
        }
        if (position === "P_ME") {
          record.mescore = item["score"];
        } else if (position === "P_020") {
          record.partscore = item["score"];
        } else if (position === "P_050" || position === "P_049") {
          record.teamscore = item["score"];
        }
      });
      result.push(record);
      return result;
    });
  }

  /**
   * 과제업무1 완료리스트 가져오기 (employeeId ="" 불가능)
   * @param employeeId 조회 대상자
   */
  public GetCompletedWorkList(employeeId: string) {
    return this.workApi.GetCompletedWorkList(employeeId);
  }

  /**
   * 과제업무2 기획리스트 가져오기
   * @param employeeName 조회 대상자 이름
   */
  public SelectPlanningWork2List(employeeName: string) {
    return this.workApi.SelectPlanningWork2List(employeeName);
  }
}
