import React from "react";
import ReactDOM from "react-dom";
import "src/index.css";
import App from "./App";
import reportWebVitals from "src/reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "src/Style/loader.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./azure/AuthConfig";
import { MsalProvider } from "@azure/msal-react";

const container = document.getElementById("root");

export const msalInstance = new PublicClientApplication(msalConfig);

// registering syncfusion license

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <App />
  </MsalProvider>
  ,
  container
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
