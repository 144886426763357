import { Button, Grid, Paper } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React, { ReactElement, useState, useEffect } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import Page from "src/components/common/Page";
import KeyboardDatePicker from "src/components/KeyboardDatePicker";
import SelectCombobox from "src/components/SelectCombobox";
import SmallTextField from "src/components/SmallTextField";
import { ComboboxItem } from "src/components/TitleOneComboBox";
import WarnMessage from "src/components/WarnMessage";
import { InterviewRecordRoutes } from "src/system/types";
import { ErrorHandler, InterviewApi, InterviewCategoryApi } from "src/system/ApiService";
import { isInArray } from "src/system/Helper";
import { AuthorityCategory, ROWS_PER_PAGE } from "src/system/types";
import { InterviewListItem, InterviewSearch, SearchResult } from "src/system/types";
import { useUserState } from "src/system/UserContext";
import ListTable from "src/ViewParts/ListTable";

interface SearchHeaderProps {
  title: string;
}

function SearchInnerHead({ title }: SearchHeaderProps) {
  return (
    <Grid item xs={2} container alignItems="center">
      <Grid item>{title}</Grid>
    </Grid>
  );
}

interface SearchOptionProps {
  searchOption: InterviewSearch;
}

function SearchHeaderTable({ searchOption }: SearchOptionProps): ReactElement {
  const [state, setState] = useState<InterviewSearch>(searchOption);

  const [categoryOption, setCategoryOption] = useState<ComboboxItem[]>([]);
  const history = useHistory();

  useEffect(() => {
    InterviewCategoryApi.GetAll()
      .then((response) => {
        let k = response.data.map((x) => ({
          key: x.id + "",
          value: x.comment,
        }));
        setCategoryOption(k);
      })
      .catch((error) => {});
  }, []);

  const onChangeText = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    let k = { ...state };
    if (name === "writer") {
      k.writer = value;
    } else if (name === "interviewer") {
      k.interviewer = value;
    } else if (name === "intervieweePart") {
      k.intervieweePart = value;
    } else if (name === "intervieweeTeam") {
      k.intervieweeTeam = value;
    } else if (name === "intervieweeName") {
      k.intervieweeName = value;
    }

    setState(k);
  };

  const onChangeSelect = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { name, value } = event.target;

    let k = { ...state };
    if (name === "category") {
      k.categoryId = parseInt(value + "");
    } else if (name === "dateOption") {
      k.dateOption = value + "";
    }
    setState(k);
  };

  const onSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    history.push({
      pathname: InterviewRecordRoutes.interview + "/list",
      search: "?page=1",
      state: {
        ...state,
        page: 1,
      },
    });
  };

  const onChangeDate = (date: Date | null) => {
    setState({
      ...state,
      date: date === null ? null : date.getTime(),
    });
  };

  return (
    <Paper className="p-3" variant="outlined">
      <Grid container spacing={3} direction="column" justify="space-evenly" alignItems="flex-start">
        <Grid container item>
          <SearchInnerHead title="작성자 검색"></SearchInnerHead>
          <Grid container item xs={10} spacing={2}>
            <Grid item>
              <SmallTextField
                label="작성자"
                value={state.writer}
                name="writer"
                onChange={onChangeText}
              ></SmallTextField>
            </Grid>
            <Grid item>
              <KeyboardDatePicker
                label={"작성일"}
                style={{ marginTop: "0px" }}
                margin={"none"}
                value={null}
                onChange={onChangeDate}
                selecteddate={state.date === null ? undefined : state.date}
              ></KeyboardDatePicker>
            </Grid>
            <Grid item>
              <SelectCombobox
                key="search-page-dateoption"
                name="dateOption"
                value={state.dateOption}
                options={[
                  { key: "prev", value: "이전" },
                  { key: "after", value: "이후" },
                ]}
                onChange={onChangeSelect}
              ></SelectCombobox>
            </Grid>
          </Grid>
        </Grid>

        <Grid container item>
          <SearchInnerHead title="면담 내용 검색"></SearchInnerHead>
          <Grid container item xs={10} spacing={2}>
            <Grid container item spacing={2}>
              <Grid item>
                <SmallTextField
                  label={"면담 접수자"}
                  value={state.interviewer}
                  name="interviewer"
                  onChange={onChangeText}
                ></SmallTextField>
              </Grid>
              <Grid item>
                <SelectCombobox
                  key="search-page-category"
                  empty
                  name="category"
                  value={state.categoryId + ""}
                  options={categoryOption}
                  onChange={onChangeSelect}
                ></SelectCombobox>
              </Grid>
            </Grid>
            <Grid container item spacing={2}>
              <Grid item>
                <SmallTextField
                  label={"면담 대상자 파트"}
                  name="intervieweePart"
                  value={state.intervieweePart}
                  onChange={onChangeText}
                ></SmallTextField>
              </Grid>
              <Grid item>
                <SmallTextField
                  label={"면담 대상자 팀"}
                  name="intervieweeTeam"
                  value={state.intervieweeTeam}
                  onChange={onChangeText}
                ></SmallTextField>
              </Grid>
              <Grid item>
                <SmallTextField
                  label={"면담 대상자 이름"}
                  name="intervieweeName"
                  onChange={onChangeText}
                  value={state.intervieweeName}
                ></SmallTextField>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={onSubmit}>
                  검색
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

function SearchBody({ searchOption }: SearchOptionProps) {
  const [data, setData] = useState<SearchResult<InterviewListItem>>(new SearchResult());
  const history = useHistory();
  const page = searchOption.page;

  const user = useUserState();
  const isShowList = isInArray(user.authority, AuthorityCategory.LIST);

  /** searchOption 에 따라 내용 검색 */
  useEffect(() => {
    let opt = { ...searchOption, page: page };
    InterviewApi.search(opt)
      .then((response) => {
        if (response.status === 204) {
        } else {
          let ret = Math.floor(response.data.count / ROWS_PER_PAGE);
          ret = ret === 0 ? 1 : ret;
          response.data.count = ret;
          setData(response.data);
        }
      })
      .catch((error) => {
        let msg = ErrorHandler(error);

        alert(msg);
      });
  }, [page, searchOption]);

  const onChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    history.push({
      pathname: InterviewRecordRoutes.interview + "/list",
      search: `?page=${page}`,
      state: { ...searchOption, page: page },
    });
  };

  return (
    <>
      {!isShowList && <WarnMessage>데이터를 볼 수 있는 권한이 없습니다.</WarnMessage>}
      <ListTable data={data.list}></ListTable>
      <div className="d-flex justify-content-center">
        <Pagination
          count={data.count}
          defaultValue={1}
          page={data.page}
          showFirstButton
          showLastButton
          onChange={onChangePage}
        ></Pagination>
      </div>
    </>
  );
}

function SearchPage(): ReactElement {
  const { state } = useLocation<InterviewSearch>();
  if (state) {
    return (
      <Page>
        <SearchHeaderTable searchOption={state}></SearchHeaderTable>
        <br />
        {/* 리스트가 나올 곳 */}
        <SearchBody searchOption={state}></SearchBody>
      </Page>
    );
  } else {
    const regex = /page=([\d]+)/;
    const match = regex.exec(window.location.search);

    let page = 1;

    if (match != null && !isNaN(parseInt(match[1]))) {
      page = parseInt(match[1]);
    }

    return (
      <Redirect
        to={{
          pathname: InterviewRecordRoutes.interview + "/list",
          search: `?page=${page}`,
          state: {
            writer: "",
            dateOption: "prev",
            interviewer: "",
            categoryId: null,
            date: null,
            intervieweeName: "",
            intervieweePart: "",
            intervieweeTeam: "",
            page: page,
          },
        }}
      ></Redirect>
    );
  }
}

export default SearchPage;
