import React, { ReactElement, useState } from "react";
import { Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { GetInitSearchResult, QNAItem, QNARoutes, SearchResult } from "src/system/types";
import LockIcon from "@material-ui/icons/Lock";
import { getDateUsingNumber } from "src/system/Helper";
import { Pagination } from "@material-ui/lab";
import { MyStyle } from "src/Style/theme";
import { Button } from "@material-ui/core";
import { useLoadingDispatch } from "src/system/LoadingContext";
import { ErrorHandler, QNAApi } from "src/system/ApiService";

function MarList(): ReactElement {
  const [qnaList, setQnaList] = useState<SearchResult<QNAItem>>(GetInitSearchResult<QNAItem>([]));
  const LoadingDispatch = useLoadingDispatch();
  const classes = MyStyle();

  const tableHeader = (
    <tr style={{ backgroundColor: "#efefef" }}>
      <th className="no">No</th>
      <th className="writer">요청자</th>
      <th className="title">제목</th>
      <th className="date">날짜</th>
      <th className="views">상태</th>
    </tr>
  );

  const LoadData = React.useCallback(() => {
    LoadingDispatch({ type: "LOADING" });

    let pageSize = 10;

    QNAApi.GetQNAList(pageSize, qnaList.page)
      .then((res) => {
        setQnaList(res);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      })
      .finally(() => {
        LoadingDispatch({ type: "COMPLETE" });
      });
  }, [LoadingDispatch, qnaList.page]);

  React.useEffect(() => {
    LoadData();
  }, [LoadData]);

  const tableBody = (item: QNAItem, i: number) => (
    <tr
      className={item.isOpen ? "list-pinned" : undefined}
      key={`tr-${i}-${item.id}`}
      onClick={() => onClickRow(item.id)}
    >
      {/* (row*page)+i+1 */}
      <td>{item.isOpen ? <LockIcon color="secondary" /> : item.id}</td>
      <td>{item.requester}</td>
      <td className="link" data-id={item.id}>
        {/* onClick={_ViewItem}  */}
        {`${item.title}`}
      </td>
      <td>{getDateUsingNumber(item.created)}</td>
      <td>{item.status}</td>
    </tr>
  );

  const history = useHistory();

  // const _ViewItem = (event: React.MouseEvent<HTMLElement>) => {
  //   if (!(event.target instanceof HTMLElement)) {
  //     return;
  //   }
  //   let str = event.target.dataset["id"];
  //   if (str) {
  //     history.push({
  //       pathname: `${QNARoutes.root}/view/${str}`,
  //     });
  //   }
  // };

  const _WriteItem = (event: React.MouseEvent<HTMLElement>) => {
    if (!(event.target instanceof HTMLElement)) {
      return;
    }
    history.push({
      pathname: `${QNARoutes.edit}`,
    });
  };

  const _onPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setQnaList({
      ...qnaList,
      page: value,
    });
  };

  const onClickRow = (id: number) => {
    history.push({
      pathname: `${QNARoutes.root}/view/${id}`,
    });
  };

  return (
    <div className={classes.paperMainWhite}>
      {/* 글쓰기 버튼 */}
      <div className="text-right mb-1">
        <Button variant="contained" color="secondary" style={{ marginRight: "20px" }}>
          My Question
        </Button>
        <Button variant="contained" color="secondary" onClick={_WriteItem}>
          요청하기
        </Button>
      </div>

      <Table className="notice" style={{ borderTop: "2px solid #8a1c22", borderBottom: "2px solid #8a1c22" }}>
        <thead>{tableHeader}</thead>
        <tbody className={classes.link}>{qnaList.list.map((x, i) => tableBody(x, i))}</tbody>
      </Table>

      {/* pagination */}
      {/* <div className="d-flex justify-content-center"> */}
      <div className="d-flex justify-content-center">
        <Pagination
          count={qnaList.totalPage}
          page={qnaList.page}
          onChange={_onPageChange}
          color="primary"
          boundaryCount={5}
        />
      </div>
    </div>
  );
}

export default MarList;
