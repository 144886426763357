import { createStyles, Grid, Typography } from "@material-ui/core";
import withStyles, {
  CSSProperties,
  WithStyles,
} from "@material-ui/core/styles/withStyles";
import React, { PureComponent, ReactElement } from "react";
import DivOverflowShadow from "src/components/DivOverflowShadow";
import { HrStyle, PRIMARY } from "src/Style/theme";

interface TitleTableWrapperProps {
  title?: string;
  children?: React.ReactNode;
  colgroup?: React.ReactNode;
  caption?: string;
  height?: number;
  className?: string;
}

function TableInsaCard({
  title,
  colgroup,
  caption,
  height,
  children,
  className,
}: TitleTableWrapperProps): ReactElement {
  const classes = HrStyle();
  const style: CSSProperties | undefined = colgroup
    ? { tableLayout: "fixed" }
    : undefined;

  return (
    <Grid container className="py-3">
      {title && <TableTitle title={title} />}
      <DivOverflowShadow height={height}>
        <table className={`${classes.table} ${className}`} style={style}>
          {caption && <caption>최종변동일: {caption}</caption>}
          {colgroup}
          <tbody>{children}</tbody>
        </table>
      </DivOverflowShadow>
    </Grid>
  );
}

const TableTitleStyles = createStyles({
  headTitleBar: {
    borderLeft: "8px solid",
    borderColor: PRIMARY,
    paddingLeft: "5px",
    letterSpacing: "-0.5px",
    fontWeight: "bold",
  },
});

interface TableTitleProps extends WithStyles<typeof TableTitleStyles> {
  title: React.ReactNode;
}

export const TableTitle = withStyles(TableTitleStyles)(
  class extends PureComponent<TableTitleProps> {
    render() {
      const { classes, title } = this.props;
      return (
        <>
          <Grid item xs={6} className="pb-2">
            <Typography
              variant="h6"
              component="h3"
              className={classes.headTitleBar}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item xs={6} className="pb-2"></Grid>
        </>
      );
    }
  }
);

export default TableInsaCard;
