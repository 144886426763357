import React, { ReactElement, useEffect, useState } from "react";
import Select from "src/components/Select";
import { MiniMenuItem } from "src/components/SelectScroll";
import { DepartmentApi } from "src/system/ApiService";
import { DeptInfoErp } from "src/system/types";
//viewparts.

interface Props {}

// eslint-disable-next-line no-empty-pattern
export function SelectEmployment({}: Props): ReactElement {
  return (
    <select>
      <option value="기간의정함이없는근로자">기간의정함이없는근로자</option>
      <option value="기간의정함이있는근로자">기간의정함이있는근로자</option>
      <option value="신입사원인턴">신입사원인턴</option>
      <option value="시급제계약직">시급제계약직</option>
      <option value="창업사관학교">창업사관학교</option>
      <option value="카이스트인턴">카이스트인턴</option>
      <option value="소속외근로자">소속외근로자</option>
    </select>
  );
}

export function SelectDepartment(): ReactElement {
  const [state, setState] = useState<DeptInfoErp[]>([]);
  useEffect(() => {
    DepartmentApi.GetDeptInfoErp(new Date().getFullYear(), "active").then((res) => setState(res.result));
    return () => {};
  }, []);
  return (
    <Select showRow={8} style={{ minWidth: "150px" }}>
      {state.map((x) => (
        <MiniMenuItem value={x.costCenter}>{x.erpName}</MiniMenuItem>
      ))}
    </Select>
  );
}
