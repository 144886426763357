import { createStyles, Tabs, Tab, Theme, withStyles } from "@material-ui/core";
import { PRIMARY } from "src/system/Constants";

export interface StyledTabsProps {
  value: number | boolean;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

export const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      //maxWidth: 50,
      width: "100%",
      backgroundColor: PRIMARY,
    },
  },
})((props: StyledTabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />
));

export interface StyledTabProps {
  label: string;
  to: string;
}

export const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: "80px",

      fontWeight: theme.typography.fontWeightRegular,
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.1),
      marginRight: "10px",

      "&:focus": {
        opacity: 1,
      },
    },
  })
)((props: StyledTabProps) => {
  return <Tab disableRipple {...props} />;
});
