import { Button } from "@material-ui/core";
import React, { ReactElement, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Page from "src/components/common/Page";
import SideBar from "src/components/common/SideBar";
import { InterviewRecordRoutes } from "src/system/types/type";
import { ErrorHandler, IssueApi } from "src/system/ApiService";
import { InitIssue, Issue, IssueInterviewItem } from "src/system/types";
import { useUserState } from "src/system/UserContext";
import ViewIssue from "src/ViewParts/ViewIssue";
import { IsEdit } from "src/page/interview/ViewPage";
import { useIssueId } from "../interview/EditInit";
import ViewInterview from "src/ViewParts/ViewInterview";

function ViewPage(): ReactElement {
  const issueId = useIssueId();
  const [data, setData] = useState<Issue>(InitIssue);
  const [interviewData, setInterviewData] = useState<IssueInterviewItem[]>();
  const history = useHistory();
  const user = useUserState();

  const btnEdit = function (event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    history.push({
      pathname: InterviewRecordRoutes.issue + `/edit/${issueId}`,
    });
  };

  useEffect(() => {
    let _issueId = parseInt(issueId);
    if (!isNaN(_issueId)) {
      Promise.all([IssueApi.getIssue(_issueId), IssueApi.getInterviews(_issueId)])
        .then((values: any) => {
          setData(values[0]);
          setInterviewData(values[1]);
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          alert(msg);
          console.error(error);
        });
    }
    return () => {};
  }, [issueId]);

  return (
    <Page>
      {/* 작성자 본인이거나 insa1 아이디라면 btnEdit을 보여준다. */}
      {IsEdit(data.writer.employeeId, user.employeeId) && (
        <SideBar>
          <Button variant="outlined" key="btnEdit" onClick={btnEdit}>
            수정
          </Button>
        </SideBar>
      )}

      <ViewIssue issue={data}></ViewIssue>
      {/* 면담은 수정할 수 없음. */}
      {interviewData && (
        <React.Fragment>
          <br />
          <h4>관련된 면담 목록</h4>
          {interviewData.map((item, index) => (
            <div className="pb-2">
              <ViewInterview
                key={`${item.interview.id}-${index}`}
                interview={item.interview}
                file={item.file}
              ></ViewInterview>
            </div>
          ))}
        </React.Fragment>
      )}
    </Page>
  );
}

export default ViewPage;
