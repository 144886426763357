import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { useFormContext } from "react-hook-form";
import { AttendanceDocumentForm } from "../PostAttendanceRequest";
import { useTitleHooks } from "../hooks/useTitleHooks";
import { useAttendanceStyles } from "../style/useAttendanceStyle";
import { useUserState } from "src/system/UserContext";

export default function Title() {
  const user = useUserState();
  const classes = useAttendanceStyles();
  const { register } = useFormContext<AttendanceDocumentForm>();

  const {
    useChangeTitleWhenAttendanceItemChanged,
    useChangeTitleWhenTitleRefreshAtomChanged,
  } = useTitleHooks();

  useChangeTitleWhenAttendanceItemChanged();
  useChangeTitleWhenTitleRefreshAtomChanged();

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table className={classes.table} size="small">
        <TableBody>
          <TableRow>
            <TableCell component="th" width={"15%"} align="center">
              {"제목"}
            </TableCell>
            <TableCell width={"85%"} align="center">
              <TextBoxComponent cssClass="e-outline" {...register("title")} />
            </TableCell>
          </TableRow>
          <TableRow className="border-bottom">
            <TableCell component="th" align="center">
              {"작성자"}
            </TableCell>
            <TableCell align="center">
              <TextBoxComponent
                cssClass="e-outline"
                value={`${user.name} / ${user.parentTeamName} / ${user.jobPosition} / ${user.employeeNo}`}
                enabled={false}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
