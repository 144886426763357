import { CircularProgress, createStyles, makeStyles, Theme } from "@material-ui/core";
import React, { ReactElement, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { UserApi } from "src/system/ApiService";
import { CommonRoutes } from "src/system/types";
import { useUserDispatch } from "src/system/UserContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      top: "50%",
      left: "50%",
      position: "absolute",
      "& > * + *": {
        marginLeft: theme.spacing(2),
      },
    },
  })
);

function Logout(): ReactElement {
  const dispatch = useUserDispatch();
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    UserApi.Logout()
      .then(() => {
        dispatch({ type: "LOGOUT" });
      })
      .catch(() => {
        alert("error");
      })
      .finally(() => {
        history.push(CommonRoutes.login);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <CircularProgress />
    </div>
  );
}

export default Logout;
