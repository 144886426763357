import React, { Fragment, ReactElement, MouseEvent, useCallback, MutableRefObject } from "react";
import { Aftercare, initAftercare, initDetailCare, initEmployee } from "src/system/types";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton, Button } from "@material-ui/core";
import { Table } from "react-bootstrap";
import { getDateUsingDate } from "src/system/Helper";
import { ComboboxItem, TitleOneComboBox } from "src/components/TitleOneComboBox";
import KeyboardDatePicker from "src/components/KeyboardDatePicker";
import MultilineTextField from "src/components/MultilineTextField";
import WriteDetailTarget from "./WriteDetailTarget";
import { useInterviewDispatch } from "src/system/InterviewContext";
import { AutocompleteChangeDetails, AutocompleteChangeReason } from "@material-ui/lab";
import { MyStyle } from "src/Style/theme";

interface WriteItem2Props {
  state: Aftercare;
  nextId: MutableRefObject<number>;
  empSelectOptions: ComboboxItem[];
}

function WriteItem2({ state, empSelectOptions, nextId }: WriteItem2Props): ReactElement {
  const classes = MyStyle();
  const dispatch = useInterviewDispatch();

  const onDelete = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (event.currentTarget.hasAttribute("data-id") && event.currentTarget.dataset.id) {
        let k = parseInt(event.currentTarget.dataset.id);
        if (!isNaN(k)) {
          dispatch({ type: "REMOVE-AFTERCARE", id: k });
        }
      }
    },
    [dispatch]
  );

  const onChangeTaskMaster = (
    event: React.ChangeEvent<{}>,
    value: ComboboxItem | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<ComboboxItem> | undefined
  ) => {
    if (reason === "select-option") {
      let k = { ...state };
      if (value) {
        k.taskMaster = { employeeId: value.key, name: value.value, team: "" };
        dispatch({ type: "CHANGE-AFTERCARE", id: state.id, item: k });
      }
    } else if (reason === "clear") {
      dispatch({
        type: "CHANGE-AFTERCARE",
        id: state.id,
        item: {
          ...state,
          taskMaster: initEmployee,
        },
      });
    }
  };

  const onChangeDate = (type: string, date: Date) => {
    let k = { ...state, [type]: date.getTime() };
    dispatch({ type: "CHANGE-AFTERCARE", item: k, id: state.id });
  };

  const ChangeEstimated = (date: Date | null) => {
    if (date) onChangeDate("estimated", date);
  };

  const ChangeCompleted = (date: Date | null) => {
    if (date) onChangeDate("completed", date);
  };

  const textfieldChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    let k = { ...state, [name]: value };

    dispatch({ type: "CHANGE-AFTERCARE", item: k, id: state.id });
  };

  const addDetailCare = (event: React.MouseEvent<HTMLButtonElement>) => {
    let k = { ...initDetailCare };
    k.id = nextId.current;
    nextId.current -= 1;
    dispatch({ type: "ADD-DETAILCARE", parentId: state.id, item: k });
  };

  return (
    <div>
      <div className={classes.textRight}>
        <IconButton aria-label="delete" onClick={onDelete} data-id={state.id}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
      <Table>
        <tbody>
          <tr>
            <th>책임자</th>
            <td>
              <TitleOneComboBox
                onChange={onChangeTaskMaster}
                userSelected={{
                  key: state.taskMaster.employeeId,
                  value: state.taskMaster.name,
                }}
                options={empSelectOptions}
              ></TitleOneComboBox>
            </td>
            <th>작성일</th>
            <td>{getDateUsingDate(new Date())}</td>
          </tr>
          <tr>
            <th>완료 예상일</th>
            <td>
              <KeyboardDatePicker
                value={null}
                onChange={ChangeEstimated}
                selecteddate={new Date(state.estimated)}
              ></KeyboardDatePicker>
            </td>
            <th>실완료일</th>
            <td>
              <KeyboardDatePicker
                value={null}
                onChange={ChangeCompleted}
                selecteddate={state.completed}
              ></KeyboardDatePicker>
            </td>
          </tr>
          <tr>
            <td colSpan={4}>
              <MultilineTextField
                name="content"
                rows={5}
                onChange={textfieldChange}
                value={state.content}
                placeholder="사후관리 내용을 작성해주세요"
              ></MultilineTextField>
            </td>
          </tr>
        </tbody>
      </Table>
      <Button variant="outlined" onClick={addDetailCare}>
        세부관리사항 추가
      </Button>
      {state.detailcares.map((x, index) => (
        <WriteDetailTarget key={`div-detailTarget-${index}`} parentId={state.id} state={x}></WriteDetailTarget>
      ))}
    </div>
  );
}

const WriteItem = React.memo(WriteItem2);
interface Props {
  empSelectOptions: ComboboxItem[];
  data: Aftercare[];
}

function WriteAftercare({ empSelectOptions, data: aftercares }: Props) {
  //const { aftercares } = useInterviewState();
  const dispatch = useInterviewDispatch();
  const nextId = React.useRef(-1);

  const addAftercare = function (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) {
    event.preventDefault();
    let k = { ...initAftercare };
    k.id = nextId.current;
    nextId.current -= 1;
    dispatch({ type: "ADD-AFTERCARE", item: k });
  };

  return (
    <Fragment>
      <Button variant="contained" onClick={addAftercare}>
        사후관리 추가하기
      </Button>
      {aftercares.map((x, index) => (
        <WriteItem
          nextId={nextId}
          key={`div-aftercare-${index}`}
          state={x}
          empSelectOptions={empSelectOptions}
        ></WriteItem>
      ))}
    </Fragment>
  );
}

//export default WriteAftercare;
//const WriteAftercare = React.memo(WriteAftercare2);
export default WriteAftercare;
