import { Grid } from "@material-ui/core";
import React, { ReactElement } from "react";
import RegularColgroup from "src/components/RegularColgroup";
import TableInsaCard from "src/components/TableInsaCard";
import { getDisplayTime } from "src/system/Helper";
import { PersonalData, RowView } from "src/system/types";

interface Props extends RowView {
  data: PersonalData;
}

function BasicUserView({ data }: Props): ReactElement {
  var elapsedMSec =
    data.workStatus === "퇴사"
      ? new Date(data.workRecordLastModified).getTime() -
        new Date(data.joined).getTime()
      : new Date().getTime() - new Date(data.joined).getTime();
  const elapsedYear = (elapsedMSec / 1000 / 60 / 60 / 24 / 365).toFixed(1);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <TableInsaCard
            title="개인정보"
            caption={getDisplayTime(data.lastModified)}
            colgroup={<RegularColgroup cols={7}></RegularColgroup>}
          >
            <tr>
              <td
                rowSpan={4}
                width={150}
                height={200}
                style={{ textAlign: "center" }}
              >
                사진
                {/* <img alt="사용자 이미지" src={"https://picsum.photos/163/198"} width="160px"></img> */}
              </td>
              <th>성명</th>
              <td>{data.name}</td>
              <th>영문명</th>
              <td>{data.enName}</td>
              <th>성별</th>
              <td>{data.gender === "M" ? "남자" : "여자"}</td>
            </tr>
            <tr>
              <th>사번</th>
              <td>{data.employeeNo}</td>
              <th>생일</th>
              <td>{getDisplayTime(data.birthday)}</td>
              <th>핸드폰 번호</th>
              <td>{data.phone}</td>
            </tr>
            <tr>
              <th>주소</th>
              <td colSpan={3}>{`${data.address1} ${data.address2}`}</td>
              <th>우편번호</th>
              <td>{data.zip}</td>
            </tr>
            <tr style={{ borderTop: "double" }}>
              <th>M365 e-mail</th>
              <td colSpan={3}>{data.gwMail}</td>
              <th>국적</th>
              <td>{data.nationality}</td>
            </tr>
          </TableInsaCard>
        </Grid>
        <Grid xs={12} item>
          <TableInsaCard
            title="사내정보"
            caption={getDisplayTime(data.workRecordLastModified)}
            colgroup={<RegularColgroup cols={6}></RegularColgroup>}
          >
            <tr>
              <th>소속</th>
              <td>{data.workplace}</td>
              <td>{data.department}</td>
              <td>{data.team}</td>
              <th>근무상태</th>
              <td>
                {data.workStatus && "입사재입사복직".includes(data.workStatus)
                  ? "재직"
                  : data.workStatus}
              </td>
            </tr>
            <tr>
              <th>직위</th>
              <td>{data.jobPositionRank}</td>
              <th>직급</th>
              <td>{data.jobPosition}</td>
              <th>직책</th>
              <td>{data.jobTitle}</td>
            </tr>
            <tr>
              <th>입사일</th>
              <td>{getDisplayTime(data.joined)}</td>
              <th>근속연수</th>
              <td>{elapsedYear}</td>
              <th>고용형태</th>
              <td>{data.employmentForm}</td>
            </tr>
          </TableInsaCard>
        </Grid>
      </Grid>
    </>
  );
}

export default BasicUserView;
