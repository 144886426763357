import { Button, Grid, TextField } from "@material-ui/core";
import React, { ReactElement, useState, useEffect, useCallback } from "react";
import { Table } from "react-bootstrap";
import SelectCombobox from "src/components/SelectCombobox";
import TitleMultipleComboBox from "src/components/TitleMultipleComboBox";
import { ComboboxItem } from "src/components/TitleOneComboBox";
import PaperContainer from "src/components/WriteContainer";
import { MyStyle } from "src/Style/theme";
import { ErrorHandler, IssueApi, IssueCategoryApi } from "src/system/ApiService";
import { GetComboboxList, getDateUsingNumber } from "src/system/Helper";
import { useInterviewDispatch } from "src/system/InterviewContext";
import { IssueListMiniItem, IssueSearchMiniData, SearchResult } from "src/system/types";

interface SearchIssueProps {
  onCloseModal: () => void;
  empSelectOptions: ComboboxItem[];
}

function SearchIssue({ onCloseModal, empSelectOptions }: SearchIssueProps): ReactElement {
  const [listData, setListData] = useState<SearchResult<IssueListMiniItem>>(new SearchResult());
  const [categoryOption, setCategoryOption] = useState<ComboboxItem[]>([]);
  const [searchData, setSearchData] = useState<IssueSearchMiniData>({
    id: null,
    categoryId: 0,
    concerneds: [],
    writer: null,
    page: 1,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const concerneds: ComboboxItem[] = GetComboboxList(empSelectOptions, searchData.concerneds);
  const classes = MyStyle();
  const dispatch = useInterviewDispatch();

  const onSearch = useCallback(() => {
    setLoading(true);
    IssueApi.search(searchData)
      .then((data) => {
        let k = new SearchResult<IssueListMiniItem>();
        k.page = data.page;
        k.count = data.count;
        k.list = data.list.map((x) => ({
          id: x.id,
          category: x.category,
          content: x.content,
          occurred: x.occurred,
          writer: x.writer,
        }));
        setListData(k);
      })
      .catch((error) => {
        alert(ErrorHandler(error));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchData]);

  useEffect(() => {
    //GetCategory();
    IssueCategoryApi.GetCategoryCombobox()
      .then((data) => {
        setCategoryOption(data);
      })
      .catch((error) => {
        alert("카테고리를 불러오는데 실패하였습니다.");
      });
    onSearch();
  }, [onSearch]);

  const onChangeSelect = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { name, value } = event.target;
    if (name !== undefined) {
      setSearchData({ ...searchData, [name]: value });
    }
  };

  const onChangeText = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.currentTarget;

    let k = { ...searchData };
    if (name === "issueId") {
      if (!isNaN(parseInt(value))) {
        k.id = parseInt(value);
      } else {
        k.id = null;
      }
    } else if (name === "writer") {
      k.writer = value;
    }

    setSearchData(k);
  };

  const onClickIssue = (issueId: number) => {
    //Issue 가져오기
    IssueApi.getIssue(issueId)
      .then((data) => {
        //dispatch Issue
        dispatch({ type: "ADD-ISSUE", item: data });
      })
      .catch((error) => {
        //setModalClose
        alert("데이터를 불러올 수 없습니다.");
      })
      .finally(() => {
        onCloseModal();
      });
  };

  const ChangeConcerneds = (event: React.ChangeEvent<{}>, value: ComboboxItem[] | null) => {
    let k = { ...searchData };
    if (value) {
      k.concerneds = value.map((x) => ({
        employeeId: x.key,
        name: x.value,
        team: "",
      }));

      setSearchData(k);
    }
  };

  const onKeyPressEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  return (
    <div>
      <Grid container spacing={1}>
        <PaperContainer>
          <Grid container item xs={12} spacing={1}>
            <Grid item>
              <TextField
                type="number"
                label={"이슈번호"}
                name="issueId"
                value={searchData.id}
                onChange={onChangeText}
                onKeyDown={onKeyPressEnter}
              ></TextField>
            </Grid>
            <Grid item>
              <SelectCombobox
                key="search-issue-categoryId"
                name="categoryId"
                label="이슈 구분"
                empty
                value={searchData.categoryId}
                options={categoryOption}
                onChange={onChangeSelect}
              ></SelectCombobox>
            </Grid>
            <Grid item>
              <TextField onChange={onChangeText} label={"작성자"} name="writer" onKeyDown={onKeyPressEnter}></TextField>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={1}>
            <Grid item>
              <TitleMultipleComboBox
                label={"관련자"}
                userSelected={concerneds}
                onChange={ChangeConcerneds}
                options={empSelectOptions}
              ></TitleMultipleComboBox>
            </Grid>
            <Grid item xs={1}>
              <Button onClick={onSearch} color="primary" variant="contained">
                검색
              </Button>
            </Grid>
          </Grid>
        </PaperContainer>
      </Grid>
      <br />
      <div style={{ height: "50%", overflow: "auto" }}>
        <Table className={loading ? classes.Blur : undefined}>
          <thead>
            <tr>
              <th>이슈번호</th>
              <th>카테고리</th>
              <th>상세내용(최대 25자)</th>
              <th>발생일</th>
              <th>작성자</th>
              <th>선택</th>
            </tr>
          </thead>
          <tbody>
            {listData.count === 0 && (
              <tr>
                <td colSpan={5} className={"text-center"}>
                  표시할 데이터가 없습니다.
                </td>
              </tr>
            )}
            {listData.list.map((x, i) => (
              <tr key={`table-tr-${i}`}>
                <td key={`table-td-id-${i}`}>{x.id}</td>
                <td key={`table-td-category-${i}`}>{x.category}</td>
                <td key={`table-td-content-${i}`}>{x.content}</td>
                <td key={`table-td-occurred-${i}`}>{getDateUsingNumber(x.occurred)}</td>
                <td key={`table-td-name-${i}`}>{x.writer.name}</td>
                <td key={`table-td-btn-${i}`}>
                  <Button onClick={() => onClickIssue(x.id)} variant="outlined" disabled={loading}>
                    선택
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default SearchIssue;
