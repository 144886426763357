import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React, { ReactElement, useState } from "react";
import { ErrorHandler, HRCategoryApi } from "src/system/ApiService";
import { CATEGORY_TABLE_ARRAY, HRCategory } from "src/system/types";
import Category1TableGrid from "./Category1TableGrid";
import Category2TableGrid from "./Category2TableGrid";

function MainPage(): ReactElement {
  const [category1, setCategory1] = useState<HRCategory[]>([]);
  const [category2, setCategory2] = useState<HRCategory[]>([]);
  const [category1Dic, setCategory1Dic] = useState<{}>({});
  const [table, setTable] = useState<string>("WORKRECORD");

  const onChangeTable = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const value = event.target.value as string;
    setTable(value);
  };

  const refreshCategory1 = React.useCallback(() => {
    // GET API 호출
    HRCategoryApi.GetCategoryList(1, table)
      .then((res) => {
        setCategory1(res);
        // 카테고리 1 dictionary 생성
        let temp: any = {};
        res.forEach((x) => {
          temp[x.id] = x.name;
        });
        setCategory1Dic(temp);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      });
  }, [table]);

  const refreshCategory2 = React.useCallback(() => {
    // GET API 호출
    HRCategoryApi.GetCategoryList(2, table)
      .then((res) => {
        setCategory2(res);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      });
  }, [table]);

  React.useEffect(() => {
    refreshCategory1();
    refreshCategory2();
  }, [refreshCategory1, refreshCategory2]);

  return (
    <div>
      <Alert severity="info">
        <AlertTitle>도움말</AlertTitle>
        <ul>
          <li>QNA, 인사변동, 근무상태 변동에 사용하는 분류를 편집할 수 있습니다.</li>
        </ul>
      </Alert>
      {/* 테이블 선택 */}
      <FormControl style={{ margin: "10px 0px 10px 20px" }}>
        <InputLabel id="select-placeholder">카테고리 테이블 선택</InputLabel>
        <Select name="year" value={table} onChange={onChangeTable} style={{ width: "200px" }}>
          {CATEGORY_TABLE_ARRAY.map((u) => (
            <MenuItem value={u.value} key={`tables-${u.name}-${u.value}`}>
              {u.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {/* 대분류 테이블 */}
      <Category1TableGrid table={table} data={category1} refreshData={refreshCategory1}></Category1TableGrid>
      {/* 소분류 테이블 */}
      <Category2TableGrid
        table={table}
        data={category2}
        dic={category1Dic}
        refreshData={refreshCategory2}
      ></Category2TableGrid>
    </div>
  );
}

export default MainPage;
