import { AppBar, Paper, Tab } from "@material-ui/core";
import React, { ReactElement } from "react";
import TabPanel from "src/components/TabPanel";
import BasicMainPage from "src/page/card/basic/MainPage";
import WorkMainPage from "src/page/card/work/MainPage";
import LisencePage from "src/page/card/lisence/MainPage";
import Page from "src/components/common/Page";
import { StyledTabs } from "src/components/StyledTabs";

interface CardTapsMenuProps {
  employeeId: string;
}

function CardTapsMenu({ employeeId }: CardTapsMenuProps): ReactElement {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  function a11yProps(index: any, label: string) {
    return {
      id: `simple-tab-${index}`,
      label: `${label}`,
    };
  }

  return (
    <div>
      <Paper style={{ marginTop: "20px", backgroundColor: "white" }}>
        <AppBar position="static" color="inherit">
          {/* 직원관리 탭 메뉴 */}
          <StyledTabs value={value} onChange={handleChange}>
            <Tab {...a11yProps(0, "기본정보")} />
            <Tab {...a11yProps(1, "재직정보")} />
            <Tab {...a11yProps(2, "교육/자격/어학정보")} />
            {/* <Tab {...a11yProps(3, "평가정보")} /> */}
          </StyledTabs>
          {/* 탭 리스트 */}
          <TabPanel value={value} index={0}>
            <Page>
              <BasicMainPage employeeId={employeeId}></BasicMainPage>
            </Page>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <WorkMainPage employeeId={employeeId}></WorkMainPage>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <LisencePage employeeId={employeeId}></LisencePage>
          </TabPanel>
          {/* <TabPanel value={value} index={3}>
            <EvaluatePage employeeId={employeeId}></EvaluatePage>
          </TabPanel> */}
        </AppBar>
      </Paper>
    </div>
  );
}

export default CardTapsMenu;
