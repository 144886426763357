import { Paper } from "@material-ui/core";
import React, { ReactNode, ReactElement } from "react";

interface Props {
  children: ReactNode;
}

function PaperContainer({ children }: Props): ReactElement {
  return (
    <Paper className="p-3" variant="outlined">
      {children}
    </Paper>
  );
}

export default PaperContainer;
