import { makeStyles, Theme, Typography } from "@material-ui/core";
import React, { Fragment, useState, ReactElement } from "react";
import { useHistory, useParams } from "react-router-dom";
import Page from "src/components/common/Page";
import { PrimaryTab, PrimaryTabs } from "src/components/PrimaryTab";
import AuthorityPage from "./AuthorityPage";
import DetailcareList from "./DetailcareList";
import InterviewCategory from "./InterviewCategory";
import IssueCategory from "./IssueCategory";

const useStyles = makeStyles((theme: Theme) => ({
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
}));

export enum AdminTabRoute {
  interviewCategory = "interview-category",
  issueCategory = "issue-category",
  detailcareList = "carelist",
  userAuthority = "user-authority",
}

const TabInformation = [
  { label: "면담 카테고리", route: "interview-category" },
  { label: "이슈 카테고리", route: "issue-category" },
  { label: "사후관리 리스트", route: "carelist" },
  { label: "권한 관리", route: AdminTabRoute.userAuthority },
];

interface ParamProps {
  tab: string;
}

function Index(): ReactElement {
  const classes = useStyles();

  const history = useHistory();
  const { tab } = useParams<ParamProps>();

  const initValue = TabInformation.findIndex((ele) => ele.route === tab);

  const [value, setValue] = useState(initValue === -1 ? 0 : initValue);

  const onChangeTabs = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  //일종의 라우트
  const ChangeRoute = (event: React.MouseEvent<HTMLAnchorElement>) => {
    const ele = event.currentTarget.dataset.route;
    if (ele) {
      history.push(ele);
    }
  };

  return (
    <Fragment>
      <Page>
        {/* Tab */}
        <div className={classes.demo1}>
          <PrimaryTabs value={value} onChange={onChangeTabs}>
            {TabInformation.map((x) => (
              <PrimaryTab label={x.label} key={x.route} onClick={ChangeRoute} data-route={x.route} />
            ))}
          </PrimaryTabs>
          <Typography className={classes.padding} />
        </div>

        {/* Tab 내용물 */}
        <Body routeName={tab}></Body>
      </Page>
    </Fragment>
  );
}

interface BodyProps {
  routeName: string;
}

function Body({ routeName }: BodyProps): ReactElement {
  switch (routeName) {
    case AdminTabRoute.interviewCategory:
      return <InterviewCategory></InterviewCategory>;
    case AdminTabRoute.issueCategory:
      return <IssueCategory></IssueCategory>;
    case AdminTabRoute.detailcareList:
      return <DetailcareList></DetailcareList>;
    case AdminTabRoute.userAuthority:
      return <AuthorityPage></AuthorityPage>;
    default:
      return <div>Error to serve</div>;
  }
}

export default Index;
