import { Fab, Tooltip, makeStyles } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';

interface CreateFloatingButtonProps {
  handleSubmit: any;
}

const useStyles = makeStyles((theme) => ({
  floating: {
    position: 'fixed',
    bottom: '50px',
    right: '30px',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function CreateFloatingButton({ handleSubmit }: CreateFloatingButtonProps) {
  const classes = useStyles();

  return (
    <Fab
      className={classes.floating}
      onClick={handleSubmit}
      color="primary"
      variant="extended"
      aria-label="create">
      <CreateIcon className={classes.extendedIcon} />
      저장하기
    </Fab>
  );
}