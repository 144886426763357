import React, { ReactElement } from "react";
import { useHistory, useParams } from "react-router-dom";
import { StyledTabs, StyledTab } from "src/components/StyledTabs";

interface Props {
  children: React.ReactNode;
}

function MainLayout({ children }: Props): ReactElement {
  const history = useHistory();
  const { sub1 } = useParams<{ sub1: string }>();
  const array = [
    { label: "사원 추가", to: "employeeAdd" },
    { label: "사원 엑셀 업로드", to: "employee" },
    { label: "사원 삭제", to: "employeeDelete" },
    { label: "파트/팀 관리", to: "department" },
    { label: "카테고리 관리", to: "category" },
    { label: "인사정보항목 관리", to: "constant" },
    { label: "Teams(V4)정보 관리", to: "teamsInfo" },
    { label: "증빙서류발급 관리", to: "evidence" },
  ];

  const index = array.findIndex((x) => x.to === sub1);

  const onChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    const btnEle = event.currentTarget as HTMLButtonElement;
    const path = (btnEle.getAttribute("to") as string) || "/department";
    history.push("/manage-hr/" + path);
  };

  return (
    <div>
      <StyledTabs value={index === -1 ? false : index} onChange={onChange}>
        {array.map((x) => (
          <StyledTab key={x.to} label={x.label} to={x.to} />
        ))}
      </StyledTabs>
      <br></br>
      {/* go to Route/HRRecord/ManageHRRoute.tsx */}
      {children}
    </div>
  );
}

export default MainLayout;
