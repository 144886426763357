import { Alert, AlertTitle } from "@material-ui/lab";
import MaterialTable from "material-table";
import React, { ReactElement, useEffect, useState } from "react";
import { ErrorHandler, HREmployeeApi } from "src/system/ApiService";
import { TABLE_HEADER } from "src/system/Constants";
import { LMATERIAL_TABLE } from "src/system/Localization";
import { EmployeeAutoComplete } from "src/system/types";

function MainPage(): ReactElement {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [data, setData] = useState<EmployeeAutoComplete[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);

  useEffect(() => {
    HREmployeeApi.GetEmployeeList()
      .then((res) => {
        setData(res);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      });
  }, [refresh]);

  const refreshData = () => {
    setRefresh(!refresh);
  };

  return (
    <div>
      <Alert severity="info">
        <AlertTitle>도움말</AlertTitle>
        <ul>
          <li>사원정보를 삭제합니다.</li>
        </ul>
      </Alert>
      <br></br>
      <MaterialTable
        options={{
          //toolbar: false,
          actionsColumnIndex: -1,
          search: true,
          showTitle: false,
          padding: "dense",
          paging: true,
          pageSize: 15,
          filtering: true,
          headerStyle: TABLE_HEADER,
        }}
        editable={{
          onRowDelete: (oldData) =>
            new Promise((resolve: (value: any) => void, reject) => {
              setTimeout(() => {
                if (
                  window.confirm(
                    "인사카드 삭제 시 정보를 다시 복구할 수 없습니다.\r\n 정말 삭제하시겠습니까? \r\n ※주의 : 퇴사의 개념이 아니라 정보의 완전 삭제입니다."
                  )
                ) {
                  HREmployeeApi.DeleteEmployeeById(oldData.employeeId).then(
                    () => {
                      refreshData();
                    }
                  );
                }
                resolve(0);
              }, 1000);
            }),
        }}
        columns={[
          {
            title: "ID",
            field: "employeeId",
            editable: "never",
            filtering: false,
          },
          { title: "이름", field: "name" },
          {
            title: "E-mail",
            field: "email",
          },
        ]}
        data={data}
        localization={LMATERIAL_TABLE}
      ></MaterialTable>
    </div>
  );
}

export default MainPage;
