import { Paper } from "@material-ui/core";
import React, { ReactElement, ReactNodeArray } from "react";
import { Table } from "react-bootstrap";

interface Props {
  children?: ReactNodeArray;
  title?: string;
}

function TableWrapper({ title, children }: Props): ReactElement {
  return (
    <Paper variant="outlined" className="p-1">
      {title && title.length >= 1 && <h4>{title}</h4>}
      <Table>
        <tbody>{children && children.map((x) => x)}</tbody>
      </Table>
    </Paper>
  );
}

export default TableWrapper;
