import { Paper } from "@material-ui/core";
import React, { ReactElement } from "react";
import { Table } from "react-bootstrap";
import { getDateUsingNumber } from "src/system/Helper";
import { Aftercare } from "src/system/types";
import ViewDetailTarget from "./ViewDetailTarget";
import { MyStyle } from "src/Style/theme";

interface Props {
  data: Aftercare;
}

function ViewAftercare({ data }: Props): ReactElement {
  const classes = MyStyle();

  return (
    <Paper variant="outlined" className="p-3">
      <h4>사후관리</h4>
      <Table>
        <thead>
          <tr>
            <th style={{ width: "15%" }}>책임자</th>
            <th>완료예상일</th>
            <th>실완료일</th>
            <th>작성일</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{data.taskMaster.name}</td>
            <td>{getDateUsingNumber(data.estimated)}</td>
            <td>{getDateUsingNumber(data.completed)}</td>
            <td>{getDateUsingNumber(data.created)}</td>
          </tr>
          <tr>
            <th>사후관리 내용</th>
            <td colSpan={3} className={classes.content}>
              {data.content}
            </td>
          </tr>
        </tbody>
      </Table>
      {data.detailcares.length >= 1 && (
        <div className="p-2" key={`detailcare-wrapper`}>
          <h5>세부관리</h5>
          {data.detailcares.map((x) => (
            <ViewDetailTarget key={`detailcare-${x.id}`} data={x}></ViewDetailTarget>
          ))}
        </div>
      )}
    </Paper>
  );
}

export default ViewAftercare;
