import { Divider } from "@material-ui/core";
import { Copyright } from "@material-ui/icons";
import React, { ReactElement } from "react";
import { Container } from "react-bootstrap";
import { MyStyle, SECONDARY } from "src/Style/theme";

export default function Footer(): ReactElement {
  const classes = MyStyle();
  return (
    <div className={classes.footer}>
      <Divider></Divider>
      <Container className="py-2" style={{ fontSize: "10pt", color: SECONDARY, marginTop: "15px" }}>
        Copyright <Copyright fontSize="small" /> {new Date().getFullYear()} InBody CO.,LTD. All right reserved
      </Container>
    </div>
  );
}
