import { Button, Paper, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React, { ReactElement } from "react";
import ReactDataGrid, { Column } from "react-data-grid";
import { HrStyle } from "src/Style/theme";
import { ObjType, RewardAndPunishment } from "src/system/types";
import { DefaultFormatter, IData } from "./Common";
import { ExcelUploadEnum } from "./MainPage";
import PublishIcon from "@material-ui/icons/Publish";

export interface RewardAndPunishmentUpload extends RewardAndPunishment, IData {
  error: ObjType;
}

interface Props {
  data: RewardAndPunishmentUpload[];
  errorCount: number;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  onInsertData: (type: ExcelUploadEnum) => void;
}

function RewardAndPunishmentDataImportRef({
  data,
  errorCount,
  onSubmit,
  onInsertData,
}: Props): ReactElement {
  const classes = HrStyle();
  const columns = React.useMemo(() => {
    const k = [
      {
        name: "NUM",
        key: "id",
      },
      {
        name: "사번",
        key: "employeeId",
      },
      {
        name: "포상/징계 구분",
        key: "category1",
      },
      {
        name: "포상/징계 종류",
        key: "category2",
      },
      {
        name: "확정일",
        key: "confirmed",
      },
      {
        name: "내용",
        key: "content",
      },
      {
        name: "요청 파트/팀",
        key: "requestTeam",
      },
      {
        name: "요청자",
        key: "requester",
      },
      {
        name: "경위서작성여부",
        key: "accidentReport",
      },
      {
        name: "경위서 문서번호",
        key: "accidentReport",
      },
      {
        name: "인사위원회 결정",
        key: "personalCommitteeDecision",
      },
      {
        name: "최종결정",
        key: "finalDecision",
      },
      {
        name: "문서번호",
        key: "documentNo",
      },
      {
        name: "비고",
        key: "remark",
      },
    ];
    const nk: Column<RewardAndPunishmentUpload>[] = k.map((x) => {
      if (x.key === "id") return x;
      else {
        return {
          ...x,
          formatter: DefaultFormatter,
        } as Column<RewardAndPunishmentUpload>;
      }
    });
    return nk;
  }, []);

  const onClickUpload = () => onInsertData("rewardandpunishment");

  return (
    <Paper variant="outlined" className={classes.paperUpload}>
      <form onSubmit={onSubmit} name="rewardAndPunishment">
        <input type="file" name="file" id="input-file" required />
        <input type="submit" />
        <input type="reset" />
      </form>
      <br />
      <Button
        onClick={onClickUpload}
        variant="contained"
        startIcon={<PublishIcon />}
      >
        데이터 업로드
      </Button>

      {errorCount > 0 && (
        <Alert severity="error" title="에러가 발생한 위치">
          <AlertTitle>에러가 발생한 위치</AlertTitle>
          <ul>
            {data
              .filter((x) => Object.keys(x.error).length >= 1)
              .map((x) => (
                <li>{x.id}</li>
              ))}
          </ul>
        </Alert>
      )}

      <div className="text-right">
        <Typography variant="body2">에러데이터 : {errorCount}건</Typography>
      </div>

      <ReactDataGrid
        rows={data}
        columns={columns}
        rowKeyGetter={(row) => {
          return row.id;
        }}
        rowClass={(row) => {
          if (Object.keys(row.error).length >= 1) {
            return "c-error-row";
          } else return undefined;
        }}
        defaultColumnOptions={{
          resizable: true,
        }}
        className={classes.reactDataGrid}
      ></ReactDataGrid>
    </Paper>
  );
}

export default RewardAndPunishmentDataImportRef;
