import { Input } from "@material-ui/core";

const NumberInput = (props: any) => (
  <Input
    inputProps={{ min: 0 }}
    defaultValue={props.value}
    onChange={(e) => props.onChange(e.target.value)}
    type="number"
  />
);

export default NumberInput;
