import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio as MaterialRadio,
  RadioGroup,
  RadioGroupProps,
} from "@material-ui/core";

import React, { ReactElement, PureComponent } from "react";

interface RadioProps extends RadioGroupProps {
  children?: React.ReactNode;
  title?: string;
  row?: boolean;
}

export function Radio({ children, title, name, value, onChange, ...others }: RadioProps): ReactElement {
  return (
    <FormControl component="fieldset">
      {title && <FormLabel component="legend">{title}</FormLabel>}
      <RadioGroup aria-label={title} name={name} value={value} onChange={onChange} {...others}>
        {children}
      </RadioGroup>
    </FormControl>
  );
}

interface RadioInputProps {
  size?: "medium" | "small";
  label: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: ((event: React.ChangeEvent<{}>, checked: boolean) => void) | undefined;
  labelPlacement?: "bottom" | "end" | "start" | "top";
  classes?: object;
  className?: string;
  value?: string;
}

export class RadioInput extends PureComponent<RadioInputProps> {
  render() {
    const { size, ...others } = this.props;
    return (
      <FormControlLabel
        style={{ padding: "2px", marginBottom: "0" }}
        control={<MaterialRadio disableRipple size={size || "small"} />}
        {...others}
      />
    );
  }
}
