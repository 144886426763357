import React, { ReactElement } from "react";
import { Route, Switch } from "react-router-dom";
import DepartmentPage from "src/page/manage-hr/department/MainPage";
import EmployeePage from "src/page/manage-hr/employee/MainPage";
import ConstantPage from "src/page/manage-hr/constant/MainPage";
import CategoryPage from "src/page/manage-hr/category/MainPage";
import MainLayout from "src/page/manage-hr/MainLayout";
import EmployeeAddPage from "src/page/manage-hr/employeeAdd/MainPage";
import EmployeeDeletePage from "src/page/manage-hr/employeeDelete/MainPage";
import TeamsInfoGrid from "src/page/manage-hr/teamsInfo/TeamsInfoGrid";
import EvidencePage from "src/page/manage-hr/evidence/EvidencePage";

function ManageHrRoute(): ReactElement {
  return (
    <MainLayout>
      <Switch>
        <Route path="/manage-hr/department">
          <DepartmentPage></DepartmentPage>
        </Route>
        <Route path="/manage-hr/employee">
          <EmployeePage></EmployeePage>
        </Route>
        <Route path="/manage-hr/category">
          <CategoryPage></CategoryPage>
        </Route>
        <Route path="/manage-hr/constant">
          <ConstantPage></ConstantPage>
        </Route>
        <Route path="/manage-hr/employeeAdd" exact>
          <EmployeeAddPage></EmployeeAddPage>
        </Route>
        <Route path="/manage-hr/employeeDelete" exact>
          <EmployeeDeletePage></EmployeeDeletePage>
        </Route>
        <Route path="/manage-hr/teamsInfo" exact>
          <TeamsInfoGrid></TeamsInfoGrid>
        </Route>
        <Route path="/manage-hr/evidence" exact>
          <EvidencePage></EvidencePage>
        </Route>
      </Switch>
    </MainLayout>
  );
}

export default ManageHrRoute;
