import ReactDataGrid, { Column } from "react-data-grid";
import React, { useState } from "react";
import { Radio, RadioInput } from "src/components/Radio";
import { Account, HrEmployee, ObjType } from "src/system/types";
import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { ExcelUploadEnum } from "src/page/manage-hr/employee/MainPage";
import PublishIcon from "@material-ui/icons/Publish";
import { DefaultFormatter, IData } from "src/page/manage-hr/employee/Common";
import { HrStyle } from "src/Style/theme";

interface Props {
  data: BasicUpload[];
  errorCount: number;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  onInsertData: (type: ExcelUploadEnum) => void;
}

export interface BasicUpload
  extends Omit<HrEmployee, "joined" | "birthday">,
    Account,
    IData {
  index: number;
  joined: string;
  birthday: string;
  error: ObjType;
}

const BasicDataImport = ({
  data,
  errorCount,
  onSubmit,
  onInsertData,
}: Props) => {
  const classes = HrStyle();
  const [option, setOption] = useState("abbr-en");
  const onChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOption(event.target.value);
  };

  const columns = React.useMemo(() => {
    const k = [
      {
        name: "NUM",
        key: "index",
      },
      {
        name: "사번",
        key: "employeeId",
      },
      {
        name: "이름",
        key: "name",
      },
      {
        name: "영문이름",
        key: "enName",
      },
      {
        name: "성별",
        key: "gender",
      },
      {
        name: "입사일",
        key: "joined",
      },
      {
        name: "핸드폰",
        key: "phone",
      },
      {
        name: "내선번호",
        key: "tel",
      },
      {
        name: "gw메일",
        key: "gwMail",
      },
      {
        name: "gw이름",
        key: "gwName",
      },
      {
        name: "국적",
        key: "nationality",
      },
      {
        name: "주민번호",
        key: "residenceNumber",
      },
      {
        name: "생년월일",
        key: "birthday",
      },
      {
        name: "우편번호",
        key: "zip",
      },
      {
        name: "도로명주소",
        key: "address1",
      },
      {
        name: "상세주소",
        key: "address2",
      },
      {
        name: "은행",
        key: "bank",
      },
      {
        name: "계좌번호",
        key: "number",
      },
      {
        name: "예금주",
        key: "holder",
      },
    ];

    const nk: Column<BasicUpload>[] = k.map((x) => {
      if (x.key === "index") return x;
      else {
        return {
          ...x,
          formatter: DefaultFormatter,
        } as Column<BasicUpload>;
      }
    });

    return nk;
  }, []);

  const onClickUpload = () => onInsertData("basic");

  return (
    <Paper variant="outlined" className={classes.paperUpload}>
      <h4>사번 및 기본정보 등록</h4>
      <Alert severity="warning">
        <Typography variant="body2">
          성별은 M(남자), F(여자)로 데이터를 구분합니다.
          <br />
          M,F는 기본 값이기 때문에 서버에서 언제나 구분 가능합니다.
          <br /> 예를 들어 성별의 구분이 (남성,여성) 과 (M,F)가 혼용되어
          있더라도 아래 성별구분에 (남성,여성)을 선택하시면 됩니다.
          <br />
          만약 (남성,여성)과 (남,여)가 혼용된 경우는 구분하지 못합니다.
        </Typography>
      </Alert>
      <form onSubmit={onSubmit} name="basic">
        <input type="file" name="file" id="input-file" required />
        <br />
        <Radio
          name="abbrGender"
          style={{ width: "900px" }}
          title="성별구분"
          aria-required="true"
          value={option}
          onChange={onChangeRadio}
        >
          <Grid container>
            <Grid item xs={3}>
              <RadioInput value="abbr-en" label="M, F(기본 값)"></RadioInput>
            </Grid>
            <Grid item xs={3}>
              <RadioInput value="en" label="Male, Female"></RadioInput>
            </Grid>
            <Grid item xs={3}>
              <RadioInput value="abbr-ko" label="남, 여"></RadioInput>
            </Grid>
            <Grid item xs={3}>
              <RadioInput value="ko" label="남성, 여성"></RadioInput>
            </Grid>
          </Grid>
        </Radio>
        <br />
        <input type="submit" />
      </form>

      <br />

      <Button
        onClick={onClickUpload}
        variant="contained"
        startIcon={<PublishIcon />}
      >
        데이터 업로드
      </Button>

      {errorCount > 0 && (
        <Alert severity="error" title="에러가 발생한 위치">
          <AlertTitle>에러가 발생한 위치</AlertTitle>
          <ul>
            {data
              .filter((x) => Object.keys(x.error).length >= 1)
              .map((x) => (
                <li>{x.index}</li>
              ))}
          </ul>
        </Alert>
      )}

      <div className="text-right">
        <Typography variant="body2">에러데이터 : {errorCount}건</Typography>
      </div>
      <ReactDataGrid
        style={{ overflow: "scroll" }}
        rows={data}
        columns={columns}
        rowKeyGetter={(row) => {
          return row.index;
        }}
        rowClass={(row) => {
          if (Object.keys(row.error).length >= 1) {
            return "c-error-row";
          } else return undefined;
        }}
        defaultColumnOptions={{
          resizable: true,
        }}
        className={classes.reactDataGrid}
      ></ReactDataGrid>
    </Paper>
  );
};

export default BasicDataImport;
