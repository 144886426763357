import React, { ReactElement, ReactNode, useState } from "react";
import SearchBarView from "src/page/card/SearchBarView";
import { EmployeeAutoComplete } from "src/system/types";
import { useUserState } from "src/system/UserContext";
import CardTapsMenu from "./CardTapsMenu";

interface MainLayoutProps {
  children?: ReactNode;
}

function MainLayout({ children }: MainLayoutProps): ReactElement {
  const user = useUserState();
  const admin = user.role === "admin";
  const [id, setId] = useState<string>(user.employeeId);

  const onChangeSelect = (event: React.ChangeEvent<{}>, value: EmployeeAutoComplete | null) => {
    if (value?.employeeId !== undefined) {
      setId(value?.employeeId);
    }
  };

  return (
    <div style={{ marginTop: "15px" }}>
      {admin && <SearchBarView onChangeSelect={onChangeSelect}></SearchBarView>}
      <CardTapsMenu employeeId={id}></CardTapsMenu>
      {/* <TabsMenuView></TabsMenuView> */}
      {/* <div className="p-2">{children}</div> */}
    </div>
  );
}

export default MainLayout;
