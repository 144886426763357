import { Button, Grid, Modal } from '@material-ui/core';
import { ChangeEventArgs, MaskedTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { NodeClickEventArgs, TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import { useEffect, useState } from "react";
import { UseFieldArrayUpdate, useFormContext } from 'react-hook-form';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { DepartmentApi, ErrorHandler, HREmployeeApi } from 'src/system/ApiService';
import { CustomTreeView, EmployeeAutoComplete, InitDeptTreeView } from 'src/system/types';
import { AttendanceDocumentForm } from '../../PostAttendanceRequest';
import { useAttendanceHooks } from '../../hooks/useAttendanceHooks';
import { attendanceAppendAtom } from '../../recoils/AttendanceAppendAtom';
import { employeeChangeModalAtom } from '../../recoils/EmployeeChangeModalAtom';
import { useAttendanceStyles } from '../../style/useAttendanceStyle';
import { getLastBelong } from '../../hooks/getLastBelong';

interface EmployeeChangeModalProps {
  update: UseFieldArrayUpdate<AttendanceDocumentForm, "attendanceItem">
}

export default function EmployeeChangeModal({ update }: EmployeeChangeModalProps) {

  const classes = useAttendanceStyles();
  const [employeeList, setEmployeeList] = useState<EmployeeAutoComplete[]>([]);
  const [checkedEmployeeId, setCheckedEmployeeId] = useState('');
  const [employeeTreeData, setEmployeeTreeData] = useState<CustomTreeView>(InitDeptTreeView);

  let treeObj: TreeViewComponent;
  let maskObj: MaskedTextBoxComponent;

  const modalState = useRecoilValue(employeeChangeModalAtom);
  const setAttendanceAppendState = useSetRecoilState(attendanceAppendAtom);
  const resetModalState = useResetRecoilState(employeeChangeModalAtom);

  const { getValues } = useFormContext<AttendanceDocumentForm>();

  const { getAttendanceBalanceCountData } = useAttendanceHooks();

  useEffect(() => {
    DepartmentApi.GetDeptAndEmployeeTreeView(new Date().getFullYear())
      .then((res) => {
        if (res !== undefined) {
          const treeData = res as CustomTreeView;
          treeData.subChild?.forEach((x) => {
            x.icon = 'department';
            x.expanded = true;
            addIconAndExpandAll(x, 1);
          });
          setEmployeeTreeData({ ...treeData, expanded: true });
        }
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        console.log(msg);
      });

    const addIconAndExpandAll = (data: CustomTreeView, index: number) => {
      if (!/^[a-zA-Z]/.test(data.id)) {
        data.icon = 'user';
      } else {
        data.icon = 'department';
        data.expanded = true;
      }

      if (data.subChild) {
        data.subChild.forEach(sub => addIconAndExpandAll(sub, index + 1));
      }
    }

    HREmployeeApi.GetEmployeeList()
      .then((res) => setEmployeeList(res))
      .catch((error) => {
        let msg = ErrorHandler(error);
      });

  }, []);

  const field: Object = {
    dataSource: [{ ...employeeTreeData }],
    text: "name",
    child: "subChild",
    iconCss: 'icon',
  }

  const nodeClicked = (args: NodeClickEventArgs) => {
    if (!/^[a-zA-Z]/.test(args.node.dataset.uid ?? 'a')) {
      setCheckedEmployeeId(args.node.dataset.uid ?? '');
      return;
    }
    setCheckedEmployeeId('');
  }

  const handleChangeEmployeeId = () => {
    if (checkedEmployeeId === '') {
      alert('임직원을 선택해주세요.');
      return;
    }
    const selectEmployee = employeeList.find(e => e.employeeId === checkedEmployeeId);
    if (!selectEmployee) return;

    if (!modalState.isItem) {
      setAttendanceAppendState(pre => ({
        ...pre,
        employeeId: selectEmployee.employeeId,
        employeeName: selectEmployee.name,
        employeeDepartment: getLastBelong(selectEmployee.team, selectEmployee.department, selectEmployee.group),
      }))
    } else {
      if (checkedEmployeeId) {
        update(modalState.itemIndex, {
          ...getValues(`attendanceItem.${modalState.itemIndex}`),
          employeeId: selectEmployee.employeeId,
          employeeName: selectEmployee.name,
          department: getLastBelong(selectEmployee.team, selectEmployee.department, selectEmployee.group),
        });
      }
    }

    if (!!selectEmployee.employeeId.trim()) {
      getAttendanceBalanceCountData(selectEmployee.employeeId);
    }

    resetModalState();
  }

  const changeDataSource = (data: CustomTreeView | null) => {
    treeObj.fields = {
      dataSource: [{ ...data }], id: 'id', text: 'name', child: 'subChild', iconCss: 'icon'
    }
  }

  const filterTreeData = (treeData: CustomTreeView, searchText: string) => {
    let cloneData = null;

    if (treeData.name.includes(searchText)) {
      cloneData = { ...treeData };
    }

    if (treeData.subChild) {
      const filteredChildren: CustomTreeView[] = [];

      treeData.subChild.forEach(sub => {
        const result = filterTreeData(sub, searchText);
        if (result) {
          filteredChildren.push(result);
        }
      })

      if (filteredChildren.length > 0) {
        cloneData = cloneData ? cloneData : { ...treeData, subChild: [] };
        cloneData.subChild = filteredChildren;
      }
    }
    return cloneData;
  }

  let debounceTimer: NodeJS.Timeout | null = null;

  const searchNodes = (args: ChangeEventArgs) => {
    let _text = maskObj.element.value;

    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    debounceTimer = setTimeout(() => {
      if (_text === "") {
        changeDataSource(employeeTreeData);
      } else {
        const filteredList = filterTreeData(employeeTreeData, _text);
        changeDataSource(filteredList);
      }
    }, 200);
  }

  return (
    <Modal
      className='remove-column'
      open={modalState.isOpen}
      onClose={resetModalState}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.paper}>
        <div className={classes.header}>
          <span>인바디 조직도</span>
        </div>
        <MaskedTextBoxComponent
          cssClass='e-outline'
          placeholder="Search"
          floatLabelType="Never"
          ref={(mask) => { maskObj = mask as MaskedTextBoxComponent; }}
          change={searchNodes}
        />
        <div className={classes.tree}>
          <TreeViewComponent
            cssClass='e-outline'
            fields={field}
            ref={(treeview) => { treeObj = treeview as TreeViewComponent }}
            nodeClicked={nodeClicked}
          />
        </div>
        <div className={classes.modalButton}>
          <Grid
            container
            spacing={1}
            justify='center'>
            <Grid item>
              <Button
                type='button'
                variant='contained'
                onClick={handleChangeEmployeeId}
              >
                확인
              </Button>
            </Grid>
            <Grid item>
              <Button
                type='button'
                variant='contained'
                onClick={resetModalState}
              >
                취소
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </Modal>
  );
}
