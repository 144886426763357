import { useEffect, useState } from "react";
import { GetAttendanceItem } from '../../post-attendance-request/interface/RequestInterface';
import { AttendanceRequestApi } from 'src/system/ApiService';

interface AttendanceDocumentUrlItemProps {
  data: GetAttendanceItem
}

export default function AttendanceDocumentUrlItem({ data }: AttendanceDocumentUrlItemProps) {
  const [documentUrl, setDocumentUrl] = useState('#');
  useEffect(() => {
    AttendanceRequestApi.GetAttendanceDocumentDetail(data.attendanceRequestId)
      .then(data => setDocumentUrl(data.documentUrl));
  })

  return (
    <a
      href={documentUrl}
      target='blank'
      style={{ fontWeight: 600 }}>
      {`열기`}
    </a>
  )
}