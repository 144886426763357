import { Tab } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useRecoilState } from 'recoil';
import { StyledTabs } from "src/components/StyledTabs";
import TabPanel from "src/components/TabPanel";
import { AttendanceRequestApi } from 'src/system/ApiService';
import { AttendanceTabIndexAtom } from './AttendanceTabIndexAtom';
import AttendanceMain from "./attendance-request-list/AttendanceMain";
import PostAttendanceRequest from './post-attendance-request/PostAttendanceRequest';
import { GetAttendanceDocumentDetail } from './post-attendance-request/interface/RequestInterface';

interface ApplyMainPageProps {
  mode: "create" | "edit";
  documentId: number | null;
}

function AttendanceRequestPage({ mode, documentId }: ApplyMainPageProps) {
  const [tabIndex, setTabIndex] = useRecoilState(AttendanceTabIndexAtom);
  const [attendanceRequest, setAttendanceRequest] = useState<GetAttendanceDocumentDetail | undefined>(undefined);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  function a11yProps(index: any, label: string) {
    return {
      id: `simple-tab-${index}`,
      label: `${label}`,
    };
  }

  useEffect(() => {
    if (mode === "edit" && documentId) {
      AttendanceRequestApi.GetAttendanceDocumentDetail(documentId)
        .then(res => {
          setAttendanceRequest(res)
        });
    }
  }, [mode, documentId])

  return (
    <>
      <StyledTabs value={tabIndex} onChange={handleChange}>
        <Tab {...a11yProps(0, "통합근태 작성")} />
        <Tab {...a11yProps(1, "통합근태 내역")} />
        {/* <Tab {...a11yProps(2, "오늘의 근태")} /> */}
        {/* <Tab {...a11yProps(3, "근태 현황")} /> */}
      </StyledTabs>

      <TabPanel value={tabIndex} index={0} />
      {tabIndex === 0 && mode === 'create'
        && <PostAttendanceRequest />}
      {tabIndex === 0 && mode === 'edit' && attendanceRequest
        && <PostAttendanceRequest attendanceRequest={attendanceRequest} />}

      <TabPanel value={tabIndex} index={1} />
      {tabIndex === 1 && <AttendanceMain />}

    </>
  );
}

export default AttendanceRequestPage;
