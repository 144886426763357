import React, { ReactNode, ReactElement } from "react";
import { useHistory, useParams } from "react-router-dom";
import { InterviewRecordRoutes } from "src/system/types/type";
import { InterviewApi, IssueApi } from "src/system/ApiService";
import { useInterviewDispatch } from "src/system/InterviewContext";
import { initViewPage } from "src/system/types";
import { useUserState } from "src/system/UserContext";

export const useInterviewId = () => {
  const { interviewId } = useParams<{ interviewId: string }>();
  return interviewId;
};

export const useIssueId = () => {
  const { issueId } = useParams<{ issueId: string }>();
  return issueId;
};

interface Props {
  type: InterviewRecordRoutes;
  children?: ReactNode;
}

function EditInit({ type, children }: Props): ReactElement {
  const interviewId = useInterviewId();
  const issueId = useIssueId();

  const dispatch = useInterviewDispatch();
  const history = useHistory();
  const user = useUserState();

  React.useEffect(() => {
    if (type === InterviewRecordRoutes.issue) {
      let _id = parseInt(issueId);
      if (!isNaN(_id)) {
        IssueApi.getIssue(_id)
          .then((res) => {
            if (user.employeeId === res.writer.employeeId) {
              dispatch({
                type: "EDIT-INIT",
                item: {
                  ...initViewPage,
                  issue: res,
                },
              });
            } else {
              //url을 이용해서 억지로 들어옴
              alert("잘못된 접근입니다.");
              history.goBack();
            }
          })
          .catch(() => {
            alert("데이터를 가져오는데 실패했습니다.");
            history.goBack();
          });
      }
    } else if (type === InterviewRecordRoutes.interview) {
      let _id = parseInt(interviewId);
      if (!isNaN(_id)) {
        InterviewApi.getInterview(_id)
          .then((res) => {
            if (user.employeeId === res.interview.writer.employeeId) {
              dispatch({ type: "EDIT-INIT", item: res });
            } else {
              alert("잘못된 접근입니다.");
              history.goBack();
            }
          })
          .catch(() => {
            alert("데이터를 가져오는데 실패했습니다.");
            history.goBack();
          });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <>{children}</>;
}

export default EditInit;
