import { ReactElement } from "react";
import { Route, Switch } from "react-router-dom";
import TodayAttendance from 'src/page/today-attendance/TodayAttendance';

function Routes(): ReactElement {
  return (
    <Switch>
      <Route path="/today-attendance" exact>
        <TodayAttendance />
      </Route>
    </Switch>
  );
}

export default Routes;
