export const TYPE_INTERVIEW = "interview";

//backend에서도 똑같이 바꿔줘야 합니다.
export const ROWS_PER_PAGE = 30.0;

//consult.AuthorityCategory의 id와 일치해야 합니다.
export enum AuthorityCategory {
  WRITE = "WRITE",
  LOGIN = "LOGIN",
  LIST = "LIST",
}

export enum ProgressStatus {
  DOING = 0,
  SUCCESS = 1,
  FAIL = 2,
}

export enum EnumPositionCode {
  P_000 = "P_000",
  P_019 = "P_019",
  P_020 = "P_020",
  P_049 = "P_049",
  P_050 = "P_050",
  P_060 = "P_060",
  P_070 = "P_070",
}

export const POSITION_CODE: { [name: string]: string } = {
  P_000: "사장",
  P_019: "관리용",
  P_020: "파트장",
  P_049: "팀장",
  P_050: "팀장",
  P_060: "팀원",
  P_070: "신입",
};

export const PROGRESS_NAME_SAVE_CONTENT = "내용 저장";
export const PROGRESS_NAME_DELETE_RESERVED_EMAIL = "기존 이메일 예약 내용";
export const PROGRESS_NAME_RESERVE_EMAIL = "이메일 예약";
export const PROGRESS_NAME_UPLOAD_FILE = "파일 업로드";

export enum RouteData {
  interview = "interview",
  issue = "issue",
}

export enum RouteAction {
  write = "write",
  edit = "edit",
}

/**
 * 가족관계
 * @summary helper/Constants.cs 의 FAMILY_RELATION_ARR 와 일치해야 함.
 */
export const FAMILY_RELATION_ARR = [
  "배우자",
  "부",
  "모",
  "배우자부",
  "배우자모",
  "자녀",
  "손자녀",
  "형제",
  "(외)조부",
  "(외)조무",
  "(외)증조부",
  "(외)증조모",
  "동거인",
];

// 카테고리 관련
export const CATEGORY_TABLE_ARRAY = [
  { name: "인사변동이력", value: "WORKRECORD" },
  // { name: "근무상태변동", value: "EMPLOYMENTRECORD" },
  { name: "상벌사항", value: "REWARDANDPUNISHMENT" },
  { name: "Q&A", value: "QNA" },
];

export const CATEGORY_TABLE = {
  WORKRECORD: "인사변동이력",
  // EMPLOYMENTRECORD: "근무상태변동",
  REWARDANDPUNISHMENT: "상벌사항",
  QNA: "Q&A",
};

// 인사정보 항목 관련
export const CONSTANT_TABLE_ARRAY = [
  { name: "가족정보", value: "FAMILYRECORD" },
  { name: "인사변동이력", value: "WORKRECORD" },
  { name: "근무상태변동", value: "EMPLOYMENTRECORD" },
  // { name: "상벌사항", value: "REWARDANDPUNISHMENT" },
  { name: "교육사항", value: "EDUCATION" },
  { name: "자격사항", value: "CERTIFICATE" },
  { name: "병역사항", value: "PDMILITARY" },
  { name: "장애사항", value: "PDDISABILITY" },
  { name: "보훈사항", value: "PDVETERANS" },
  { name: "학력사항", value: "EDUCATIONSCHOOL" },
  { name: "경력사항", value: "CAREER" },
];

export const CONSTANT_TABLE = {
  FAMILYRECORD: "가족정보",
  WORKRECORD: "인사변동이력",
  EMPLOYMENTRECORD: "근무상태변동",
  // REWARDANDPUNISHMENT: "상벌사항",
  EDUCATION: "교육사항",
  CERTIFICATE: "자격사항",
  PDMILITARY: "병역사항",
  PDDISABILITY: "장애사항",
  PDVETERANS: "보훈사항",
  EDUCATIONSCHOOL: "학력사항",
  CAREER: "경력사항",
};

export const CONSTANT_TABLE_COLUMN: {
  [index: string]: { [name: string]: string };
} = {
  FAMILYRECORD: {
    RELATION: "관계",
  },
  WORKRECORD: {
    WORKPLACE: "사업장명",
    JOBGROUP: "직군",
    JOBDUTY: "직무",
    JOBPOSITION: "직급",
    JOBTITLE: "직책",
    JOBPOSITIONRANK: "직위",
  },
  EMPLOYMENTRECORD: {
    EMPLOYMENTFORM: "고용형태",
    EMPLOYEESTATUS: "근무상태",
  },
  // REWARDANDPUNISHMENT: {
  //   KIND: "포상/징계 구분",
  //   REWARDANDPUNISHMENT: "포상/징계 종류",
  // },
  EDUCATION: {
    KIND: "교육분류",
    FIELD: "분야",
    ABILITY: "직능구분",
  },
  CERTIFICATE: {
    CERTIFICATION: "자격명",
  },
  PDMILITARY: {
    STATUS: "병역구분",
    TYPE: "군별",
    RANK: "계급",
    DISCHARGECATEGORY: "제대구분",
  },
  PDDISABILITY: {
    RANK: "장애등급",
    CONTENT: "장애내용",
  },
  PDVETERANS: {
    TYPE: "보훈대상",
    RELATION: "보훈관계",
    RATIO: "보훈비율",
  },
  EDUCATIONSCHOOL: {
    KIND: "학교구분",
    GRADUATESTATUS: "졸업구분",
    ISMAIN: "본교구분",
    SCALE: "만점기준",
  },
  CAREER: {
    EMPLOYMENTFORM: "고용형태",
  },
};

// 파트/팀 연도선택
export const YEARS: number[] = [];
for (var i = 1996; i < new Date().getFullYear() + 1; i++) {
  YEARS.push(i);
}

// category2도 api 로 data Source 받아올 시 query에 where 가 실행안됨.
export const CATEGORY2COLL: object[] = [
  { parentId: 5, id: 15, name: "승진", order: 1, kind: "WORKRECORD" },
  { parentId: 5, id: 16, name: "강등", order: 2, kind: "WORKRECORD" },
  { parentId: 6, id: 17, name: "직군변동", order: 1, kind: "WORKRECORD" },
  { parentId: 6, id: 18, name: "직무변동", order: 2, kind: "WORKRECORD" },
  { parentId: 6, id: 19, name: "직책변동", order: 3, kind: "WORKRECORD" },
  { parentId: 6, id: 53, name: "직급변동", order: 4, kind: "WORKRECORD" },
  { parentId: 6, id: 20, name: "조직이동", order: 5, kind: "WORKRECORD" },
  { parentId: 6, id: 23, name: "사업장이동", order: 6, kind: "WORKRECORD" },
  { parentId: 6, id: 55, name: "해외파견", order: 7, kind: "WORKRECORD" },
  { parentId: 1011, id: 24, name: "연봉 변경", order: 1, kind: "WORKRECORD" },
  { parentId: 1012, id: 25, name: "입사", order: 0, kind: "WORKRECORD" },
  { parentId: 1012, id: 26, name: "재입사", order: 1, kind: "WORKRECORD" },
  { parentId: 1012, id: 27, name: "휴직", order: 2, kind: "WORKRECORD" },
  { parentId: 1012, id: 51, name: "복직", order: 3, kind: "WORKRECORD" },
  { parentId: 1012, id: 28, name: "출산휴가", order: 4, kind: "WORKRECORD" },
  { parentId: 1012, id: 29, name: "육아휴직", order: 5, kind: "WORKRECORD" },
  { parentId: 1012, id: 30, name: "병가", order: 6, kind: "WORKRECORD" },
  { parentId: 1012, id: 31, name: "퇴사", order: 7, kind: "WORKRECORD" },
  {
    parentId: 1013,
    id: 32,
    name: "기간의정함이없는근로자",
    order: 0,
    kind: "WORKRECORD",
  },
  {
    parentId: 1013,
    id: 54,
    name: "연봉제계약직",
    order: 0,
    kind: "WORKRECORD",
  },
  { parentId: 1013, id: 57, name: "IPP", order: 0, kind: "WORKRECORD" },
  { parentId: 1013, id: 33, name: "등기계약직", order: 1, kind: "WORKRECORD" },
  {
    parentId: 1013,
    id: 52,
    name: "연봉제계약직",
    order: 2,
    kind: "WORKRECORD",
  },
  {
    parentId: 1013,
    id: 34,
    name: "신입사원인턴",
    order: 3,
    kind: "WORKRECORD",
  },
  { parentId: 1013, id: 35, name: "단기인턴", order: 4, kind: "WORKRECORD" },
  {
    parentId: 1013,
    id: 36,
    name: "시급제계약직",
    order: 5,
    kind: "WORKRECORD",
  },
  {
    parentId: 1013,
    id: 37,
    name: "창업사관학교",
    order: 6,
    kind: "WORKRECORD",
  },
  {
    parentId: 1013,
    id: 38,
    name: "카이스트인턴",
    order: 7,
    kind: "WORKRECORD",
  },
  {
    parentId: 1013,
    id: 39,
    name: "소속외근로자(파견직)",
    order: 8,
    kind: "WORKRECORD",
  },
  { parentId: 1016, id: 56, name: "가오픈용", order: 0, kind: "WORKRECORD" },

  { parentId: 1017, id: 58, name: "조직명 변경", order: 1, kind: "WORKRECORD" },
  { parentId: 1017, id: 59, name: "조직 신설", order: 2, kind: "WORKRECORD" },
  { parentId: 1017, id: 62, name: "조직 개편", order: 3, kind: "WORKRECORD" },
];
