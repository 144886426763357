import { setCulture } from '@syncfusion/ej2-base';
import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import {
  ColumnDirective,
  ColumnsDirective,
  Edit,
  EditSettingsModel,
  ExcelExport,
  GridComponent,
  Inject,
  Page,
  Resize,
  Search,
  Sort,
  Toolbar,
  ToolbarItems
} from "@syncfusion/ej2-react-grids";
import { useEffect, useRef, useState } from "react";
import { AttendanceBalanceApi, ErrorHandler } from "src/system/ApiService";
import { useLoadingDispatch } from "src/system/LoadingContext";
import { CustomDialogEditEventArgs } from "src/system/types";
import AttendanceBalanceGridDialog, { ABDialogTemplateProps } from './components/AttendanceBalanceGridDialog';
import { GetAttendanceBalanceForAdmin } from 'src/page/attendance/attendance-request/post-attendance-request/interface/BalanceInterface';

export default function AttendanceBalancePage() {
  const [data, setData] = useState<GetAttendanceBalanceForAdmin[]>([]);
  const gridRef = useRef<GridComponent | null>(null);
  const LoadingDispatch = useLoadingDispatch();

  setCulture('ko-KR');

  useEffect(() => {
    AttendanceBalanceApi.GetAllAttendanceBalanceForAdmin()
      .then(res => setData(res))
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      })
  }, []);

  const get_yyyyMMdd_Format = (field: string, data: any) => {
    const date = new Date(data[field]);
    const yyyy = date.getFullYear();
    const MM = String(date.getMonth() + 1).padStart(2, '0');
    const dd = String(date.getDate()).padStart(2, '0');

    return `${yyyy}-${MM}-${dd}`;
  }

  // Toolbar Click 이벤트
  const toolbarClick = (args: ClickEventArgs | undefined) => {
    if (args !== undefined && args.item !== undefined) {
      if (args.item.id?.includes("excel") && gridRef.current !== null) {
        gridRef.current.excelExport();
      }
    }
  };

  const dialogTemplate = (attendanceBalance: ABDialogTemplateProps) => {
    if (attendanceBalance) {
      return <AttendanceBalanceGridDialog attendanceBalance={attendanceBalance} />;
    }
    else {
      return <div>loading...</div>
    }
  };

  // action begin 이벤트
  const actionBegin = (args: any) => {
    if (args.requestType === 'delete') {
      if (!window.confirm("정말로 삭제하시겠습니까?")) {
        args.cancel = true;
      }
    }
  };
  const actionComplete = (args: CustomDialogEditEventArgs): void => {
    if (args && args.requestType === "save") {
      if (args.action === "add") {
        LoadingDispatch({ type: "LOADING" });
        AttendanceBalanceApi.CreateAttendanceBalance(args.data)
          .then(() => { alert('연차가 정상적으로 생성되었습니다.') })
          .catch((err) => {
            let msg = ErrorHandler(err);
            alert(msg);
          })
          .finally(() => {
            LoadingDispatch({ type: "COMPLETE" });
          });
      } else if (args.action === "edit") {
        LoadingDispatch({ type: "LOADING" });
        AttendanceBalanceApi.UpdateAttendanceBalance(args.data)
          .then(() => { alert('연차가 정상적으로 수정되었습니다.') })
          .catch((err) => {
            let msg = ErrorHandler(err);
            alert(msg);
          })
          .finally(() => {
            LoadingDispatch({ type: "COMPLETE" });
          });
      }
    } else if (args && args.requestType === "delete") {
      LoadingDispatch({ type: "LOADING" });
      AttendanceBalanceApi.DeleteAttendanceBalance(args.data[0].id)
        .then(() => { alert('연차가 정상적으로 삭제되었습니다.') })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        })
        .finally(() => {
          LoadingDispatch({ type: "COMPLETE" });
        });
    }
  };

  const editOptions: EditSettingsModel = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    mode: "Dialog",
    template: dialogTemplate,
  };

  const toolbarOptions: ToolbarItems[] = [
    "Add",
    "Edit",
    "Update",
    "Delete",
    "Cancel",
    "Search",
    "ExcelExport"
  ];

  return (
    <div>
      <GridComponent
        dataSource={data}
        editSettings={editOptions}
        allowResizing={true}
        allowPaging={true}
        allowSorting={true}
        allowExcelExport={true}
        toolbar={toolbarOptions}
        toolbarClick={toolbarClick}
        actionBegin={actionBegin}
        actionComplete={actionComplete}
        pageSettings={{ pageSize: 20, pageSizes: true }}
        ref={gridRef}
      >
        <ColumnsDirective>
          <ColumnDirective field="id" headerText="id" width="0" isPrimaryKey={true} visible={false} />
          <ColumnDirective
            headerTextAlign='Center'
            textAlign='Left'
            field="category"
            headerText="종류"
            validationRules={{ required: [true, "* 휴가종류는 필수로 입력해야합니다."] }}
            width="10%" />
          <ColumnDirective
            headerTextAlign='Center'
            textAlign='Left'
            field="createdBy"
            headerText="연차부여자"
            validationRules={{ required: true }}
            width="10%" />
          <ColumnDirective
            headerTextAlign='Center'
            textAlign='Center'
            field="employeeId"
            headerText="사번"
            validationRules={{ required: [true, "* 부여대상은 필수로 선택해야합니다."] }}
            width="10%" />
          <ColumnDirective
            headerTextAlign='Center'
            textAlign='Center'
            field="name"
            headerText="성명"
            width="10%" />
          <ColumnDirective
            headerTextAlign='Center'
            textAlign='Center'
            field="term"
            type='number'
            headerText="연차수"
            validationRules={{ required: [true, "* 부여할 연차수는 필수로 입력해야합니다."] }}
            width="10%" />
          <ColumnDirective
            headerTextAlign='Center'
            textAlign='Center'
            field="available"
            headerText="사용가능여부"
            width="10%" />
          <ColumnDirective
            headerTextAlign='Center'
            textAlign='Left'
            field="expired"
            headerText="만료일"
            validationRules={{ required: [true, '* 만료일은 필수로 입력해야합니다.'] }}
            valueAccessor={get_yyyyMMdd_Format}
            width="10%" />
          <ColumnDirective
            headerTextAlign='Center'
            textAlign='Left'
            field="created"
            headerText="생성일"
            valueAccessor={get_yyyyMMdd_Format}
            width="10%" />
        </ColumnsDirective>
        <Inject services={[Resize, Edit, Toolbar, Page, Sort, Search, ExcelExport]} />
      </GridComponent>
    </div>
  );
}
