import { Paper } from "@material-ui/core";
import React from "react";
import { MyStyle } from "src/Style/theme";
import EvidenceList from "./EvidenceList";

function MainPage() {
  const classes = MyStyle();

  return (
    <div>
      <div className={classes.divMainPage}>
        <Paper style={{ margin: "20px", backgroundColor: "white" }}>
          <EvidenceList></EvidenceList>
        </Paper>
      </div>
    </div>
  );
}

export const CertificateOfEmployment = "재직증명서";

export default MainPage;
