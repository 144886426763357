import { Button, IconButton } from "@material-ui/core";
import { AutocompleteChangeDetails, AutocompleteChangeReason } from "@material-ui/lab";
import React, { ReactElement, useEffect, useState } from "react";
import TableWrapper from "src/components/common/TableWrapper";
import KeyboardDatePicker from "src/components/KeyboardDatePicker";
import MultilineTextField from "src/components/MultilineTextField";
import SelectCombobox from "src/components/SelectCombobox";
import TitleMultipleComboBox from "src/components/TitleMultipleComboBox";
import { ComboboxItem } from "src/components/TitleOneComboBox";
import { MyStyle } from "src/Style/theme";
import { IssueCategoryApi } from "src/system/ApiService";
import { GetComboboxList, getDateUsingNumber } from "src/system/Helper";
import { useInterviewDispatch } from "src/system/InterviewContext";
import { initEmployee, InitIssue, Issue } from "src/system/types";
import CloseIcon from "@material-ui/icons/Close";
import SideBar from "src/components/common/SideBar";
import { useUserState } from "src/system/UserContext";
import LinkingModal from "./LinkingModal";
import ViewIssue from "../ViewIssue";
import NoWriteAuthority from "src/components/NoWriteAuthority";

interface WriteIssueWrapperProps {
  empSelectOptions: ComboboxItem[];
  data: Issue | null;
}

export const WriteIssueWrapper = React.memo(({ empSelectOptions, data }: WriteIssueWrapperProps) => {
  const dispatch = useInterviewDispatch();
  const user = useUserState();
  const [modal, setModal] = useState<boolean>(false);
  const isWriter = user.employeeId === data?.writer.employeeId;
  const classes = MyStyle();

  const onNewIssue = function () {
    dispatch({
      type: "ADD-ISSUE",
      item: { ...InitIssue, writer: { ...initEmployee, name: user.name, employeeId: user.employeeId } },
    });
  };

  const onModalClose = function () {
    setModal(false);
  };
  const onModalOpen = function () {
    //연결하는 것
    setModal(true);
  };

  const onDelete = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (data !== null) {
        let confirmMsg = "";
        if (data.id >= 1) {
          confirmMsg =
            "이슈 연결을 해제하시겠습니까?\n(이슈가 삭제되진 않으며, 이슈리스트에서 해당 면담을 연결지어 볼 수 없습니다.)";
        } else {
          confirmMsg = "작성한 이슈 내역을 모두 지우시겠습니까?";
        }
        if (window.confirm(confirmMsg)) {
          dispatch({ type: "REMOVE-ISSUE" });
        }
      }
    },
    [data, dispatch]
  );

  return (
    <>
      <SideBar align="center">
        <Button disabled={data !== null} onClick={onNewIssue}>
          새로운 이슈 쓰기
        </Button>
        <Button onClick={onModalOpen}>이슈 연결하기</Button>
      </SideBar>
      {/* 작성자만 이슈를 수정할 수 있다. */}
      {/* 데이터가 존재하면서, user가 작성자라면, WriteIssue 그게 아니라면 view */}
      {data !== null &&
        (isWriter ? (
          <div>
            <div className={classes.textRight}>
              <IconButton aria-label="delete" onClick={onDelete}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
            <WriteIssue data={data} empSelectOptions={empSelectOptions}></WriteIssue>
          </div>
        ) : (
          <div>
            <div className={classes.textRight}>
              <IconButton aria-label="delete" onClick={onDelete}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
            <ViewIssue issue={data}></ViewIssue>
          </div>
        ))}
      <LinkingModal empSelectOptions={empSelectOptions} open={modal} onCloseModal={onModalClose}></LinkingModal>
    </>
  );
});

interface WriteIssueProps {
  empSelectOptions: ComboboxItem[];
  data: Issue;
}

export const WriteIssue = React.memo(({ empSelectOptions, data: issue }: WriteIssueProps): ReactElement => {
  const [categoryOption, setCategoryOption] = useState<ComboboxItem[]>([]);
  const concerneds: ComboboxItem[] = GetComboboxList(empSelectOptions, issue.concerneds);
  const accessReaders: ComboboxItem[] = GetComboboxList(empSelectOptions, issue.accessReader);
  const dispatch = useInterviewDispatch();
  const classes = MyStyle();
  const user = useUserState();

  useEffect(() => {
    //GetCategory();
    IssueCategoryApi.GetCategoryCombobox()
      .then((data) => {
        setCategoryOption(data);
      })
      .catch((error) => {
        alert("카테고리를 불러오는데 실패하였습니다.");
      });
  }, []);

  const onChangeSelect = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { name, value } = event.target;
    if (name !== undefined) {
      dispatch({
        type: "CHANGE-ISSUE",
        item: {
          ...issue,
          [name]: value,
        },
      });
    }
  };

  const onChangeText = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    let k = { ...issue, [name]: value };
    dispatch({ type: "CHANGE-ISSUE", item: k });
  };

  const onChangeDate = (date: Date | null) => {
    if (date) {
      let k: Issue = { ...issue, occurred: date.getTime() };
      dispatch({ type: "CHANGE-ISSUE", item: k });
    }
  };

  const ChangeAccessReader = (
    event: React.ChangeEvent<{}>,
    value: ComboboxItem[] | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<ComboboxItem> | undefined
  ) => {
    let k = { ...issue };
    if (value) {
      k.accessReader = value.map((x) => ({
        employeeId: x.key,
        name: x.value,
        team: "",
      }));

      dispatch({ type: "CHANGE-ISSUE", item: k });
    }
  };

  const ChangeConcerneds = (
    event: React.ChangeEvent<{}>,
    value: ComboboxItem[] | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<ComboboxItem> | undefined
  ) => {
    let k = { ...issue };
    if (value) {
      k.concerneds = value.map((x) => ({
        employeeId: x.key,
        name: x.value,
        team: "",
      }));

      dispatch({ type: "CHANGE-ISSUE", item: k });
    }
  };

  return (
    <div>
      <TableWrapper>
        <tr className={classes.RemoveThBorder}>
          <th>작성일</th>
          <th>카테고리</th>
          <th>작성자</th>
          <th>발생일</th>
        </tr>
        <tr>
          <td>{getDateUsingNumber(issue.created)}</td>
          <td>
            <SelectCombobox
              key="write-issue-cateogry"
              name="categoryId"
              value={issue.categoryId}
              options={categoryOption}
              onChange={onChangeSelect}
            ></SelectCombobox>
          </td>
          <td>{issue.writer.name}</td>
          <td>
            <KeyboardDatePicker
              value={null}
              onChange={onChangeDate}
              selecteddate={new Date(issue.occurred)}
            ></KeyboardDatePicker>
          </td>
        </tr>

        <tr>
          <th colSpan={1}>관련자</th>
          <td colSpan={3}>
            <TitleMultipleComboBox
              userSelected={concerneds}
              onChange={ChangeConcerneds}
              options={empSelectOptions}
            ></TitleMultipleComboBox>
          </td>
        </tr>
        <tr>
          <th>열람제한</th>
          <td>
            <SelectCombobox
              key="write-issue-accessmodifier"
              name="accessModifierId"
              value={issue.accessModifierId}
              options={[
                { key: "1", value: "작성자" },
                { key: "2", value: "작성자 및 인사파트장" },
                { key: "3", value: "파트장" },
              ]}
              onChange={onChangeSelect}
            ></SelectCombobox>
          </td>
          <th>추가열람가능자</th>
          <td>
            <TitleMultipleComboBox
              userSelected={accessReaders}
              onChange={ChangeAccessReader}
              options={empSelectOptions}
            ></TitleMultipleComboBox>
          </td>
        </tr>
        <tr>
          <th colSpan={4}>
            이슈 내용
            <NoWriteAuthority authority={user.authority}></NoWriteAuthority>
            <MultilineTextField
              name="content"
              rows={5}
              value={issue.content}
              onChange={onChangeText}
              placeholder="이슈 상세내용"
            ></MultilineTextField>
          </th>
        </tr>
      </TableWrapper>
    </div>
  );
});
