import { Grid, Typography } from '@material-ui/core';
import TodayAttendanceTable from './components/TodayAttendanceTable';

export default function TodayAttendance() {
  const now = new Date();

  return (
    <Grid container spacing={3} style={{ padding: 24 }}>
      {/* Title */}
      <Grid item xs={12} style={{ margin: 20 }}>
        <Typography variant="h5" align="center">
          {"오늘의 근태"}  {`(${now.getFullYear()}.${now.getMonth() + 1}.${String(now.getDate()).padStart(2, '0')})`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid>
          <TodayAttendanceTable />
        </Grid>
      </Grid>
    </Grid>
  );
}
