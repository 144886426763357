import React, { ChangeEvent } from "react";
import { TextField, InputAdornment } from "@material-ui/core";

interface EndTextFieldProps {
  name: string;
  value: string;
  endAdornment?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
  errorMsg?: string;
  successMsg?: string;
  type?: string;
  label?: string;
  style?: any;
  disabled?: boolean;
  shrink?: boolean;
}

export function EndTextField({
  name,
  value,
  endAdornment,
  onChange,
  error,
  errorMsg,
  successMsg,
  type,
  label,
  style,
  disabled,
  shrink,
}: EndTextFieldProps) {
  const handleChange = React.useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e);
    },
    [onChange]
  );

  return (
    <TextField
      label={label}
      disabled={disabled || false}
      name={name}
      style={style}
      type={type || "text"}
      InputLabelProps={{ shrink: shrink }}
      helperText={error ? errorMsg : successMsg}
      error={error || false}
      value={value || ""}
      onChange={handleChange}
      InputProps={{ endAdornment: <InputAdornment position="end">{endAdornment}</InputAdornment> }}
    ></TextField>
  );
}
