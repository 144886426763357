import { TextField } from "@material-ui/core";
import React, { ReactElement, useEffect, useState } from "react";
import { HrStyle } from "src/Style/theme";
import { AttendanceApi, ErrorHandler } from "src/system/ApiService";
import { Autocomplete } from "@material-ui/lab";
import { EmployeeAutoComplete } from "src/system/types";
import { useUserState } from "src/system/UserContext";
import { getLastBelong } from '../attendance/attendance-request/post-attendance-request/hooks/getLastBelong';

interface SearchBarViewProps {
  onChangeSelect: (event: React.ChangeEvent<{}>, value: EmployeeAutoComplete | null) => void;
}

function SearchBarView({ onChangeSelect }: SearchBarViewProps): ReactElement {
  const styles = HrStyle();
  const user = useUserState();
  const [data, setData] = useState<EmployeeAutoComplete[]>([]);

  useEffect(() => {
    // 사원 리스트 조회
    AttendanceApi.GetEmployeeList(user.employeeId)
      .then((res) => {
        setData(res);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      });
  }, [user]);

  return (
    <div>
      <div className={styles.divSearchBar}>
        <Autocomplete
          options={data}
          onChange={onChangeSelect}
          getOptionLabel={(option) => option.name}
          renderOption={(option) => (
            <React.Fragment>
              {option.name}
              <span
                style={{ marginLeft: "20px", fontSize: "14px", color: "#888888" }}
              >{`\t ${option.employeeNo}, ${getLastBelong(option.team, option.department, option.group)}, ${option.jobPosition} , ${option.email}`}</span>
            </React.Fragment>
          )}
          renderInput={(params) => <TextField {...params} label="사원 검색" variant="outlined" />}
          style={{ minWidth: "700px" }}
        ></Autocomplete>
      </div>
    </div>
  );
}

export default SearchBarView;
