import { Typography } from "@material-ui/core";
import React, { ReactElement } from "react";
import Page from "src/components/common/Page";
import { MyStyle } from "src/Style/theme";
import Routes from "./Routes";

function MainLayout(): ReactElement {
  const classes = MyStyle();

  return (
    <div className={classes.bodyWrap}>
      <Page>
        <div className={classes.jumbotron}>
          <div className="container">
            <Typography variant="h3">Q&A Board</Typography>
            <Typography variant="subtitle1">
              궁금하신 질문은 자유롭게 하실 수 있으나, 이와 무관하다고 판단되는 게시글은 관리자 요청에 의해 삭제될 수
              있습니다.
            </Typography>
          </div>
        </div>
        <Routes></Routes>
      </Page>
    </div>
  );
}

export default MainLayout;
