import { createStyles, makeStyles } from '@material-ui/core';
import { AccentBlue, AccentGray, BasicFontKR, BoxBlue, ContentBlue, ContentGray } from 'src/Style/globalStyle';

export const useBalanceStatusStyle = makeStyles((theme) =>
  createStyles({
    grid: {
      cursor: 'pointer',
      padding: '20px 0',
      "&:hover": {
        backgroundColor: BoxBlue
      }
    },
    item: {
      padding: '20px 0',
    },
    alert: {
      padding: '0 20px',
      fontFamily: BasicFontKR
    },
    tableCell: {
      padding: "0",
    },
    wrapperGrid: {
      textAlign: "center",
      "& div": {
        borderRight: "1px solid #DDDDDD",
      },
    },
    number: {
      marginTop: "10px",
      color: "#888888",
    },
    numberGray: {
      marginTop: "10px",
      color: ContentGray,
      cursor: 'pointer',
      '&:hover': {
        color: AccentGray,
        textDecoration: 'underline'
      }
    },
    totalGray: {
      marginTop: "10px",
      color: ContentGray,
    },
    numberBlue: {
      marginTop: "10px",
      color: ContentBlue,
      cursor: 'pointer',
      '&:hover': {
        color: AccentBlue,
        textDecoration: 'underline'
      }
    },
    totalBlue: {
      marginTop: "10px",
      color: ContentBlue,
    }
  })
);