import React, { ReactElement, useEffect, useState } from "react";
import TableInsaCard from "src/components/TableInsaCard";
import { ErrorHandler, HREmployeeApi } from "src/system/ApiService";
import { Career } from "src/system/types";
import { getDisplayTime } from "src/system/Helper";
import RegularColgroup from "src/components/RegularColgroup";

interface CareerTableProps {
  employeeId: string;
}

function CareerTable({ employeeId }: CareerTableProps): ReactElement {
  const [data, setData] = useState<Career[]>([]);

  useEffect(() => {
    HREmployeeApi.GetCareerList(employeeId)
      .then((res) => {
        setData(res);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      });
  }, [employeeId]);

  return (
    <TableInsaCard title="경력사항" colgroup={<RegularColgroup cols={7}></RegularColgroup>}>
      <tr>
        <th>고용형태(직위)</th>
        <th>회사명</th>
        <th>재직기간</th>
        <th>담당업무</th>
        <th>직전연봉(만원)</th>
        <th colSpan={3}>퇴직사유</th>
        <th>비고</th>
      </tr>
      {data.map((x) => {
        return (
          <>
            <tr>
              <td>{`${x.employmentForm}(${x.jobPosition})`}</td>
              <td>{x.company}</td>
              <td>
                {getDisplayTime(x.started)}
                <br></br>
                {`~ ${getDisplayTime(x.ended)}`}
              </td>
              <td>{x.work}</td>
              <td>{x.salary}</td>
              <td colSpan={3}>{x.retirementReason}</td>
              <td>{x.remark}</td>
            </tr>
          </>
        );
      })}
    </TableInsaCard>
  );
}

export default CareerTable;
