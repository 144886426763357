import { Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import React, { ReactElement } from "react";
import { Table } from "react-bootstrap";
import { IssueListItem } from "src/system/types";
import { InterviewRecordRoutes } from "src/system/types/type";
import { format } from "date-fns";

interface ListIssueTableProps {
  data: IssueListItem[];
}

function ListIssueTable({ data }: ListIssueTableProps): ReactElement {
  return (
    <Table>
      <thead>
        <tr>
          <th>번호</th>
          <th>구분</th>
          <th>내용</th>
          <th>발생일</th>
          <th>작성자</th>
          <th>작성일</th>
        </tr>
      </thead>
      <tbody>
        {data.length >= 1 ? (
          data.map((x, i) => (
            <tr key={`tr-${i}`}>
              <td key={`td-${i}-id`}>{x.id}</td>
              <td key={`td-${i}-category`}>{x.category}</td>
              <td key={`td-${i}-content`}>
                <Link
                  component={RouterLink}
                  color="secondary"
                  to={{
                    pathname: InterviewRecordRoutes.issue + `/${x.id}`,
                  }}
                >
                  {x.content}
                </Link>
              </td>
              <td key={`td-${i}-occurred`}>{format(new Date(x.occurred), "yyyy/MM/dd")}</td>
              <td key={`td-${i}-writer`}>{x.writer.name}</td>
              <td key={`td-${i}-created`}>{format(new Date(x.created), "yyyy/MM/dd")}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={6}>데이터가 없습니다.</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}

export default ListIssueTable;
