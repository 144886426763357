import { TitleBlue, TitleGreen, TitleRed } from 'src/Style/globalStyle';

interface AttendanceRequestItemStatusProps {
  isCancel: boolean;
  status: string | null;
}

export default function AttendanceRequestItemStatus({ isCancel, status }: AttendanceRequestItemStatusProps) {
  if (!status) {
    return <span>{'-'}</span>
  }

  switch (status) {
    case '1':
    case '임시저장':
      return (
        <span style={{ color: TitleRed, fontWeight: 600 }}>
          {'임시저장'}
        </span>)
    case '2':
    case '결재중':
      return (
        <span style={{ color: TitleBlue, fontWeight: 600 }}>
          {'결재중'}
        </span>)
    case '4':
    case '반려':
      return (
        <span style={{ color: TitleRed, fontWeight: 600 }}>
          {'반려'}
        </span>)
    case '3':
    default:
      return (
        <span
          style={{
            color: TitleGreen,
            fontWeight: 600
          }}>
          {isCancel ? '취소완료' : '결재완료'}
        </span>)
  }
}
