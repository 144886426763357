import { Button, Paper, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React, { ReactElement } from "react";
import ReactDataGrid, { Column } from "react-data-grid";
import { HrStyle } from "src/Style/theme";
import { EducationSchool, ObjType } from "src/system/types";
import { BitFormatter, DefaultFormatter, IData } from "./Common";
import { ExcelUploadEnum } from "./MainPage";
import PublishIcon from "@material-ui/icons/Publish";

export interface EducationSchoolUpload extends EducationSchool, IData {
  error: ObjType;
}

interface Props {
  data: EducationSchoolUpload[];
  errorCount: number;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  onInsertData: (type: ExcelUploadEnum) => void;
}

function EducationSchoolDataImportRef({
  data,
  errorCount,
  onSubmit,
  onInsertData,
}: Props): ReactElement {
  const classes = HrStyle();
  const columns = React.useMemo(() => {
    const k = [
      {
        name: "NUM",
        key: "id",
      },
      {
        name: "사번",
        key: "employeeId",
      },
      {
        name: "최종학력",
        key: "isFinal",
      },
      {
        name: "학교구분",
        key: "kind",
      },
      {
        name: "학교명",
        key: "name",
      },
      {
        name: "입학일",
        key: "started",
      },
      {
        name: "졸업일",
        key: "ended",
      },
      {
        name: "졸업구분",
        key: "graduateStatus",
      },
      {
        name: "주전공",
        key: "major",
      },
      {
        name: "복수전공",
        key: "doubleMajor",
      },
      {
        name: "부전공",
        key: "minor",
      },
      {
        name: "학위1(주전공)",
        key: "degree",
      },
      {
        name: "학위2(복수전공)",
        key: "doubleDegree",
      },
      {
        name: "소재지",
        key: "location",
      },
      {
        name: "본교구분",
        key: "isMain",
      },
      {
        name: "성적",
        key: "gpa",
      },
      {
        name: "만점기준",
        key: "scale",
      },
      {
        name: "비고",
        key: "remark",
      },
    ];
    const nk: Column<EducationSchoolUpload>[] = k.map((x) => {
      if (x.key === "id") return x;
      else if (x.key === "isFinal") {
        return {
          ...x,
          formatter: (props) => (
            <BitFormatter {...props} trueValue="최종학력" falseValue="" />
          ),
        };
      } else {
        return {
          ...x,
          formatter: DefaultFormatter,
        } as Column<EducationSchoolUpload>;
      }
    });
    return nk;
  }, []);

  const onClickUpload = () => onInsertData("educationSchool");

  return (
    <Paper variant="outlined" className={classes.paperUpload}>
      <form onSubmit={onSubmit} name="educationSchool">
        <input type="file" name="file" id="input-file" required />
        <input type="submit" />
        <input type="reset" />
      </form>
      <br />
      <Button
        onClick={onClickUpload}
        variant="contained"
        startIcon={<PublishIcon />}
      >
        데이터 업로드
      </Button>

      {errorCount > 0 && (
        <Alert severity="error" title="에러가 발생한 위치">
          <AlertTitle>에러가 발생한 위치</AlertTitle>
          <ul>
            {data
              .filter((x) => Object.keys(x.error).length >= 1)
              .map((x) => (
                <li>{x.id}</li>
              ))}
          </ul>
        </Alert>
      )}

      <div className="text-right">
        <Typography variant="body2">에러데이터 : {errorCount}건</Typography>
      </div>

      <ReactDataGrid
        rows={data}
        columns={columns}
        rowKeyGetter={(row) => {
          return row.id;
        }}
        rowClass={(row) => {
          if (Object.keys(row.error).length >= 1) {
            return "c-error-row";
          } else return undefined;
        }}
        defaultColumnOptions={{
          resizable: true,
        }}
        className={classes.reactDataGrid}
      ></ReactDataGrid>
    </Paper>
  );
}

export default EducationSchoolDataImportRef;
