import React, { Component } from "react";

import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-icons/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-splitbuttons/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-lists/styles/material.css";
import "@syncfusion/ej2-navigations/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-richtexteditor/styles/material.css";

import {
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
  FontFamilyModel,
  FontSizeModel,
  HtmlEditor,
  Table,
  ToolbarSettingsModel,
  ToolbarType,
  NodeSelection,
} from "@syncfusion/ej2-react-richtexteditor";

//fontSize를 쓰면 default가 뭔가 이상해짐..
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fontSize: FontSizeModel = {
  default: "12 pt",
};

//실제처럼 적용이 안됨.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EditorfontFamily: FontFamilyModel = {
  default: "Noto Sans KR",
  items: [
    { text: "Segoe UI", value: "Segoe UI", command: "Font", subCommand: "FontName" },
    { text: "Roboto", value: "Roboto", command: "Font", subCommand: "FontName" },

    { text: "Noto Sans KR", value: "Noto Sans KR", command: "Font", subCommand: "FontName" },
    { text: "Impact", value: "Impact,Charcoal,sans-serif", command: "Font", subCommand: "FontName" },
    { text: "Arial", value: "Arial", command: "Font", subCommand: "FontName" },
  ],
  width: "60px",
};
interface Props {
  content: string;
}
interface State {
  value: string;
}

export default class Editor extends Component<Props, State> {
  state: State = {
    value: this.props.content,
  };
  public selection: NodeSelection = new NodeSelection();

  private editorRef = React.createRef<RichTextEditorComponent>();
  private editorToolbarSetting: ToolbarSettingsModel = {
    items: [
      "Bold",
      "Italic",
      "Underline",
      "FontName",
      "FontSize",
      "FontColor",
      "|",
      "Formats",
      "Alignments",
      "OrderedList",
      "UnorderedList",
      "Outdent",
      "Indent",
      "|",
      "CreateTable",
      "|",
      "CreateLink",
      "Image",
      "FullScreen",
      "|",
      "Undo",
      "Redo",
    ],
    type: ToolbarType.MultiRow,
  };

  public getContent() {
    return this.editorRef.current?.value || "";
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.content !== prevProps.content) {
      this.setState({
        value: this.props.content,
      });
    }
  }

  /**
   * 이미지 업로드 후, user의 업로드 파일이름과 서버가 보내준 파일이름이 다르면 content의 파일이름을 server가 보내준 파일이름으로 변경한다.
   */
  private imageUploadSuccess(args: any): void {
    if (args.e.currentTarget.getResponseHeader("name") != null) {
      const filename = decodeURIComponent(args.e.currentTarget.getResponseHeader("name"));
      // 같지 않을 때,
      if (filename !== args.file.name) {
        args.file.name = filename;
        const fileEle: any = document.querySelectorAll(".e-file-name")[0];
        fileEle.innerHTML = args.file.name.replace(document.querySelectorAll(".e-file-type")[0].innerHTML, "");
        fileEle.title = args.file.name;
      }
    }
  }

  public render() {
    const _content = this.state.value ? this.state.value : undefined;

    return (
      <div>
        <RichTextEditorComponent
          id="rich-text-editor"
          toolbarSettings={this.editorToolbarSetting}
          ref={this.editorRef}
          value={_content}
          imageUploadSuccess={this.imageUploadSuccess}
          quickToolbarSettings={{
            table: [
              "TableHeader",
              "TableRows",
              "TableColumns",
              "BackgroundColor",
              "-",
              "TableRemove",
              "Alignments",
              "TableCellVerticalAlign",
              "Styles",
              "TableCell",
            ],
          }}
          insertImageSettings={{
            saveUrl: `${window.location.origin}/api/file/rich-texteditor/uploads`,
            removeUrl: `${window.location.origin}/api/file/rich-texteditor/remove`,
            // 반드시 뒤에 '/'를 붙여주어야 한다.
            path: "/uploads/rte/",
          }}
        >
          {/* HtmlEditor를 넣지 않으면 renderError가 발생함. */}
          <Inject services={[Toolbar, Image, Link, QuickToolbar, HtmlEditor, Table]} />
        </RichTextEditorComponent>
      </div>
    );
  }
}
