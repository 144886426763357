import { Checkbox, FormControlLabel, Switch } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { Pagination } from "@material-ui/lab";
import React, { ReactElement, useState, Fragment, useEffect } from "react";
import { Table } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import Page from "src/components/common/Page";
import { MyStyle } from "src/Style/theme";
import { AftercareApi, ErrorHandler } from "src/system/ApiService";
import { ROWS_PER_PAGE, InterviewRecordRoutes } from "src/system/types";
import { getDateUsingNumber } from "src/system/Helper";
import { AlarmDetail, AlarmListItem } from "src/system/types";

export enum AftercareAlarm {
  UNCOMPLETED = "un",
  ALL = "all",
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AftercareList: React.FC = () => {
  const query = useQuery();
  const history = useHistory();
  const strPage = query.get("page") || "1";
  const type = query.get("type") || "all";
  const page = strPage ? parseInt(strPage) : 1;
  const classes = MyStyle();

  const [count, setCount] = useState<number>(10);
  const [list, setList] = useState<AlarmListItem[]>();

  React.useEffect(() => {
    if (strPage && type) {
      AftercareApi.AlarmList(strPage, type)
        .then((response) => {
          let ret = Math.floor(response.data.count / ROWS_PER_PAGE);
          ret = ret === 0 ? 1 : ret;
          setCount(ret);
          setList(response.data.list);
        })
        .catch((error) => {
          setList([]);
          let msg = ErrorHandler(error);
          if (msg) {
            alert(msg);
          }
        });
    }
  }, [strPage, type]);

  /**
   * 미완료 <-> 완료 switch 변경
   */
  const onChangeCompleted = () => {
    if (type === AftercareAlarm.UNCOMPLETED) {
      history.push(`../alarm?page=1&type=${AftercareAlarm.ALL}`);
    } else {
      history.push(`../alarm?page=1&type=${AftercareAlarm.UNCOMPLETED}`);
    }
  };

  const onChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    history.push({
      pathname: InterviewRecordRoutes.root,
      search: `?page=${page}&type=${type}`,
    });
  };

  return (
    <Page>
      <div style={{ textAlign: "right" }} className="m-2">
        <FormControl component="fieldset">
          <FormControlLabel
            value={type === AftercareAlarm.UNCOMPLETED}
            onChange={onChangeCompleted}
            control={<Switch color="primary" />}
            label="미완료만 보기"
            labelPlacement="start"
          />
        </FormControl>
      </div>
      <Table className={classes.tableHover}>
        <thead>
          <tr>
            <th>완료예상일</th>
            <th>내용</th>
            <th>완료일</th>
            <th>완료/세부목표</th>
          </tr>
        </thead>
        <tbody>
          {list ? (
            <TableRows contents={list}></TableRows>
          ) : (
            <tr>
              <td colSpan={4}>데이터 로딩중입니다.</td>
            </tr>
          )}
        </tbody>
      </Table>
      <div className="d-flex justify-content-center">
        <Pagination
          count={count}
          defaultValue={1}
          page={page}
          showFirstButton
          showLastButton
          onChange={onChangePage}
        ></Pagination>
      </div>
    </Page>
  );
};

interface TableRowsProps {
  contents: AlarmListItem[];
  block?: boolean;
}
function TableRows({ contents, block }: TableRowsProps): ReactElement {
  const [state, setState] = React.useState(contents);

  useEffect(() => {
    setState(contents);
  }, [contents]);

  const onChangeComplete = React.useCallback(
    (detail: AlarmDetail) => {
      let nextState = state.map((x, i) =>
        x.aftercareId === detail.aftercareId
          ? {
              ...x,
              completedCount: detail.completed ? x.completedCount + 1 : x.completedCount - 1,
              details: x.details.map((y, i) =>
                y.id === detail.id
                  ? {
                      ...y,
                      ...detail,
                    }
                  : y
              ),
            }
          : x
      );

      setState(nextState);
    },
    [state]
  );

  return (
    <>
      {state.length === 0 ? (
        <tr>
          <td colSpan={4} className={"text-center"}>
            표시할 데이터가 없습니다.
          </td>
        </tr>
      ) : (
        state.map((x, i) => <TableRow2 onChangeComplete={onChangeComplete} data={x} key={`tablerow-${i}`}></TableRow2>)
      )}
    </>
  );
}

interface TableRowProps {
  data: AlarmListItem;
  onChangeComplete: (detail: AlarmDetail) => void;
}
function TableRow({ data, onChangeComplete }: TableRowProps): ReactElement {
  const [hidden, setHidden] = useState(true);

  const onClickRow = (event: React.MouseEvent) => {
    setHidden(!hidden);
  };
  return (
    <Fragment>
      <tr onClick={onClickRow} className="header">
        <td>{getDateUsingNumber(data.estimated)}</td>
        <td>{data.content}</td>
        <td>{getDateUsingNumber(data.completed)}</td>
        <td>{`${data.completedCount}/${data.allCount}`}</td>
      </tr>

      {!hidden && (
        <tr>
          <td colSpan={4}>
            <DetailTable2 contents={data.details} onChangeComplete={onChangeComplete}></DetailTable2>
          </td>
        </tr>
      )}
    </Fragment>
  );
}

const TableRow2 = React.memo(TableRow);

interface DetailTableProps {
  contents: AlarmDetail[];
  onChangeComplete: (detail: AlarmDetail) => void;
}

function DetailTable({ contents, onChangeComplete: changeState }: DetailTableProps): ReactElement {
  const onChangeCheckbox = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (contents) {
        let i = parseInt(event.target.dataset.id + "");

        let obj = {
          ...contents[i],
        };

        let type = "type";
        if (event.target.checked) {
          obj.completed = new Date().getTime();
          type = "completed";
        } else {
          obj.completed = undefined;
          type = "uncompleted";
        }

        AftercareApi.ChangeDetailCompletedByType(obj.id, type)
          .then(() => {
            changeState(obj);
          })
          .catch((error) => {
            console.log(error);
            alert("업데이트를 실패하였습니다.");
          });
      }
    },
    [changeState, contents]
  );

  return (
    <Table>
      <tr>
        <th>완료</th>
        <th>완료일</th>
        <th>사후목표</th>
        <th>결과</th>
      </tr>
      {contents.map((x, i) => (
        <tr key={`dt-tr${i}`}>
          <td key={`dt-ta${i}`}>
            <Checkbox
              key={`dt-ch${i}`}
              checked={x.completed && !isNaN(x.completed) ? true : false}
              inputProps={{
                "aria-label": "checkbox",
                //@ts-ignore
                "data-id": `${i}`,
                "data-k": `${typeof x.completed === "number"}`,
              }}
              onChange={onChangeCheckbox}
            ></Checkbox>
          </td>
          <td key={`dt-tc${i}`}>{getDateUsingNumber(x.completed)}</td>
          <td key={`dt-t${i}`}>{x.target}</td>
          <td key={`dt-td${i}`}>{x.result}</td>
        </tr>
      ))}
    </Table>
  );
}

const DetailTable2 = React.memo(DetailTable);

export default AftercareList;
