import { Button } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import MaterialTable from "material-table";
import React, { useCallback, useEffect, useState } from "react";
import { ErrorHandler, EvidenceApi } from "src/system/ApiService";
import { TABLEICONS, TABLE_ACTION, TABLE_HEADER } from "src/system/Constants";
import { Evidence } from "src/system/types";
import { useUserState } from "src/system/UserContext";

function EvidencePage() {
  const user = useUserState();
  const [data, setData] = useState<Evidence[]>([]);

  const onLoad = useCallback(() => {
    EvidenceApi.GetEvidenceList("admin")
      .then((res) => {
        console.log(res);
        setData(res.data);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      });
  }, []);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const onChangeRequest = (evidence: Evidence, approve: boolean) => {
    var status = "";
    var message = "승인";
    if (approve) {
      // 승인
      status = "승인완료";
    } else {
      // 승인취소
      status = "대기";
      message = "승인취소";
    }
    if (window.confirm(`${message} 하시겠습니까?`)) {
      var inputEvidence = { ...evidence, status: status, approverName: user.name, approverId: user.employeeId };
      EvidenceApi.UpdateEvidence(inputEvidence)
        .then((res) => {
          onLoad();
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          alert(msg);
        });
    }
  };

  return (
    <div>
      <Alert severity="info">
        <AlertTitle>도움말</AlertTitle>
        <ul>
          <li>증빙서류 발급을 요청 목록을 볼 수 있습니다.</li>
          <li>승인완료된 경우에만 요청자가 요청페이지에서 인쇄 가능합니다.</li>
        </ul>
      </Alert>
      <MaterialTable
        icons={TABLEICONS}
        title="증빙서류 발급 신청"
        data={data}
        columns={[
          {
            title: "신청일",
            field: "requested",
            cellStyle: { width: "10%" },
            render: (props) => {
              return <>{props.requested && props.requested.split("T")[0]}</>;
            },
          },
          { title: "소속", field: "team", cellStyle: { width: "10%" } },
          { title: "신청자", field: "name", cellStyle: { width: "10%" } },
          { title: "구분", field: "type", cellStyle: { width: "10%" } },
          { title: "발급용도", field: "usage", cellStyle: { width: "20%" } },
          { title: "진행 상태", field: "status", cellStyle: { width: "10%" } },
          {
            title: "승인일",
            field: "approved",
            cellStyle: { width: "10%" },
            render: (props) => {
              return <>{props.approved && props.approved.split("T")[0]}</>;
            },
          },
          { title: "승인자", field: "approverName", cellStyle: { width: "10%" } },
          {
            title: "-",
            field: "",
            cellStyle: { width: "10%" },
            render: (props) => {
              if (props.status === "승인완료")
                return (
                  <Button variant="contained" color="default" onClick={() => onChangeRequest(props, false)}>
                    승인취소
                  </Button>
                );
              else if (props.status === "인쇄완료") return <></>;
              else
                return (
                  <Button variant="contained" color="primary" onClick={() => onChangeRequest(props, true)}>
                    승인
                  </Button>
                );
            },
          },
        ]}
        localization={{
          body: {
            emptyDataSourceMessage: "표시할 데이터가 없습니다.",
          },
        }}
        options={{
          headerStyle: TABLE_HEADER,
          actionsCellStyle: TABLE_ACTION,
          search: true,
          pageSize: 10,
          filtering: false,
        }}
      ></MaterialTable>
    </div>
  );
}

export default EvidencePage;
