import React, { ReactElement } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import WriteInit from "src/ViewParts/WriteInit";
import EditInit from "src/page/interview/EditInit";
import SearchPage from "src/page/issue/SearchPage";
import ViewPage from "src/page/issue/ViewPage";
import WritePage from "src/page/issue/WritePage";
import { InterviewContextProvider } from "src/system/InterviewContext";
import { RouteData } from "src/system/types";
import { InterviewRecordRoutes } from "../system/types/type";

function IssueRoute(): ReactElement {
  let { path } = useRouteMatch();
  return (
    <InterviewContextProvider>
      <Switch>
        <Route path={`${path}/list`} exact>
          <SearchPage></SearchPage>
        </Route>
        <Route path={`${path}/write`} exact>
          <WriteInit dataType={RouteData.issue}>
            <WritePage></WritePage>
          </WriteInit>
        </Route>
        <Route path={`${path}/edit/:issueId`}>
          <EditInit type={InterviewRecordRoutes.issue}>
            <WritePage></WritePage>
          </EditInit>
        </Route>
        <Route path={`${path}/:issueId`}>
          <ViewPage></ViewPage>
        </Route>
      </Switch>
    </InterviewContextProvider>
  );
}

export default IssueRoute;
