import DateFnsUtils from "@date-io/date-fns";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import "date-fns";
import ko from "date-fns/locale/ko";
import { Fragment, useEffect, useRef, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useRecoilValue } from "recoil";
import { useUserState } from "src/system/UserContext";
import ARCancellation from "./components/ARCancellation";
import ARHistory from "./components/ARHistory";
import AttendanceAppend from "./components/AttendanceAppend";
import AttendanceItem from "./components/AttendanceItem";
import BalanceStatus from "./components/BalanceStatus";
import Content from "./components/Content";
import ErrorText from "./components/ErrorText";
import HelpText from "./components/HelpText";
import Title from "./components/Title";
import CreateFloatingButton from "./components/button/CreateFloatingButton";
import EmployeeChangeModal from "./components/modal/EmployeeChangeModal";
import LoadingSpinnerModal from "./components/modal/LoadingSpinnerModal";
import { AnnualLeave, AnnualLeaveType } from "./data/AnnualLeaveType";
import { useAttendanceHooks } from "./hooks/useAttendanceHooks";
import { useResetAtomsWhenPageLeave } from "./hooks/useResetAtomWhenPageLeave";
import {
  GetAttendanceDocumentDetail,
  GetAttendanceForm,
} from "./interface/RequestInterface";
import { attendanceBalanceCountMapAtom } from "./recoils/AttendanceMapAtom";
import { employeeChangeModalAtom } from "./recoils/EmployeeChangeModalAtom";
import { saveLoadingModalAtom } from "./recoils/SaveLoadingModalAtom";
import { useAttendanceStyles } from "./style/useAttendanceStyle";
import "./style/attendance.css";

export interface AttendanceDocumentForm {
  title: string;
  reason: string;
  call: string;
  category: string;
  isCheckOwnVehicle: boolean;
  attendanceItem: {
    employeeId: string;
    employeeName: string;
    department: string;
    attendanceForm: GetAttendanceForm;
    formName: string;
    content: string;
    vehicle: string;
    isTimeEntered: boolean;
    workDate: string;
    startTime: string;
    endTime: string;
    term: number;
    expiredAt: Date;
  }[];
  attendanceRequestItemCancelIdList: number[];
}

interface PostAttendanceRequestProps {
  attendanceRequest?: GetAttendanceDocumentDetail;
}

export default function PostAttendanceRequest({
  attendanceRequest,
}: PostAttendanceRequestProps) {
  const classes = useAttendanceStyles();
  const formRef = useRef<HTMLFormElement>(null);
  const user = useUserState();
  const [tabValue, setTabValue] = useState(0);

  const attendanceBalanceCountMap = useRecoilValue(
    attendanceBalanceCountMapAtom
  );
  const [targetEmployeeIdList, setTargetEmployeeIdList] = useState<string[]>(
    []
  );

  const {
    attendanceRequestSchema,
    submitFormData,
    useFetchAttendanceFormList,
    getAttendanceBalanceCountData,
  } = useAttendanceHooks();

  useResetAtomsWhenPageLeave([saveLoadingModalAtom, employeeChangeModalAtom]);

  // react-hook-form
  const formHook = useForm<AttendanceDocumentForm>({
    defaultValues: {
      title: "",
      reason: "",
      call: "",
      category: "",
      isCheckOwnVehicle: false,
      attendanceItem: [],
      attendanceRequestItemCancelIdList: [],
    },
    resolver: yupResolver(attendanceRequestSchema) as any,
  });

  const { fields, append, remove, update } = useFieldArray({
    control: formHook.control,
    name: "attendanceItem",
  });

  // 재기안시에 첫 데이터 setting
  useEffect(() => {
    if (attendanceRequest) {
      if (attendanceRequest.attendanceRequestItemList.length > 0) {
        formHook.setValue("reason", attendanceRequest.reason);
        formHook.setValue("call", attendanceRequest.call);
      } else {
        alert("통합근태 취소 양식은 재기안 할 수 없습니다.");
      }
    }
  }, []);

  // Data Fetching
  const formList = useFetchAttendanceFormList();

  // item이 변경되었을 때
  useEffect(() => {
    const targetEmployeeList = new Set<string>();
    formHook.watch("attendanceItem").forEach((item) => {
      // 연차 사용하는 대상들 employeeId 값 넣기
      targetEmployeeList.add(item.employeeId);

      // 연차 데이터가 없다면 set
      if (!attendanceBalanceCountMap.has(item.employeeId)) {
        getAttendanceBalanceCountData(item.employeeId);
      }
    });

    setTargetEmployeeIdList(Array.from(targetEmployeeList));
  }, [formHook.watch("attendanceItem")]);

  const resetAllItem = () => {
    formHook.reset({
      title: "",
      reason: "",
      call: "",
      category: formHook.watch("category"),
      isCheckOwnVehicle: false,
      attendanceItem: [],
      attendanceRequestItemCancelIdList: [],
    });
  };

  return (
    <FormProvider {...formHook}>
      <form
        ref={formRef}
        onSubmit={formHook.handleSubmit((data) =>
          submitFormData(data, formHook.reset, user.employeeId)
        )}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ko}>
          <Grid style={{ padding: 24 }} container spacing={1}>
            {/* Title */}
            <Grid item xs={12}>
              <Typography variant="h5" align="center">
                {"통합근태 작성"}
              </Typography>
            </Grid>
            {/* Content */}
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <BalanceStatus />
                </Grid>
                <Grid item xs={12}>
                  <Tabs
                    value={tabValue}
                    variant="fullWidth"
                    classes={{
                      indicator: classes.indicator,
                    }}
                    textColor="inherit"
                    onChange={(e, value) => {
                      formHook.reset();
                      setTabValue(value);
                    }}
                  >
                    <Tab
                      value={0}
                      label={"통합근태 작성"}
                      classes={{
                        root: classes.root,
                        selected: classes.selected,
                      }}
                    />
                    <Tab
                      value={1}
                      label={"통합근태 취소"}
                      classes={{
                        root: classes.root,
                        selected: classes.selected,
                      }}
                    />
                  </Tabs>
                </Grid>
                <Grid id="capture" className={classes.capture} item container>
                  <Grid item xs={12} className="exclude">
                    <Title />
                  </Grid>
                  {tabValue === 0 && (
                    <>
                      <Grid item xs={12} className="exclude">
                        {formList && (
                          <AttendanceAppend
                            formList={formList}
                            append={append}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Grid
                          className="exclude"
                          container
                          justify="flex-end"
                          style={{ marginBottom: 5 }}
                        >
                          <Button
                            variant="contained"
                            type="button"
                            onClick={() => resetAllItem()}
                          >
                            {"전체삭제"}
                          </Button>
                        </Grid>
                        <TableContainer
                          component={Paper}
                          className={classes.container}
                        >
                          <Table className={classes.table} size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell align="center" width={"15%"}>
                                  {"양식종류"}
                                </TableCell>
                                <TableCell align="center" width={"15%"}>
                                  {"소속"}
                                </TableCell>
                                <TableCell align="center" width={"15%"}>
                                  {"대상자"}
                                </TableCell>
                                <TableCell align="center" width={"15%"}>
                                  {"신청일"}
                                </TableCell>
                                <TableCell align="center" width={"20%"}>
                                  {"신청시간"}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  width={"10%"}
                                  className={
                                    formHook.watch("isCheckOwnVehicle")
                                      ? ""
                                      : "exclude"
                                  }
                                >
                                  {"차량선택"}
                                </TableCell>
                                <TableCell align="center" width={"5%"}>
                                  {"잔여휴가"}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  width={"5%"}
                                  className="exclude"
                                >
                                  {"삭제"}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {fields.length === 0 && (
                                <TableRow>
                                  <TableCell colSpan={8} align="center">
                                    <span>
                                      양식 선택 후, 반영 버튼을 눌러주세요.
                                    </span>
                                  </TableCell>
                                </TableRow>
                              )}
                              {fields.map((attendanceItem, index) => {
                                const leaveType = attendanceItem.attendanceForm
                                  .leaveType as AnnualLeaveType;
                                let remainingAnnualLeaveCount = "-";

                                if (leaveType) {
                                  const data = attendanceBalanceCountMap.get(
                                    attendanceItem.employeeId
                                  );

                                  if (!data) {
                                    return <Fragment key={index}></Fragment>;
                                  }
                                  let unUsedAttendanceBalance = 0;

                                  switch (leaveType) {
                                    case AnnualLeave.annualLeave:
                                      unUsedAttendanceBalance =
                                        data.annualLeave - data.usedAnnualLeave;
                                      break;
                                    case AnnualLeave.specialLeave:
                                      unUsedAttendanceBalance =
                                        data.specialLeave -
                                        data.usedSpecialLeave;
                                      break;
                                    case AnnualLeave.alternativeLeave:
                                      unUsedAttendanceBalance =
                                        data.alternativeLeave -
                                        data.usedAlternativeLeave;
                                      break;
                                  }
                                  const usedAttendanceCount = fields
                                    .slice(0, index + 1)
                                    .reduce((sum, value) => {
                                      if (
                                        value.employeeId ===
                                          attendanceItem.employeeId &&
                                        value.attendanceForm.leaveType ===
                                          attendanceItem.attendanceForm
                                            .leaveType
                                      ) {
                                        sum -= value.term;
                                      }
                                      return sum;
                                    }, 0);

                                  remainingAnnualLeaveCount = `${
                                    unUsedAttendanceBalance -
                                    usedAttendanceCount
                                  }`;
                                }

                                return (
                                  <AttendanceItem
                                    key={attendanceItem.workDate + index}
                                    index={index}
                                    remove={remove}
                                    remainingAnnualLeaveCount={
                                      remainingAnnualLeaveCount
                                    }
                                  />
                                );
                              })}
                            </TableBody>
                          </Table>
                          <ErrorText
                            message={
                              formHook.formState.errors.attendanceItem?.message
                            }
                          />
                        </TableContainer>
                      </Grid>
                      <Grid item xs={12}>
                        <Content />
                      </Grid>
                      <Grid item xs={12}>
                        <HelpText />
                        {formHook.watch("category") === "휴가원" &&
                          targetEmployeeIdList.map((employeeId, index) => {
                            return (
                              <ARHistory
                                key={index}
                                targetEmployeeId={employeeId}
                              />
                            );
                          })}
                      </Grid>
                    </>
                  )}
                  {tabValue === 1 && (
                    <Grid item xs={12}>
                      <ARCancellation />
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Grid container justify="center">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={<CreateIcon />}
                    >
                      {"저장하기"}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </form>
      <LoadingSpinnerModal />
      <EmployeeChangeModal update={update} />
      <CreateFloatingButton
        handleSubmit={formHook.handleSubmit((data) =>
          submitFormData(data, formHook.reset, user.employeeId)
        )}
      />
    </FormProvider>
  );
}
