//import { SelectProps } from "@material-ui/core";
import { MenuItem, withStyles } from "@material-ui/core";
import React, { PureComponent, ReactNode } from "react";
import Select, { SelectProps } from "src/components/Select";

export const MiniMenuItem = withStyles({
  root: {
    padding: "2px",
  },
})(MenuItem);

interface SelectScrollProps extends SelectProps {
  value: string | number | undefined;
  disabled?: boolean;
  row?: number;
  children: ReactNode;
}

const ITEM_PADDING_TOP = 8; //8px
const ITEM_HEIGHT = 28; //28px

export default class SelectScroll extends PureComponent<SelectScrollProps> {
  render() {
    const { value, disabled, row, children, ...others } = this.props;

    return (
      <Select
        value={value}
        disabled={disabled}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: ITEM_HEIGHT * (row || 5) + ITEM_PADDING_TOP,
            },
          },
        }}
        {...others}
      >
        {children}
      </Select>
    );
  }
}
