import { Button } from "@material-ui/core";
import React, { ReactElement, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SideBar from "src/components/common/SideBar";
import Page from "src/components/common/Page";
import { InterviewRecordRoutes } from "src/system/types/type";
import { ErrorHandler, InterviewApi } from "src/system/ApiService";
import { initViewPage, ViewPageData } from "src/system/types";
import { useUserState } from "src/system/UserContext";
import ViewAftercare from "src/ViewParts/ViewAftercare";
import ViewInterview from "src/ViewParts/ViewInterview";
import ViewIssue from "src/ViewParts/ViewIssue";
import { useInterviewId } from "./EditInit";

function ViewPage(): ReactElement {
  const interviewId = useInterviewId();
  const [data, setData] = useState<ViewPageData>(initViewPage);

  const history = useHistory();
  const user = useUserState();

  useEffect(() => {
    if (!isNaN(parseInt(interviewId))) {
      InterviewApi.getInterview(parseInt(interviewId))
        .then((x) => {
          setData(x);
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          alert(msg);
        });
    }
    return () => {};
  }, [interviewId]);

  const btnEdit = function (event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    history.push({
      pathname: InterviewRecordRoutes.interview + `/edit/${interviewId}`,
    });
  };

  return (
    <Page>
      {/* 작성자 본인이거나 insa1 아이디라면 btnEdit을 보여준다. */}
      {IsEdit(data.interview.writer.employeeId, user.employeeId) && (
        <SideBar>
          <Button variant="outlined" key="btnEdit" onClick={btnEdit}>
            수정
          </Button>
        </SideBar>
      )}

      <ViewIssue issue={data.issue}></ViewIssue>

      <ViewInterview file={data.file} interview={data.interview}></ViewInterview>
      <br />

      {data.aftercares.map((x, i) => (
        <ViewAftercare key={`viewAftercare-${i}`} data={x}></ViewAftercare>
      ))}
    </Page>
  );
}

export const IsEdit = (writerEmpId: string, userEmpId: string): boolean => {
  let ret = false;

  //작성자 본인
  ret = writerEmpId === userEmpId;

  if (!ret) {
    //관리자 아이디
    ret = userEmpId === "M000000";
  }

  return ret;
};

export default ViewPage;
