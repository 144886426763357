import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { TextField, TextFieldProps } from "@material-ui/core";
import React, { ReactElement } from "react";

const marginTop = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      resize: "both",
    },
  })
);

function MultilineTextField({ title, rows, value, placeholder, name, onChange, style }: TextFieldProps): ReactElement {
  const classes = marginTop();
  return (
    <TextField
      className={classes.root}
      style={style}
      rowsMax={100}
      name={name}
      multiline
      rows={rows || 10}
      fullWidth
      variant="outlined"
      value={value}
      label={title}
      placeholder={placeholder || ""}
      onChange={onChange}
    ></TextField>
  );
}

export default MultilineTextField;
