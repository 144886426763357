import { atom } from 'recoil';

export interface saveLoadingModalProps {
  isOpen: boolean;
  text: string;
}

export const initSavingLoadingModalState = {
  isOpen: false,
  text: ''
}
export const saveLoadingModalAtom = atom<saveLoadingModalProps>({
  key: 'saveLoadingModalAtom',
  default: initSavingLoadingModalState
})