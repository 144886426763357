import MaterialTable from "material-table";
import React, { ReactElement } from "react";
import { HRCategoryApi } from "src/system/ApiService";
import { TABLE_HEADER } from "src/system/Constants";
import { LMATERIAL_TABLE } from "src/system/Localization";
import { CATEGORY_TABLE, HRCategory } from "src/system/types";

interface Category2TableGridProps {
  table: string;
  data: HRCategory[];
  dic: {};
  refreshData: () => void;
}

function Category2TableGrid({
  table,
  data,
  dic,
  refreshData,
}: Category2TableGridProps): ReactElement {
  const ref = React.createRef<MaterialTable<HRCategory>>();

  return (
    <div style={{ maxWidth: "100%" }}>
      <MaterialTable
        ref={ref}
        title={"소분류"}
        options={{
          actionsColumnIndex: -1,
          search: false,
          showTitle: true,
          padding: "dense",
          paging: false,
          filtering: true,
          headerStyle: TABLE_HEADER,
        }}
        editable={{
          onRowAdd: (newData) => {
            return HRCategoryApi.UpsertCategory(2, {
              ...newData,
              id: 0,
              kind: table,
            }).then(() => refreshData());
          },
          onRowUpdate: (newData, oldData) => {
            // todo 카테고리 수정 시 그 동안의 이력도 같이 수정된다는 경고창 띄우기
            return HRCategoryApi.UpsertCategory(2, newData).then((res) => {
              refreshData();
            });
          },
          onRowDelete: (oldData) => {
            return HRCategoryApi.DeleteCategory(2, oldData.id).then((res) => {
              refreshData();
            });
          },
        }}
        columns={[
          { title: "ID", field: "id", editable: "never", filtering: false },
          {
            title: "테이블명",
            field: "kind",
            lookup: CATEGORY_TABLE,
            editable: "never",
            filtering: false,
          },
          { title: "대분류", field: "parentId", lookup: dic },
          { title: "이름", field: "name" },
          {
            title: "표시순서",
            field: "order",
            type: "numeric",
            filtering: false,
          },
        ]}
        data={data}
        localization={LMATERIAL_TABLE}
      ></MaterialTable>
    </div>
  );
}

export default Category2TableGrid;
