import React, { ReactElement, useEffect, useState } from "react";
import TableInsaCard from "src/components/TableInsaCard";
import { ErrorHandler, HREmployeeApi } from "src/system/ApiService";
import { FamilyMember } from "src/system/types";
import { getAgeByBirthDay, getDisplayTime } from "src/system/Helper";
import RegularColgroup from "src/components/RegularColgroup";

interface FamilyRecordTableProps {
  employeeId: string;
}

function FamilyRecordTable({ employeeId }: FamilyRecordTableProps): ReactElement {
  const [data, setData] = useState<FamilyMember[]>([]);

  const lastUpdated = data.reduce(function (prev, current) {
    return prev.updated > current.updated ? prev : current;
  }, data[0]);

  useEffect(() => {
    HREmployeeApi.GetFamilyList(employeeId)
      .then((res) => {
        setData(res);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      });
  }, [employeeId]);

  return (
    <TableInsaCard
      title="가족사항"
      caption={lastUpdated !== undefined ? getDisplayTime(lastUpdated.updated) : ""}
      colgroup={<RegularColgroup cols={7}></RegularColgroup>}
    >
      <tr>
        <th>관계</th>
        <th>성명</th>
        <th>생년월일</th>
        <th>나이</th>
        <th>장애 여부</th>
        <th>외국인 여부</th>
        <th>비고</th>
      </tr>
      {data.map((x) => {
        return (
          <>
            <tr>
              <td>{x.relation}</td>
              <td>{x.name}</td>
              <td>{getDisplayTime(x.birthday)}</td>
              <td>{`만 ${getAgeByBirthDay(x.birthday)}세`}</td>
              <td>{x.isDisability ? "대상" : "비대상"}</td>
              <td>{x.isForeign ? "대상" : "비대상"}</td>
              <td>
                <div style={{ maxWidth: "250px" }}>{x.remark}</div>
              </td>
            </tr>
          </>
        );
      })}
    </TableInsaCard>
  );
}

export default FamilyRecordTable;
