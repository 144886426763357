import { DataManager } from '@syncfusion/ej2-data';
import { ColumnDirective, ColumnsDirective, Filter, GridComponent, Inject, Page, Resize, Search, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { useEffect, useRef, useState } from 'react';
import { getLastBelong } from 'src/page/attendance/attendance-request/post-attendance-request/hooks/getLastBelong';
import { useDateHooks } from 'src/page/attendance/attendance-request/post-attendance-request/hooks/useDateHooks';
import { GetAttendanceRequestItem } from 'src/page/attendance/attendance-request/post-attendance-request/interface/RequestInterface';
import { AttendanceRequestApi, ErrorHandler } from 'src/system/ApiService';

export default function TodayAttendanceTable() {
  const [todayAttendanceDataList, setTodayAttendanceDataList] = useState<GetAttendanceRequestItem[]>([]);
  const gridRef = useRef<GridComponent | null>(null);

  const { getDateStringFormat } = useDateHooks();

  useEffect(() => {
    const kr_cur_time = new Date().toLocaleDateString('ko-KR', {
      timeZone: 'Asia/Seoul',
    });
    const dateString = getDateStringFormat(kr_cur_time, 'YYYY-MM-DD');

    AttendanceRequestApi.GetTodayAttendanceList('ALL', dateString)
      .then(res => setTodayAttendanceDataList(res))
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      })
  }, [])

  const getStartTimeValue = (fields: string, data: any) => {
    const todayAttendance = data as GetAttendanceRequestItem;
    if (todayAttendance.formName.includes('연차')) {
      return '-';
    }
    return todayAttendance.started;
  }

  const getEndTimeValue = (fields: string, data: any) => {
    const todayAttendance = data as GetAttendanceRequestItem;
    if (todayAttendance.formName.includes('연차')) {
      return '-';
    }
    return todayAttendance.ended;
  }

  return (
    <GridComponent
      dataSource={new DataManager(todayAttendanceDataList)}
      allowResizing={true}
      allowPaging={true}
      allowSorting={true}
      allowFiltering={true}
      filterSettings={{ type: 'CheckBox' }}
      toolbar={["Search"]}
      pageSettings={{ pageSize: 20, pageSizes: true }}
      ref={gridRef}
    >
      <ColumnsDirective>
        {/** TODO 여기 group추가 */}
        <ColumnDirective field="id" isPrimaryKey={true} visible={false} />
        <ColumnDirective
          textAlign='Center'
          field="team"
          headerText="소속"
          valueAccessor={(_, data) => {
            const attendance = data as GetAttendanceRequestItem;
            return getLastBelong(attendance.team, attendance.parentTeam, attendance.group);
          }}
          width="15%" />
        <ColumnDirective
          textAlign='Center'
          field="name"
          headerText="이름"
          width="15%" />
        <ColumnDirective
          textAlign='Center'
          field="jobTitle"
          headerText="직책"
          width="10%" />
        <ColumnDirective
          textAlign='Center'
          field="category"
          headerText="대분류"
          width="15%" />
        <ColumnDirective
          textAlign='Center'
          field="formName"
          headerText="소분류"
          width="15%" />
        <ColumnDirective
          textAlign='Center'
          field="started"
          headerText="시작시간"
          valueAccessor={getStartTimeValue}
          width="15%" />
        <ColumnDirective
          textAlign='Center'
          field="ended"
          headerText="종료시간"
          valueAccessor={getEndTimeValue}
          width="15%" />
        <ColumnDirective
          textAlign='Center'
          field="attendanceRequestStatus"
          headerText="상태"
          width="15%" />
      </ColumnsDirective>
      <Inject services={[Resize, Filter, Toolbar, Page, Sort, Search]} />
    </GridComponent>
  )
}
