import {
  Button,
  ButtonProps,
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import React, { ReactElement } from "react";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { SECONDARY } from "src/system/Constants";

interface Styles {
  variant: string;
  children: React.ReactNode;
  [key: string]: any;
}

interface SizeMapping {
  contained: string;
  [key: string]: any;
}

export interface ButtonStyles extends WithStyles<typeof styles> {
  variant: string;
}
const color = SECONDARY;

// Like https://github.com/brunobertolini/styled-by
export const styledBy =
  (property: string, mapping: SizeMapping) => (props: Styles) =>
    mapping[props[property]];

const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: styledBy("variant", {
        contained: color,
        outlined: "#fff",
      }),
      color: styledBy("variant", {
        contained: theme.palette.getContrastText(color),
        outlined: color,
      }),
      padding: "4px 12px",
      boxShadow: styledBy("variant", {
        contained:
          "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        outlined: "0px 0px 0px 0px",
      }),
      "&:hover": {
        backgroundColor: styledBy("variant", {
          contained: color,
          outlined: fade(color, 0.04),
        }),
      },
      border: styledBy("variant", {
        contained: "0",
        outlined: `1px solid ${color}`,
      }),
    },
  });

const StyledButton = withStyles(styles)(Button);

function ButtonBlue(props: ButtonProps): ReactElement {
  return (
    <StyledButton variant={props.variant || "contained"} {...props}>
      {props.children}
    </StyledButton>
  );
}

export default ButtonBlue;
