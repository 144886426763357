import React, { ReactElement } from "react";
import { Route, Switch } from "react-router-dom";
import ManageHrRoute from "src/Route/HRRecord/ManageHrRoute";
import MainLayout from "src/page/card/MainLayout";

function Routes(): ReactElement {
  return (
    <Switch>
      <Route path="/card">
        <MainLayout></MainLayout>
      </Route>
      <Route path="/manage-hr/:sub1?">
        <ManageHrRoute></ManageHrRoute>
      </Route>
    </Switch>
  );
}

export default Routes;
