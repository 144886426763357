import React, { ReactElement, useMemo, useState } from "react";
import TableInsaCard from "src/components/TableInsaCard";
import { DepartmentApi, HRCategoryApi } from "src/system/ApiService";
import { DeptInfoErp, HRCategory } from "src/system/types";
import RewardAndPunishmentDataGrid from "./RewardAndPunishmentDataGrid";
import WorkRecordGridComponent from "./WorkRecordGridComponent";
interface Props {
  employeeId: string;
}

export function MainPage({ employeeId }: Props): ReactElement {
  const [team, setTeam] = useState<DeptInfoErp[]>([]);
  const [category, setCategory] = useState<HRCategory[]>([]);

  useMemo(() => {
    DepartmentApi.GetDeptInfoErp().then((res) => {
      setTeam(res.result);
    });
    HRCategoryApi.GetCategoryList(2, "workrecord").then((res) => {
      setCategory(res);
    });
  }, []);

  return (
    <>
      <TableInsaCard title="인사변동 이력">
        <WorkRecordGridComponent
          employeeId={employeeId}
          teamList={team}
          categoryList={category}
        ></WorkRecordGridComponent>
      </TableInsaCard>
      <TableInsaCard title="상벌사항">
        <RewardAndPunishmentDataGrid
          employeeId={employeeId}
        ></RewardAndPunishmentDataGrid>
      </TableInsaCard>
    </>
  );
}

export default MainPage;
