import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { ReactElement, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Editor } from "src/components/common/RichTextBox";
import { MyStyle } from "src/Style/theme";
import { ErrorHandler, HRCategoryApi, QNAApi } from "src/system/ApiService";
import { HRCategory, InitQNA, QNA, QNARoutes } from "src/system/types";

function EditPage(): ReactElement {
  // const { id } = useParams<{ id: string }>();
  const classes = MyStyle();
  const ref = React.useRef<Editor>(null);
  const [parentId, setParentId] = useState<number>(0);
  const [category1, setCategory1] = useState<HRCategory[]>([]);
  const [category2, setCategory2] = useState<HRCategory[]>([]);

  const [qna, setQNA] = useState<QNA>(InitQNA);
  const [file, setFile] = useState<File>();
  // const [fileDeleteOpen, setFileDeleteOpen] = useState<boolean>(false);

  // const qnaId = isNaN(parseInt(id)) ? 0 : parseInt(id);
  // const isEdit = qnaId !== 0 ? true : false;
  const history = useHistory();

  useEffect(() => {
    // API
    HRCategoryApi.GetCategoryList(1, "qna")
      .then((res) => {
        setCategory1(res);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      });

    HRCategoryApi.GetCategoryList(2, "qna")
      .then((res) => {
        setCategory2(res);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      });
  }, []);

  const confirmError = (title: string, content: string | null | undefined) => {
    let errorMsg = undefined;
    if (!title) {
      errorMsg = "제목을 입력해주세요.";
    } else if (!qna.categoryId) {
      errorMsg = "카테고리를 선택해주세요.";
    } else if (!content) {
      errorMsg = "내용을 입력해주세요.";
    }
    return errorMsg;
  };

  const setFormData = () => {
    const formData = new FormData();

    if (file !== undefined && qna.fileQ !== undefined) {
      formData.append("FormfileQ", file);
    }

    formData.append("id", qna.id + "");
    formData.append("title", qna.title);
    formData.append("categoryId", qna.categoryId + "");
    formData.append("fileQ", qna.fileQ);

    return formData;
  };

  const onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQNA({ ...qna, title: event.currentTarget.value });
  };

  const onChangeSelect = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    const { name, value } = event.target;
    let num: number;

    if (typeof value === "number") {
      num = value;
    } else if (typeof value === "string") {
      num = parseInt(value);
    } else {
      return;
    }

    if (!isNaN(num)) {
      if (name === "category1") {
        setParentId(num);
      } else if (name === "category2") {
        setQNA({ ...qna, categoryId: num });
      } else {
        throw new Error("Not Implemented Error");
      }
    }
  };

  const saveFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      setFile(event.target.files[0]);
      setQNA({ ...qna, fileQ: event.target.files[0].name });
    }
  };

  const onSave = () => {
    let errorMsg: string | undefined = undefined;
    const title = qna.title;
    const value: string | null | undefined = ref.current?.getContent();

    if (ref.current) {
      errorMsg = confirmError(title, value);
    } else {
      errorMsg = "Somthing wrong. Please reload this page.";
    }

    if (errorMsg) {
      alert(errorMsg);
    } else if (value) {
      // qna 저장
      const formData = setFormData();
      formData.append("content", value);

      QNAApi.UpsertQNA(formData)
        .then((res) => {
          history.replace({
            pathname: `${QNARoutes.view}/${res}`,
          });
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          alert(msg);
        });
    }
  };

  return (
    <div className={classes.paperMainWhite}>
      {/* 요청 폼 */}
      <Table>
        <TableRow>
          <TableCell align="right">
            <Typography variant="h5">제목 : </Typography>
          </TableCell>
          <TableCell>
            <TextField value={qna.title} onChange={onChangeText} fullWidth={true}></TextField>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right">
            <Typography variant="h5">분류 : </Typography>
          </TableCell>
          <TableCell>
            <FormControl>
              <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                대분류
              </InputLabel>
              <Select
                name="category1"
                onChange={onChangeSelect}
                value={parentId}
                displayEmpty
                style={{ width: "200px" }}
              >
                {category1.map((x) => (
                  <MenuItem value={x.id} key={x.id}>
                    {x.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl style={{ marginLeft: "20px" }}>
              <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                소분류
              </InputLabel>
              <Select
                name="category2"
                onChange={onChangeSelect}
                value={qna.categoryId}
                displayEmpty
                style={{ width: "200px" }}
              >
                {category2
                  .filter((x) => x.parentId === parentId)
                  .map((x) => (
                    <MenuItem value={x.id} key={x.id}>
                      {x.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right">
            <Typography variant="h5">내용 : </Typography>
          </TableCell>
          <TableCell>
            <Editor content={qna.content} ref={ref}></Editor>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right">
            <Typography variant="h5">파일 : </Typography>
          </TableCell>
          <TableCell>
            <input type="file" name="file" id="file-element" onChange={saveFile} />
          </TableCell>
        </TableRow>
      </Table>
      {/* 요청 버튼 */}
      <div style={{ textAlign: "right", marginTop: "10px" }}>
        <Button variant="contained" color="secondary" onClick={onSave}>
          요청하기
        </Button>
      </div>
    </div>
  );
}

export default EditPage;
