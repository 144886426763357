import React, { ReactElement, ReactNode } from "react";

interface RegularColgroupProps {
  children?: ReactNode;
  cols: number;
}

// 테이블 컬럼 수 만큼 width 동일한 넓이로 설정하는 colsgroup 생성
function RegularColgroup({ cols, children }: RegularColgroupProps): ReactElement {
  let array = new Array(cols);
  return (
    <colgroup>
      {children}
      {array.map(() => {
        return <col width={`${100 / cols}%`}></col>;
      })}
    </colgroup>
  );
}

export default RegularColgroup;
