//권한 관련 페이지
import { Checkbox, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import MaterialTable from "material-table";
import React, { ReactElement, useState } from "react";
import { MyStyle } from "src/Style/theme";
import { EmployeeApi } from "src/system/ApiService";
import { TABLEICONS } from "src/system/Constants";
import { isInArray } from "src/system/Helper";
import { AuthorityCategory, AuthorityUser, EnumPositionCode, POSITION_CODE } from "src/system/types";

const columns = [
  {
    field: "parentTeamName",
  },
  {
    field: "teamName",
  },
  {
    field: "name",
  },
  {
    field: "positionCode",
    lookup: POSITION_CODE,
  },
];

function AuthorityPage(): ReactElement {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<AuthorityUser[]>([]);

  React.useEffect(() => {
    let isSuccess: boolean | null = null;

    EmployeeApi.getAutorityUsers()
      .then((response) => {
        isSuccess = true;
        setData(response.data);
      })
      .catch(() => {
        isSuccess = false;
      })
      .finally(() => {
        setLoading(false);
        if (!isSuccess) alert("데이터를 불러오는데 실패하였습니다.");
      });
  }, []);

  return (
    <React.Fragment>
      <Alert severity="info">
        <AlertTitle>안내</AlertTitle>
        파트장 아이디는 권한 설정이 없어도, 로그인, 면담 조회 및 작성이 가능합니다.
      </Alert>
      <br />
      <MaterialTable
        icons={TABLEICONS}
        columns={columns}
        isLoading={loading}
        data={data}
        style={{ overflow: "unset" }}
        components={{
          Header: (props) => <Header></Header>,
          Row: ({ data }) => <Row data={data}></Row>,
        }}
        options={{ showTitle: false, pageSize: 30, pageSizeOptions: [10, 30, data.length], search: true }}
      ></MaterialTable>
    </React.Fragment>
  );
}

const Header = () => {
  const classes = MyStyle();

  return (
    <TableHead>
      <TableRow className={classes.MaterialTable}>
        <TableCell rowSpan={2}>파트</TableCell>
        <TableCell rowSpan={2}>팀</TableCell>
        <TableCell rowSpan={2}>이름</TableCell>
        <TableCell rowSpan={2}>직책</TableCell>
        <TableCell colSpan={3}>권한</TableCell>
        {/* AuthorityCategory 총 개수 */}
      </TableRow>
      <TableRow>
        <TableCell>로그인</TableCell>
        <TableCell>조회</TableCell>
        <TableCell>작성</TableCell>
      </TableRow>
    </TableHead>
  );
};

interface RowProps {
  data: AuthorityUser;
}

const Row = ({ data }: RowProps) => {
  const [state, setState] = useState(data);
  const [doing, setDoing] = useState(false);

  const isLogin = isInArray(state.authority, AuthorityCategory.LOGIN);
  const isList = isInArray(state.authority, AuthorityCategory.LIST);
  const isWrite = isInArray(state.authority, AuthorityCategory.WRITE);
  const isDisabled = state.positionCode === EnumPositionCode.P_020 || state.employeeId === "M000000";
  const onChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (!doing) {
      setDoing(true);

      const grant = event.currentTarget.name;
      //변경 시, API 날리기
      EmployeeApi.changeAuthority(data.employeeId, grant, checked)
        .then(() => {
          const nextState: AuthorityUser = checked
            ? { ...state, authority: state.authority.concat(grant) }
            : {
                ...state,
                authority: state.authority.filter((x) => x !== grant),
              };

          setState(nextState);
        })
        .catch((error) => {
          if (error.response) {
            switch (error.response.status) {
              case 404:
                alert("변경할 데이터가 없습니다.");
                break;
              default:
                alert("변경 시, 문제가 발생했습니다. 관리자에게 문의해주세요");
            }
          } else {
            alert("서버와 연결할 수 없습니다.");
          }
        })
        .finally(() => {
          setDoing(false);
        });
    } else {
      alert("동작 중입니다....");
    }
  };
  const classes = MyStyle();

  return (
    <TableRow className={classes.MaterialTable}>
      <TableCell>{state.parentTeamName}</TableCell>
      <TableCell>{state.teamName}</TableCell>
      <TableCell>{state.name}</TableCell>
      <TableCell>{POSITION_CODE[state.positionCode]}</TableCell>
      <TableCell>
        <Checkbox disabled={isDisabled} checked={isLogin} onChange={onChangeCheckbox} name={AuthorityCategory.LOGIN} />
      </TableCell>
      <TableCell>
        <Checkbox disabled={isDisabled} checked={isList} onChange={onChangeCheckbox} name={AuthorityCategory.LIST} />
      </TableCell>
      <TableCell>
        <Checkbox disabled={isDisabled} checked={isWrite} onChange={onChangeCheckbox} name={AuthorityCategory.WRITE} />
      </TableCell>
    </TableRow>
  );
};

export default AuthorityPage;
