import { Button, Grid } from "@material-ui/core";
import React, { ReactElement, createRef, useEffect, useState } from "react";
import CrossEditor from "src/components/common/CrossEditor";
import { AttendanceApi } from "src/system/ApiService";

function MainLayout(): ReactElement {
  const [data, setData] = useState<string>("");

  let _editorStarted = false;

  const editorRef = createRef<CrossEditor>();
  const params = { Width: 1200, Height: 500 };

  const onLoaded = (_: any, editor: { GetBodyValue: () => any }) => {
    // 초기화 후 사용할 수 있는 Method는 아래와 같이
    // 두번째 인자인 editor 객체를 통해서 사용할 수 있습니다.
    console.log(editor.GetBodyValue());
    _editorStarted = true;
  };

  const click = () => {
    if (!_editorStarted) {
      window.alert(
        "에디터가 로드되지 않았습니다. 잠시 후 다시 시도해 주시기 바랍니다."
      );
      return;
    }
    if (editorRef.current) {
      const editor = editorRef.current.editorRef;
      // 에디터 API 사용
      let content = editor.GetBodyValue();
      console.log(content);
    }
  };

  useEffect(() => {
    AttendanceApi.GetMonthlyAttendanceStatics("20190907", new Date()).then(
      (res) => {
        setData("Asd");
      }
    );
  }, []);

  return (
    <div>
      <h1>Cross Editor Test</h1>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {data !== "" && (
            <CrossEditor
              ref={editorRef}
              name="editor1"
              params={params}
              value={data}
              onLoaded={onLoaded}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={click}>
            저장
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default MainLayout;
