import { Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import React, { ReactElement } from "react";
import { Table } from "react-bootstrap";
import { InterviewListItem } from "src/system/types";
import { format } from "date-fns";
import { InterviewRecordRoutes } from "src/system/types/type";

interface Props {
  data: InterviewListItem[];
}

function ListTable({ data }: Props): ReactElement {
  return (
    <Table>
      <thead>
        <tr>
          <th>구분</th>
          <th>면담자</th>
          <th>피면담자</th>
          <th>면담일</th>
          <th>내용</th>
          <th>작성일</th>
          <th>작성자</th>
        </tr>
      </thead>
      <tbody>
        {data.length >= 1 ? (
          data.map((x, i) => {
            return (
              <tr key={`tr-${i}`}>
                <td key={`td-category-${i}`}>{x.category}</td>
                <td key={`td-interviewer-${i}`}>{x.interviewer.name}</td>
                <td key={`td-interviewee-${i}`}>{x.interviewee.name}</td>
                <td key={`td-occurred-${i}`}>{format(new Date(x.occurred), "yyyy/MM/dd")}</td>
                <td key={`td-link-${i}`}>
                  <Link
                    component={RouterLink}
                    color={"secondary"}
                    to={{
                      pathname: InterviewRecordRoutes.interview + `/${x.id}`,
                    }}
                  >
                    {x.content}
                  </Link>
                </td>
                <td key={`td-${x.created}-${i}`}>{format(new Date(x.created), "yyyy/MM/dd")}</td>
                <td key={`td-writer-${i}`}>{x.writer.name}</td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan={7}>데이터가 없습니다.</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}

export default ListTable;
