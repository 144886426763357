import {
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import React, {
  ChangeEvent,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from "react";
import DaumPostcode, { AddressData } from "react-daum-postcode";
import { EndTextField } from "src/components/EndTextField";
import RegularColgroup from "src/components/RegularColgroup";
import SelectCombobox from "src/components/SelectCombobox";
import TableInsaCard from "src/components/TableInsaCard";
import CloseIcon from "@material-ui/icons/Close";
import {
  HRCategory,
  InitValidationPD,
  PersonalData,
  TextFieldError,
  ValidationPD,
  DeptInfoErp,
} from "src/system/types";
import {
  ConstantApi,
  DepartmentApi,
  ErrorHandler,
  HRCategoryApi,
  HREmployeeApi,
  MSGraphApi,
} from "src/system/ApiService";
import { Autocomplete } from "@material-ui/lab";
import axios from "axios";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      // backgroundColor: "#eeeeee",
      width: "1000px",
      display: "inline-block",
      textAlign: "left",
    },
    sub: {
      padding: "20px",
      width: "100%",
      "& .MuiGrid-item": {
        height: "80px",
      },
    },
  })
);

interface InputPersonalDataProps {
  data: PersonalData;
  TextFieldError: TextFieldError;
  setTextFieldError: React.Dispatch<React.SetStateAction<TextFieldError>>;
  onChangeText: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeSelect: (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => void;
  onChangeValue: (name: string, value: string) => void;
  onChangeAutoComplete: (
    event: React.ChangeEvent<{}>,
    value: string | null,
    name: string
  ) => void;
  onChangeAutoCompleteTeam: (
    event: React.ChangeEvent<{}>,
    value: DeptInfoErp | null,
    name: string
  ) => void;
  onChangeAutoCompleteCategory: (
    event: React.ChangeEvent<{}>,
    value: HRCategory | null,
    name: string
  ) => void;
  onChangeCheck: (
    tab: string,
    e: ChangeEvent<HTMLInputElement>,
    index: number
  ) => void;
}

function InputPersonalData({
  data,
  TextFieldError,
  setTextFieldError,
  onChangeText,
  onChangeSelect,
  onChangeValue,
  onChangeAutoComplete,
  onChangeAutoCompleteTeam,
  onChangeAutoCompleteCategory,
  onChangeCheck,
}: InputPersonalDataProps): ReactElement {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [val, setVal] = useState<ValidationPD>(InitValidationPD);
  const [e1LicenseCount, setE1LicenseCount] = useState<number | null>(null);

  const [groupList, setGroupList] = useState<DeptInfoErp[]>([]);
  const [departmentList, setDepartmentList] = useState<DeptInfoErp[]>([]);
  const [teamList, setTeamList] = useState<DeptInfoErp[]>([]);

  useEffect(() => {
    axios
      .get<DeptInfoErp[]>(`${window.location.origin}/api/department/grid/group`)
      .then((res) => setGroupList(res.data));

    axios
      .get<DeptInfoErp[]>(
        `${window.location.origin}/api/department/grid/department`
      )
      .then((res) => setDepartmentList(res.data));

    axios
      .get<DeptInfoErp[]>(`${window.location.origin}/api/department/grid/team`)
      .then((res) => setTeamList(res.data));
  }, []);

  useMemo(() => {
    let mss: string[] = [""]; // militaryServiceStatus
    let mst: string[] = [""]; // militaryServiceType
    let msr: string[] = [""]; // militaryServiceRank
    let msd: string[] = [""]; // militaryServiceDischargeCategory
    let dr: string[] = [""]; // disabilityRank
    let dc: string[] = [""]; // disabilityContent
    let vt: string[] = [""]; // veteransType
    let vr: string[] = [""]; // veteransRelation
    let vrt: string[] = [""]; // veteransRatio
    let wef: HRCategory[] = []; // employmentForm
    let wws: HRCategory[] = []; // workStatus
    let wwp: string[] = [""]; // workplace
    let wjg: string[] = [""]; // jobGroup
    let wjd: string[] = [""]; // jobDuty
    let wjp: string[] = [""]; // jobPosition
    let wjpr: string[] = [""]; // jobPositionRank
    let wjt: string[] = [""]; // jobTitle

    ConstantApi.GetConstant("", "").then((res) => {
      res.result.forEach((x) => {
        if (x.tableName === "PDMILITARY") {
          // 병역사항 유효값 SET
          if (x.columnName === "STATUS") {
            mss.push(x.name);
          } else if (x.columnName === "TYPE") {
            mst.push(x.name);
          } else if (x.columnName === "RANK") {
            msr.push(x.name);
          } else if (x.columnName === "DISCHARGECATEGORY") {
            msd.push(x.name);
          }
        } else if (x.tableName === "PDDISABILITY") {
          // 장애사항 유효값 SET
          if (x.columnName === "RANK") {
            dr.push(x.name);
          } else if (x.columnName === "CONTENT") {
            dc.push(x.name);
          }
        } else if (x.tableName === "PDVETERANS") {
          // 보훈사항 유효값 SET
          if (x.columnName === "TYPE") {
            vt.push(x.name);
          } else if (x.columnName === "RELATION") {
            vr.push(x.name);
          } else if (x.columnName === "RATIO") {
            vrt.push(x.name);
          }
        } else if (x.tableName === "WORKRECORD") {
          // 사내정보 유효값 API
          if (x.columnName === "JOBGROUP") {
            wjg.push(x.name);
          } else if (x.columnName === "WORKPLACE") {
            wwp.push(x.name);
          } else if (x.columnName === "JOBDUTY") {
            wjd.push(x.name);
          } else if (x.columnName === "JOBPOSITION") {
            wjp.push(x.name);
          } else if (x.columnName === "JOBPOSITIONRANK") {
            wjpr.push(x.name);
          } else if (x.columnName === "JOBTITLE") {
            wjt.push(x.name);
          }
        }
      });
    });
    // 사내정보 유효값 API
    HRCategoryApi.GetCategoryList(2, "WORKRECORD").then((res) => {
      res.forEach((x) => {
        if (x.parentId === 1012) {
          wws.push(x);
        } else if (x.parentId === 1013) {
          wef.push(x);
        }
      });
    });
    //let year = new Date().getFullYear();
    // 파트/팀 불러오기 API - NEW(DeptInfoErp)
    // DepartmentApi.GetDeptInfoErp(undefined, "active", undefined)
    //   .then((res) => {
    //     res.result.forEach((dept) => {
    //       if (dept.costCenter.includes("C") && !dept.costCenter.includes("C04")) {
    //         deptInfoErp.push({ ...dept });
    //       }
    //     });
    //   })
    //   .catch((error) => {
    //     let msg = ErrorHandler(error);
    //     alert(msg);
    //   });
    // ConstantApi.GetConstant("");
    setVal({
      mss: mss,
      mst: mst,
      msr: msr,
      msd: msd,
      dr: dr,
      dc: dc,
      vt: vt,
      vr: vr,
      vrt: vrt,
      wef: wef,
      wws: wws,
      wwp: wwp,
      wjg: wjg,
      wjd: wjd,
      wjp: wjp,
      wjpr: wjpr,
      wjt: wjt,
      // deptInfoErp: deptInfoErp,
    });
  }, []);

  const CheckEmployeeId = () => {
    if (data.employeeId === "") {
      setTextFieldError({
        ...TextFieldError,
        employeeId: {
          isError: true,
          message: "사번을 입력하고 중복체크를 해주세요.",
        },
      });
      return;
    }
    HREmployeeApi.GetIsExistsEmployeeId(data.employeeId)
      .then((res) => {
        if (res) {
          setTextFieldError({
            ...TextFieldError,
            employeeId: { isError: true, message: "이미 존재하는 사번입니다." },
          });
        } else {
          alert("사용할 수 있는 사번입니다.");
          setTextFieldError({
            ...TextFieldError,
            employeeId: {
              isError: false,
              message: "사용할 수 있는 사번입니다.",
            },
          });
        }
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      });
  };

  const handleM365AccountCreateButtonClick = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    onChangeCheck("personalData", e, 0);
    if (e.target.checked) {
      setTextFieldError({
        ...TextFieldError,
        gwMail: {
          isError: true,
          message: "M365메일 주소를 입력하고 중복체크를 해주세요.",
        },
      });
    } else {
      setTextFieldError({
        ...TextFieldError,
        gwMail: {
          isError: false,
          message: "",
        },
      });
    }
  };
  const CheckM365Email = () => {
    if (data.isMSAccountCreate) {
      if (data.gwMail === "@inbody.com") {
        setTextFieldError({
          ...TextFieldError,
          gwMail: {
            isError: true,
            message: "M365메일 주소를 입력하고 중복체크를 해주세요.",
          },
        });
        return;
      }
      MSGraphApi.GetIsExistMsEmail(data.gwMail)
        .then((res) => {
          if (res) {
            setTextFieldError({
              ...TextFieldError,
              gwMail: {
                isError: true,
                message: "이미 존재하는 이메일 주소입니다.",
              },
            });
          } else {
            alert("사용할 수 있는 이메일 주소입니다.");
            setTextFieldError({
              ...TextFieldError,
              gwMail: {
                isError: false,
                message: "사용할 수 있는 이메일 주소입니다.",
              },
            });
          }
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          alert(msg);
        });
    }
  };

  const handleLicenseButtonClick = () => {
    MSGraphApi.GetE1LicenseCount()
      .then((res) => setE1LicenseCount(res))
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      });
  };

  const onCompleteAddress = (data: AddressData) => {
    let fullAddress = data.address;
    let extraAddress = "";
    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    onChangeValue("address", fullAddress + "/" + data.zonecode);
    setOpen(false);
  };

  return (
    <div>
      <div className={classes.main}>
        <TableInsaCard
          title="개인정보"
          colgroup={<RegularColgroup cols={6}></RegularColgroup>}
        >
          <div className={classes.sub}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <EndTextField
                  label="성명(한글이름)"
                  name="name"
                  value={data.name}
                  error={TextFieldError.name.isError}
                  errorMsg={TextFieldError.name.message}
                  style={{ width: "420px" }}
                  onChange={onChangeText}
                ></EndTextField>
              </Grid>
              <Grid item xs={6}>
                <EndTextField
                  label="사번"
                  name="employeeId"
                  value={data.employeeId}
                  error={TextFieldError.employeeId.isError}
                  errorMsg={TextFieldError.employeeId.message}
                  successMsg={TextFieldError.employeeId.message}
                  style={{ width: "320px" }}
                  onChange={onChangeText}
                ></EndTextField>
                <Button
                  variant="contained"
                  style={{ marginTop: "10px" }}
                  color="primary"
                  onClick={CheckEmployeeId}
                >
                  중복체크
                </Button>
              </Grid>
              <Grid item xs={6}>
                <EndTextField
                  label="영문이름"
                  name="enName"
                  value={data.enName}
                  error={TextFieldError.enName.isError}
                  errorMsg={TextFieldError.enName.message}
                  style={{ width: "420px" }}
                  onChange={onChangeText}
                ></EndTextField>
              </Grid>
              <Grid item xs={2}>
                {/* <EndTextField
                  label="그룹웨어이름"
                  name="gwName"
                  value={data.gwName}
                  error={TextFieldError.gwName.isError}
                  errorMsg={TextFieldError.gwName.message}
                  successMsg={TextFieldError.gwName.message}
                  style={{ width: "260px" }}
                  onChange={onChangeText}
                ></EndTextField> */}
                <FormControl
                  fullWidth
                  error={
                    data.groupId === "" &&
                    data.departmentId === "" &&
                    data.teamId === ""
                  }
                >
                  <InputLabel shrink htmlFor="groupId-select">
                    그룹
                  </InputLabel>
                  <Select
                    id={"groupId-select"}
                    value={data.groupId}
                    name={"groupId"}
                    displayEmpty
                    onChange={onChangeSelect}
                  >
                    <MenuItem value="">{"선택안함"}</MenuItem>
                    {groupList.map((group) => (
                      <MenuItem key={group.costCenter} value={group.costCenter}>
                        {group.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl
                  fullWidth
                  error={
                    data.groupId === "" &&
                    data.departmentId === "" &&
                    data.teamId === ""
                  }
                >
                  <InputLabel shrink htmlFor="departmentId-select">
                    파트
                  </InputLabel>
                  <Select
                    id={"departmentId-select"}
                    name={"departmentId"}
                    label={"파트"}
                    value={data.departmentId}
                    displayEmpty
                    onChange={onChangeSelect}
                  >
                    <MenuItem value="">{"선택안함"}</MenuItem>
                    {departmentList
                      .filter((department) => {
                        if (data.groupId === "") {
                          return department.seniorDeptCostCenter === "Root";
                        }
                        return department.seniorDeptCostCenter === data.groupId;
                      })
                      .map((department) => (
                        <MenuItem
                          key={department.costCenter}
                          value={department.costCenter}
                        >
                          {department.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl
                  fullWidth
                  disabled={data.departmentId === ""}
                  error={
                    data.groupId === "" &&
                    data.departmentId === "" &&
                    data.teamId === ""
                  }
                >
                  <InputLabel shrink htmlFor="teamId-select">
                    팀
                  </InputLabel>
                  <Select
                    id={"teamId-select"}
                    name={"teamId"}
                    label={"팀"}
                    value={data.teamId}
                    displayEmpty
                    onChange={onChangeSelect}
                  >
                    <MenuItem value="">{"선택안함"}</MenuItem>
                    {teamList
                      .filter(
                        (team) =>
                          team.seniorDeptCostCenter === data.departmentId
                      )
                      .map((team) => (
                        <MenuItem key={team.costCenter} value={team.costCenter}>
                          {team.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <SelectCombobox
                  label="성별"
                  name="gender"
                  style={{ width: "150px" }}
                  empty={true}
                  value={data.gender}
                  options={[
                    { key: "M", value: "남자" },
                    { key: "F", value: "여자" },
                  ]}
                  onChange={onChangeSelect}
                ></SelectCombobox>
              </Grid>
              <Grid item xs={3}>
                <EndTextField
                  label="국적"
                  name="nationality"
                  value={data.nationality}
                  error={TextFieldError.nationality.isError}
                  errorMsg={TextFieldError.nationality.message}
                  style={{ width: "150px" }}
                  onChange={onChangeText}
                ></EndTextField>
              </Grid>
              <Grid item xs={4}>
                <EndTextField
                  label="생일"
                  name="birthday"
                  type="date"
                  value={data.birthday.split("T")[0]}
                  error={TextFieldError.birthday.isError}
                  errorMsg={TextFieldError.birthday.message}
                  style={{ width: "260px" }}
                  onChange={onChangeText}
                  shrink={true}
                ></EndTextField>
              </Grid>
              <Grid item xs={4}>
                <EndTextField
                  label="주민등록번호(뒷자리 7자)"
                  name="residenceNumber"
                  value={data.residenceNumber}
                  error={TextFieldError.residenceNumber.isError}
                  errorMsg={TextFieldError.residenceNumber.message}
                  style={{ width: "260px" }}
                  onChange={onChangeText}
                ></EndTextField>
              </Grid>
              <Grid item xs={4}>
                <EndTextField
                  label="핸드폰"
                  name="phone"
                  value={data.phone}
                  error={TextFieldError.phone.isError}
                  errorMsg={TextFieldError.phone.message}
                  style={{ width: "260px" }}
                  onChange={onChangeText}
                ></EndTextField>
              </Grid>
              <Grid item xs={6}>
                <EndTextField
                  label="E-mail"
                  name="email"
                  value={data.email}
                  error={TextFieldError.email.isError}
                  errorMsg={TextFieldError.email.message}
                  style={{ width: "420px" }}
                  onChange={onChangeText}
                ></EndTextField>
              </Grid>
              <Grid item xs={6}>
                <EndTextField
                  label="지원경로"
                  name="applyChannel"
                  value={data.applyChannel}
                  style={{ width: "420px" }}
                  onChange={onChangeText}
                ></EndTextField>
              </Grid>
              <Grid item xs={12}>
                <EndTextField
                  label="주소"
                  name="address1"
                  value={data.address1}
                  error={TextFieldError.address1.isError}
                  errorMsg={TextFieldError.address1.message}
                  style={{ width: "900px" }}
                  onChange={onChangeText}
                ></EndTextField>
              </Grid>
              <Grid item xs={8}>
                <EndTextField
                  label="상세주소"
                  name="address2"
                  value={data.address2}
                  style={{ width: "600px" }}
                  onChange={onChangeText}
                ></EndTextField>
              </Grid>
              <Grid item xs={2}>
                <EndTextField
                  label="우편번호"
                  name="zip"
                  value={data.zip}
                  style={{ width: "120px" }}
                  onChange={onChangeText}
                ></EndTextField>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  style={{ marginTop: "10px" }}
                  color="primary"
                  onClick={() => setOpen(true)}
                >
                  주소 검색
                </Button>
                <Dialog
                  open={open}
                  onClose={() => setOpen(false)}
                  maxWidth="xs"
                  fullWidth
                >
                  <DialogTitle
                    disableTypography
                    style={{ padding: "8px 24px" }}
                  >
                    <Typography variant="h6" display="inline">
                      주소검색
                    </Typography>
                    <IconButton
                      disableRipple
                      aria-label="close"
                      style={{
                        position: "absolute",
                        right: "8px",
                        top: "-3px",
                      }}
                      onClick={() => setOpen(false)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <div>
                    <DaumPostcode onComplete={onCompleteAddress}></DaumPostcode>
                  </div>
                </Dialog>
              </Grid>
            </Grid>
          </div>
        </TableInsaCard>
        <TableInsaCard
          title="사내정보"
          colgroup={<RegularColgroup cols={6}></RegularColgroup>}
        >
          <div className={classes.sub}>
            <Grid container>
              <Grid item xs={3} container alignContent="center">
                <FormControlLabel
                  style={{ marginLeft: "16px" }}
                  control={
                    <Checkbox
                      checked={data.isMSAccountCreate}
                      onChange={handleM365AccountCreateButtonClick}
                      name="isMSAccountCreate"
                    />
                  }
                  label="M365 계정생성"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="라이선스 잔여"
                  disabled
                  value={e1LicenseCount !== null ? `${e1LicenseCount}개` : ""}
                  style={{ width: "130px" }}
                ></TextField>
                <Button
                  variant="contained"
                  style={{ marginTop: "10px" }}
                  color="primary"
                  onClick={handleLicenseButtonClick}
                >
                  라이선스 수량체크
                </Button>
              </Grid>
              <Grid item xs={5}>
                <EndTextField
                  label="M365 메일주소"
                  name="gwMail"
                  value={data.gwMail}
                  error={TextFieldError.gwMail.isError}
                  errorMsg={TextFieldError.gwMail.message}
                  style={{ width: "260px" }}
                  onChange={onChangeText}
                ></EndTextField>
                <Button
                  variant="contained"
                  style={{ marginTop: "10px" }}
                  color="primary"
                  onClick={CheckM365Email}
                  disabled={!data.isMSAccountCreate}
                >
                  중복체크
                </Button>
              </Grid>
              <Grid item xs={6}>
                <EndTextField
                  label="내선번호"
                  name="tel"
                  value={data.tel}
                  style={{ width: "420px" }}
                  onChange={onChangeText}
                ></EndTextField>
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  value={data.wsCategory}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => {
                    if (value !== null) {
                      onChangeAutoCompleteCategory(event, value, "wsCategory");
                    }
                  }}
                  options={val.wws}
                  style={{ width: 420 }}
                  renderOption={(option) => (
                    <React.Fragment>{option.name}</React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="근무상태"
                      helperText={
                        TextFieldError.wsCategory.isError
                          ? TextFieldError.wsCategory.message
                          : ""
                      }
                      error={TextFieldError.wsCategory.isError}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  value={data.efCategory}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => {
                    if (value !== null) {
                      onChangeAutoCompleteCategory(event, value, "efCategory");
                    }
                  }}
                  options={val.wef}
                  style={{ width: 420 }}
                  renderOption={(option) => (
                    <React.Fragment>{option.name}</React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="고용형태"
                      helperText={
                        TextFieldError.efCategory.isError
                          ? TextFieldError.efCategory.message
                          : ""
                      }
                      error={TextFieldError.efCategory.isError}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  value={data.workplace}
                  onChange={(event, value) => {
                    if (value !== null) {
                      onChangeAutoComplete(event, value, "workplace");
                    }
                  }}
                  inputValue={data.workplace}
                  onInputChange={(event, newInputValue) => {
                    onChangeValue("workplace", newInputValue);
                  }}
                  options={val.wwp}
                  style={{ width: 420 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="사업장"
                      helperText={
                        TextFieldError.workplace.isError
                          ? TextFieldError.workplace.message
                          : ""
                      }
                      error={TextFieldError.workplace.isError}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <EndTextField
                  label="입사일"
                  name="joined"
                  type="date"
                  value={data.joined.split("T")[0]}
                  error={TextFieldError.joined.isError}
                  errorMsg={TextFieldError.joined.message}
                  style={{ width: "420px", marginTop: "15px" }}
                  onChange={onChangeText}
                  shrink={true}
                ></EndTextField>
              </Grid>
              <Grid item xs={6}>
                <EndTextField
                  label="종료일"
                  name="ended"
                  type="date"
                  value={data.ended}
                  style={{ width: "420px", marginTop: "15px" }}
                  onChange={onChangeText}
                  shrink={true}
                ></EndTextField>
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  value={data.jobGroup}
                  onChange={(event, value) => {
                    if (value !== null) {
                      onChangeAutoComplete(event, value, "jobGroup");
                    }
                  }}
                  inputValue={data.jobGroup}
                  onInputChange={(event, newInputValue) => {
                    onChangeValue("jobGroup", newInputValue);
                  }}
                  options={val.wjg}
                  style={{ width: 420, marginTop: "15px" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="직군"
                      helperText={
                        TextFieldError.jobGroup.isError
                          ? TextFieldError.jobGroup.message
                          : ""
                      }
                      error={TextFieldError.jobGroup.isError}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  value={data.jobDuty}
                  onChange={(event, value) => {
                    if (value !== null) {
                      onChangeAutoComplete(event, value, "jobDuty");
                    }
                  }}
                  inputValue={data.jobDuty}
                  onInputChange={(event, newInputValue) => {
                    onChangeValue("jobDuty", newInputValue);
                  }}
                  options={val.wjd}
                  style={{ width: 420, marginTop: "15px" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="직무"
                      helperText={
                        TextFieldError.jobDuty.isError
                          ? TextFieldError.jobDuty.message
                          : ""
                      }
                      error={TextFieldError.jobDuty.isError}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  value={data.jobPosition}
                  onChange={(event, value) => {
                    if (value !== null) {
                      onChangeAutoComplete(event, value, "jobPosition");
                    }
                  }}
                  inputValue={data.jobPosition}
                  onInputChange={(event, newInputValue) => {
                    onChangeValue("jobPosition", newInputValue);
                  }}
                  options={val.wjp}
                  style={{ width: 420, marginTop: "15px" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="직급(Teams에 표시)"
                      helperText={
                        TextFieldError.jobPosition.isError
                          ? TextFieldError.jobPosition.message
                          : ""
                      }
                      error={TextFieldError.jobPosition.isError}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  value={data.jobPositionRank}
                  onChange={(event, value) => {
                    if (value !== null) {
                      onChangeAutoComplete(event, value, "jobPositionRank");
                    }
                  }}
                  inputValue={data.jobPositionRank}
                  onInputChange={(event, newInputValue) => {
                    onChangeValue("jobPositionRank", newInputValue);
                  }}
                  options={val.wjpr}
                  style={{ width: 420, marginTop: "15px" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="직위"
                      helperText={
                        TextFieldError.jobPositionRank.isError
                          ? TextFieldError.jobPositionRank.message
                          : ""
                      }
                      error={TextFieldError.jobPositionRank.isError}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  value={data.jobTitle}
                  onChange={(event, value) => {
                    if (value !== null) {
                      onChangeAutoComplete(event, value, "jobTitle");
                    }
                  }}
                  inputValue={data.jobTitle}
                  onInputChange={(event, newInputValue) => {
                    onChangeValue("jobTitle", newInputValue);
                  }}
                  options={val.wjt}
                  style={{ width: 420, marginTop: "15px" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="직책"
                      helperText={
                        TextFieldError.jobTitle.isError
                          ? TextFieldError.jobTitle.message
                          : ""
                      }
                      error={TextFieldError.jobTitle.isError}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </div>
        </TableInsaCard>
        <TableInsaCard
          title="병역정보"
          colgroup={<RegularColgroup cols={6}></RegularColgroup>}
        >
          <div className={classes.sub}>
            <Grid container>
              <Grid item xs={6}>
                <Autocomplete
                  value={data.serviceStatus}
                  onChange={(event, value) => {
                    if (value !== null) {
                      onChangeAutoComplete(event, value, "serviceStatus");
                    }
                  }}
                  inputValue={data.serviceStatus}
                  onInputChange={(event, newInputValue) => {
                    onChangeValue("serviceStatus", newInputValue);
                  }}
                  options={val.mss}
                  style={{ width: 420 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="병역구분"
                      helperText={
                        TextFieldError.serviceStatus.isError
                          ? TextFieldError.serviceStatus.message
                          : ""
                      }
                      error={TextFieldError.serviceStatus.isError}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  value={data.serviceType}
                  onChange={(event, value) => {
                    if (value !== null) {
                      onChangeAutoComplete(event, value, "serviceType");
                    }
                  }}
                  inputValue={data.serviceType}
                  onInputChange={(event, newInputValue) => {
                    onChangeValue("serviceType", newInputValue);
                  }}
                  options={val.mst}
                  style={{ width: 420 }}
                  renderInput={(params) => (
                    <TextField {...params} label="군별" />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <EndTextField
                  label="병과"
                  name="serviceSpeciality"
                  value={data.serviceSpeciality}
                  style={{ width: "420px" }}
                  onChange={onChangeText}
                ></EndTextField>
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  value={data.serviceRank}
                  onChange={(event, value) => {
                    if (value !== null) {
                      onChangeAutoComplete(event, value, "serviceRank");
                    }
                  }}
                  inputValue={data.serviceRank}
                  onInputChange={(event, newInputValue) => {
                    onChangeValue("serviceRank", newInputValue);
                  }}
                  options={val.msr}
                  style={{ width: 420 }}
                  renderInput={(params) => (
                    <TextField {...params} label="계급" />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  value={data.serviceDischargeCategory}
                  onChange={(event, value) => {
                    if (value !== null) {
                      onChangeAutoComplete(
                        event,
                        value,
                        "serviceDischargeCategory"
                      );
                    }
                  }}
                  inputValue={data.serviceDischargeCategory}
                  onInputChange={(event, newInputValue) => {
                    onChangeValue("serviceDischargeCategory", newInputValue);
                  }}
                  options={val.msd}
                  style={{ width: 420 }}
                  renderInput={(params) => (
                    <TextField {...params} label="제대구분" />
                  )}
                />
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <EndTextField
                  label="복무시작일"
                  name="serviceStarted"
                  type="date"
                  value={
                    data.serviceStarted !== null
                      ? data.serviceStarted.split("T")[0]
                      : ""
                  }
                  style={{ width: "420px", marginTop: "15px" }}
                  onChange={onChangeText}
                  shrink={true}
                ></EndTextField>
              </Grid>
              <Grid item xs={6}>
                <EndTextField
                  label="복무종료일"
                  name="serviceEnded"
                  type="date"
                  value={
                    data.serviceEnded !== null
                      ? data.serviceEnded.split("T")[0]
                      : ""
                  }
                  style={{ width: "420px", marginTop: "15px" }}
                  onChange={onChangeText}
                  shrink={true}
                ></EndTextField>
              </Grid>
              <Grid item xs={12}>
                <EndTextField
                  label="비고"
                  name="serviceRemark"
                  value={data.serviceRemark}
                  style={{ width: "900px" }}
                  onChange={onChangeText}
                ></EndTextField>
              </Grid>
            </Grid>
          </div>
        </TableInsaCard>
        <TableInsaCard
          title="장애정보"
          colgroup={<RegularColgroup cols={6}></RegularColgroup>}
        >
          <div className={classes.sub}>
            <Grid container>
              <Grid item xs={6}>
                <SelectCombobox
                  label="장애여부"
                  name="isDisability"
                  style={{ width: "200px" }}
                  empty={false}
                  value={data.isDisability ? "true" : "false"}
                  options={[
                    { key: "true", value: "대상" },
                    { key: "false", value: "비대상" },
                  ]}
                  onChange={onChangeSelect}
                ></SelectCombobox>
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  value={data.disabilityRank}
                  onChange={(event, value) => {
                    if (value !== null) {
                      onChangeAutoComplete(event, value, "disabilityRank");
                    }
                  }}
                  inputValue={data.disabilityRank}
                  onInputChange={(event, newInputValue) => {
                    onChangeValue("disabilityRank", newInputValue);
                  }}
                  options={val.dr}
                  style={{ width: 420 }}
                  renderInput={(params) => (
                    <TextField {...params} label="장애등급" />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  value={data.disabilityContent}
                  onChange={(event, value) => {
                    if (value !== null) {
                      onChangeAutoComplete(event, value, "disabilityContent");
                    }
                  }}
                  inputValue={data.disabilityContent}
                  onInputChange={(event, newInputValue) => {
                    onChangeValue("disabilityContent", newInputValue);
                  }}
                  options={val.dc}
                  style={{ width: 420, marginTop: "15px" }}
                  renderInput={(params) => (
                    <TextField {...params} label="내용" />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <EndTextField
                  label="장애판정일"
                  name="disabilityOnsetDate"
                  type="date"
                  value={data.disabilityOnsetDate}
                  style={{ width: "420px", marginTop: "15px" }}
                  onChange={onChangeText}
                  shrink={true}
                ></EndTextField>
              </Grid>
              <Grid item xs={6}>
                <EndTextField
                  label="비고"
                  name="disabilityRemark"
                  value={data.disabilityRemark}
                  style={{ width: "900px" }}
                  onChange={onChangeText}
                ></EndTextField>
              </Grid>
            </Grid>
          </div>
        </TableInsaCard>
        <TableInsaCard
          title="보훈정보"
          colgroup={<RegularColgroup cols={6}></RegularColgroup>}
        >
          <div className={classes.sub}>
            <Grid container>
              <Grid item xs={6}>
                <SelectCombobox
                  label="보훈여부"
                  name="isVeterans"
                  style={{ width: "200px" }}
                  empty={false}
                  value={data.isVeterans ? "true" : "false"}
                  options={[
                    { key: "true", value: "대상" },
                    { key: "false", value: "비대상" },
                  ]}
                  onChange={onChangeSelect}
                ></SelectCombobox>
              </Grid>
              <Grid item xs={6}>
                <EndTextField
                  label="보훈번호"
                  name="veteransNumber"
                  value={data.veteransNumber}
                  style={{ width: "420px" }}
                  onChange={onChangeText}
                ></EndTextField>
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  value={data.veteransType}
                  onChange={(event, value) => {
                    if (value !== null) {
                      onChangeAutoComplete(event, value, "veteransType");
                    }
                  }}
                  inputValue={data.veteransType}
                  onInputChange={(event, newInputValue) => {
                    onChangeValue("veteransType", newInputValue);
                  }}
                  options={val.vt}
                  style={{ width: 420 }}
                  renderInput={(params) => (
                    <TextField {...params} label="보훈대상" />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  value={data.veteransRelation}
                  onChange={(event, value) => {
                    if (value !== null) {
                      onChangeAutoComplete(event, value, "veteransRelation");
                    }
                  }}
                  inputValue={data.veteransRelation}
                  onInputChange={(event, newInputValue) => {
                    onChangeValue("veteransRelation", newInputValue);
                  }}
                  options={val.vr}
                  style={{ width: 420 }}
                  renderInput={(params) => (
                    <TextField {...params} label="보훈관계" />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  value={data.veteransRatio}
                  onChange={(event, value) => {
                    if (value !== null) {
                      onChangeAutoComplete(event, value, "veteransRatio");
                    }
                  }}
                  inputValue={data.veteransRatio}
                  onInputChange={(event, newInputValue) => {
                    onChangeValue("veteransRatio", newInputValue);
                  }}
                  options={val.vrt}
                  style={{ width: 420 }}
                  renderInput={(params) => (
                    <TextField {...params} label="보훈비율" />
                  )}
                />
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <EndTextField
                  label="비고"
                  name="veteransRemark"
                  value={data.veteransRemark}
                  style={{ width: "900px" }}
                  onChange={onChangeText}
                ></EndTextField>
              </Grid>
            </Grid>
          </div>
        </TableInsaCard>
        <TableInsaCard
          title="계좌정보"
          colgroup={<RegularColgroup cols={3}></RegularColgroup>}
        >
          <div className={classes.sub}>
            <Grid container>
              <Grid item xs={12}>
                <EndTextField
                  label="은행"
                  name="bank"
                  value={data.bank}
                  error={TextFieldError.bank.isError}
                  errorMsg={TextFieldError.bank.message}
                  style={{ width: "900px" }}
                  onChange={onChangeText}
                ></EndTextField>
              </Grid>
              <Grid item xs={12}>
                <EndTextField
                  label="계좌번호"
                  name="number"
                  value={data.number}
                  error={TextFieldError.number.isError}
                  errorMsg={TextFieldError.number.message}
                  style={{ width: "900px" }}
                  onChange={onChangeText}
                ></EndTextField>
              </Grid>
              <Grid item xs={12}>
                <EndTextField
                  label="예금주"
                  name="holder"
                  value={data.holder}
                  error={TextFieldError.holder.isError}
                  errorMsg={TextFieldError.holder.message}
                  style={{ width: "900px" }}
                  onChange={onChangeText}
                ></EndTextField>
              </Grid>
            </Grid>
          </div>
        </TableInsaCard>
      </div>
    </div>
  );
}

export default InputPersonalData;
