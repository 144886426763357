import { Grid } from "@material-ui/core";
import React, { Component, createRef } from "react";

interface DivOverflowShadowProps {
  children?: React.ReactNode;
  height?: number | string;
}
interface DivOverflowShadowState {
  style: React.CSSProperties;
}

class DivOverflowShadow extends Component<DivOverflowShadowProps, DivOverflowShadowState> {
  private myRef = createRef<HTMLDivElement>();
  state = {
    style: {
      overflow: "auto",
      height: this.props.height || "inherit",
    },
  };

  private hasOverflowingChidren(element: HTMLDivElement | null) {
    if (element) {
      return element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth;
    } else {
      return false;
    }
  }

  componentDidMount() {
    const element = this.myRef.current;
    if (this.hasOverflowingChidren(element)) {
      this.setState((prev) => ({
        style: {
          ...prev.style,
          boxShadow: "inset 0px -10px 10px -10px #aaa",
        },
      }));
    } else {
      this.setState({
        style: {
          overflow: "auto",
          height: this.props.height,
        },
      });
    }
  }

  componentDidUpdate(prevProps: DivOverflowShadowProps) {
    if (this.props !== prevProps) {
      const element = this.myRef.current;
      const isOverflow = this.hasOverflowingChidren(element);
      //overflow 가 발생하면, boxShadow를 추가한다.
      if (this.props.height && isOverflow) {
        this.setState((prev) => ({
          style: {
            ...prev.style,
            boxShadow: "inset 0px -10px 10px -10px #aaa",
          },
        }));
      } else {
        this.setState({
          style: {
            overflow: "auto",
            height: this.props.height,
          },
        });
      }
    }
  }

  render() {
    return (
      <Grid xs={12} item ref={this.myRef} style={this.state.style}>
        {this.props.children}
      </Grid>
    );
  }
}

export default DivOverflowShadow;
