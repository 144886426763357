import React, { useCallback, useEffect, useState } from "react";
import CertificateOfEmploymentPage from "./CertificateOfEmploymentPage";

interface printParams {
  id: number;
  type: string;
}

function PrintPage() {
  const [param, setParam] = useState<printParams>({ id: 0, type: "" });
  const onLoad = useCallback(() => {
    let params = new URLSearchParams(window.location.search);
    let typeId = parseInt(params.get("id") || "");
    let typeParam = params.get("type");
    if (typeParam) setParam({ id: typeId, type: typeParam });
  }, []);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  if (param.type === "재직증명서") {
    return <CertificateOfEmploymentPage id={param.id}></CertificateOfEmploymentPage>;
  } else {
    return <></>;
  }
}

export default PrintPage;
