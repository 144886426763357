import { Property } from "csstype";
import React, { ReactElement, ReactNodeArray, ReactNode } from "react";

type TLength = (string & {}) | 0;

interface Props {
  align?: Property.TextAlign;
  marginBottom?: Property.MarginBottom<TLength>;
  children?: ReactNode | ReactNodeArray;
}

const SideBar = function ({ align, marginBottom, children }: Props): ReactElement {
  const style = {
    textAlign: align ? align : "right",
    marginBottom: marginBottom ? marginBottom : "3px",
  };

  if (children) {
    let childrenArray = children as ReactNodeArray;
    if (childrenArray && childrenArray.map) {
      return (
        <div style={style}>
          {childrenArray.map((x, i) => {
            return <React.Fragment key={`sidebar-children-${i}`}>{x}</React.Fragment>;
          })}
        </div>
      );
    } else {
      return <div style={style}>{children}</div>;
    }
  } else {
    return <></>;
  }
};

export default SideBar;
