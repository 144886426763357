import React, { Fragment, ReactElement } from "react";
import { L10n } from "@syncfusion/ej2-base";
import { BrowserRouter as Router, Route, useHistory } from "react-router-dom";
import "./App.css";
import { UserContextProvider, useUserDispatch } from "./system/UserContext";
import UserRoute from "./Route/UserRoute";
import LoginPage from "./page/LoginPage";
import { UserApi } from "./system/ApiService";
import { CommonRoutes } from "src/system/types";
import Routes from "src/Route/Routes";
import { LoadingContextProvider } from "./system/LoadingContext";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import LoginRedirect from "./azure/LoginRedirect";
import { loginRequest } from "./azure/AuthConfig";
import PrintPage from "./Route/Evidence/PrintPage";
import { RecoilRoot } from "recoil";

L10n.load({
  "ko-KR": {
    //https://ej2.syncfusion.com/react/documentation/grid/global-local/ 참고
    grid: {
      Add: "추가",
      Edit: "수정",
      Cancel: "취소",
      Update: "변경사항 저장",
      Delete: "삭제",
      EditOperationAlert: "수정할 행이 선택되지 않았습니다",
      DeleteOperationAlert: "삭제할 행이 선택되지 않았습니다",
      EmptyRecord: "표시할 데이터가 없습니다",
      Item: "개",
      Items: "개",
      Search: "검색",
      TextFilter: "검색 조건",
      OKButton: "확인",
      CancelButton: "취소",
      StartsWith: "~으로 시작",
      EndsWith: "~으로 끝",
      Contains: "포함",
      Equal: "같음",
      NotEqual: "같지 않음",
      SelectAll: "전체선택",
      CustomFilter: "필터 설정",
      ShowRowsWhere: "열 조건",
      AddFormTitle: "신규추가",
      EditFormTitle: "수정 중인 데이터 id = ",
    },
    dropdowns: {
      noRecordsTemplate: "검색결과가 없습니다",
      actionFailureTemplate: "요청이 실패하였습니다",
    },
    formValidator: {
      required: "필수",
    },
    pager: {
      currentPageInfo: "{0} 페이지 / 총 {1} 페이지",
      totalItemsInfo: "({0} 항목)",
    },
    filter: {},
  },
});

function App() {
  return (
    <>
      <UnauthenticatedTemplate>
        <LoginRedirect></LoginRedirect>
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <RecoilRoot>
          <UserContextProvider>
            <LoadingContextProvider>
              <UserWrapper></UserWrapper>
            </LoadingContextProvider>
          </UserContextProvider>
        </RecoilRoot>
      </AuthenticatedTemplate>
    </>
  );
}

export default App;

//사용자 정보가 있는지 없는지 체크합니다.
function UserWrapper(): ReactElement {
  const dispatch = useUserDispatch();
  const { instance, accounts } = useMsal();
  const [isInit, setIsInit] = React.useState(false);
  const history = useHistory();

  React.useEffect(() => {
    // sso-login
    let savedToken = window.sessionStorage.getItem("token");

    const getToken = async () => {
      try {
        let _token;

        // lookinbody.com 메일 있는 경우 inbody.com 계정 찾는 부분
        const account = accounts.find((x) =>
          x.username.includes("@inbody.com")
        );

        if (_token === undefined) {
          const response = await instance.acquireTokenSilent({
            ...loginRequest,
            scopes: [],
            account: account,
          });
          _token = response.idToken;
          window.sessionStorage.setItem("token", _token);
        }
        UserApi.SetToken(_token);
        return _token;
      } catch (error) {
        console.error(error);
      }
    };

    const ssoLogin = async (savedToken: string | null) => {
      if (!savedToken) {
        savedToken = (await getToken()) || "";
        if (!savedToken) {
          alert("로그인 정보 처리 중 에러가 발생하였습니다.");
          window.location.replace("https://weareinbody.com");
          return;
        }
      }

      try {
        // 우선 account 에 있는 그룹웨어 이름을 post 로 보내서 로그인
        const account = accounts.find((x) =>
          x.username.includes("@inbody.com")
        );
        const res = await UserApi.SSOLogin(
          savedToken,
          account && account.username
        );
        dispatch({ type: "LOGIN", item: res.data.user });
        //메인페이지로 이동
        history.push("/");
      } catch (error: any) {
        if (error.response) {
          if (error.response.status === 401) {
            window.sessionStorage.removeItem("token");
            history.push("/");
          } else {
            alert("등록되지 않은 이메일로 접속이 불가능합니다.");
            window.location.replace("https://weareinbody.com");
          }
        }
      } finally {
        setIsInit(true);
      }
    };

    ssoLogin(savedToken);
  }, [accounts, dispatch, history, instance]);

  if (isInit) {
    if (window.location.pathname.includes("evidence/print")) {
      return <PrintPage></PrintPage>;
    } else {
      return (
        <Router>
          <Route path={CommonRoutes.login}>
            <LoginPage></LoginPage>
          </Route>
          <UserRoute Component={Routes}></UserRoute>
        </Router>
      );
    }
  } else {
    return <Fragment></Fragment>;
  }
}
