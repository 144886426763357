import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { Evidence, InitEvidence } from "src/system/types";
import { useUserState } from "src/system/UserContext";
import { ErrorHandler, EvidenceApi } from "src/system/ApiService";

interface RequestEvidenceModalProps {
  title: string;
  open: boolean;
  onClose: () => void;
}

function RequestEvidenceModal({ title, open, onClose }: RequestEvidenceModalProps) {
  const user = useUserState();
  const [evidence, setEvidence] = useState<Evidence>({
    ...InitEvidence,
    type: title,
    department: user.parentTeamName,
    team: user.teamName,
    name: user.name,
    employeeId: user.employeeId,
  });

  useEffect(() => {
    if (title !== undefined) setEvidence({ ...evidence, type: title });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEvidence({ ...evidence, usage: e.target.value });
  };

  const onSave = () => {
    EvidenceApi.InsertEvidence(evidence)
      .then((res) => {
        setEvidence({ ...evidence, usage: "" });
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      })
      .finally(() => {
        onClose();
      });
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>{`${title} 발급 신청`}</DialogTitle>
        <Divider></Divider>
        <DialogContent>
          <Grid container>
            <Grid item xs={3}>
              <Typography variant="subtitle1" align="right" style={{ fontWeight: "bold" }}>
                신청자
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="subtitle1" style={{ marginLeft: "10px" }}>
                {evidence.name}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle1" align="right" style={{ fontWeight: "bold" }}>
                구분
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="subtitle1" style={{ marginLeft: "10px" }}>
                {title}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle1" align="right" style={{ fontWeight: "bold" }}>
                발급용도 / 제출처
              </Typography>
            </Grid>
            <Grid item xs={8} style={{ marginLeft: "10px" }}>
              <TextField
                style={{ width: "100%" }}
                rows={3}
                multiline={true}
                value={evidence.usage}
                onChange={handleChange}
                placeholder={`생활자금대출 / 인바디은행`}
              ></TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="default" variant="contained" onClick={onClose}>
            CANCEL
          </Button>
          {/* 발급용도(usage) 공백일 시 비활성화 */}
          <Button onClick={onSave} color="primary" variant="contained" disabled={evidence.usage === "" ? true : false}>
            SAVE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default RequestEvidenceModal;
