import { Grid } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import {
  ColumnDirective,
  ColumnsDirective,
  DialogEditEventArgs,
  Edit,
  EditSettingsModel,
  ExcelExport,
  Filter,
  GridComponent,
  Inject,
  Page,
  Resize,
  Search,
  Sort,
  Toolbar,
  ToolbarItems,
} from "@syncfusion/ej2-react-grids";
import React, { useEffect, useState } from "react";
import { ErrorHandler, UserInfoErpApi } from "src/system/ApiService";
import { useLoadingDispatch } from "src/system/LoadingContext";
import { CustomDialogEditEventArgs, UserInfoErp } from "src/system/types";
import TeamsInfoGridDialog from "./TeamsInfoGridDialog";

function TeamsInfoGrid() {
  const [data, setData] = useState<UserInfoErp[]>([]);
  const LoadingDispatch = useLoadingDispatch();

  const getList = () => {
    LoadingDispatch({ type: "LOADING" });
    UserInfoErpApi.GetUserInfoErp()
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      })
      .finally(() => {
        LoadingDispatch({ type: "COMPLETE" });
      });
  };

  const getListByV4 = () => {
    LoadingDispatch({ type: "LOADING" });
    UserInfoErpApi.GetUserInfoErpByV4()
      .then((res) => {})
      .catch((err) => {
        let msg = ErrorHandler(err);
        alert(msg);
      })
      .finally(() => {
        getList();
      });
  };

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const makeOrganization = () => {
    if (
      window.confirm(
        "조직도 생성에는 20~30분의 시간이 소요됩니다. 실행 중 중복 호출 시 오류가 발생할 수 있습니다. 정말 실행 하시겠습니까?"
      )
    ) {
      LoadingDispatch({ type: "LOADING" });
      UserInfoErpApi.UpdatetOrganization()
        .then((res) => {})
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        })
        .finally(() => {
          LoadingDispatch({ type: "COMPLETE" });
        });
    }
  };

  // GirdObject
  var gridObj: GridComponent | null;
  // Toolbar Click 이벤트
  const toolbarClick = (args: ClickEventArgs | undefined) => {
    if (args !== undefined && args.item !== undefined) {
      if (args.item.id?.includes("excel") && gridObj !== null) {
        gridObj.excelExport();
      }
    }
  };

  const dialogTemplate = (info: UserInfoErp): any => {
    return <TeamsInfoGridDialog info={info} />;
  };

  // action begin 이벤트
  const actionBegin = (args: DialogEditEventArgs) => {
    if (args.requestType === "save" && args.form) {
    } else if (args.requestType === "delete") {
      if (!window.confirm("정말로 삭제하시겠습니까?")) {
        args.cancel = true;
      }
    }
  };
  const actionComplete = (args: CustomDialogEditEventArgs): void => {
    if (args && args.requestType === "save") {
      if (args.action === "add") {
        LoadingDispatch({ type: "LOADING" });
        if (args.data.o365Create === "true") args.data = { ...args.data, o365Create: true };
        else args.data = { ...args.data, o365Create: false };
        console.log(args.data);
        UserInfoErpApi.InsertUserInfoErp(args.data)
          .then((res) => {})
          .catch((err) => {
            let msg = ErrorHandler(err);
            alert(msg);
          })
          .finally(() => {
            LoadingDispatch({ type: "COMPLETE" });
          });
      } else if (args.action === "edit") {
        LoadingDispatch({ type: "LOADING" });
        UserInfoErpApi.UpdatetUserInfoErp({
          ...args.data,
          department: args.data.department.includes("_") ? args.data.department.split("_")[0] : args.data.department,
        })
          .then((res) => {})
          .catch((err) => {
            let msg = ErrorHandler(err);
            alert(msg);
          })
          .finally(() => {
            LoadingDispatch({ type: "COMPLETE" });
          });
      }
    } else if (args && args.requestType === "delete") {
      LoadingDispatch({ type: "LOADING" });
      UserInfoErpApi.DeleteUserInfoErp(args.data[0].id)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        })
        .finally(() => {
          LoadingDispatch({ type: "COMPLETE" });
        });
    }
  };

  const editOptions: EditSettingsModel = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    mode: "Dialog",
    template: dialogTemplate,
  };
  const toolbarOptions: ToolbarItems[] = ["Add", "Edit", "Update", "Delete", "Cancel", "Search", "ExcelExport"];

  return (
    <div>
      <Alert severity="info">
        <AlertTitle>도움말</AlertTitle>
        <ul>
          <li>Groupware V4(Teams)의 정보를 수정할 수 있습니다.</li>
          <li>
            'V4최신정보 불러오기' 버튼 클릭 시 최신 정보를 불러와서 동기화합니다. (통신 오류 등으로 데이터가 일치 하지
            않은 경우)
          </li>
          <li>'Add'로 사용자를 추가하는 경우 O365계정을 생성여부를 체크할 수 있습니다. </li>
          <li>
            'Delete'로 사용자를 삭제하는 경우 그룹웨어V4에서 사용자가 삭제됩니다. 하지만 O365계정은 관리자가
            Azure포탈에서 직접 라이선스를 제거해야합니다.
          </li>
        </ul>
      </Alert>
      <br></br>
      <Grid container>
        <Grid item xs={6}>
          <ButtonComponent onClick={getListByV4} cssClass="e-info">
            V4 최신정보 불러오기
          </ButtonComponent>
        </Grid>
        <Grid item xs={6}>
          <div className="text-right">
            <ButtonComponent onClick={makeOrganization} cssClass="e-danger">
              조직도 생성
            </ButtonComponent>
          </div>
        </Grid>
      </Grid>
      <br />
      <GridComponent
        dataSource={data}
        editSettings={editOptions}
        allowResizing={true}
        allowFiltering={true}
        allowPaging={true}
        allowSorting={true}
        allowExcelExport={true}
        toolbar={toolbarOptions}
        toolbarClick={toolbarClick}
        actionBegin={actionBegin}
        actionComplete={actionComplete}
        pageSettings={{ pageSize: 20, pageSizes: true }}
        ref={(g) => {
          gridObj = g;
        }}
      >
        <ColumnsDirective>
          <ColumnDirective field="id" headerText="id" width="0" isPrimaryKey={true} visible={false} />
          <ColumnDirective field="email" headerText="이메일" width="150" />
          <ColumnDirective field="name" headerText="이름" width="120" />
          <ColumnDirective field="mobilePhone" headerText="전화번호" width="100" />
          <ColumnDirective field="companyName" headerText="회사명" width="120" />
          <ColumnDirective field="department" headerText="부서" width="120" />
          <ColumnDirective field="costCenter" headerText="CostCenter" width="90" />
          <ColumnDirective field="personalNum" headerText="사원번호" width="100" />
          <ColumnDirective field="legalEntity" headerText="LegalEntity" width="90" />
          <ColumnDirective field="hireDate" headerText="HireDate" format="yyyy-MM-dd" width="90" />
          <ColumnDirective field="duties" headerText="Duties" width="120" />
        </ColumnsDirective>
        <Inject services={[Resize, Edit, Toolbar, Filter, Page, Sort, Search, ExcelExport]} />
      </GridComponent>
    </div>
  );
}

export default TeamsInfoGrid;
