import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ChangeEventArgs } from '@syncfusion/ej2-react-dropdowns';
import { ChangeEvent, Dispatch, SetStateAction, useEffect } from 'react';
import { UseFieldArrayAppend, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import { useUserState } from 'src/system/UserContext';
import { AttendanceDocumentForm } from '../PostAttendanceRequest';
import { AnnualLeave, AnnualLeaveType } from '../data/AnnualLeaveType';
import { GetAttendanceForm } from '../interface/RequestInterface';
import { attendanceAppendAtom } from '../recoils/AttendanceAppendAtom';
import { useAttendanceHooks } from './useAttendanceHooks';
import { useDateHooks } from './useDateHooks';
import ToastAlert from './ToastAlert';
import { getLastBelong } from './getLastBelong';

export const useAttendanceAppendHooks = () => {
  const [attendanceItemState, setAttendanceItemState] = useRecoilState(attendanceAppendAtom);
  const user = useUserState();

  const { getDateStringFormat,getEndTime_ConsiderLunchTime, hourStringParseToDate} = useDateHooks();

  const { getAttendanceBalanceCountData } = useAttendanceHooks();

  /**
   * @public
   * 첫 로딩시 유저 정보 set
   */
  const useSetDefaultStateData = () => {
    useEffect(() => {
      setAttendanceItemState(pre => ({
        ...pre,
        employeeId: user.employeeId,
        employeeDepartment: getLastBelong(user.teamName, user.parentTeamName, user.group),
        employeeName: user.name,
        startDate: getDateStringFormat(new Date(Date.now()), 'YYYY-MM-DD'),
        endDate: getDateStringFormat(new Date(Date.now()), 'YYYY-MM-DD'),
      }))
    }, [])
  }

  /**
   * 양식 변경에 따른 state값 변경
   * @public
   * @param args 선택 양식
   * @param watch
   * @param setValue
   */
  const changeSelectedForm = (
    args: ChangeEventArgs,
    watch: UseFormWatch<AttendanceDocumentForm>,
    setValue: UseFormSetValue<AttendanceDocumentForm>
  ) => {
    const selectedForm = args.itemData as GetAttendanceForm
    // 양식 선택했을 경우
    if (selectedForm) {
      // 1. 기존 반영된 item이 없다면 대분류, 차량 이용여부 변경
      if (watch('attendanceItem').length === 0) {
        setValue('category', selectedForm.category);
        setValue('isCheckOwnVehicle', selectedForm.isCheckOwnVehicle);
      }
      // 2. 시간 설정용 form이면 출근 시간에 맞게 설정
      if (selectedForm.isTimeEntered) {
        // TODO 본인 출근시간으로 변경 나중에 대상자에 따른 출근시간 변경
        const workingStartDate = new Date(user.scheduleStartTime);
        const workingEndDate = new Date(workingStartDate);
        const term = Math.abs(selectedForm.term);

        // 반차, 반반차는 term에 맞게, 아니면 8시간
        if (0 < term && term < 1) {
          workingEndDate.setHours(workingEndDate.getHours() + (8 * term));
        } else {
          workingEndDate.setHours(workingEndDate.getHours() + 8);
        }

        setAttendanceItemState(pre => ({
          ...pre,
          formId: selectedForm.id,
          selectedForm: selectedForm,
          startTime: getDateStringFormat(workingStartDate, 'hh:mm'),
          endTime: getEndTime_ConsiderLunchTime(workingStartDate, workingEndDate),
          startDate: getDateStringFormat(new Date(Date.now()),'YYYY-MM-DD'),
          endDate: getDateStringFormat(new Date(Date.now()),'YYYY-MM-DD'),
        }))
      } else {
        setAttendanceItemState(pre => ({
          ...pre,
          formId: selectedForm.id,
          selectedForm: selectedForm,
          startTime: '',
          endTime: '',
          startDate: getDateStringFormat(new Date(Date.now()),'YYYY-MM-DD'),
          endDate: getDateStringFormat(new Date(Date.now()),'YYYY-MM-DD'),
        }))
      }
    }
  }

  /**
   * 시작 시간 변경
   * @public
   * @param date
   * @param startDateValue
   * @returns
   */
  const handleStartDateChange = (date: MaterialUiPickersDate, startDateValue?: string | null | undefined) => {
    if (!startDateValue) return;

    // 종료일이 비어있거나, 종료일이 더 빠르면? 시작일로 변경
    if (attendanceItemState.endDate === '' || new Date(startDateValue) > new Date(attendanceItemState.endDate)) {
      setAttendanceItemState(pre => ({
        ...pre,
        startDate: startDateValue,
        endDate: startDateValue
      }))
    }

    // 종료일이 더 느리면? 시작일만 변경
    if (new Date(startDateValue) <= new Date(attendanceItemState.endDate)) {
      setAttendanceItemState(pre => ({ ...pre, startDate: startDateValue }))
    }
  }

  /**
   * 종료 시간 변경
   * @public
   * @param date
   * @param endDateValue
   * @returns
   */
  const handleEndDateChange = (date: MaterialUiPickersDate, endDateValue?: string | null | undefined) => {
    if (!endDateValue) return;

    // 시작일이 비어있거나, 시작일이 더 느리면? 시작일을 종료일로 변경
    if (attendanceItemState.startDate === '' || new Date(attendanceItemState.startDate) > new Date(endDateValue)) {
      setAttendanceItemState(pre => ({
        ...pre,
        startDate: endDateValue,
        endDate: endDateValue
      }));
    }
    // 시작일이 더 빠르면? 종료일만 변경
    if (new Date(attendanceItemState.startDate) <= new Date(endDateValue)) {
      setAttendanceItemState(pre => ({ ...pre, endDate: endDateValue }));
    }
  }

  /**
   * 시작 시간 변경 및 종료시간 자동 설정(점심 시간 고려)
   * @public
   * @param e
   */
  const handleStartTimeChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setAttendanceItemState(pre => ({ ...pre, startTime: e.target.value }));

    // 00:00 경고 Alert
    if (e.target.value.localeCompare('01:00') === -1) {
      ToastAlert(`시작시간이 ${e.target.value}로 설정되었습니다.`,'warning')
    }

    // 종료시간 자동 선택(점심시간 고려)
    if (attendanceItemState.selectedForm && 0 < Math.abs(attendanceItemState.selectedForm.term)
      && Math.abs(attendanceItemState.selectedForm.term) < 1) {
      const startTime = new Date(`1970-01-01T${e.target.value}:00`);
      const endTime = new Date(startTime.getTime() + Math.abs(attendanceItemState.selectedForm.term) * 60 * 60 * 1000 * 8);
      setAttendanceItemState(pre => ({
        ...pre,
        endTime: getEndTime_ConsiderLunchTime(startTime, endTime)
      }))
    }
  }


  /**
   * 종료 시간 변경
   * @public
   * @param e
   * @returns
   */
  const handleEndTimeChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (attendanceItemState.startTime > e.target.value) {
      alert('종료시간이 시작시간보다 빠릅니다.')
      return;
    }

    // 반차는 최대 6시간까지 설정 가능
    if (attendanceItemState.selectedForm &&
      attendanceItemState.selectedForm.term === -0.5 &&
      Math.abs(hourStringParseToDate(attendanceItemState.startTime).getTime()
        - hourStringParseToDate(e.target.value).getTime()) > 60 * 60 * 1000 * 5) {
      alert("반차 사용은 연속된 5시간 이내의 근무시간에만 가능합니다.\n선택하신 시간은 5시간을 초과하므로 반차 사용이 불가능합니다.\n시작시간과 종료시간을 5시간 이내로 조정해 주세요.");
      return;
    }

    setAttendanceItemState(pre => ({ ...pre, endTime: e.target.value }));
  }


  /**
   * 반영 버튼 클릭
   * @public
   */
  const handleAppendButtonClick = async (
    setValue: UseFormSetValue<AttendanceDocumentForm>,
    watch: UseFormWatch<AttendanceDocumentForm>,
    append: UseFieldArrayAppend<AttendanceDocumentForm, "attendanceItem">
  ) => {
    // 1.벨리데이션, 선택양식 확인
    if (!isValid() || !attendanceItemState.selectedForm) return;

    setValue('category', attendanceItemState.selectedForm.category);

    // 2. 각각의 통근태 Item 생성
    const sDay = new Date(attendanceItemState.startDate);
    const eDay = new Date(attendanceItemState.endDate);
    const sDateOnly = new Date(getDateStringFormat(sDay,'YYYY-MM-DD'));
    const eDateOnly = new Date(getDateStringFormat(eDay,'YYYY-MM-DD'));
    const diffTime = Math.abs(sDateOnly.getTime() - eDateOnly.getTime());
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24)) + 1;

    // let nextDate = eDay;
    let isAppendAnyItem = false;
    let isHolidayAlert = false;

    // item 값들 세팅
    for (let i = 0; i < diffDays; i++) {
      let workDate = new Date(sDay);
      workDate.setDate(sDay.getDate() + i);

      // 연차, 반차, 반반차의 경우 => 토요일 일요일은 건너뜀
      const day = workDate.getDay();
      let formattedWorkDate = getDateStringFormat(workDate, 'YYYY-MM-DD');

      if (attendanceItemState.selectedForm.leaveType && (day === 0 || day === 6)) {
        isHolidayAlert = true;
        continue;
      }

      // leaveType이 있는경우(연차), 초과사용/일반사용 확인
      if (attendanceItemState.selectedForm.leaveType) {

        let unUsedAttendanceBalanceCount = 0;
        const leaveType = attendanceItemState.selectedForm.leaveType as AnnualLeaveType;

        const data = await getAttendanceBalanceCountData(attendanceItemState.employeeId);

        switch (leaveType) {
          case AnnualLeave.annualLeave:
            unUsedAttendanceBalanceCount = data.annualLeave - data.usedAnnualLeave;
            break;
          case AnnualLeave.specialLeave:
            unUsedAttendanceBalanceCount = data.specialLeave - data.usedSpecialLeave;
            break;
          case AnnualLeave.alternativeLeave:
            unUsedAttendanceBalanceCount = data.alternativeLeave - data.usedAlternativeLeave;
            break;
        }

        //사용하려는 휴가 종류의 잔여 연차 확인
        let targetAttendanceRemainingCount = watch('attendanceItem')
          .reduce((sum, item) => {
            if (item.employeeId === attendanceItemState.employeeId
              && item.attendanceForm.leaveType === leaveType) {
              return sum + item.term;
            }
            return sum;
          }, unUsedAttendanceBalanceCount)

        // 초과는 모든 연차가 0개여야 사용 가능
        if (attendanceItemState.selectedForm.name.includes('초과')) {
          const OtherAttendanceRemainingCount = watch('attendanceItem')
            .reduce((sum, item) => {
              if (item.employeeId === attendanceItemState.employeeId) {
                return sum + item.term;
              }
              return sum;
            }, (data.annualLeave + data.specialLeave + data.alternativeLeave - data.annualLeave - data.specialLeave - data.usedAlternativeLeave))

          if (targetAttendanceRemainingCount > 0 || OtherAttendanceRemainingCount > 0) {
            alert(`초과사용 양식은 대체휴가, 특별휴가, 잔여연차가 모두 0개 이하일 때만 사용할 수 있습니다.`)
            return;
          }
        }

        targetAttendanceRemainingCount += attendanceItemState.selectedForm.term;

        if (targetAttendanceRemainingCount < 0 && !attendanceItemState.selectedForm.name.includes('초과')) {
          alert(`연차종류에 맞는 잔여 휴가가 0개 이하입니다.\n${workDate.getMonth() + 1}월 ${workDate.getDate()}일부터 ${attendanceItemState.selectedForm.name}(을)를 추가하지 못했습니다.\n연차초과사용의 경우, 양식종류를 초과사용으로 변경해주세요!`)
          return;
        }
      }

      isAppendAnyItem = true;
      // nextDate = workDate;
      append({
        employeeId: attendanceItemState.employeeId,
        employeeName: attendanceItemState.employeeName,
        department: attendanceItemState.employeeDepartment,
        attendanceForm: attendanceItemState.selectedForm,
        formName: attendanceItemState.selectedForm.name,
        content: attendanceItemState.selectedForm.content,
        vehicle: attendanceItemState.vehicle,
        isTimeEntered: attendanceItemState.selectedForm.isTimeEntered,
        startTime: attendanceItemState.startTime,
        endTime: attendanceItemState.endTime,
        term: attendanceItemState.selectedForm.term,
        workDate: formattedWorkDate,
        expiredAt: attendanceItemState.expiredAt,
      });
    }

    if (isHolidayAlert) {
      if (isAppendAnyItem) {
        alert(`선택한 날짜 중 주말 또는 공휴일을 제외한 근무일에만 ${attendanceItemState.selectedForm.name}가 반영되었습니다.`)
      } else {
        alert(`${attendanceItemState.selectedForm.name}는 주말 또는 공휴일에 사용할 수 없습니다.`)
      }
    }

    // 3. 휴가이유, 차량이용여부 set
    setValue('isCheckOwnVehicle', attendanceItemState.selectedForm.isCheckOwnVehicle);
    if (isAppendAnyItem && watch('reason') === '' && watch('category') === '휴가원') {
      setValue('reason', '개인 사유로 인해 사용합니다.')
    }

    // // 4. 시작일을 자동으로 다음날로 변경(연차,반차,반반차 휴가만)
    // if (attendanceItemState.selectedForm.leaveType && isAppendAnyItem) {
    //   nextDate.setDate(nextDate.getDate() + 1);
    //   const day = nextDate.getDay();
    //   if (day === 0) {
    //     nextDate.setDate(nextDate.getDate() + 1);
    //   } else if (day === 6) {
    //     nextDate.setDate(nextDate.getDate() + 2);
    //   }

    //   setAttendanceItemState(pre => ({
    //     ...pre,
    //     startDate: getDateStringFormat(nextDate, 'YYYY-MM-DD'),
    //     endDate: getDateStringFormat(nextDate, 'YYYY-MM-DD')
    //   }))
    // }
  }

  /**
   * 반영 버튼 클릭 Validation
   * @private
   */
  const isValid = () => {
    if (attendanceItemState.startDate === '') {
      alert('시작일을 지정해주세요!');
      return false;
    }
    if (attendanceItemState.endDate === '') {
      alert('종료일을 지정해주세요!');
      return false;
    }
    if (!attendanceItemState.selectedForm) {
      alert('양식종류를 선택해주세요!');
      return false;
    }
    if (attendanceItemState.startTime > attendanceItemState.endTime) {
      alert('시작시간이 종료시간보다 늦습니다.');
      return false;
    }
    return true;
  }

  return {
    useSetDefaultStateData,

    changeSelectedForm,

    handleStartDateChange,
    handleEndDateChange,
    handleStartTimeChange,
    handleEndTimeChange,
    handleAppendButtonClick,

  }
};