import React, { ReactElement, useMemo } from "react";
import { Applicant, SummaryRow } from "src/system/types";
import ReactDataGrid, { SelectCellFormatter } from "react-data-grid";

interface DataGridApplicantProps {
  loadApplicant: (applicant: Applicant) => void;
  data: Applicant[];
}

function DataGridApplicant({ data, loadApplicant }: DataGridApplicantProps): ReactElement {
  const summaryRows = useMemo(() => {
    const summaryRow: SummaryRow = {
      totalCount: data.length,
      insertCount: data.filter((x) => x.enrollment === true).length,
    };
    return [summaryRow];
  }, [data]);

  const columns = React.useMemo(() => {
    const k = [
      {
        name: "이름",
        key: "name",
        summaryFormatter() {
          return <>등록 합계</>;
        },
      },
      {
        name: "성별",
        key: "gender",
      },
      {
        name: "E-mail",
        key: "email",
      },
      {
        name: "지역",
        key: "region",
      },
      {
        name: "1지망",
        key: "division1",
      },
      {
        name: "2지망",
        key: "division2",
      },
      {
        name: "등록하기",
        key: "",
        formatter(props: any) {
          return (
            <SelectCellFormatter
              value={props.row.enrollment}
              onChange={() => {}}
              onClick={() => {
                if (!props.row.enrollment) {
                  if (
                    // eslint-disable-next-line no-restricted-globals
                    confirm(
                      `채용사이트에서 ${props.row.name}의 정보를 불러오시겠습니까?\r\n※ 주의! 작성중인 정보는 초기화됩니다.`
                    )
                  ) {
                    // 채용DB에서 정보 불러오기
                    loadApplicant(props.row);
                  }
                } else {
                  alert("이미 등록한 합격자입니다.");
                }
              }}
              isCellSelected={props.row.enrollment}
            />
          );
        },
        summaryFormatter({ row: { insertCount, totalCount } }: any) {
          return (
            <>
              {totalCount !== 0 ? (
                `${insertCount}/${totalCount} (${Math.floor((100 * insertCount) / totalCount)}% ✔️)`
              ) : (
                <>합격자 없음</>
              )}
            </>
          );
        },
      },
    ];
    return k;
  }, [loadApplicant]);

  return (
    <div>
      <ReactDataGrid
        rows={data}
        columns={columns}
        rowKeyGetter={(row) => {
          return row.rcode + "-" + row.ano;
        }}
        defaultColumnOptions={{
          resizable: true,
        }}
        summaryRows={summaryRows}
      ></ReactDataGrid>
    </div>
  );
}

export default DataGridApplicant;
