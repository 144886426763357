import React, { ReactElement } from "react";
import { Route, Switch } from "react-router-dom";
import MainPage from "src/page/test/MainPage";

function Routes(): ReactElement {
  return (
    <Switch>
      <Route path="/test">
        <MainPage></MainPage>
      </Route>
    </Switch>
  );
}

export default Routes;
