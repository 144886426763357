import React, { ReactElement, ReactNode } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  FormControl,
  InputLabel,
  Select as MaterialSelect,
  SelectProps as MaterialSelectProps,
  MenuItem,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 130,
    },
  })
);

export interface SimpleChangeEvent {
  name?: string | undefined;
  value: unknown;
}
export interface SelectProps extends MaterialSelectProps {
  empty?: boolean;
  size?: "medium" | "small";
  label?: string;
  emptyLabel?: string;
  emptyValue?: string | number;
  children: ReactNode;
  showRow?: number;
}

const ITEM_PADDING_TOP = 8; //8px
const ITEM_HEIGHT = 28; //28px

function Select({
  empty,
  size,
  label,
  fullWidth,
  variant,
  className,
  children,
  emptyLabel,
  emptyValue,
  showRow,
  ...others
}: SelectProps): ReactElement {
  const classes = useStyles();
  const formControlClass = size === "small" ? undefined : classes.formControl;

  return (
    <FormControl
      fullWidth={fullWidth}
      variant={variant}
      className={className ? className : formControlClass}
      size={size}
    >
      {label && <InputLabel>{label}</InputLabel>}
      <MaterialSelect
        {...others}
        label={label}
        MenuProps={
          showRow !== undefined
            ? {
                PaperProps: {
                  style: {
                    maxHeight: ITEM_HEIGHT * (showRow || 5) + ITEM_PADDING_TOP,
                    width: 60,
                  },
                },
              }
            : undefined
        }
      >
        {empty && (
          <MenuItem value={emptyValue === undefined ? "" : emptyValue}>
            <em>{emptyLabel || "None"}</em>
          </MenuItem>
        )}
        {children}
      </MaterialSelect>
    </FormControl>
  );
}

export default Select;
