import {
  Grid,
  TextField,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import SubTitleBar from "src/components/SubTitleBar";
import {
  AttendanceApi,
  AttendanceBalanceApi,
  AttendanceRequestApi,
  ErrorHandler,
} from "src/system/ApiService";
import { useUserState } from "src/system/UserContext";
import { EmployeeAutoComplete } from "src/system/types";
import {
  GetAttendanceBalance,
  GetAttendanceBalanceCount,
  InitAttendanceBalanceCount,
} from "../post-attendance-request/interface/BalanceInterface";
import { GetAttendanceItem } from "../post-attendance-request/interface/RequestInterface";
import AttendanceBalanceHistory from "./components/AttendanceBalanceHistory";
import AttendanceBalanceStatus from "./components/AttendanceBalanceStatus";
import AttendanceRequestList from "./components/AttendanceRequestList";
import "./style/attendanceRequest.css";
import { getLastBelong } from "../post-attendance-request/hooks/getLastBelong";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      marginTop: "20px",
    },
    headTitleBar: {
      borderLeft: "8px solid",
      borderColor: "#8a1c22",
      paddingLeft: "5px",
      fontWeight: "bold",
      fontSize: "18px",
    },
  })
);

function AttendanceMain() {
  const now = new Date();
  const classes = useStyles();
  const user = useUserState();
  const [employeeList, setEmployeeList] = useState<EmployeeAutoComplete[]>([]);

  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string>(
    user.employeeId
  );
  const [attendanceBalanceStatus, setAttendanceBalanceStatus] =
    useState<GetAttendanceBalanceCount>(InitAttendanceBalanceCount);
  const [attendanceBalanceList, setAttendanceBalanceList] = useState<
    GetAttendanceBalance[]
  >([]);
  const [attendanceRequestItemList, setAttendanceRequestItemList] = useState<
    GetAttendanceItem[]
  >([]);
  const [selectedEmployee, setSelectedEmployee] =
    useState<EmployeeAutoComplete>({
      employeeNo: user.employeeNo,
      employeeId: user.employeeId,
      name: user.name,
      email: user.gwMail,
      workplace: user.workplace,
      group: user.group,
      groupId: "",
      departmentId: "",
      department: user.parentTeamName,
      teamId: "",
      team: user.teamName,
      jobPosition: user.jobPosition,
      jobTitle: user.jobTitle,
      phone: "",
      tel: "",
      gwName: user.gwName,
      joined: user.joined,
    });

  useEffect(() => {
    // 사원 리스트 조회
    AttendanceApi.GetEmployeeList(user.employeeId)
      .then((res) => {
        setEmployeeList(res);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      });
  }, [user]);
  useEffect(() => {
    if (employeeList.length > 0) {
      const selectedEmployee = employeeList.find(
        (e) => e.employeeId === selectedEmployeeId
      );
      if (selectedEmployee) {
        setSelectedEmployee(selectedEmployee);
      }
    }
  }, [selectedEmployeeId, employeeList]);

  useEffect(() => {
    AttendanceRequestApi.GetAttendanceItemList(0, selectedEmployeeId)
      .then((res) => setAttendanceRequestItemList(res))
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      });

    AttendanceBalanceApi.GetAttendanceBalanceList(selectedEmployeeId)
      .then((res) => {
        setAttendanceBalanceList(res);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      });

    AttendanceBalanceApi.GetAttendanceBalanceCount(selectedEmployeeId)
      .then((res) => {
        setAttendanceBalanceStatus(res);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      });
  }, [selectedEmployeeId]);

  const onChangeSelect = (
    event: React.ChangeEvent<{}>,
    value: EmployeeAutoComplete | null
  ) => {
    if (value !== null) {
      setSelectedEmployeeId(value.employeeId);
    }
  };

  return (
    <>
      <Grid container spacing={1} style={{ padding: 24 }}>
        {user.role === "admin" && employeeList.length > 0 && (
          <>
            <Autocomplete
              options={employeeList}
              onChange={onChangeSelect}
              getOptionLabel={(option) => option.name}
              renderOption={(option) => (
                <React.Fragment>
                  {option.name}
                  <span
                    style={{
                      marginLeft: "20px",
                      fontSize: "14px",
                      color: "#888888",
                    }}
                  >{`\t ${option.employeeNo}, ${getLastBelong(
                    option.team,
                    option.department,
                    option.group
                  )}, ${option.jobPosition} , ${option.email}`}</span>
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField {...params} label="사원 검색" variant="outlined" />
              )}
              style={{ minWidth: "700px" }}
            ></Autocomplete>
          </>
        )}
        {/* 날짜 */}
        <Grid item xs={12}>
          <Typography variant="h5" align="center">
            {`${now.getFullYear()}.${now.getMonth() + 1}.${String(
              now.getDate()
            ).padStart(2, "0")}`}
          </Typography>
        </Grid>
        {/* Content */}
        <Grid item xs={12}>
          <Grid className={classes.wrapper} item xs={12}>
            <SubTitleBar title={"연차현황"} />
            <AttendanceBalanceStatus
              selectedEmployee={selectedEmployee}
              attendanceBalanceStatus={attendanceBalanceStatus}
            />
          </Grid>
          <Grid className={classes.wrapper} item xs={12}>
            <SubTitleBar title={"연차 발생이력"} />
            <AttendanceBalanceHistory
              attendanceBalanceList={attendanceBalanceList}
            />
          </Grid>
          <Grid className={classes.wrapper} item xs={12}>
            <SubTitleBar title={"연차 사용이력"} />
            <AttendanceRequestList
              attendanceRequestItemList={attendanceRequestItemList}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default AttendanceMain;
