import React, { useCallback, useEffect, useState } from "react";
import { AttendanceApi, ErrorHandler } from "src/system/ApiService";
import { LateStatistics } from "src/system/types";
import {
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  ExcelExport,
  Filter,
  Grid,
  GridComponent,
  Inject,
  Page,
  Resize,
  Search,
  Sort,
  Toolbar,
  ToolbarItems,
} from "@syncfusion/ej2-react-grids";
import { ClickEventArgs } from "@syncfusion/ej2-react-navigations";
import { useLoadingDispatch } from "src/system/LoadingContext";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";

function LateStatisticsGrid() {
  const [data, setData] = useState<LateStatistics[]>([]);
  const [workplace, setWorkplace] = useState<string>("본사");
  const LoadingDispatch = useLoadingDispatch();

  const LoadData = useCallback(() => {
    LoadingDispatch({ type: "LOADING" });

    AttendanceApi.GetYearlyLateStatistics(workplace)
      .then((res) => {
        setData(res);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      })
      .finally(() => {
        LoadingDispatch({ type: "COMPLETE" });
      });
  }, [LoadingDispatch, workplace]);

  useEffect(() => {
    LoadData();
  }, [LoadData]);

  const onChange = (event: any) => {
    setWorkplace(event.target.value);
  };

  const toolbarOptions: ToolbarItems[] = ["Search", "ColumnChooser", "ExcelExport"];

  // GirdObject
  var gridObj: Grid | null;
  // Toolbar Click 이벤트
  const toolbarClick = (args: ClickEventArgs | undefined) => {
    if (args !== undefined && args.item !== undefined) {
      if (args.item.id?.includes("excel") && gridObj !== null) {
        gridObj.excelExport();
      }
    }
  };

  const months = [];
  for (let i = 1; i < new Date().getMonth() + 1; i++) {
    months.push(i);
  }

  const month = new Date().getMonth() + 1;

  return (
    <div>
      <div style={{ textAlign: "left" }}>
        <DropDownListComponent
          placeholder="사업장 선택"
          width="300px"
          id="ddlelement"
          value={workplace}
          dataSource={["본사", "천안공장"]}
          onChange={onChange}
        />
      </div>
      <GridComponent
        dataSource={data}
        allowResizing={true}
        allowFiltering={true}
        allowPaging={true}
        allowSorting={true}
        allowExcelExport={true}
        selectedRowIndex={1}
        selectionSettings={{ type: "Multiple", mode: "Both" }}
        showColumnChooser={true}
        toolbar={toolbarOptions}
        toolbarClick={toolbarClick}
        pageSettings={{ pageSize: 100, pageSizes: true }}
        ref={(g) => {
          gridObj = g;
        }}
      >
        <ColumnsDirective>
          <ColumnDirective field="employeeNo" headerText="사번" width="150" />
          <ColumnDirective field="team" headerText="팀" width="200" />
          <ColumnDirective field="name" headerText="이름" width="120" />
          <ColumnDirective field={`final`} headerText={`연간지각`} textAlign="Right" width="95" visible={true} />
          <ColumnDirective field={`l1`} headerText={`1지각`} textAlign="Right" width="95" visible={false} />
          <ColumnDirective field={`a1`} headerText={`1미타각`} textAlign="Right" width="95" visible={false} />
          <ColumnDirective field={`e1`} headerText={`1소명`} textAlign="Right" width="95" visible={false} />
          <ColumnDirective field={`f1`} headerText={`1월최종`} textAlign="Right" width="95" visible={month > 0} />
          <ColumnDirective field={`l2`} headerText={`2지각`} textAlign="Right" width="95" visible={false} />
          <ColumnDirective field={`a2`} headerText={`2미타각`} textAlign="Right" width="95" visible={false} />
          <ColumnDirective field={`e2`} headerText={`2소명`} textAlign="Right" width="95" visible={false} />
          <ColumnDirective field={`f2`} headerText={`2월최종`} textAlign="Right" width="95" visible={month > 1} />
          <ColumnDirective field={`l3`} headerText={`3지각`} textAlign="Right" width="95" visible={false} />
          <ColumnDirective field={`a3`} headerText={`3미타각`} textAlign="Right" width="95" visible={false} />
          <ColumnDirective field={`e3`} headerText={`3소명`} textAlign="Right" width="95" visible={false} />
          <ColumnDirective field={`f3`} headerText={`3월최종`} textAlign="Right" width="95" visible={month > 2} />
          <ColumnDirective field={`l4`} headerText={`4지각`} textAlign="Right" width="95" visible={false} />
          <ColumnDirective field={`a4`} headerText={`4미타각`} textAlign="Right" width="95" visible={false} />
          <ColumnDirective field={`e4`} headerText={`4소명`} textAlign="Right" width="95" visible={false} />
          <ColumnDirective field={`f4`} headerText={`4월최종`} textAlign="Right" width="95" visible={month > 3} />
          <ColumnDirective field={`l5`} headerText={`5지각`} textAlign="Right" width="95" visible={false} />
          <ColumnDirective field={`a5`} headerText={`5미타각`} textAlign="Right" width="95" visible={false} />
          <ColumnDirective field={`e5`} headerText={`5소명`} textAlign="Right" width="95" visible={false} />
          <ColumnDirective field={`f5`} headerText={`5월최종`} textAlign="Right" width="95" visible={month > 4} />
          <ColumnDirective field={`l6`} headerText={`6지각`} textAlign="Right" width="95" visible={false} />
          <ColumnDirective field={`a6`} headerText={`6미타각`} textAlign="Right" width="95" visible={false} />
          <ColumnDirective field={`e6`} headerText={`6소명`} textAlign="Right" width="95" visible={false} />
          <ColumnDirective field={`f6`} headerText={`6월최종`} textAlign="Right" width="95" visible={month > 5} />
          <ColumnDirective field={`l7`} headerText={`7지각`} textAlign="Right" width="95" visible={false} />
          <ColumnDirective field={`a7`} headerText={`7미타각`} textAlign="Right" width="95" visible={false} />
          <ColumnDirective field={`e7`} headerText={`7소명`} textAlign="Right" width="95" visible={false} />
          <ColumnDirective field={`f7`} headerText={`7월최종`} textAlign="Right" width="95" visible={month > 6} />
          <ColumnDirective field={`l8`} headerText={`8지각`} textAlign="Right" width="95" visible={false} />
          <ColumnDirective field={`a8`} headerText={`8미타각`} textAlign="Right" width="95" visible={false} />
          <ColumnDirective field={`e8`} headerText={`8소명`} textAlign="Right" width="95" visible={false} />
          <ColumnDirective field={`f8`} headerText={`8월최종`} textAlign="Right" width="95" visible={month > 7} />
          <ColumnDirective field={`l9`} headerText={`9지각`} textAlign="Right" width="95" visible={false} />
          <ColumnDirective field={`a9`} headerText={`9미타각`} textAlign="Right" width="95" visible={false} />
          <ColumnDirective field={`e9`} headerText={`9소명`} textAlign="Right" width="95" visible={false} />
          <ColumnDirective field={`f9`} headerText={`9월최종`} textAlign="Right" width="95" visible={month > 8} />
          <ColumnDirective field={`l10`} headerText={`10지각`} textAlign="Right" width="95" visible={false} />
          <ColumnDirective field={`a10`} headerText={`10미타각`} textAlign="Right" width="95" visible={false} />
          <ColumnDirective field={`e10`} headerText={`10소명`} textAlign="Right" width="95" visible={false} />
          <ColumnDirective field={`f10`} headerText={`10월최종`} textAlign="Right" width="95" visible={month > 9} />
          <ColumnDirective field={`l11`} headerText={`11지각`} textAlign="Right" width="95" visible={false} />
          <ColumnDirective field={`a11`} headerText={`11미타각`} textAlign="Right" width="95" visible={false} />
          <ColumnDirective field={`e11`} headerText={`11소명`} textAlign="Right" width="95" visible={false} />
          <ColumnDirective field={`f11`} headerText={`11월최종`} textAlign="Right" width="95" visible={month > 10} />
          <ColumnDirective field={`l12`} headerText={`12지각`} textAlign="Right" width="95" visible={false} />
          <ColumnDirective field={`a12`} headerText={`12미타각`} textAlign="Right" width="95" visible={false} />
          <ColumnDirective field={`e12`} headerText={`12소명`} textAlign="Right" width="95" visible={false} />
          <ColumnDirective field={`f12`} headerText={`12월최종`} textAlign="Right" width="95" visible={month > 11} />
          {/* {month.map((item) => {
            return (
              <>
                <ColumnDirective
                  field={`l${item}`}
                  headerText={`${item}지각`}
                  textAlign="Right"
                  width="95"
                  key={`late-${item}`}
                />
                <ColumnDirective
                  field={`a${item}`}
                  headerText={`${item}미타각`}
                  textAlign="Right"
                  width="95"
                  key={`absent-${item}`}
                />
                <ColumnDirective
                  field={`e${item}`}
                  headerText={`${item}소명`}
                  textAlign="Right"
                  width="95"
                  key={`explanation-${item}`}
                />
              </>
            );
          })} */}
        </ColumnsDirective>
        <Inject services={[Resize, Toolbar, Filter, Page, Sort, Search, ExcelExport, ColumnChooser]} />
      </GridComponent>
    </div>
  );
}

export default LateStatisticsGrid;
