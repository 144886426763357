import { Button, Divider, Grid, Table, Typography } from "@material-ui/core";
import React, { ReactElement, useEffect, useState } from "react";
import { HrStyle, MyStyle } from "src/Style/theme";
import { InitQNA, QNA, QNARoutes } from "src/system/types";
import { useHistory, useParams } from "react-router-dom";
import { ErrorHandler, QNAApi } from "src/system/ApiService";
import { CalculateFileSize, getDateUsingNumber } from "src/system/Helper";
import { ContentView } from "src/components/common/RichTextBox";
import HtmlContent from "src/components/HtmlContent";
import fileDownload from "js-file-download";

function ViewPage(): ReactElement {
  const { id } = useParams<{ id: string }>();
  const [qna, setQNA] = useState<QNA>(InitQNA);
  const classes = MyStyle();
  const hrclasses = HrStyle();
  const history = useHistory();

  useEffect(() => {
    const qnaId = isNaN(parseInt(id)) ? 0 : parseInt(id);
    if (qnaId !== 0) {
      QNAApi.GetQNA(qnaId)
        .then((res) => {
          setQNA(res);
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          alert(msg);
        });
    }
  }, [id]);

  const _toList = (event: React.MouseEvent<HTMLElement>) => {
    if (!(event.target instanceof HTMLElement)) {
      return;
    }
    history.push({
      pathname: `${QNARoutes.root}`,
    });
  };

  const onDownLoad = (filename: string) => {
    let contentType: string = "application/octet-stream";
    QNAApi.DownloadFile(filename, contentType)
      .then((res) => {
        fileDownload(res, filename);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      });
  };

  return (
    <div className={classes.paperMainWhite}>
      <div>
        <Grid container>
          <Grid item xs={6} style={{ textAlign: "left" }}>
            <Button variant="contained" color="secondary" onClick={_toList}>
              목록
            </Button>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Button variant="contained" color="secondary" onClick={_toList} style={{ marginRight: "10px" }}>
              삭제
            </Button>
            <Button variant="contained" color="secondary" onClick={_toList}>
              수정
            </Button>
          </Grid>
        </Grid>
      </div>
      <br></br>
      {/* 카테고리 */}
      <div>
        <Typography variant="subtitle1" style={{ color: "#888888", marginBottom: "20px" }}>
          {qna.category}
        </Typography>
      </div>
      {/* 요청 제목 */}
      <div
        style={{
          borderTop: "2px solid #8a1c22",
          borderBottom: "2px solid #8a1c22",
          backgroundColor: "#efefef",
          padding: "2rem 1rem",
        }}
      >
        <Typography variant="h5" color="secondary">
          {`Q. ${qna.title}`}
        </Typography>
      </div>
      {/* 요청 내용 */}
      <div style={{ padding: "2rem 2rem" }}>
        <Table className={hrclasses.table}>
          <colgroup>
            <col width={200}></col>
            <col width={400}></col>
            <col width={200}></col>
            <col width={200}></col>
          </colgroup>
          <tr>
            <th>요청자</th>
            <td>{qna.requester}</td>
            <th>요청일</th>
            <td>{getDateUsingNumber(qna.created)}</td>
          </tr>
          <tr>
            <th>첨부파일</th>
            <td>
              <div onClick={() => onDownLoad(qna.fileQ)}>
                <a href="#file">{qna.fileQ && `${qna.fileQ} ${CalculateFileSize(qna.fileQSize)}`}</a>
              </div>
            </td>
            <th>상태</th>
            <td>{qna.status}</td>
          </tr>
          <tr>
            <th colSpan={4}>요청 내용</th>
          </tr>
          <tr>
            <td colSpan={4}>
              <ContentView>
                <HtmlContent data={qna.content}></HtmlContent>
              </ContentView>
            </td>
          </tr>
        </Table>
        <br></br>
        <Divider style={{ marginTop: "20px", marginBottom: "20px" }}></Divider>
        <Table className={classes.tableAnswer}>
          <colgroup>
            <col width={200}></col>
            <col width={400}></col>
            <col width={200}></col>
            <col width={200}></col>
          </colgroup>
          <tr>
            <th colSpan={4}>답변 내용</th>
          </tr>
          <tr>
            <td colSpan={4}>
              <ContentView>
                <HtmlContent data={qna.answer || "-"}></HtmlContent>
              </ContentView>
            </td>
          </tr>
          <tr>
            <th>답변자</th>
            <td>{qna.answerer || "-"}</td>
            <th>예상완료일</th>
            <td>{qna.expected ? getDateUsingNumber(qna.expected) : "-"}</td>
          </tr>
          <tr>
            <th>첨부파일</th>
            <td>
              <div onClick={() => onDownLoad(qna.fileA)}>
                <a href="#file">{qna.fileA && `${qna.fileA} ${CalculateFileSize(qna.fileASize)}`}</a>
              </div>
            </td>
            <th>완료일</th>
            <td>{qna.completed ? getDateUsingNumber(qna.completed) : "-"}</td>
          </tr>
        </Table>
      </div>
      <Divider style={{ borderTop: "2px solid #8a1c22" }}></Divider>
      {/* 답변 */}
    </div>
  );
}

export default ViewPage;
