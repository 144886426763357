import { Divider, Grid, IconButton, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import React, { ChangeEvent, ReactElement } from "react";
import { Button } from "react-bootstrap";
import { EndTextField } from "src/components/EndTextField";
import RegularColgroup from "src/components/RegularColgroup";
import TableInsaCard from "src/components/TableInsaCard";
import { Language } from "src/system/types";
import ClearIcon from "@material-ui/icons/Clear";
import SelectCombobox from "src/components/SelectCombobox";

interface Props {
  data: Language[];
  onAddRows: (tab: string) => void;
  onRemoveRows: (tab: string, index: number) => void;
  onChangeText: (tab: string, e: ChangeEvent<HTMLInputElement>, index: number) => void;
  onChangeFile: (tab: string, e: ChangeEvent<HTMLInputElement>, index: number) => void;
  onChangeSelect: (tab: string, e: React.ChangeEvent<{ name?: string; value: unknown }>, index: number) => void;
  onChangeValue: (tab: string, name: string, value: string, index: number) => void;
  onChangeAutoComplete: (
    tab: string,
    event: React.ChangeEvent<{}>,
    value: string | null,
    name: string,
    index: number
  ) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      // backgroundColor: "#eeeeee",
      width: "1000px",
      display: "inline-block",
      textAlign: "left",
    },
    sub: {
      padding: "20px",
      width: "100%",
      "& .MuiGrid-item": {
        height: "70px",
      },
    },
  })
);

function InputLanguageData({
  data,
  onAddRows,
  onRemoveRows,
  onChangeText,
  onChangeFile,
  onChangeValue,
  onChangeSelect,
  onChangeAutoComplete,
}: Props): ReactElement {
  const classes = useStyles();
  const tab = "language";

  return (
    <div>
      <div className={classes.main}>
        <TableInsaCard title="어학사항" colgroup={<RegularColgroup cols={6}></RegularColgroup>}>
          <div className={classes.sub}>
            <Grid container>
              <Grid item xs={6}>
                <Button onClick={() => onAddRows(tab)}>어학 추가</Button>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}></Grid>
            </Grid>
            {data.length > 0 &&
              data.map((x, index) => {
                return (
                  <>
                    <Divider style={{ margin: "15px 5px 15px 5px" }}></Divider>
                    <Grid container>
                      <Grid item xs={4}>
                        <EndTextField
                          label="어학 시험명"
                          name="certification"
                          value={x.certification}
                          style={{ width: "210px" }}
                          onChange={(e) => onChangeText(tab, e, index)}
                        ></EndTextField>
                      </Grid>
                      <Grid item xs={4}>
                        <EndTextField
                          label="자격번호"
                          name="registrationNo"
                          value={x.registrationNo}
                          style={{ width: "210px" }}
                          onChange={(e) => onChangeText(tab, e, index)}
                        ></EndTextField>
                      </Grid>
                      <Grid item xs={3}>
                        <EndTextField
                          label="응시일"
                          name="tested"
                          type="date"
                          value={x.tested !== null ? x.tested.split("T")[0] : ""}
                          style={{ width: "210px" }}
                          onChange={(e) => onChangeText(tab, e, index)}
                          shrink={true}
                        ></EndTextField>
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton aria-label="delete" color="secondary" onClick={() => onRemoveRows(tab, index)}>
                          <ClearIcon />
                        </IconButton>
                      </Grid>
                      <Grid item xs={2}>
                        <EndTextField
                          label="취득점수"
                          name="score"
                          value={x.score}
                          style={{ width: "105px" }}
                          onChange={(e) => onChangeText(tab, e, index)}
                        ></EndTextField>
                      </Grid>
                      <Grid item xs={2}>
                        <EndTextField
                          label="만점기준"
                          name="maxScore"
                          value={x.maxScore}
                          style={{ width: "105px" }}
                          onChange={(e) => onChangeText(tab, e, index)}
                        ></EndTextField>
                      </Grid>
                      <Grid item xs={4}>
                        <SelectCombobox
                          label="증빙서류"
                          name="evidence"
                          style={{ width: "210px" }}
                          empty={false}
                          value={x.evidence}
                          options={[
                            { key: "미제출", value: "미제출" },
                            { key: "제출", value: "제출" },
                            { key: "파일첨부", value: "파일첨부" },
                            { key: "기타", value: "기타" },
                          ]}
                          onChange={(e) => onChangeSelect(tab, e, index)}
                        ></SelectCombobox>
                      </Grid>
                      <Grid item xs={4}>
                        {x.evidence === "파일첨부" && (
                          <input
                            type="file"
                            style={{ marginTop: "20px" }}
                            onChange={(e) => onChangeFile(tab, e, index)}
                          ></input>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <EndTextField
                          label="비고"
                          name="remark"
                          value={x.remark}
                          style={{ width: "850px" }}
                          onChange={(e) => onChangeText(tab, e, index)}
                        ></EndTextField>
                      </Grid>
                    </Grid>
                  </>
                );
              })}
          </div>
        </TableInsaCard>
      </div>
    </div>
  );
}

export default InputLanguageData;
