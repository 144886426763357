import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SettingsIcon from "@material-ui/icons/Settings";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import PrintIcon from "@material-ui/icons/Print";
import PeopleIcon from "@material-ui/icons/People";
import BallotIcon from "@material-ui/icons/Ballot";
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import FolderSharedIcon from "@material-ui/icons/FolderShared";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import EditIcon from "@material-ui/icons/Edit";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import TextsmsIcon from "@material-ui/icons/Textsms";
import { Link } from "react-router-dom";
import { useUserState } from "src/system/UserContext";
import { SECONDARY } from "src/Style/theme";
import PublicIcon from "@material-ui/icons/Public";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      "& a": {
        paddingLeft: "24px",
      },
    },
    drawerPaper: {
      width: drawerWidth + 2,
      fontSize: "20px",
      textAlign: "center",
      backgroundColor: SECONDARY,
      color: "white",
      "& a:hover": {
        color: "white",
        backgroundColor: SECONDARY,
        filter: "brightness(1.2)",
      },
      "& svg": {
        color: "white",
      },
    },
    drawerHeader: {
      width: drawerWidth,
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    presentation: {
      //width: "100vw",
      height: "90vh",
      position: "absolute",
      zIndex: 1,
    },
    presentationShift: {
      pointerEvents: "none",
    },
  })
);

interface PersistentDrawerLeftProps {
  open: boolean;
  handleDrawerClose: () => void;
}

export default function PersistentDrawerLeft({
  open,
  handleDrawerClose,
}: PersistentDrawerLeftProps) {
  const classes = useStyles();
  const user = useUserState();
  const admin = user.role === "admin";
  const part = user.role.includes("파트장");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const team = user.role.includes("팀장");

  return (
    <>
      <CssBaseline />
      <div
        style={{ height: "90vh", position: "absolute" }}
        role="presentation"
        //onClick={toggleDrawer(false)}
        //onKeyDown={toggleDrawer(false)}
        className={open ? classes.presentation : classes.presentationShift}
      >
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}></div>
          <Divider />
          <List>
            {/* 인사만사 사용자 기능 */}
            <ListItem button key={"인사카드"} component={Link} to="/card">
              <ListItemIcon>
                <FolderSharedIcon></FolderSharedIcon>
              </ListItemIcon>
              <ListItemText primary={"인사카드"} />
            </ListItem>
            <ListItem
              button
              key={"조직도"}
              component={Link}
              to="/organization/view"
            >
              <ListItemIcon>
                <PeopleIcon></PeopleIcon>
              </ListItemIcon>
              <ListItemText primary={"조직도"} />
            </ListItem>

            <ListItem
              button
              key={"attendance"}
              component={Link}
              to="/attendance"
            >
              <ListItemIcon>
                <CalendarTodayIcon></CalendarTodayIcon>
              </ListItemIcon>
              <ListItemText primary={"통합근태"} />
            </ListItem>

            <ListItem button key={"오늘의 근태"} component={Link} to="/today-attendance">
              <ListItemIcon>
                <RecentActorsIcon></RecentActorsIcon>
              </ListItemIcon>
              <ListItemText primary={"오늘의 근태"} />
            </ListItem>

            <ListItem button key={"Evidence"} component={Link} to="/evidence">
              <ListItemIcon>
                <PrintIcon></PrintIcon>
              </ListItemIcon>
              <ListItemText primary={"증빙서류 발급"} />
            </ListItem>
          </List>
          {/* 다른 사이트 링크 */}
          <Divider />
          <List>
            <ListItem
              button
              key={"InBody Portal"}
              component="a"
              href="https://weareinbody.com/"
              target="_blank"
            >
              <ListItemIcon>
                <PublicIcon></PublicIcon>
              </ListItemIcon>
              <ListItemText primary={"InBody Portal"} />
            </ListItem>
            <ListItem
              button
              key={"사업계획"}
              component="a"
              href="https://plan.weareinbody.com/"
              target="_blank"
            >
              <ListItemIcon>
                <BallotIcon></BallotIcon>
              </ListItemIcon>
              <ListItemText primary={"사업계획"} />
            </ListItem>
            <ListItem
              button
              key={"다면평가"}
              component="a"
              href="https://360degree.weareinbody.com/"
              target="_blank"
            >
              <ListItemIcon>
                <RecentActorsIcon></RecentActorsIcon>
              </ListItemIcon>
              <ListItemText primary={"다면평가"} />
            </ListItem>
            <ListItem
              button
              key={"InBody Q&A"}
              component="a"
              href="https://inbodykr.sharepoint.com/sites/InBodyQNA"
              target="_blank"
            >
              <ListItemIcon>
                <LiveHelpIcon></LiveHelpIcon>
              </ListItemIcon>
              <ListItemText primary={"회사생활 가이드"} />
            </ListItem>
            {(part || admin) && (
              <ListItem
                button
                key={"면담록"}
                component="a"
                href="https://insamansa.weareinbody.com/"
                target="_blank"
              >
                <ListItemIcon>
                  <TextsmsIcon></TextsmsIcon>
                </ListItemIcon>
                <ListItemText primary={"면담록"} />
              </ListItem>
            )}
          </List>
          <Divider />
          {/* 인사만사 관리자 기능 */}
          <List>
            {admin && (
              <>
                <ListItem
                  button
                  key={"관리자"}
                  component={Link}
                  to="/manage-hr/employee"
                >
                  <ListItemIcon>
                    <SettingsIcon></SettingsIcon>
                  </ListItemIcon>
                  <ListItemText primary={"관리자"} />
                </ListItem>
                <ListItem
                  button
                  key={"인사등록"}
                  component={Link}
                  to="/manage-hr/employeeAdd"
                >
                  <ListItemIcon>
                    <PersonAddIcon></PersonAddIcon>
                  </ListItemIcon>
                  <ListItemText primary={"인사등록"} />
                </ListItem>
                <ListItem
                  button
                  key={"근태관리"}
                  component={Link}
                  to="/attendance/admin"
                >
                  <ListItemIcon>
                    <EventAvailableIcon></EventAvailableIcon>
                  </ListItemIcon>
                  <ListItemText primary={"근태관리"} />
                </ListItem>
                {/* <ListItem
                  button
                  key={"에디터 테스트"}
                  component={Link}
                  to="/test"
                >
                  <ListItemIcon>
                    <EditIcon></EditIcon>
                  </ListItemIcon>
                  <ListItemText primary={"에디터 테스트"} />
                </ListItem> */}
              </>
            )}
          </List>
          <Divider />
        </Drawer>
      </div>
    </>
  );
}
