import { TextField } from "@material-ui/core";
import Autocomplete, { AutocompleteChangeDetails, AutocompleteChangeReason } from "@material-ui/lab/Autocomplete";
import React, { Fragment, ReactElement } from "react";
import { useCallback } from "react";
import { Row, Col } from "react-bootstrap";

export interface ComboboxItem {
  key: string;
  value: string;
}

export interface DefaultComboboxProps {
  label?: string;
  options: ComboboxItem[];
}
interface ComboboxProps extends DefaultComboboxProps {
  name?: string;
  userSelected?: ComboboxItem | undefined;
  onChange?: (
    event: React.ChangeEvent<{}>,
    value: ComboboxItem | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<ComboboxItem> | undefined
  ) => void;
}

export function TitleOneComboBox({ name, label, options, userSelected, onChange }: ComboboxProps): ReactElement {
  const getOptionSelected = useCallback((option: ComboboxItem, value: ComboboxItem): boolean => {
    if (option === undefined || option.key === undefined) return false;
    return option.key === value.key;
  }, []);

  return (
    <Row className="d-flex align-items-center justify-content-between">
      {label && <Col className="col-md-3">{label}&nbsp;</Col>}
      <Col>
        <Autocomplete
          id={`combo-box-${name}`}
          value={userSelected}
          options={options}
          onChange={onChange}
          fullWidth
          getOptionLabel={(option) => option.value}
          getOptionSelected={getOptionSelected}
          renderInput={(params) => (
            <Fragment>
              <TextField {...params} fullWidth label={label} variant="outlined" />
            </Fragment>
          )}
        />
      </Col>
    </Row>
  );
}
