import MaterialTable from "material-table";
import React, { ReactElement } from "react";
import { ConstantApi } from "src/system/ApiService";
import { TABLE_HEADER } from "src/system/Constants";
import { LMATERIAL_TABLE } from "src/system/Localization";
import {
  Constant,
  CONSTANT_TABLE,
  CONSTANT_TABLE_COLUMN,
} from "src/system/types";

interface ConstantTableGridProps {
  table: string;
  data: Constant[];
  refreshData: () => void;
}

function ConstantTableGrid({
  table,
  data,
  refreshData,
}: ConstantTableGridProps): ReactElement {
  const ref = React.createRef<MaterialTable<Constant>>();

  return (
    <div style={{ maxWidth: "100%" }}>
      <MaterialTable
        ref={ref}
        options={{
          //toolbar: false,
          actionsColumnIndex: -1,
          search: true,
          showTitle: false,
          padding: "dense",
          paging: true,
          pageSize: 15,
          filtering: true,
          headerStyle: TABLE_HEADER,
        }}
        editable={{
          onRowAdd: (newData) => {
            return ConstantApi.UpsertConstant({
              ...newData,
              id: 0,
              tableName: table,
            }).then(() => refreshData());
          },
          onRowUpdate: (newData, oldData) => {
            return ConstantApi.UpsertConstant(newData).then((res) => {
              refreshData();
            });
          },
          onRowDelete: (oldData) => {
            return ConstantApi.DeleteConstant(oldData.id).then((res) => {
              refreshData();
            });
          },
        }}
        columns={[
          { title: "ID", field: "id", editable: "never", filtering: false },
          {
            title: "테이블명",
            field: "tableName",
            lookup: CONSTANT_TABLE,
            editable: "never",
            filtering: false,
          },
          {
            title: "항목명",
            field: "columnName",
            lookup: CONSTANT_TABLE_COLUMN[table],
          },
          { title: "이름", field: "name" },
          {
            title: "표시순서",
            field: "order",
            type: "numeric",
            filtering: false,
          },
        ]}
        data={data}
        localization={LMATERIAL_TABLE}
      ></MaterialTable>
    </div>
  );
}

export default ConstantTableGrid;
