export type StringFormat =
  'YYYY-MM'
  |'YYYY-MM-DD'
  | 'YYYY-MM-DD(W)'
  | 'YYYY-MM-DD hh:mm:ss'
  | 'MM-DD'
  | 'MM/DD'
  | 'hh:mm';

export const useDateHooks = () => {

  /**
   * @public
   * 날짜 format 변환용 함수
   */
  const getDateStringFormat = (
    date: Date | string | undefined,
    format: StringFormat
  ): string => {
    if (!date) return '';

    const dateFormatValue = typeof (date) === 'string' ? new Date(date) : date;

    const yyyy = dateFormatValue.getFullYear();
    const MM = String(dateFormatValue.getMonth() + 1).padStart(2, '0');
    const dd = String(dateFormatValue.getDate()).padStart(2, '0');
    const hh = String(dateFormatValue.getHours()).padStart(2, '0');
    const mm = String(dateFormatValue.getMinutes()).padStart(2, '0');
    const ss = String(dateFormatValue.getSeconds()).padStart(2, '0');

    const week = weekToKRString(dateFormatValue.getDay());

    switch (format) {
      case 'hh:mm':
        return `${hh}:${mm}`;
      case 'MM-DD':
        return `${MM}-${dd}`;
      case 'MM/DD':
        return `${MM}/${dd}`;
      case 'YYYY-MM':
        return `${yyyy}년 ${MM}월`;
      case 'YYYY-MM-DD':
        return `${yyyy}-${MM}-${dd}`;
      case 'YYYY-MM-DD(W)':
        return `${yyyy}-${MM}-${dd} (${week})`;
      case 'YYYY-MM-DD hh:mm:ss':
        return `${yyyy}-${MM}-${dd} ${hh}:${mm}:${ss}`;
      default:
        return '';
    }
  }

  const weekToKRString = (week : number) => {
    switch (week) {
      case 0:
        return '일';
      case 1:
        return '월';
      case 2:
        return '화';
      case 3:
        return '수';
      case 4:
        return '목';
      case 5:
        return '금';
      case 6:
        return '토';

    }
  }

  /**
   * @public
   * 점심시간(12시 ~ 1시)를 고려해서 시간 term 계산
   * @param startTime Date
   * @param endTime Date
   * @return string
   */
  const getEndTime_ConsiderLunchTime = (startTime: Date, endTime: Date) => {
    if ((startTime.getHours() < 12 || (startTime.getHours() === 12 && startTime.getMinutes() === 0)) && endTime.getHours() >= 12) {
      endTime.setHours(endTime.getHours() + 1);
    }

    if (startTime.getHours() === 12 && startTime.getMinutes() > 0) {
      const diffMins = 60 - startTime.getMinutes();
      endTime.setMinutes(endTime.getMinutes() + diffMins);
    }

    return `${endTime.getHours().toString().padStart(2, '0')}:${endTime.getMinutes().toString().padStart(2, '0')}`;
  }

  /** @public 시간 string을 date로 변환 - 날짜는 임의로 부여
   * @param timeString
   * @returns
   */
  const hourStringParseToDate = (timeString: string) => {
    const [hours, minutes] = timeString.split(":").map(Number);
    return new Date(2023, 0, 1, hours, minutes)
  }

  return {
    getDateStringFormat,
    getEndTime_ConsiderLunchTime,
    hourStringParseToDate,
  }
}
