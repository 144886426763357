import React, { ReactElement } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import InterviewRoute from "src/Route/InterviewRoute";
import AftercareList from "src/page/AftercareList";
import { default as AdminPage } from "src/page/manage/Index";
import AdminRoute from "../AdminRoute";
import { InterviewRecordRoutes } from "../../system/types/type";
import IssueRoute from "../IssueRoute";

function MainRoute(): ReactElement {
  return (
    <Switch>
      <Route path={InterviewRecordRoutes.interview}>
        <InterviewRoute></InterviewRoute>
      </Route>
      <Route path={InterviewRecordRoutes.issue}>
        <IssueRoute></IssueRoute>
      </Route>
      <AdminRoute
        path={InterviewRecordRoutes.manage}
        exact
        Component={() => <Redirect to={`/manage/interview-category`}></Redirect>}
      />
      <AdminRoute path={`${InterviewRecordRoutes.manage}/:tab`} exact Component={AdminPage} />
      <Route path={InterviewRecordRoutes.root}>
        <AftercareList></AftercareList>
      </Route>
    </Switch>
  );
}

export default MainRoute;
