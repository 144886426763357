import React, { ReactElement, useState } from "react";
import { Avatar, Button, Chip, Typography } from "@material-ui/core";
import { MyStyle } from "src/Style/theme";
import TableAttendance from "./TableAttendance";
import DatePicker from "src/components/DatePicker";
import { Alert, AlertTitle } from "@material-ui/lab";
import { EmployeeAutoComplete } from "src/system/types";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
interface PersonalAttendanceProps {
  employee: EmployeeAutoComplete;
  isAdmin: boolean;
}

function PersonalAttendance({
  employee,
  isAdmin,
}: PersonalAttendanceProps): ReactElement {
  const [selectedDate, setDateChange] = useState(new Date());
  const now = new Date();
  const classes = MyStyle();

  const onDateChange = (date: Date | null) => {
    if (date) {
      setDateChange(date);
    }
  };

  const onLeftClick = () => {
    setDateChange(
      new Date(selectedDate.getFullYear(), selectedDate.getMonth() - 1, 1)
    );
  };

  const onRightClick = () => {
    setDateChange(
      new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 1)
    );
  };

  return (
    <>
      <div className={classes.paperMainTitle}>
        <div className="text-center">
          <Button
            style={{ padding: "0px" }}
            disabled={
              isAdmin
                ? false
                : new Date(
                    now.getFullYear(),
                    now.getMonth() - 1,
                    1
                  ).getMonth() >= selectedDate.getMonth()
            }
            onClick={onLeftClick}
          >
            <ArrowLeftIcon style={{ marginTop: "5px" }}></ArrowLeftIcon>
          </Button>
          <DatePicker
            views={["year", "month"]}
            openTo="month"
            format="yyyy년  M월"
            minDate={
              isAdmin
                ? `${new Date("2020-01-01")}`
                : `${new Date().getFullYear()}-${new Date().getMonth()}-01`
            }
            maxDate={
              isAdmin
                ? `${new Date("2030-01-01")}`
                : `${new Date().getFullYear() + 2}-${new Date().getMonth()}-01`
            }
            value={selectedDate}
            onChange={onDateChange}
            style={{ textAlign: "center", width: "120px" }}
          />
          <Button style={{ padding: "0px" }} onClick={onRightClick}>
            <ArrowRightIcon style={{ marginTop: "5px" }}></ArrowRightIcon>
          </Button>
        </div>
        <hr />

        {/* 근태 관리 표 */}
        <TableAttendance
          date={selectedDate}
          employee={employee}
        ></TableAttendance>
        <br></br>

        {/* 근태 관리 보는 방법 */}
        <Alert severity="warning" icon={false}>
          <AlertTitle>
            <Typography variant="h6">※ 근태 관리(캘린더)</Typography>
          </AlertTitle>
          <Typography variant="body1">
            1. 지문인식(카드인식)에 따라 실시간으로 반영되며, 표시 되는 시각은
            해당 일에 지문(카드)이 태그 된 "출근시간" ~ "퇴근시간"입니다.
          </Typography>
          <Typography variant="body1">
            2.
            <Chip
              size="small"
              label="야근식대"
              style={{
                marginLeft: "10px",
                marginRight: "5px",
                backgroundColor: "deepskyblue",
                color: "white",
                boxShadow: "#999999 1px 1px 1px",
              }}
            ></Chip>
            : 오후 8시 이후 퇴근 중 지문인식기의 "퇴근"버튼을 누르고
            지문(카드)을 태그 한 경우입니다.
          </Typography>
          <Typography variant="body1">
            3.
            <Chip
              size="small"
              label="지각"
              color="secondary"
              style={{
                marginLeft: "10px",
                marginRight: "5px",
                boxShadow: "#999999 1px 1px 1px",
              }}
            ></Chip>
            : ① 최초 지문(카드)이 태그 된 시간이 오전 9시 또는 그 이후 혹은 ②
            지문(카드)이 태그 된 기록이 없거나 별도의 통합근태신청서도 상신이
            되지 않은 경우입니다.
          </Typography>
          <Typography variant="body1">
            4. 결재가 완료된 통합근태신청서 내역만 표시되며, 동일한 일자에 여러
            건의 통합근태신청서를 상신하셨을 경우 가장 최근에 올려주신 내역이
            표시됩니다.
            <Chip
              size="small"
              avatar={<Avatar>GW</Avatar>}
              label={"통합근태신청서"}
              style={{
                fontSize: "10px",
                marginLeft: "5px",
                marginRight: "5px",
                boxShadow: "#999999 1px 1px 1px",
                backgroundColor: "lightgray",
              }}
            />
            : ① 기안한 통합근태신청서
            <Chip
              size="small"
              avatar={<Avatar>GW</Avatar>}
              label={"통합근태신청서"}
              color="primary"
              style={{
                fontSize: "10px",
                marginLeft: "5px",
                marginRight: "5px",
                boxShadow: "#999999 1px 1px 1px",
              }}
            />
            : ② 승인된 통합근태신청서
          </Typography>
          <br></br>
          <Typography variant="body1">
            * 지문(카드) 태그는 <strong>근무 중인 층</strong>의 기록만 인정
            됩니다.
          </Typography>
        </Alert>
      </div>
    </>
  );
}

export default PersonalAttendance;
