import { TextField } from "@material-ui/core";
import React, { ReactElement } from "react";
import { Row, Col } from "react-bootstrap";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { ComboboxItem, DefaultComboboxProps } from "./TitleOneComboBox";
import Autocomplete, { AutocompleteChangeDetails, AutocompleteChangeReason } from "@material-ui/lab/Autocomplete";
import { useCallback } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 500, //to-do
      "& > * + *": {
        marginTop: theme.spacing(3),
      },
    },
  })
);

interface MultipleComboboxProps extends DefaultComboboxProps {
  userSelected?: ComboboxItem[];
  onChange?: (
    event: React.ChangeEvent<{}>,
    value: ComboboxItem[] | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<ComboboxItem> | undefined
  ) => void;
}

function TitleMultipleComboBox({ label, options, userSelected, onChange }: MultipleComboboxProps): ReactElement {
  const classes = useStyles();
  const getOptionSelected = useCallback((option: ComboboxItem, value: ComboboxItem): boolean => {
    if (option === undefined || option.key === undefined) return false;
    return option.key === value.key;
  }, []);

  return (
    <Row className="d-flex align-items-center justify-content-between">
      {/* {title && <Col className="col-md-4">{title}:&nbsp;</Col>} */}
      <Col className={classes.root}>
        <Autocomplete
          multiple
          value={userSelected}
          options={options}
          fullWidth
          getOptionLabel={(option) => option.value}
          onChange={onChange}
          getOptionSelected={getOptionSelected}
          renderInput={(params) => <TextField {...params} fullWidth label={label} />}
        />
      </Col>
    </Row>
  );
}

export default TitleMultipleComboBox;
