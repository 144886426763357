import { Grid, Typography } from "@material-ui/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { MyStyle } from "src/Style/theme";
import { ErrorHandler, EvidenceApi } from "src/system/ApiService";
import { EvidenceContent, InitEvidenceContent } from "src/system/types";

interface CertificateOfEmploymentPageProps {
  id: number;
}

function CertificateOfEmploymentPage({ id }: CertificateOfEmploymentPageProps) {
  const [data, setData] = useState<EvidenceContent>(InitEvidenceContent);
  const printRef = useRef<HTMLDivElement>(null);
  const classes = MyStyle();
  const date = new Date();

  const onLoad = useCallback(() => {
    EvidenceApi.GetEvidence(id)
      .then((res) => {
        EvidenceApi.GetEvidenceContent(id, "재직증명서")
          .then((res2) => {
            setData(res2.data);
            // 인쇄
            window.print();
          })
          .catch((error) => {
            let msg = ErrorHandler(error);
            alert(msg);
          });
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      });
  }, [id]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <>
      <div
        ref={printRef}
        id="print-section"
        style={{
          backgroundColor: "white",
          margin: "80px",
          width: "882px",
          height: "1247px",
          fontFamily: "굴림체",
        }}
      >
        {/* 인쇄 영역 */}
        <Grid container>
          <Grid item xs={12} style={{ marginTop: "0px" }}>
            <Typography
              variant="subtitle1"
              style={{ fontSize: "18px", fontFamily: "굴림체" }}
            >{`제 ${date.getFullYear()}${date.getMonth() > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`} - ${
              data.no
            } 호`}</Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "170px" }}>
            <Typography variant="h3" align="center" style={{ fontFamily: "굴림체" }}>
              재 직 증 명 서
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "100px" }}>
            <Typography variant="h6" align="left" style={{ fontFamily: "굴림체" }}>
              1. 인적사항
            </Typography>
            <table className={classes.printTable} style={{ border: "3px solid black" }}>
              <colgroup>
                <col width="25%"></col>
                <col width="25%"></col>
                <col width="25%"></col>
                <col width="25%"></col>
              </colgroup>
              <tr>
                <th>성 명</th>
                <td>{data.name}</td>
                <th>주민등록번호</th>
                <td>{data.residenceNumber}</td>
              </tr>
              <tr>
                <th>주 소</th>
                <td colSpan={3}>{data.address}</td>
              </tr>
            </table>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "100px" }}>
            <Typography variant="h6" align="left" style={{ fontFamily: "굴림체" }}>
              2. 재직사항
            </Typography>
            <table className={classes.printTable} style={{ border: "3px solid black" }}>
              <colgroup>
                <col width="25%"></col>
                <col width="25%"></col>
                <col width="25%"></col>
                <col width="25%"></col>
              </colgroup>
              <tr>
                <th>회사명</th>
                <td>인바디</td>
                <th>사업자번호</th>
                <td>220-81-13603</td>
              </tr>
              <tr>
                <th>소 속</th>
                <td>{data.team}</td>
                <th>직 위</th>
                <td>{data.jobPosition}</td>
              </tr>
              <tr>
                <th>재직기간</th>
                <td colSpan={3}>{data.term}</td>
              </tr>
              <tr>
                <th>발급용도 / 제출처</th>
                <td colSpan={3}>{data.usage}</td>
              </tr>
            </table>
          </Grid>
          <div
            style={{
              width: "100%",
              backgroundImage: `url("/images/inbodySigniture.PNG")`,
            }}
          >
            <Grid item xs={12} style={{ marginTop: "100px" }}>
              <Typography variant="h5" align="center" style={{ fontFamily: "굴림체" }}>
                상기와 같이 재직하고 있음을 증명합니다.
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "100px" }}>
              <Typography variant="h5" align="center" style={{ fontFamily: "굴림체" }}>
                {`${date.getFullYear()}년  ${date.getMonth() + 1}월  ${date.getDate()}일`}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "40px", fontSize: "2rem" }}>
              <Typography variant="h4" align="center" style={{ fontFamily: "굴림체" }}>
                주식회사 인바디
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "40px", fontSize: "2rem" }}>
              <Typography variant="h4" align="center" style={{ fontFamily: "굴림체" }}>
                대표이사 차기철, 이라미
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "20px", minHeight: "100px" }}></Grid>
          </div>
        </Grid>
      </div>
    </>
  );
}

export default CertificateOfEmploymentPage;
