import { Theme, Typography, createStyles, makeStyles } from "@material-ui/core";
import React from "react";
import { PRIMARY } from "src/system/Constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headTitleBar: {
      borderLeft: "8px solid",
      borderColor: PRIMARY,
      paddingLeft: "5px",
      fontWeight: "bold",
      fontSize: "18px",
      marginBottom: "5px",
    },
  })
);

interface SubTitleBarProps {
  title: string;
}

function SubTitleBar({ title }: SubTitleBarProps) {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h6" component="h3" className={classes.headTitleBar}>
        {title}
      </Typography>
    </>
  );
}

export default SubTitleBar;
