import React, { ReactElement } from "react";
import { Switch, Route } from "react-router-dom";
import MainPage from "src/page/qna/MainPage";
import { QNARoutes } from "../../system/types/type";
import ViewPage from "../../page/qna/ViewPage";
import EditPage from "src/page/qna/EditPage";

function MainRoute(): ReactElement {
  return (
    <Switch>
      <Route path={QNARoutes.root} exact>
        <MainPage></MainPage>
      </Route>
      <Route path={`${QNARoutes.view}/:id?`}>
        <ViewPage></ViewPage>
      </Route>
      <Route path={`${QNARoutes.edit}/:id?`}>
        <EditPage></EditPage>
      </Route>
    </Switch>
  );
}

export default MainRoute;
