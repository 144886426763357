import { createContext, Dispatch, useContext, useReducer } from "react";
import {
  Aftercare,
  DetailCare,
  Interview,
  ViewPageData,
  initViewPage,
  initInterview,
  User,
  Issue,
  InitIssue,
} from "./types";

const InterviewStateContext = createContext<ViewPageData | undefined>(undefined);

type Action =
  | { type: "CHANGE-INTERVIEW"; item: Interview }
  | { type: "CHANGE-AFTERCARE"; id: number; item: Aftercare }
  | { type: "CHANGE-DETAILCARE"; parentId: number; id: number; item: DetailCare }
  | { type: "CHANGE-ISSUE"; item: Issue }
  | { type: "ADD-AFTERCARE"; item: Aftercare }
  | { type: "ADD-DETAILCARE"; item: DetailCare; parentId: number }
  | { type: "ADD-ISSUE"; item: Issue }
  | { type: "REMOVE-ISSUE" }
  | { type: "REMOVE-AFTERCARE"; id: number }
  | { type: "REMOVE-DETAILCARE"; id: number; parentId: number }
  | { type: "EDIT-INIT"; item: ViewPageData }
  | { type: "WRITE-INIT-INTERVIEW"; item: User }
  | { type: "REMOVE-FILE" }
  | { type: "ADD-FILE" }
  | { type: "WRITE-INIT-ISSUE"; item: User };

type InterviewDispath = Dispatch<Action>;
const InterviewDispatchContext = createContext<InterviewDispath | undefined>(undefined);

function InterviewReducer(state: ViewPageData, action: Action): ViewPageData {
  switch (action.type) {
    case "REMOVE-FILE": {
      return {
        ...state,
        isFile: "Deleted",
      };
    }
    case "ADD-AFTERCARE":
      return {
        ...state,
        aftercares: state.aftercares.concat(action.item),
      };
    case "ADD-DETAILCARE": {
      return {
        ...state,
        aftercares: state.aftercares.map((aftercare) =>
          aftercare.id === action.parentId
            ? //새로운 detailcare를 추가해야함
              {
                ...aftercare,
                detailcares: aftercare.detailcares.concat(action.item),
              }
            : aftercare
        ),
      };
    }
    case "ADD-ISSUE": {
      return {
        ...state,
        interview: {
          ...state.interview,
          issueId: action.item.id,
        },
        issue: {
          ...action.item,
        },
      };
    }
    case "REMOVE-AFTERCARE": {
      return { ...state, aftercares: state.aftercares.filter((x) => x.id !== action.id) };
    }
    case "REMOVE-DETAILCARE": {
      return {
        ...state,
        aftercares: state.aftercares.map((aftercare) =>
          aftercare.id === action.parentId
            ? // detailcare를 지워야함
              {
                ...aftercare,
                detailcares: aftercare.detailcares.filter((x) => x.id !== action.id),
              }
            : aftercare
        ),
      };
    }
    case "REMOVE-ISSUE": {
      return {
        ...state,
        interview: {
          ...state.interview,
          issueId: undefined,
        },
        issue: null,
      };
    }
    case "CHANGE-INTERVIEW": {
      return { ...state, interview: action.item };
    }
    case "CHANGE-AFTERCARE": {
      //실수로 배열을 바꾸지 않게...
      return {
        ...state,
        aftercares: state.aftercares.map((aftercare) =>
          aftercare.id === action.id
            ? {
                ...aftercare,
                ...action.item,
                detailcares: aftercare.detailcares.map((x) => x),
              }
            : aftercare
        ),
      };
    }
    case "CHANGE-ISSUE": {
      return {
        ...state,
        issue: {
          ...action.item,
        },
      };
    }
    case "CHANGE-DETAILCARE": {
      return {
        ...state,
        aftercares: state.aftercares.map((aftercare) =>
          aftercare.id === action.parentId
            ? {
                ...aftercare,
                detailcares: aftercare.detailcares.map((x) =>
                  x.id === action.id
                    ? {
                        ...x,
                        ...action.item,
                      }
                    : x
                ),
              }
            : aftercare
        ),
      };
    }
    case "WRITE-INIT-ISSUE": {
      return {
        ...initViewPage,
        issue: {
          ...InitIssue,
          writer: {
            employeeId: action.item.employeeId,
            name: action.item.name,
            team: "",
          },
        },
      };
    }
    case "WRITE-INIT-INTERVIEW": {
      return {
        ...initViewPage,
        interview: {
          ...initInterview,
          writer: {
            name: action.item.name,
            employeeId: action.item.employeeId,
            team: "",
          },
          interviewer: {
            name: action.item.name,
            employeeId: action.item.employeeId,
            team: "",
          },
          created: new Date().getTime(),
          occurred: new Date().getTime(),
        },
      };
    }
    case "EDIT-INIT": {
      return {
        ...initViewPage,
        issue: action.item.issue,
        interview: action.item.interview,
        aftercares: action.item.aftercares,
        file: action.item.file,
      };
    }
    default:
      throw new Error("unhandled action");
  }
}

export function InterviewContextProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(InterviewReducer, initViewPage);
  return (
    <InterviewDispatchContext.Provider value={dispatch}>
      <InterviewStateContext.Provider value={state}>{children}</InterviewStateContext.Provider>
    </InterviewDispatchContext.Provider>
  );
}

//custom hook
export function useInterviewState() {
  const state = useContext(InterviewStateContext);
  if (!state) throw new Error("InterviewProvider not found");
  return state;
}

export function useInterviewDispatch() {
  const dispatch = useContext(InterviewDispatchContext);
  if (!dispatch) throw new Error("InverviewProvider not found");
  return dispatch;
}
