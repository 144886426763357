import { BalanceCategoryType } from '../data/BalanceCategory';

export interface UpdateAttendanceBalance{
  employeeId: string;
  category: string;
  term: string;
  createdBy: string;
  expired: string;
  id: number;
}

export interface CreateAttendanceBalance extends Omit<UpdateAttendanceBalance, 'id'>{
}

export interface GetAttendanceBalance {
  id: number;
  employeeId: string;
  employeeNo: string;
  category: BalanceCategoryType;
  term: string;
  createdBy: string;
  expired: string;
  created: string;
  updated: string;
}

export interface GetAttendanceBalanceCount{
  employeeId: string;
  yearOfService: string;
  annualLeave: number;
  alternativeLeave: number;
  specialLeave: number;
  usedAnnualLeave: number;
  usedAlternativeLeave: number;
  usedSpecialLeave: number;

  usedAnnualLeaveTemp: number;
  usedAnnualLeaveInProgress: number;
  usedAnnualLeaveApproved: number;

  usedAlternativeLeaveTemp: number;
  usedAlternativeLeaveInProgress: number;
  usedAlternativeLeaveApproved: number;

  usedSpecialLeaveTemp: number;
  usedSpecialLeaveInProgress: number;
  usedSpecialLeaveApproved: number;

  annualLeaveExpired: string | null;
  alternativeLeaveExpired: string | null;
  specialLeaveExpired: string | null;
}

export interface GetAttendanceBalanceForAdmin extends Omit<GetAttendanceBalance, 'employeeNo'>{
  name: string;
  available: boolean;
}

export const InitAttendanceBalanceCount: GetAttendanceBalanceCount = {
  alternativeLeave: 0,
  annualLeave: 0,
  employeeId: '',
  specialLeave: 0,
  usedAlternativeLeave: 0,
  usedAnnualLeave: 0,
  usedSpecialLeave: 0,
  yearOfService: '0개월(0일)',

  usedAnnualLeaveTemp: 0,
  usedAnnualLeaveInProgress: 0,
  usedAnnualLeaveApproved: 0,

  usedAlternativeLeaveTemp: 0,
  usedAlternativeLeaveInProgress: 0,
  usedAlternativeLeaveApproved: 0,

  usedSpecialLeaveTemp: 0,
  usedSpecialLeaveInProgress: 0,
  usedSpecialLeaveApproved: 0,

  annualLeaveExpired: '',
  alternativeLeaveExpired: '',
  specialLeaveExpired: '',
};

export interface GetSnapshotAttendanceBalance {
  id: number,
  employeeId: string,
  team: string,
  parentTeam: string,
  jobTitle: string,
  jobPosition: string,
  joined: string,
  dataAreaId: string,
  name: string,
  annualLeave: number,
  alternativeLeave: number,
  specialLeave: number,
  usedAnnualLeave: number,
  usedSpecialLeave: number,
  year: number,
  isLast: boolean,
  created: string
}