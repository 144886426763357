import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import React, { useEffect } from "react";
import { loginRequest } from "./AuthConfig";

function LoginRedirect() {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const inProgress = window.sessionStorage.getItem("msal_progress");

  useEffect(() => {
    if (!isAuthenticated) {
      window.sessionStorage.setItem("msal_progress", "true");
      instance
        .loginRedirect(loginRequest)
        .then((res) => {})
        .catch((error) => {
          console.error(error);
          window.sessionStorage.removeItem("msal_progress");
          alert("로그인 중 에러가 발생하였습니다.");
          window.location.replace("https://insamansa2.weareinbody.com");
        });
    }
  }, [inProgress, isAuthenticated, instance]);

  return <div>loginRedirect</div>;
}

export default LoginRedirect;
