import React, { ReactElement, useEffect, useState } from "react";
import ReactDataGrid, { Column } from "react-data-grid";
import { Education } from "src/system/types";
import { ErrorHandler, HREmployeeApi } from "src/system/ApiService";
import { getDisplayTime } from "src/system/Helper";

interface Props {
  employeeId: string;
}

function EducationDataGrid({ employeeId }: Props): ReactElement {
  const [data, setData] = useState<Education[]>([]);

  useEffect(() => {
    if (employeeId !== "") {
      // API 호출
      HREmployeeApi.GetEducationList(employeeId)
        .then((res) => {
          setData(res);
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          alert(msg);
        });
    }
  }, [employeeId]);

  const defaultColumnProperties = {
    width: 150,
  };

  const columns = React.useMemo(() => {
    const k = [
      {
        name: "교육분류",
        key: "kind",
      },
      {
        name: "외부/내부",
        key: "location",
      },
      {
        name: "교육명",
        key: "name",
      },
      {
        name: "분야",
        key: "field",
      },
      {
        name: "직능구분",
        key: "ability",
      },
      {
        name: "교육시작일",
        key: "started",
        formatter: (props: any) => {
          return <>{getDisplayTime(props.row.started)}</>;
        },
      },
      {
        name: "교육종료일",
        key: "ended",
        formatter: (props: any) => {
          return <>{getDisplayTime(props.row.ended)}</>;
        },
      },
      {
        name: "교육기관",
        key: "agency",
      },
      {
        name: "교육비",
        key: "cost",
      },
      {
        name: "증빙서류",
        key: "evidence",
      },
      {
        name: "R&R 문서번호",
        key: "rnrNo",
      },
      {
        name: "교육신청서 문서번호",
        key: "applicationNo",
      },
      {
        name: "교육기록부 문서번호",
        key: "reportNo",
      },
      {
        name: "비고",
        key: "remark",
      },
    ].map((c) => ({ ...c, ...defaultColumnProperties }));
    const nk: Column<Education>[] = k.map((x) => {
      if (x.key === "id") return x;
      else {
        return {
          ...x,
          // formatter: DefaultFormatter,
        } as Column<Education>;
      }
    });
    return nk;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ReactDataGrid
        rows={data}
        columns={columns}
        rowKeyGetter={(row) => {
          return row.id;
        }}
        defaultColumnOptions={{
          resizable: true,
        }}
        style={{ height: "200px" }}
      ></ReactDataGrid>
    </>
  );
}

export default EducationDataGrid;
