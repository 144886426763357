import { FormatterProps } from "react-data-grid";
import { ObjType } from "src/system/types";

export interface IData {
  error: ObjType;
  highlight: ObjType;
}

type MyFormatterProps<T> = React.PropsWithChildren<FormatterProps<T, unknown>>;

export function DefaultFormatter<T extends IData>(props: MyFormatterProps<T>) {
  const { column, row } = props;
  const val = (row as ObjType)[column.key] as string;
  if (Object.keys(row.error).length >= 1) {
    return (
      //for tooltip
      //see grid.css
      <div data-tooltip-text={row.error[column.key]}>{val || <>&nbsp;</>}</div>
    );
  } else {
    return <>{val || <>&nbsp;</>}</>;
  }
}

export function HighlightFormatter<T extends IData>(props: MyFormatterProps<T>) {
  const { column, row } = props;
  const val = (row as ObjType)[column.key] as string;
  if (Object.keys(row.highlight).length >= 1) {
    if (row.highlight[column.key] !== undefined) {
      return <div style={{ fontWeight: "bold", color: "#64b5f6" }}>{val || <>&nbsp;</>}</div>;
    } else {
      return <div>{val || <>&nbsp;</>}</div>;
    }
  } else {
    return <>{val || <>&nbsp;</>}</>;
  }
}

export function BitFormatter<T extends IData>({
  trueValue,
  falseValue,
  ...props
}: MyFormatterProps<T> & { trueValue: string; falseValue: string }) {
  const { column, row } = props;
  const obj = row as ObjType;
  if (typeof (row as ObjType)[column.key] === "boolean") {
    const val = ((row as ObjType)[column.key] as boolean) ? trueValue : falseValue;
    if (Object.keys(obj.error).length >= 1) {
      return (
        //for tooltip
        //see grid.css
        <div data-tooltip-text={obj.error[column.key]}>
          <>{val}</>
        </div>
      );
    } else {
      return <>{val}</>;
    }
  } else {
    return <DefaultFormatter {...props} />;
  }
}
