import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React, { ReactElement, useState } from "react";
import { ConstantApi, ErrorHandler } from "src/system/ApiService";
import { Constant, CONSTANT_TABLE_ARRAY, GridData } from "src/system/types";
import ConstantTableGrid from "./ConstantTableGrid";
export interface CState extends GridData {
  result: Constant[];
  count: number;
}

function MainPage(): ReactElement {
  const [constant, setConstant] = React.useState<CState>({
    result: [],
    count: 0,
  });

  const [table, setTable] = useState<string>("WORKRECORD");

  const onChangeTable = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const value = event.target.value as string;
    setTable(value);
  };

  const refreshConstant = React.useCallback(() => {
    ConstantApi.GetConstant(table, "")
      .then((res) => {
        setConstant(res);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      });
  }, [table]);

  React.useEffect(() => {
    refreshConstant();
  }, [refreshConstant]);

  return (
    <div>
      <Alert severity="info">
        <AlertTitle>도움말</AlertTitle>
        <ul>
          <li>인사정보에 들어갈 수 있는 값들을 설정합니다.</li>
        </ul>
      </Alert>
      {/* 인사정보 테이블 선택 */}
      <FormControl style={{ margin: "10px 0px 10px 20px" }}>
        <InputLabel id="select-placeholder">인사정보 테이블 선택</InputLabel>
        <Select name="year" value={table} onChange={onChangeTable} style={{ width: "200px" }}>
          {CONSTANT_TABLE_ARRAY.map((u) => (
            <MenuItem value={u.value} key={`tables-${u.name}-${u.value}`}>
              {u.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {/* 해당 테이블 항목들 */}
      <ConstantTableGrid table={table} data={constant.result} refreshData={refreshConstant}></ConstantTableGrid>
    </div>
  );
}

export default MainPage;
