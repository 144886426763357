import { Button, Paper, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React, { ReactElement } from "react";
import ReactDataGrid, { Column } from "react-data-grid";
import { HrStyle } from "src/Style/theme";
import { Career, ObjType } from "src/system/types";
import { DefaultFormatter, IData } from "./Common";
import { ExcelUploadEnum } from "./MainPage";
import PublishIcon from "@material-ui/icons/Publish";

export interface CareerUpload extends Career, IData {
  error: ObjType;
}

interface Props {
  data: CareerUpload[];
  errorCount: number;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  onInsertData: (type: ExcelUploadEnum) => void;
}

function CareerDataImportRef({
  data,
  errorCount,
  onSubmit,
  onInsertData,
}: Props): ReactElement {
  const classes = HrStyle();
  const columns = React.useMemo(() => {
    const k = [
      {
        name: "NUM",
        key: "id",
      },
      {
        name: "사번",
        key: "employeeId",
      },
      {
        name: "고용형태",
        key: "employmentForm",
      },
      {
        name: "회사명",
        key: "company",
      },
      {
        name: "입사일",
        key: "started",
      },
      {
        name: "퇴사일",
        key: "ended",
      },
      {
        name: "부서",
        key: "department",
      },
      {
        name: "직위",
        key: "jobPosition",
      },
      {
        name: "담당업무",
        key: "work",
      },
      {
        name: "직전연봉(만원)",
        key: "salary",
      },
      {
        name: "직무",
        key: "jobDuty",
      },
      {
        name: "퇴직사유",
        key: "retirementReason",
      },
    ];
    const nk: Column<CareerUpload>[] = k.map((x) => {
      if (x.key === "id") return x;
      else {
        return {
          ...x,
          formatter: DefaultFormatter,
        } as Column<CareerUpload>;
      }
    });
    return nk;
  }, []);

  const onClickUpload = () => onInsertData("career");

  return (
    <Paper variant="outlined" className={classes.paperUpload}>
      <form onSubmit={onSubmit} name="career">
        <input type="file" name="file" id="input-file" required />
        <input type="submit" />
        <input type="reset" />
      </form>
      <br />
      <Button
        onClick={onClickUpload}
        variant="contained"
        startIcon={<PublishIcon />}
      >
        데이터 업로드
      </Button>

      {errorCount > 0 && (
        <Alert severity="error" title="에러가 발생한 위치">
          <AlertTitle>에러가 발생한 위치</AlertTitle>
          <ul>
            {data
              .filter((x) => Object.keys(x.error).length >= 1)
              .map((x) => (
                <li>{x.id}</li>
              ))}
          </ul>
        </Alert>
      )}

      <div className="text-right">
        <Typography variant="body2">에러데이터 : {errorCount}건</Typography>
      </div>

      <ReactDataGrid
        rows={data}
        columns={columns}
        rowKeyGetter={(row) => {
          return row.id;
        }}
        rowClass={(row) => {
          if (Object.keys(row.error).length >= 1) {
            return "c-error-row";
          } else return undefined;
        }}
        defaultColumnOptions={{
          resizable: true,
        }}
        className={classes.reactDataGrid}
      ></ReactDataGrid>
    </Paper>
  );
}

export default CareerDataImportRef;
