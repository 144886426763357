import React, { ReactElement, useState } from "react";
import { Route, Switch } from "react-router-dom";
import Logout from "src/page/Logout";
import InterviewRecordMain from "src/Route/InterviewRecord/MainLayout";
import HRRecordMain from "src/Route/HRRecord/MainLayout";
import OrganizationMain from "src/Route/Organization/MainLayout";
import EvidenceMain from "src/Route/Evidence/MainLayout";
import TestMain from "src/Route/Test/MainLayout";
import MarMain from "src/Route/MAR/MainLayout";
import {
  AttendanceRoutes,
  CommonRoutes,
  EvidenceRoutes,
  InterviewRecordRoutes,
  OrganizationRoutes,
  QNARoutes,
  TestRoutes,
  TodayAttendanceRoutes,
} from "src/system/types";
import { MyStyle } from "../Style/theme";
import MainPage from "src/page/MainPage";
import AttendanceMain from "src/Route/Attendance/MainLayout";
import TodayAttendanceMain from "src/Route/TodayAttendance/MainLayout";
import Footer from "src/ViewParts/Footer";
import Header from "src/ViewParts/Header";
import Content from "src/ViewParts/Content";

function Routes(): ReactElement {
  const classes = MyStyle();
  const [open, setOpen] = useState<boolean>(true);

  return (
    <div className={classes.bodyWrap}>
      {/* Header */}
      <Header open={open} setOpen={setOpen}></Header>
      {/* Content - Switch */}
      <Content open={open}>
        <Switch>
          {/* 인사만사 메인 페이지 */}
          <Route path={CommonRoutes.root} component={MainPage} exact></Route>
          {/* 근태 관리 페이지 */}
          <Route
            path={AttendanceRoutes.root}
            component={AttendanceMain}
          ></Route>
          {/* 오늘의 근태 페이지 */}
          <Route path={TodayAttendanceRoutes.root} component={TodayAttendanceMain} exact></Route>
          {/* 조직도 페이지 */}
          <Route
            path={OrganizationRoutes.root}
            component={OrganizationMain}
          ></Route>
          {/* 증빙서류 발급 */}
          <Route path={EvidenceRoutes.root} component={EvidenceMain}></Route>
          {/* 인사총무 MAR 페이지 */}
          <Route path={QNARoutes.root} component={MarMain} />
          {/* 기존 면담록 시작 */}
          <Route
            path={InterviewRecordRoutes.interview}
            component={InterviewRecordMain}
          />
          <Route
            path={InterviewRecordRoutes.root}
            component={InterviewRecordMain}
          />
          <Route
            path={InterviewRecordRoutes.issue}
            component={InterviewRecordMain}
          />
          {/* 기존 면담록 끝 */}
          <Route path={CommonRoutes.logout}>
            <Logout></Logout>
          </Route>
          {/* 테스트 페이지 */}
          <Route path={TestRoutes.root} component={TestMain}></Route>
          {/* manage-hr Rotues */}
          <Route component={HRRecordMain}></Route>
        </Switch>
      </Content>
      {/*footer */}
      <Footer></Footer>
    </div>
  );
}

export default Routes;
