import { Checkbox, Divider, FormControlLabel, Grid, IconButton, TextField, Theme } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { createStyles, makeStyles } from "@material-ui/styles";
import React, { ChangeEvent, ReactElement, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { EndTextField } from "src/components/EndTextField";
import RegularColgroup from "src/components/RegularColgroup";
import TableInsaCard from "src/components/TableInsaCard";
import { ConstantApi, ErrorHandler } from "src/system/ApiService";
import { FamilyMember } from "src/system/types";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      // backgroundColor: "#eeeeee",
      width: "1000px",
      display: "inline-block",
      textAlign: "left",
    },
    sub: {
      padding: "20px",
      width: "100%",
      "& .MuiGrid-item": {
        height: "70px",
      },
    },
  })
);

interface InputFamilyDataProps {
  data: FamilyMember[];
  onAddRows: (tab: string) => void;
  onRemoveRows: (tab: string, index: number) => void;
  onChangeText: (tab: string, e: ChangeEvent<HTMLInputElement>, index: number) => void;
  onChangeCheck: (tab: string, e: ChangeEvent<HTMLInputElement>, index: number) => void;
  onChangeValue: (tab: string, name: string, value: string, index: number) => void;
  onChangeAutoComplete: (
    tab: string,
    event: React.ChangeEvent<{}>,
    value: string | null,
    name: string,
    index: number
  ) => void;
}

function InputFamilyData({
  data,
  onAddRows,
  onRemoveRows,
  onChangeText,
  onChangeCheck,
  onChangeValue,
  onChangeAutoComplete,
}: InputFamilyDataProps): ReactElement {
  const classes = useStyles();
  const tab = "family";
  const [val, setVal] = useState<string[]>([]);

  useMemo(() => {
    let relation: string[] = [""];
    ConstantApi.GetConstant("FAMILYRECORD", "")
      .then((res) => {
        res.result.forEach((x) => {
          if (x.columnName === "RELATION") {
            relation.push(x.name);
          }
        });
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      });
    setVal(relation);
  }, []);

  return (
    <div className={classes.main}>
      <TableInsaCard title="가족사항" colgroup={<RegularColgroup cols={6}></RegularColgroup>}>
        <div className={classes.sub}>
          <Grid container>
            <Grid item xs={6}>
              <Button onClick={() => onAddRows(tab)}>가족 추가</Button>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right" }}></Grid>
          </Grid>
          {data.length > 0 &&
            data.map((x, index) => {
              return (
                <>
                  <Divider style={{ margin: "15px 5px 15px 5px" }}></Divider>
                  <Grid container>
                    <Grid item xs={4}>
                      <Autocomplete
                        value={x.relation === null ? "" : x.relation}
                        onChange={(event, value) => {
                          if (value !== null) {
                            onChangeAutoComplete(tab, event, value, "relation", index);
                          }
                        }}
                        inputValue={x.relation}
                        onInputChange={(event, newInputValue) => {
                          if (newInputValue !== null) {
                            onChangeValue(tab, "relation", newInputValue, index);
                          }
                        }}
                        options={val}
                        style={{ width: 210 }}
                        renderInput={(params) => <TextField {...params} label="관계" />}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <EndTextField
                        label="이름"
                        name="name"
                        value={x.name}
                        style={{ width: "210px" }}
                        onChange={(e) => onChangeText(tab, e, index)}
                      ></EndTextField>
                    </Grid>
                    <Grid item xs={3}>
                      <EndTextField
                        label="생년월일"
                        name="birthday"
                        type="date"
                        value={x.birthday}
                        style={{ width: "210px" }}
                        onChange={(e) => onChangeText(tab, e, index)}
                        shrink={true}
                      ></EndTextField>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton aria-label="delete" color="secondary" onClick={() => onRemoveRows(tab, index)}>
                        <ClearIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={4}>
                      <EndTextField
                        label="연락처"
                        name="tel"
                        value={x.tel}
                        style={{ width: "210px" }}
                        onChange={(e) => onChangeText(tab, e, index)}
                      ></EndTextField>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={x.isDisability}
                            onChange={(e) => onChangeCheck(tab, e, index)}
                            name="isDisability"
                          />
                        }
                        label="장애여부"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={x.isForeign}
                            onChange={(e) => onChangeCheck(tab, e, index)}
                            name="isForeign"
                          />
                        }
                        label="외국인여부"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <EndTextField
                        label="비고"
                        name="remark"
                        value={x.remark}
                        style={{ width: "850px" }}
                        onChange={(e) => onChangeText(tab, e, index)}
                      ></EndTextField>
                    </Grid>
                  </Grid>
                </>
              );
            })}
        </div>
      </TableInsaCard>
    </div>
  );
}

export default InputFamilyData;
