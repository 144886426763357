import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { useUserState } from "src/system/UserContext";
import { AnnualLeave, AnnualLeaveType } from "../data/AnnualLeaveType";
import { useAttendanceHooks } from "../hooks/useAttendanceHooks";
import { GetAttendanceBalanceCount } from "../interface/BalanceInterface";
import { useBalanceStatusStyle } from "../style/useBalanceStatusStyle";
import BalanceStatusDialog from "./BalanceStatusDialog";

export interface BalanceStatusDialogState {
  open: boolean;
  totalCount: number;
  tempCount: number;
  inProgressCount: number;
  approvedCount: number;
  text: string;
}

export const initBalanceStatusDialogState: BalanceStatusDialogState = {
  open: false,
  totalCount: 0,
  tempCount: 0,
  inProgressCount: 0,
  approvedCount: 0,
  text: "",
};

export default function BalanceStatus() {
  const classes = useBalanceStatusStyle();
  const user = useUserState();
  const [userAttendanceBalanceCount, setUserAttendanceBalanceCount] =
    useState<GetAttendanceBalanceCount | null>(null);
  const [balanceStatusState, setBalanceStatusState] =
    useState<BalanceStatusDialogState>();

  const { getAttendanceBalanceCountData } = useAttendanceHooks();

  useEffect(() => {
    getAttendanceBalanceCountData(user.employeeId).then((data) =>
      setUserAttendanceBalanceCount(data)
    );
  }, [user.employeeId, getAttendanceBalanceCountData]);

  const changeDialogState = (type: AnnualLeaveType) => {
    if (userAttendanceBalanceCount) {
      switch (type) {
        case AnnualLeave.annualLeave:
          setBalanceStatusState({
            open: true,
            totalCount: userAttendanceBalanceCount.annualLeave,
            tempCount: userAttendanceBalanceCount.usedAnnualLeaveTemp,
            inProgressCount:
              userAttendanceBalanceCount.usedAnnualLeaveInProgress,
            approvedCount: userAttendanceBalanceCount.usedAnnualLeaveApproved,
            text: "연차",
          });
          break;
        case AnnualLeave.alternativeLeave:
          setBalanceStatusState({
            open: true,
            totalCount: userAttendanceBalanceCount.alternativeLeave,
            tempCount: userAttendanceBalanceCount.usedAlternativeLeaveTemp,
            inProgressCount:
              userAttendanceBalanceCount.usedAlternativeLeaveInProgress,
            approvedCount:
              userAttendanceBalanceCount.usedAlternativeLeaveApproved,
            text: "대체휴가",
          });
          break;
        case AnnualLeave.specialLeave:
          setBalanceStatusState({
            open: true,
            totalCount: userAttendanceBalanceCount.specialLeave,
            tempCount: userAttendanceBalanceCount.usedSpecialLeaveTemp,
            inProgressCount:
              userAttendanceBalanceCount.usedSpecialLeaveInProgress,
            approvedCount: userAttendanceBalanceCount.usedSpecialLeaveApproved,
            text: "특별휴가",
          });
          break;
      }
    }
  };

  return (
    <>
      {userAttendanceBalanceCount && (
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className={classes.tableCell} align="center">
                  <Alert severity="info" className={classes.alert}>
                    <strong>
                      연차/대체휴가/특별휴가를 클릭하시면 결재 진행사항을 포함한
                      상세 내용을 보실 수 있습니다.
                    </strong>
                  </Alert>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCell}>
                  <Grid className={classes.wrapperGrid} container>
                    <Grid item xs={2} className={classes.item}>
                      {"근속연수"}
                      <Typography className={classes.number} variant="h6">
                        {userAttendanceBalanceCount.yearOfService}
                      </Typography>
                    </Grid>
                    <Grid item xs={1} className={classes.item}>
                      {"총계"}
                      <Typography className={classes.totalGray} variant="h6">
                        {userAttendanceBalanceCount.annualLeave +
                          userAttendanceBalanceCount.alternativeLeave +
                          userAttendanceBalanceCount.specialLeave +
                          userAttendanceBalanceCount.usedAlternativeLeaveApproved +
                          userAttendanceBalanceCount.usedAlternativeLeaveInProgress +
                          userAttendanceBalanceCount.usedAlternativeLeaveTemp}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      className={classes.grid}
                      onClick={() => changeDialogState(AnnualLeave.annualLeave)}
                    >
                      {"연차"}
                      <Typography className={classes.numberGray} variant="h6">
                        {userAttendanceBalanceCount.annualLeave}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      className={classes.grid}
                      onClick={() =>
                        changeDialogState(AnnualLeave.alternativeLeave)
                      }
                    >
                      {"대체휴가"}
                      <Typography className={classes.numberGray} variant="h6">
                        {userAttendanceBalanceCount.alternativeLeave +
                          userAttendanceBalanceCount.usedAlternativeLeaveApproved +
                          userAttendanceBalanceCount.usedAlternativeLeaveInProgress +
                          userAttendanceBalanceCount.usedAlternativeLeaveTemp}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      className={classes.grid}
                      onClick={() =>
                        changeDialogState(AnnualLeave.specialLeave)
                      }
                    >
                      {"특별휴가"}
                      <Typography className={classes.numberGray} variant="h6">
                        {userAttendanceBalanceCount.specialLeave}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      container
                      alignItems="center"
                      justify="center"
                    ></Grid>
                    <Grid item xs={1} className={classes.item}>
                      {"총 사용계"}
                      <Typography className={classes.totalBlue} variant="h6">
                        {userAttendanceBalanceCount.usedAnnualLeave +
                          userAttendanceBalanceCount.usedSpecialLeave +
                          userAttendanceBalanceCount.usedAlternativeLeaveApproved +
                          userAttendanceBalanceCount.usedAlternativeLeaveInProgress +
                          userAttendanceBalanceCount.usedAlternativeLeaveTemp}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      className={classes.grid}
                      onClick={() => changeDialogState(AnnualLeave.annualLeave)}
                    >
                      {"연차사용"}
                      <Typography className={classes.numberBlue} variant="h6">
                        {userAttendanceBalanceCount.usedAnnualLeave}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      className={classes.grid}
                      onClick={() =>
                        changeDialogState(AnnualLeave.alternativeLeave)
                      }
                    >
                      {"대체휴가사용"}
                      <Typography className={classes.numberBlue} variant="h6">
                        {userAttendanceBalanceCount.usedAlternativeLeaveApproved +
                          userAttendanceBalanceCount.usedAlternativeLeaveInProgress +
                          userAttendanceBalanceCount.usedAlternativeLeaveTemp}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      className={classes.grid}
                      onClick={() =>
                        changeDialogState(AnnualLeave.specialLeave)
                      }
                    >
                      {"특별휴가사용"}
                      <Typography className={classes.numberBlue} variant="h6">
                        {userAttendanceBalanceCount.usedSpecialLeave}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          {balanceStatusState && (
            <BalanceStatusDialog
              dialogState={balanceStatusState}
              setDialogState={setBalanceStatusState}
            />
          )}
        </TableContainer>
      )}
    </>
  );
}
