import { CircularProgress, Dialog, DialogContent, DialogTitle, makeStyles } from "@material-ui/core";
import React, { ReactElement } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
  },
}));

interface ProgressModalProps {
  open: boolean;
  title: string;
  children?: ReactElement;
  onProgressBar?: boolean;
  onClose?: () => void;
}

function ProgressModal({ open, onProgressBar, title, onClose, children }: ProgressModalProps): ReactElement {
  const classes = useStyles();
  //onProgressBar : undefined //항상보임
  const _onProgressBar = onProgressBar === undefined || onProgressBar;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {/* 값이 있으면서, 안보이게 하겠다! 라고 한다면, */}
        {_onProgressBar && (
          <div className={classes.root}>
            <CircularProgress />
          </div>
        )}
        {children}
      </DialogContent>
    </Dialog>
  );
}

export default ProgressModal;
