import { Paper, AppBar, Tab } from "@material-ui/core";
import TabPanel from "src/components/TabPanel";
import LateExplanationList from "./LateExplanationList";
import React, { ReactElement } from "react";
import { MyStyle } from "src/Style/theme";
import { Alert, AlertTitle } from "@material-ui/lab";
import NightShiftGrid from "./NightShiftGrid";
import LateStatisticsGrid from "./LateStatisticsGrid";
import { StyledTabs } from "src/components/StyledTabs";
import AttendanceBalancePage from '../attendance-balance/AttendanceBalancePage';
import AttendanceDashBoardPage from '../attendance-dashboard/AttendanceDashBoardPage';
import AttendanceRequestItemListPage from '../attendance-request-item-list/AttendanceRequestItemListPage';

interface Props { }

// eslint-disable-next-line no-empty-pattern
function MainPage({ }: Props): ReactElement {
  const classes = MyStyle();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  function a11yProps(index: any, label: string) {
    return {
      id: `simple-tab-${index}`,
      label: `${label}`,
    };
  }

  return (
    <div>
      {/* 점보트론 */}
      <Alert severity="info">
        <AlertTitle>근태관리</AlertTitle>
        <ul>
          <li>• “조근”은 9시 이전 출근을 일컫습니다. </li>
          <li>
            • 공식 주말출근과 비공식 주말출근은… 전자는 통합근태 작성 후 출근,
            후자는 통합근태 작성 없이 출근한 것을 지문인식으로 체크한 것입니다.
          </li>
          <li>
            • 누적업무시간은 출근, 조근 ,야근, 외근, 출장, 주말출근을 모두 합친
            시간입니다.{" "}
          </li>
        </ul>
      </Alert>
      <div className={classes.divMainPage}>
        <Paper style={{ margin: "20px", backgroundColor: "white" }}>
          <AppBar position="static" color="inherit">
            <StyledTabs value={value} onChange={handleChange}>
              <Tab {...a11yProps(0, "지각 소명 내역")} />
              <Tab {...a11yProps(1, "연간 지각 통계")} />
              <Tab {...a11yProps(2, "월간 야근 식대")} />
              <Tab {...a11yProps(3, "연차 사용 조회")} />
              <Tab {...a11yProps(4, "연차 부여")} />
              <Tab {...a11yProps(5, "연차 현황 조회")} />
            </StyledTabs>
            <TabPanel value={value} index={0}>
              <LateExplanationList></LateExplanationList>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <LateStatisticsGrid></LateStatisticsGrid>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <NightShiftGrid></NightShiftGrid>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <AttendanceRequestItemListPage></AttendanceRequestItemListPage>
            </TabPanel>
            <TabPanel value={value} index={4}>
              <AttendanceBalancePage></AttendanceBalancePage>
            </TabPanel>
            <TabPanel value={value} index={5}>
              <AttendanceDashBoardPage></AttendanceDashBoardPage>
            </TabPanel>
          </AppBar>
        </Paper>
      </div>
    </div>
  );
}

export default MainPage;
