import "src/Style/grid.css";

import React, { ReactElement, useState } from "react";
import { ErrorHandler, DataApi } from "src/system/ApiService";
import { ObjType } from "src/system/types";
import { Paper, Tab, Theme, createStyles, makeStyles } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import BasicDataImport, {
  BasicUpload,
} from "src/page/manage-hr/employee/BasicDataImportRef";
import FamilyDataImport, {
  FamilyUpload,
} from "src/page/manage-hr/employee/FamilyDataImport";
import WorkRecordDataImport, { WorkRecordUpload } from "./WorkRecordDataImport";
import RewardAndPunishmentDataImportRef, {
  RewardAndPunishmentUpload,
} from "./RewardAndPunishmentDataImportRef";
import EducationDataImportRef, {
  EducationUpload,
} from "./EducationDataImportRef";
import CertificateDataImportRef, {
  CertificateUpload,
} from "./CertificateDataImportRef";
import LanguageDataImportRef, { LanguageUpload } from "./LanguageDataImportRef";
import InternEvaluationDataImportRef, {
  InternEvaluationUpload,
} from "./InternEvaluationDataImportRef";
import MilitaryDataImportRef, { MilitaryUpload } from "./MilitaryDataImportRef";
import DisabilityDataImportRef, {
  DisabilityUpload,
} from "./DisabilityDataImportRef";
import VeteransDataImportRef, { VeteransUpload } from "./VeteransDataImportRef";
import TabPanel from "src/components/TabPanel";
import EducationSchoolDataImportRef, {
  EducationSchoolUpload,
} from "./EducationSchoolDataImportRef";
import CareerDataImportRef, { CareerUpload } from "./CareerDataImportRef";
import { Alert, AlertTitle } from "@material-ui/lab";
import { StyledTabs } from "src/components/StyledTabs";
import { MyStyle } from "src/Style/theme";

export type ExcelUploadEnum =
  | "basic" // 기본정보
  | "family" // 가족정보
  | "workRecord" // 인사변동이력
  | "employmentRecord" // 근무상태변동
  | "rewardandpunishment" // 상벌사항
  | "education" // 교육사항
  | "certificate" // 자격사항
  | "language" // 어학사항
  | "internEvaluation" // 인턴평가
  | "military" // 병역사항
  | "disability" // 장애사항
  | "veterans" // 보훈사항
  | "educationSchool" // 학력사항
  | "career" // 경력사항
  | "internEvaluation"; // 인턴평가;
export type ExcelUploadType = BasicUpload | ObjType;

/**
 * 에러 데이터 Count
 * @param data
 * @returns
 */
function CalculateErrorRows(data: ObjType[]) {
  let cnt = 0;
  for (let i = data.length; i >= 0; i--) {
    const x = data[i];
    if (x) {
      if (x.error && Object.keys(x.error).length >= 1) {
        cnt++;
      }
    }
  }
  return cnt;
}

function MainPage(): ReactElement {
  const [data, setData] = useState<ExcelUploadType[]>([]);
  const [value, setValue] = React.useState(0);

  /**
   * 엑셀 데이터 -> JSON
   * @param event
   */
  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    const formData = new FormData(form);

    DataApi.uploadExcel(form.name, formData)
      .then((res) => {
        setData(res);
      })
      .catch((error) => {
        const msg = ErrorHandler(error);
        alert(msg);
        setData([]);
      })
      .finally(() => {});
  }

  /**
   * 데이터 업로드
   * @param type
   * @returns
   */
  function onInsertData(type: ExcelUploadEnum) {
    if (data.length === 0) {
      //error
      alert("업로드할 데이터가 없습니다.");
      return;
    }
    //error check logic
    if (errorCount !== 0) {
      alert(
        `${errorCount}건의 잘못된 데이터가 있습니다.\n업로드 했던 파일을 수정 후 다시 "제출"해주세요.`
      );
      return;
    }
    //upload logic
    let msg = "";
    DataApi.uploadData(type, data)
      .then((x) => {
        msg = "성공";
      })
      .catch((error) => {
        msg = ErrorHandler(error);
      })
      .finally(() => {
        alert(msg);
      });
  }

  const errorCount = React.useMemo(() => CalculateErrorRows(data), [data]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setData([]);
    setValue(newValue);
  };

  function a11yProps(index: any, label: string) {
    return {
      id: `simple-tab-${index}`,
      label: `${label}`,
    };
  }

  const MyStyle = makeStyles((theme: Theme) =>
    createStyles({
      tabItem: {
        minWidth: "120px",
        width: "120px",
      },
    })
  );

  const classes = MyStyle();

  return (
    <div>
      <Alert severity="info">
        <AlertTitle>도움말</AlertTitle>
        <ul>
          <li>엑셀 업로드를 통해 사원정보를 추가할 수 있습니다.</li>
        </ul>
      </Alert>
      <Paper style={{ marginTop: "20px" }}>
        <AppBar position="static" color="default">
          {/* 직원관리 탭 메뉴 */}
          <StyledTabs value={value} onChange={handleChange}>
            <Tab {...a11yProps(0, "기본정보")} className={classes.tabItem} />
            <Tab {...a11yProps(1, "가족정보")} className={classes.tabItem} />
            <Tab
              {...a11yProps(2, "인사변동이력")}
              className={classes.tabItem}
            />
            {/* <Tab {...a11yProps(3, "근무상태변동")} /> */}
            <Tab {...a11yProps(3, "상벌사항")} className={classes.tabItem} />
            <Tab {...a11yProps(4, "교육사항")} className={classes.tabItem} />
            <Tab {...a11yProps(5, "어학사항")} className={classes.tabItem} />
            <Tab {...a11yProps(6, "자격사항")} className={classes.tabItem} />
            <Tab {...a11yProps(7, "병역사항")} className={classes.tabItem} />
            <Tab {...a11yProps(8, "장애사항")} className={classes.tabItem} />
            <Tab {...a11yProps(9, "보훈사항")} className={classes.tabItem} />
            <Tab {...a11yProps(10, "학력사항")} className={classes.tabItem} />
            <Tab {...a11yProps(11, "경력사항")} className={classes.tabItem} />
            <Tab {...a11yProps(12, "인턴평가")} className={classes.tabItem} />
          </StyledTabs>
          {/* 탭 리스트 */}
          <TabPanel value={value} index={0}>
            <h4>기본정보 업로드</h4>
            <BasicDataImport
              errorCount={errorCount}
              data={data as BasicUpload[]}
              onSubmit={onSubmit}
              onInsertData={onInsertData}
            ></BasicDataImport>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <h4>가족정보 업로드</h4>
            <FamilyDataImport
              errorCount={errorCount}
              onSubmit={onSubmit}
              onInsertData={onInsertData}
              data={data as FamilyUpload[]}
            ></FamilyDataImport>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <h4>인사변동이력</h4>
            <WorkRecordDataImport
              errorCount={errorCount}
              onSubmit={onSubmit}
              onInsertData={onInsertData}
              data={data as WorkRecordUpload[]}
            ></WorkRecordDataImport>
          </TabPanel>
          {/* <TabPanel value={value} index={3}>
            <h4>근무상태변동</h4>
            <EmploymentRecordDataImport
              errorCount={errorCount}
              onSubmit={onSubmit}
              onInsertData={onInsertData}
              data={data as EmploymentRecordUpload[]}
            ></EmploymentRecordDataImport>
          </TabPanel> */}
          <TabPanel value={value} index={3}>
            <h4>상벌사항</h4>
            <RewardAndPunishmentDataImportRef
              errorCount={errorCount}
              onSubmit={onSubmit}
              onInsertData={onInsertData}
              data={data as RewardAndPunishmentUpload[]}
            ></RewardAndPunishmentDataImportRef>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <h4>교육사항</h4>
            <EducationDataImportRef
              errorCount={errorCount}
              onSubmit={onSubmit}
              onInsertData={onInsertData}
              data={data as EducationUpload[]}
            ></EducationDataImportRef>
          </TabPanel>
          <TabPanel value={value} index={5}>
            <h4>어학사항</h4>
            <LanguageDataImportRef
              errorCount={errorCount}
              onSubmit={onSubmit}
              onInsertData={onInsertData}
              data={data as LanguageUpload[]}
            ></LanguageDataImportRef>
          </TabPanel>
          <TabPanel value={value} index={6}>
            <h4>자격사항</h4>
            <CertificateDataImportRef
              errorCount={errorCount}
              onSubmit={onSubmit}
              onInsertData={onInsertData}
              data={data as CertificateUpload[]}
            ></CertificateDataImportRef>
          </TabPanel>
          <TabPanel value={value} index={7}>
            <h4>병역사항</h4>
            <MilitaryDataImportRef
              errorCount={errorCount}
              onSubmit={onSubmit}
              onInsertData={onInsertData}
              data={data as MilitaryUpload[]}
            ></MilitaryDataImportRef>
          </TabPanel>
          <TabPanel value={value} index={8}>
            <h4>장애사항</h4>
            <DisabilityDataImportRef
              errorCount={errorCount}
              onSubmit={onSubmit}
              onInsertData={onInsertData}
              data={data as DisabilityUpload[]}
            ></DisabilityDataImportRef>
          </TabPanel>
          <TabPanel value={value} index={9}>
            <h4>보훈사항</h4>
            <VeteransDataImportRef
              errorCount={errorCount}
              onSubmit={onSubmit}
              onInsertData={onInsertData}
              data={data as VeteransUpload[]}
            ></VeteransDataImportRef>
          </TabPanel>
          <TabPanel value={value} index={10}>
            <h4>학력사항</h4>
            <EducationSchoolDataImportRef
              errorCount={errorCount}
              onSubmit={onSubmit}
              onInsertData={onInsertData}
              data={data as EducationSchoolUpload[]}
            ></EducationSchoolDataImportRef>
          </TabPanel>
          <TabPanel value={value} index={11}>
            <h4>경력사항</h4>
            <CareerDataImportRef
              errorCount={errorCount}
              onSubmit={onSubmit}
              onInsertData={onInsertData}
              data={data as CareerUpload[]}
            ></CareerDataImportRef>
          </TabPanel>
          <TabPanel value={value} index={12}>
            <h4>인턴평가</h4>
            <InternEvaluationDataImportRef
              errorCount={errorCount}
              onSubmit={onSubmit}
              onInsertData={onInsertData}
              data={data as InternEvaluationUpload[]}
            ></InternEvaluationDataImportRef>
          </TabPanel>
        </AppBar>
      </Paper>
    </div>
  );
}

export default MainPage;
