import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { format } from "date-fns";
import { ComboboxItem } from "src/components/TitleOneComboBox";
import {
  CreateAttendanceBalance,
  GetAttendanceBalance,
  GetAttendanceBalanceForAdmin,
  GetSnapshotAttendanceBalance,
  GetAttendanceBalanceCount,
  UpdateAttendanceBalance,
} from "src/page/attendance/attendance-request/post-attendance-request/interface/BalanceInterface";
import { AttendanceConstant } from "src/page/attendance/attendance-request/post-attendance-request/interface/ConstantInterface";
import {
  CreateAttendanceDocument,
  GetAttendanceDocumentDetail,
  GetAttendanceDocumentList,
  GetAttendanceForm,
  GetAttendanceItem,
  GetAttendanceRequestItem,
} from "src/page/attendance/attendance-request/post-attendance-request/interface/RequestInterface";
import { BasicUpload } from "src/page/manage-hr/employee/BasicDataImportRef";
import { CareerUpload } from "src/page/manage-hr/employee/CareerDataImportRef";
import { CertificateUpload } from "src/page/manage-hr/employee/CertificateDataImportRef";
import { DisabilityUpload } from "src/page/manage-hr/employee/DisabilityDataImportRef";
import { EducationUpload } from "src/page/manage-hr/employee/EducationDataImportRef";
import { EducationSchoolUpload } from "src/page/manage-hr/employee/EducationSchoolDataImportRef";
import { FamilyUpload } from "src/page/manage-hr/employee/FamilyDataImport";
import { InternEvaluationUpload } from "src/page/manage-hr/employee/InternEvaluationDataImportRef";
import { LanguageUpload } from "src/page/manage-hr/employee/LanguageDataImportRef";
import {
  ExcelUploadEnum,
  ExcelUploadType,
} from "src/page/manage-hr/employee/MainPage";
import { MilitaryUpload } from "src/page/manage-hr/employee/MilitaryDataImportRef";
import { RewardAndPunishmentUpload } from "src/page/manage-hr/employee/RewardAndPunishmentDataImportRef";
import { VeteransUpload } from "src/page/manage-hr/employee/VeteransDataImportRef";
import { WorkRecordUpload } from "src/page/manage-hr/employee/WorkRecordDataImport";
import { _ScoreApi } from "src/system/api/ScoreApi";
import {
  AftercareSearchItem,
  AlarmListItem,
  Applicant,
  ApplicantEnrollment,
  Attendance,
  AttendanceAlarm,
  AttendanceStatics,
  AuthorityUser,
  Career,
  Category,
  Certificate,
  Constant,
  Department,
  DeptInfoErp,
  DeptTreeView,
  Education,
  EducationSchool,
  Employee,
  EmployeeAutoComplete,
  Evidence,
  FamilyMember,
  Groupware,
  HRCategory,
  InterviewListItem,
  InterviewSearch,
  InterviewWriteResponse,
  Issue,
  IssueInterviewItem,
  IssueListItem,
  IssueSearch,
  IssueSearchMiniData,
  Language,
  MonthlyAttendanceStatics,
  PersonalData,
  QNA,
  QNAItem,
  Recruitment,
  RewardAndPunishment,
  SearchResult,
  Team,
  TimeAndAttendance,
  UserInfoErp,
  UserInfoErpRequest,
  ViewPageData,
  WorkRecord,
} from "src/system/types";
import HttpClient, { HttpUrlClient } from "./http-client";

//const url = "https://localhost:5001/api";
//const url = "https://localhost:44333/api";
const url = `${window.location.origin}/api`;
//const url = "http://insatest.weareinbody.com/api";

const client = new HttpClient(url);

class _DataApi {
  private client: HttpUrlClient;
  public constructor(client: HttpClient) {
    this.client = new HttpUrlClient(client, "data");
  }

  private WrongRequest(): Promise<any> {
    return new Promise((resolve, reject) => {
      reject("잘못된 요청입니다.");
    });
  }

  /**
   * 엑셀 -> json
   * @param formName "basic" | "family" | "workRecord" | "employmentRecord" | "education" | "certificate" | "language" | "rewardAndPunishment" |
   *                  "internEvaluation" | "military" | "educationSchool" | "career"
   * @param form
   * @returns
   */
  public uploadExcel(
    formName: string,
    form: FormData
  ): Promise<ExcelUploadType[]> {
    if (formName === "basic") {
      return this.client.post<BasicUpload[]>("/excel/basic", form);
    } else if (formName === "family") {
      return this.client.post<FamilyUpload[]>("/excel/family", form);
    } else if (formName === "workRecord") {
      return this.client.post<WorkRecordUpload[]>("/excel/workRecord", form);
    } else if (formName === "education") {
      return this.client.post<EducationUpload[]>("/excel/education", form);
    } else if (formName === "certificate") {
      return this.client.post<CertificateUpload[]>("/excel/certificate", form);
    } else if (formName === "language") {
      return this.client.post<LanguageUpload[]>("/excel/language", form);
    } else if (formName === "rewardAndPunishment") {
      return this.client.post<RewardAndPunishmentUpload[]>(
        "/excel/rewardandpunishment",
        form
      );
    } else if (formName === "military") {
      return this.client.post<MilitaryUpload[]>("/excel/military", form);
    } else if (formName === "disability") {
      return this.client.post<DisabilityUpload[]>("/excel/disability", form);
    } else if (formName === "veterans") {
      return this.client.post<VeteransUpload[]>("/excel/veterans", form);
    } else if (formName === "internEvaluation") {
      return this.client.post<InternEvaluationUpload[]>(
        "/excel/internEvaluation",
        form
      );
    } else if (formName === "educationSchool") {
      return this.client.post<EducationSchoolUpload[]>(
        "/excel/educationSchool",
        form
      );
    } else if (formName === "career") {
      return this.client.post<CareerUpload[]>("/excel/career", form);
    } else {
      return this.WrongRequest();
    }
  }

  /**
   * 기본/가족정보 DB 업로드
   * @param uploadType : "basic" | "family" | "education" | "certificate" | "language" | "workRecord" | "military" | "disability" | "rewardAndPunishment"
   * | "educationSchool" | "career"
   * @param obj
   * @returns
   */
  public uploadData(uploadType: ExcelUploadEnum, obj: ExcelUploadType[]) {
    if (uploadType === "basic") {
      return this.client.post("/upload/basic", {
        list: obj,
      });
    } else if (uploadType === "family") {
      return this.client.post("/upload/family", {
        list: obj,
      });
    } else if (uploadType === "workRecord") {
      return this.client.post("/upload/workRecord", {
        list: obj,
      });
    } else if (uploadType === "education") {
      return this.client.post("/upload/education", {
        list: obj,
      });
    } else if (uploadType === "certificate") {
      return this.client.post("/upload/certificate", {
        list: obj,
      });
    } else if (uploadType === "language") {
      return this.client.post("/upload/language", {
        list: obj,
      });
    } else if (uploadType === "rewardandpunishment") {
      return this.client.post("/upload/rewardandpunishment", {
        list: obj,
      });
    } else if (uploadType === "military") {
      return this.client.post("/upload/military", {
        list: obj,
      });
    } else if (uploadType === "disability") {
      return this.client.post("/upload/disability", {
        list: obj,
      });
    } else if (uploadType === "veterans") {
      return this.client.post("/upload/veterans", {
        list: obj,
      });
    } else if (uploadType === "internEvaluation") {
      return this.client.post("/upload/internEvaluation", {
        list: obj,
      });
    } else if (uploadType === "educationSchool") {
      return this.client.post("/upload/educationSchool", {
        list: obj,
      });
    } else if (uploadType === "career") {
      return this.client.post("/upload/career", {
        list: obj,
      });
    } else {
      return this.WrongRequest();
    }
  }

  /**
   * 가족정보 validation
   * @param obj
   * @returns
   */
  public validationFamily(obj: FamilyUpload[]) {
    return this.client.post("/validate/family", {
      list: obj,
    });
  }

  /**
   * 기본정보 validation
   * @param obj
   * @returns
   */
  public validationBasic(obj: BasicUpload[]) {
    return this.client.post("/validate/basic", {
      list: obj,
    });
  }
}

class _ConstantApi {
  private client: HttpUrlClient;
  public constructor(client: HttpClient) {
    this.client = new HttpUrlClient(client, "");
  }
  GetConstant(tableName: string, columnName: string) {
    const url: string[] = [];
    if (tableName !== "") {
      url.push(`tableName=${tableName}`);
    }
    if (columnName !== "") {
      url.push(`columnNmae=${columnName}`);
    }
    const nextUrl = url.length >= 1 ? `?${url.join("&")}` : "";
    return this.client.get<{ result: Constant[]; count: number }>(
      "constant" + nextUrl
    );
  }

  UpsertConstant(constant: Constant) {
    return this.client.post<Constant>("constant", constant);
  }

  DeleteConstant(constantId: number) {
    return this.client.delete("constant/" + constantId);
  }
}

class _DepartmentApi {
  private client: HttpUrlClient;
  public constructor(client: HttpClient) {
    this.client = new HttpUrlClient(client, "");
  }

  GetDeptTreeView(year: number) {
    return this.client.get<DeptTreeView>(`department/treeview/${year}`);
  }

  GetDeptAndEmployeeTreeView(year: number) {
    return this.client.get<DeptTreeView>(
      `department/treeview/${year}/Employee`
    );
  }

  GetDeptInfoErp(
    year?: number,
    status?: string,
    seniorDeptCostCenter?: string
  ) {
    const url: string[] = [];
    if (year !== undefined) {
      url.push(`year=${year}`);
    }
    if (status !== undefined) {
      url.push(`status=${status}`);
    }
    if (seniorDeptCostCenter !== undefined) {
      url.push(`seniorDeptCostCenter=${seniorDeptCostCenter}`);
    }
    const nextUrl = url.length >= 1 ? `?${url.join("&")}` : "";
    return this.client.get<{
      result: DeptInfoErp[];
      count: number;
    }>("department" + nextUrl);
  }

  UpdateDeptInfoErpSyncForV4() {
    return this.client.post<{ result: DeptInfoErp[]; count: number }>(
      "department/syncv4"
    );
  }

  UpsertDeptInfoErp(dept: DeptInfoErp) {
    return this.client.post<DeptInfoErp>("department", dept);
  }

  DeleteDeptInfoErp(costCenter: string) {
    return this.client.delete("department/" + costCenter);
  }

  UpsertDepartment(dept: Department) {
    return this.client.post<Department>("department", dept);
  }

  DeleteDepartment(deptId: number) {
    return this.client.delete("department/" + deptId);
  }

  GetTeam(year?: number, isShow?: boolean, departmentId?: number) {
    const url: string[] = [];

    if (year !== undefined) {
      url.push(`year=${year}`);
    }
    if (isShow !== undefined) {
      url.push(`isShow=${isShow}`);
    }
    if (departmentId !== undefined) {
      url.push(`departmentId=${departmentId}`);
    }

    const nextUrl = url.length >= 1 ? `?${url.join("&")}` : "";

    return this.client.get<{
      result: Team[];
      count: number;
    }>("team" + nextUrl);
  }

  UpsertTeam(dept: Department) {
    return this.client.post<Team>("team", dept);
  }

  DeleteTeam(deptId: number) {
    return this.client.delete("team/" + deptId);
  }
}

class _InterviewApi {
  private client: HttpClient;

  public constructor(client: HttpClient) {
    this.client = client;
  }

  public search = (data: InterviewSearch) => {
    return this.client.post<SearchResult<InterviewListItem>>(
      "interviews/search",
      data
    );
  };

  getInterview(id: number): Promise<ViewPageData> {
    return new Promise((resolve, reject) =>
      this.client
        .get<ViewPageData>(`interviews/${id}`)
        .then((res) => resolve(res.data))
        .catch((error) => reject(error))
    );
  }
  deleteInterview(id: number) {
    return this.client.post(`interviews/delete/${id}`);
  }
  postInterview(data: ViewPageData) {
    return this.client.post<InterviewWriteResponse>("interviews", data);
  }
  getCategories() {
    return this.client.get<Category[]>("interviews/categories");
  }
  reserveEmail(id: number) {
    return this.client.post(`interviews/reserve-email/${id}`);
  }
  uploadFile(id: number, data: FormData) {
    return this.client.post(`interviews/upload-file/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  downloadFile(fileId: number, contentType: string) {
    return this.client.get(`interviews/download-file/${fileId}`, {
      responseType: "blob",
      headers: {
        "Content-Type": contentType,
      },
    });
  }
}

class _AftercareApi {
  private client: HttpClient;

  public constructor(client: HttpClient) {
    this.client = client;
  }

  public SearchList = (data: any) =>
    this.client.post<AftercareSearchItem[]>("aftercares/search", data);
  public AlarmList = (page: string, type: string) =>
    this.client.get<SearchResult<AlarmListItem>>(
      `aftercares/alarm/${page}/${type}`
    );

  ChangeDetailCompletedByType = (id: number, type: string) => {
    return this.client.post(`detailcares/change/${type}/${id}`);
  };
}

class _EmployeeApi {
  private client: HttpClient;

  public constructor(client: HttpClient) {
    this.client = client;
  }

  public getEmployees() {
    return this.client.get<Employee[]>("employees");
  }

  public getAutorityUsers() {
    return this.client.get<AuthorityUser[]>("employees/authority");
  }

  public changeAuthority(employeeId: string, grant: string, value: boolean) {
    return this.client.post("employees/authority/change", {
      employeeId: employeeId,
      grant: grant,
      value: value,
    });
  }
}

class CategoryApi {
  public client: HttpClient;
  protected prefix: string;

  public constructor(client: HttpClient, postfix: string) {
    this.client = client;
    this.prefix = `categories/${postfix}`;
  }

  Get(): Promise<AxiosResponse<Category[]>>;
  Get(id: number): Promise<AxiosResponse<Category>>;
  Get(id?: number) {
    if (id) {
      return this.client.get<Category>(`${this.prefix}/${id}`);
    } else {
      return this.client.get<Category[]>(this.prefix);
    }
  }
  GetCategoryCombobox(): Promise<ComboboxItem[]> {
    return this.client
      .get<Category[]>(this.prefix)
      .then(({ data }) => {
        let ret: ComboboxItem[] = [];
        data.forEach((x) => {
          ret.push({
            key: `${x.id}`,
            value: x.comment,
          });
        });
        return Promise.resolve(ret);
      })
      .catch((error) => Promise.reject(error));
  }

  GetAll() {
    return this.client.get<Category[]>(`${this.prefix}/all`);
  }

  Post(data: Category) {
    return this.client.post(this.prefix, data);
  }

  Edit(data: Category) {
    return this.client.post(`${this.prefix}/edit`, data);
  }

  Delete(id: number) {
    return this.client.post(`${this.prefix}/delete` + id);
  }
}

class _InterivewCategoryApi extends CategoryApi {
  public constructor(client: HttpClient, postfix: string) {
    super(client, postfix);
  }
  GetWithoutCache() {
    return this.client.get<Category[]>(`${this.prefix}/wd`);
  }
}

class _HRCategoryApi {
  private client: HttpUrlClient;

  public constructor(client: HttpClient) {
    this.client = new HttpUrlClient(client, "category/");
  }

  GetCategoryList(number: number, kind: string) {
    return this.client.get<HRCategory[]>(`list/${number}/${kind}`);
  }

  UpsertCategory(number: number, category: HRCategory) {
    return this.client.post<HRCategory>(number + "", category);
  }

  DeleteCategory(number: number, categoryId: number) {
    return this.client.delete<HRCategory>(`${number}/` + categoryId);
  }
}

class _HREmployeeApi {
  private client: HttpUrlClient;

  public constructor(client: HttpClient) {
    this.client = new HttpUrlClient(client, "hremployee/");
  }

  GetIsExistsEmployeeId(employeeId: string) {
    return this.client.get<boolean>(`exists/${employeeId}`);
  }

  GetPersonalData(employeeId: string) {
    return this.client.get<PersonalData>(`personal/${employeeId}`);
  }

  GetEmployeeList() {
    return this.client.get<EmployeeAutoComplete[]>(`list`);
  }

  GetFamilyList(employeeId: string) {
    return this.client.get<FamilyMember[]>(`list/family/${employeeId}`);
  }

  GetEducationSchoolList(employeeId: string) {
    return this.client.get<EducationSchool[]>(
      `list/educationSchool/${employeeId}`
    );
  }

  GetCareerList(employeeId: string) {
    return this.client.get<Career[]>(`list/career/${employeeId}`);
  }

  GetWorkRecordList(employeeId: string) {
    return this.client.get<WorkRecord[]>(`list/workrecord/${employeeId}`);
  }

  GetRewardAndPunishmentList(employeeId: string) {
    return this.client.get<RewardAndPunishment[]>(
      `list/rewardandpunishment/${employeeId}`
    );
  }

  GetEducationList(employeeId: string) {
    return this.client.get<Education[]>(`list/education/${employeeId}`);
  }

  GetCertificateList(employeeId: string) {
    return this.client.get<Certificate[]>(`list/certificate/${employeeId}`);
  }

  GetLanguageList(employeeId: string) {
    return this.client.get<Language[]>(`list/language/${employeeId}`);
  }

  UpsertPersonalData(data: PersonalData) {
    return this.client.post<PersonalData>(`personal`, data);
  }

  InsertEducationSchoolData(data: EducationSchool) {
    return this.client.post<EducationSchool>(`educationschool`, data);
  }

  InsertFamilyData(data: FamilyMember) {
    return this.client.post<FamilyMember>(`family`, data);
  }

  InsertCareerData(data: Career) {
    return this.client.post<Career>(`career`, data);
  }

  InsertCertificateData(data: Certificate) {
    return this.client.post<Certificate>(`certificate`, data);
  }

  InsertLanguage(data: Language) {
    return this.client.post<Language>(`language`, data);
  }

  InsertApplicantEnrollment(data: ApplicantEnrollment) {
    return this.client.post(`enrollment`, data);
  }

  InsertUserInfoErpToV4(data: UserInfoErpRequest) {
    return this.client.post(`userinfoerp`, data);
  }

  UploadFile(data: FormData) {
    return this.client.post(`file`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  DeleteEmployeeById(employeeId: string) {
    return this.client.delete(`delete/${employeeId}`);
  }
}

class _QNAApi {
  private client: HttpUrlClient;

  public constructor(client: HttpClient) {
    this.client = new HttpUrlClient(client, "qna/");
  }

  GetQNAList(row: number, page: number) {
    return this.client.get<SearchResult<QNAItem>>(
      `list?row=${row}&page=${page}`
    );
  }

  GetQNA(id: number) {
    return this.client.get<QNA>(`view/${id}`);
  }

  UpsertQNA(qna: FormData) {
    return this.client.post<number>(`edit`, qna);
  }

  DownloadFile(filename: string, contentType: string) {
    return this.client.get(`download/${filename}`, {
      responseType: "blob",
      headers: { "Content-Type": contentType },
    });
  }
}

class _AttendanceApi {
  private client: HttpUrlClient;

  public constructor(client: HttpClient) {
    this.client = new HttpUrlClient(client, "attendance/");
  }

  GetEmployeeList(employeeId: string) {
    return this.client.get<EmployeeAutoComplete[]>(`list/${employeeId}`);
  }

  GetAlarmList(employeeNo: string, workplace: string, date: Date) {
    const yyyyMMdd = format(date, "yyyyMMdd");
    return this.client.get<AttendanceAlarm[]>(
      `list/alarm/${employeeNo}/${workplace}/${yyyyMMdd}`
    );
  }

  GetTimeAndAttendanceList(date: Date) {
    const yearMonth = format(date, "yyyyMM");
    return this.client.get<TimeAndAttendance[]>(
      `list/timeAndAttendance/${yearMonth}`
    );
  }

  GetAttendance(employee: EmployeeAutoComplete, date: Date) {
    const year = format(date, "yyyy");
    const month = format(date, "MM");
    const day = format(date, "dd");
    return this.client.get<Attendance>(
      `${employee.employeeNo}/${year}/${month}/${day}/${employee.workplace}`
    );
  }

  GetAttendanceList(employee: EmployeeAutoComplete, date: Date) {
    const year = format(date, "yyyy");
    const month = format(date, "MM");
    return this.client.get<Attendance[]>(
      `${employee.employeeNo}/${year}/${month}/${employee.workplace}`
    );
  }

  GetMonthlyAttendanceStatics(employeeId: string, date: Date) {
    const year = format(date, "yyyy");
    const month = format(date, "MM");
    return this.client.get<MonthlyAttendanceStatics>(
      `monthly/${employeeId}/${year}/${month}`
    );
  }

  GetPersonalAttendanceStatics(name: string, year: number) {
    return this.client.get<AttendanceStatics[]>(`statics/${name}/${year}`);
  }

  GetGroupwareList(employeeNo: string, date: Date) {
    const year = format(date, "yyyy");
    return this.client.get<Groupware[]>(`groupware/${employeeNo}/${year}`);
  }

  GetDailyGroupwareList(employeeNo: string, date: Date) {
    const year = format(date, "yyyy");
    const month = format(date, "MM");
    const day = format(date, "dd");
    return this.client.get<Groupware[]>(
      `groupware/${employeeNo}/${year}/${month}/${day}`
    );
  }

  UploadGroupwareFile(formData: FormData, config?: AxiosRequestConfig) {
    return this.client.post(`groupware/upload`, formData, config);
  }

  GetWeeklyWorkTimeByName() {
    return this.client.get(`weeklyworktime/excel`);
  }

  GetYearlyLateStatistics(workplace: string) {
    return this.client.get(`latestatistics/${workplace}`);
  }

  GetMonthlyNightShift(workplace: string, year: number, month: number) {
    return this.client.get(`nightshift/${year}/${month}/${workplace}`);
  }

  // 지각 소명 조회, 수정, 삭제
  GetLateExplanation(employeeId: string, date: Date) {
    const pick = format(date, "yyyy-MM-dd");
    return this.client.get(`lateexplanation/${employeeId}/${pick}`);
  }

  GetLateExplanationList(employeeId: string, type: string) {
    return this.client.get(`lateexplanation/list/${employeeId}/${type}`);
  }

  UploadLateExplanation(lateData: FormData) {
    return this.client.post(`lateexplanation/upload`, lateData);
  }

  DeleteLateExplanation(id: number) {
    return this.client.delete(`delete/lateexplanation/${id}`);
  }
}

class _AttendanceBalanceApi {
  private client: HttpUrlClient;

  public constructor(client: HttpClient) {
    this.client = new HttpUrlClient(client, "AttendanceBalance/");
  }

  /** 본인 휴가 목록 조회 */
  GetAttendanceBalanceList(employeeId: string) {
    return this.client.get<GetAttendanceBalance[]>(`employee/${employeeId}`);
  }

  /** 휴가 개수 조회 */
  GetAttendanceBalanceCount(employeeId: string) {
    return this.client.get<GetAttendanceBalanceCount>(
      `dashboard/employee/${employeeId}`
    );
  }

  /** 휴가 전체 조회 - 관리자용 */
  GetAllAttendanceBalanceForAdmin() {
    return this.client.get<GetAttendanceBalanceForAdmin[]>("admin");
  }
  /** 휴가 생성 */
  CreateAttendanceBalance(request: CreateAttendanceBalance) {
    return this.client.post("", request);
  }
  /** 휴가 수정 */
  UpdateAttendanceBalance(request: UpdateAttendanceBalance) {
    return this.client.put("", request);
  }
  /** 휴가 삭제 */
  DeleteAttendanceBalance(id: number) {
    return this.client.delete(`${id}`);
  }

  CreateSnapshotAttendanceBalance() {
    return this.client.post("snapshot");
  }

  GetSnapshotAttendanceBalanceList() {
    return this.client.get<GetSnapshotAttendanceBalance[]>("snapshot");
  }
}

class _AttendanceRequestApi {
  private client: HttpUrlClient;

  public constructor(client: HttpClient) {
    this.client = new HttpUrlClient(client, "AttendanceRequest/");
  }
  /** Sas Token 발행 */
  GetSasToken() {
    return this.client.get<string>("sas-token");
  }
  /** 통근태 문서 생성 */
  CreateAttendanceDocument(request: CreateAttendanceDocument) {
    return this.client.post("", request);
  }
  /** 통근태 문서 상세 조회 */
  GetAttendanceDocumentDetail(id: number) {
    return this.client.get<GetAttendanceDocumentDetail>(`${id}`);
  }
  /** 통근태 문서 전체 조회 */
  GetAttendanceDocumentList() {
    return this.client.get<GetAttendanceDocumentList[]>("list");
  }

  /**
   * (관리자용) 통합근태 작성 목록 확인
   * @param kind 0:전체통합근태목록, 1:연차사용이력, 2:취소가능목록(완료), 3:전체휴가원목록
   * @param year 연도 0:전체연도
   * @param month 월 0:전체월
   * @returns
   */
  GetAttendanceRequestItemList(
    kind: number = 0,
    year: number = 0,
    month: number = 0
  ) {
    return this.client.get<GetAttendanceRequestItem[]>(
      `item/${kind}/year/${year}/month/${month}`
    );
  }

  /**
   * 통근태 아이템 전체 조회 - 휴가원 / 휴가 취소
   * @param kind 0:전체조회, 1:휴가원, 2: 취소가능 item
   * @param employeeId 사번
   * @returns 사번에 맞는 item
   */
  GetAttendanceItemList(kind: number = 1, employeeId: string) {
    return this.client.get<GetAttendanceItem[]>(
      `item/${kind}/employee/${employeeId}`
    );
  }

  /**
   * 오늘의 근태 조회
   * @param dataAreaId ALL:전체,IHQ:본사,IUS:미국,KRT:코르트,BWA:미국동부,BRA:한국지사
   * @param workDate yyyy-mm-dd string값 조회날짜 없을시 TODAY
   * @returns
   */
  GetTodayAttendanceList(dataAreaId: string = "ALL", workDate: string) {
    return this.client.get<GetAttendanceRequestItem[]>(
      `item/dataAreaId/${dataAreaId}/${workDate}`
    );
  }

  /**
   * 통근태 양식 전체 조회
   * @param isUse
   * @returns true: 사용가능, false: 전체목록
   */
  GetAttendanceFormList(isUse: boolean = true) {
    return this.client.get<GetAttendanceForm[]>(`item/form/${isUse}`);
  }
}

class _AttendanceConstantApi {
  private client: HttpUrlClient;

  public constructor(client: HttpClient) {
    this.client = new HttpUrlClient(client, "AttendanceConstant/");
  }
  /**
   * 상수 값 이름 조회
   * @param tableName 테이블명
   * @param columnName 컬럼명
   * @returns 상수 key, value 값 조회
   */
  GetConstant(tableName: string, columnName: string) {
    return this.client.get<AttendanceConstant[]>(`${tableName}/${columnName}`);
  }
}

class _RecruitApi {
  private client: HttpUrlClient;

  public constructor(client: HttpClient) {
    this.client = new HttpUrlClient(client, "recruit/");
  }

  GetRecruitmentList() {
    return this.client.get<Recruitment[]>(`recruitment/list`);
  }

  GetApplicantPersonalData(applicant: Applicant) {
    return this.client.get<PersonalData>(
      `applicant/personalData/${applicant.rcode}/${applicant.ano}`
    );
  }

  GetApplicantEducationSchool(applicant: Applicant) {
    return this.client.get<EducationSchool[]>(
      `applicant/educationSchool/${applicant.rcode}/${applicant.ano}`
    );
  }

  GetApplicantCareer(applicant: Applicant) {
    return this.client.get<Career[]>(
      `applicant/career/${applicant.rcode}/${applicant.ano}`
    );
  }

  GetApplicantLanguage(applicant: Applicant) {
    return this.client.get<Language[]>(
      `applicant/language/${applicant.rcode}/${applicant.ano}`
    );
  }

  GetApplicantCertificate(applicant: Applicant) {
    return this.client.get<Certificate[]>(
      `applicant/certificate/${applicant.rcode}/${applicant.ano}`
    );
  }

  GetApplicantList(rcode: string) {
    return this.client.get<Applicant[]>(`applicant/list/${rcode}`);
  }

  GetApplicantEnrollmentList(rcode: string) {
    return this.client.get<ApplicantEnrollment[]>(
      `applicant/enrollment/${rcode}`
    );
  }
}

class _UserApi {
  private client: HttpClient;

  public constructor(client: HttpClient) {
    this.client = client;
  }

  Login(userId: string, userPassword: string) {
    return this.client.post("login", {
      Id: userId,
      Password: userPassword,
    });
  }
  Logout() {
    client.disableToken();
    return this.client.get("login/logout");
  }
  SetToken(token: string) {
    client.setToken(token);
  }
  InitUserInfo() {
    return this.client.post("login/renewal");
  }

  SSOLogin(token: string | null, gwMail: string | undefined) {
    if (!token) {
      throw new Error("No token for sso-login");
    }
    this.SetToken(token);
    const form = new FormData();
    form.append("gwMail", gwMail || "");
    return this.client.post("login/ssologin", form);
  }
}

class _IssueApi {
  private client: HttpClient;

  public constructor(client: HttpClient) {
    this.client = client;
  }
  save(data: Issue) {
    return this.client.post<InterviewWriteResponse>("interviews", {
      issue: data,
      interview: null,
      aftercares: [],
    });
  }

  getIssue(issueId: number): Promise<Issue> {
    return new Promise((resolve, reject) => {
      this.client
        .get<Issue>(`issues/${issueId}`)
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  }

  getInterviews(issueId: number): Promise<IssueInterviewItem[]> {
    return new Promise((resolve, reject) => {
      this.client
        .get<IssueInterviewItem[]>(`issues/${issueId}/interviews`)
        .then((res) => {
          if (res.status === 204) {
            resolve([]);
          } else {
            resolve(res.data);
          }
        })
        .catch((error) => reject(error));
    });
  }

  search(
    searchData: IssueSearchMiniData | IssueSearch
  ): Promise<SearchResult<IssueListItem>> {
    let k = {
      ...searchData,
      concerneds: searchData.concerneds.map((x) => x.employeeId),
    };

    return new Promise((resolve, reject) => {
      this.client
        .post<SearchResult<IssueListItem>>(`issues/search`, k)
        .then((x) => {
          if (x.status === 204) {
            resolve(new SearchResult());
          } else {
            resolve(x.data);
          }
        })
        .catch((error) => reject(error));
    });
  }
}

class _UserInfoErpApi {
  private client: HttpClient;

  public constructor(client: HttpClient) {
    this.client = client;
  }
  GetUserInfoErp() {
    return client.get("Groupware/userinfoerp");
  }
  GetUserInfoErpByV4() {
    return client.get("Groupware/userinfoerp/v4");
  }
  InsertUserInfoErp(userinfo: UserInfoErp) {
    return client.post("Groupware/userinfoerp", userinfo);
  }
  UpdatetUserInfoErp(userinfo: UserInfoErp) {
    return client.put("Groupware/userinfoerp", { ...userinfo });
  }
  UpdatetOrganization() {
    return client.post("Groupware/userinfoerp/organization");
  }
  DeleteUserInfoErp(id: number) {
    return client.delete(`Groupware/userinfoerp/${id}`);
  }
}

class _EvidenceApi {
  private client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
  GetEvidence(id: number) {
    return client.get(`evidence/${id}`);
  }
  GetEvidenceList(employeeId: string) {
    return client.get(`evidence/employee/${employeeId}`);
  }
  InsertEvidence(evidence: Evidence) {
    return client.post(`evidence`, evidence);
  }
  UpdateEvidence(evidence: Evidence) {
    return client.put(`evidence`, evidence);
  }
  DeleteEvidence(id: number) {
    return client.delete(`evidence/${id}`);
  }
  GetEvidenceContent(id: number, type: string) {
    return client.get(`evidence/${id}/type/${type}`);
  }
}

class _MsGraphApi {
  private client: HttpUrlClient;
  public constructor(client: HttpClient) {
    this.client = new HttpUrlClient(client, "msgraph/");
  }

  /**
   * E1 라이선스 잔여 수량 조회
   * @returns number
   */
  GetE1LicenseCount() {
    return this.client.get<number>(`count/e1-license`);
  }

  /**
   * M365이메일 주소 중복 조회
   */
  GetIsExistMsEmail(email: string) {
    return this.client.get<boolean>(`exist/${email}`);
  }

  /**
   * Ms 그룹 생성
   * @returns number
   */
  CreateMsGroup() {
    return this.client.post(`create-ms-group`);
  }

  /**
   * Ms 유저 생성
   * @returns number
   */
  CreateMsUser() {
    return this.client.post(`create-ms-user`);
  }
}

export const InterviewApi = new _InterviewApi(client);
export const EmployeeApi = new _EmployeeApi(client);
export const InterviewCategoryApi = new _InterivewCategoryApi(
  client,
  "interview"
);
export const IssueCategoryApi = new CategoryApi(client, "issue");
export const AftercareApi = new _AftercareApi(client);
export const UserApi = new _UserApi(client);
export const AttendanceBalanceApi = new _AttendanceBalanceApi(client);
export const AttendanceRequestApi = new _AttendanceRequestApi(client);
export const AttendanceConstantApi = new _AttendanceConstantApi(client);
export const IssueApi = new _IssueApi(client);
export const AttendanceApi = new _AttendanceApi(client);
export const QNAApi = new _QNAApi(client);
export const ScoreApi = new _ScoreApi();
export const DepartmentApi = new _DepartmentApi(client);
export const DataApi = new _DataApi(client);
export const ConstantApi = new _ConstantApi(client);
export const HRCategoryApi = new _HRCategoryApi(client);
export const HREmployeeApi = new _HREmployeeApi(client);
export const RecruitApi = new _RecruitApi(client);
export const UserInfoErpApi = new _UserInfoErpApi(client);
export const EvidenceApi = new _EvidenceApi(client);
export const MSGraphApi = new _MsGraphApi(client);

export function ErrorHandler(error: AxiosError): string {
  let msg = "";
  if (error.response) {
    msg += error.response.data;
    if (msg === "" || msg === "[object Object]") {
      //여전히 빈 상태라면...
      switch (error.response.status) {
        case 401:
          msg = "세션이 만료되었습니다.";
          break;
        case 400: //Bad Request
          msg = "데이터 입력이 잘못되었습니다.";
          break;
        case 403: //Forbidden
          msg = "접근할 수 없습니다.";
          break;
        case 404: //NotFound
          msg = "표시할 데이터가 없습니다.";
          break;
        case 405: //method not allowd
          msg = "405: 접근할 수 없습니다.";
          break;
        case 500:
          msg = "서버 에러가 발생하였습니다.";
          break;
        default:
          msg = "알 수 없는 오류입니다. 관리자에게 문의해주세요";
          break;
      }
    }
  } else {
    msg += "서버에서 데이터를 가져올 수 없습니다.";
  }
  return msg;
}
