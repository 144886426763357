import React from "react";
import TeamsInfoGridDialogEdit from "./TeamsInfoGridDialogEdit";

interface TeamsInfoGridDialogProps {
  info: any;
}

function TeamsInfoGridDialog({ info }: TeamsInfoGridDialogProps) {

  if (info) {
    return (
      <div style={{ width: "1024px", minHeight: "354px" }}>
        <TeamsInfoGridDialogEdit info={info}></TeamsInfoGridDialogEdit>
      </div>
    );
  } else {
    return <div>Loading</div>;
  }
}

export default TeamsInfoGridDialog;
