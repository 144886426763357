import React, { ReactElement, ReactNode } from "react";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-icons/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-splitbuttons/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-lists/styles/material.css";
import "@syncfusion/ej2-navigations/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-richtexteditor/styles/material.css";
//import "style/ViewerStyle.css";
interface Props {
  children: ReactNode;
}

function ContentView({ children }: Props): ReactElement {
  return (
    <div className="e-rte-content" style={{ whiteSpace: "pre-wrap" }}>
      {children}
    </div>
  );
}

export default ContentView;
