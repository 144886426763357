import React, { ReactElement } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { InterviewRecordRoutes } from "src/system/types/type";
import { useUserState } from "src/system/UserContext";

function NavMenuInterview(): ReactElement {
  const user = useUserState();

  return (
    <Navbar expand="md">
      {/* "Link" in brand component since just redirect is needed */}
      <Navbar.Brand as={Link} to="/alarm?page=1&type=all">
        면담록
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          {user.role === "manager" && (
            <Nav.Link as={Link} to="/manage">
              관리자
            </Nav.Link>
          )}
          <Nav.Link as={Link} to="/alarm?page=1&type=all">
            사후관리
          </Nav.Link>
          <Nav.Link as={Link} to={`${InterviewRecordRoutes.interview}/list`}>
            면담 리스트
          </Nav.Link>
          <Nav.Link as={Link} to={`${InterviewRecordRoutes.interview}/write`}>
            면담 작성
          </Nav.Link>
          <Nav.Link as={Link} to={`${InterviewRecordRoutes.issue}/list`}>
            이슈 리스트
          </Nav.Link>
          <Nav.Link as={Link} to={`${InterviewRecordRoutes.issue}/write`}>
            이슈 작성
          </Nav.Link>

          {/* 면담일지 내역 */}
          {/* <NavDropdown title="면담일지" id="basic-nav-dropdown"> */}
          {/* "NavLink" here since "active" class styling is needed */}
          {/* 스타일링이 필요한 NavDropdown이나 Nav.Link는 react-router의 NavLink로 매핑해야한다. */}
          {/* <NavDropdown.Item as={NavLink} to="/interview/list">
              면담 리스트
            </NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/interview/write">
              새로운 면담 쓰기
            </NavDropdown.Item> */}
          {/* </NavDropdown> */}
          {/* <Nav.Link as={Link} to={CommonRoutes.logout}>
            로그아웃
          </Nav.Link> */}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavMenuInterview;
