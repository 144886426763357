import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAttendanceStyles } from '../style/useAttendanceStyle';
import { AttendanceDocumentForm } from '../PostAttendanceRequest';


export default function HelpText() {
  const { watch } = useFormContext<AttendanceDocumentForm>();
  const [helpTextList, setHelpTextList] = useState<string[]>([]);
  const formList = watch('attendanceItem');
  const classes = useAttendanceStyles();

  useEffect(() => {
    const newHelpText = new Set<string>();
    formList.forEach(form => newHelpText.add(form.content));
    setHelpTextList(Array.from(newHelpText));
  }, [formList])

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table className={classes.table} size='small'>
        <TableBody>
          {
            helpTextList.map((text, index) => {
              return (
                text !== '' &&
                <TableRow key={index}>
                  <TableCell component={'th'}>
                    <div className='info-text'>
                      {text}
                    </div>
                  </TableCell>
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>
    </TableContainer >
  );
}
