import MaterialTable from "material-table";
import React, { ReactElement } from "react";
import { DepartmentApi } from "src/system/ApiService";
import { TABLE_HEADER } from "src/system/Constants";
import { LMATERIAL_TABLE } from "src/system/Localization";
import { DeptInfoErp } from "src/system/types";

interface Props {
  data: DeptInfoErp[];
  dictionary?: { [key: string]: string };
  refreshData: () => void;
}

function DeptInfoErpTableGrid({
  data,
  dictionary,
  refreshData,
}: Props): ReactElement {
  const ref = React.createRef<MaterialTable<DeptInfoErp>>();
  const dic = dictionary

  return (
    <div style={{ maxWidth: "100%", padding: "30px 100px 50px 100px" }}>
      <MaterialTable
        ref={ref}
        title="파트/팀 관리"
        options={{
          actionsColumnIndex: -1,
          search: true,
          padding: "dense",
          paging: true,
          pageSize: 15,
          pageSizeOptions: [15, 30, 100],
          filtering: true,
          headerStyle: TABLE_HEADER,
        }}
        style={{ fontSize: "14px" }}
        editable={{
          onRowAdd: (newData) => {
            return DepartmentApi.UpsertDeptInfoErp({
              ...newData,
              name: newData.erpName,
              costCenter: '',
              legalEntity: "IHQ",
              status: "Active",
            }).then((res) => {
              //console.log(res);
              refreshData();
            });
          },
          onRowUpdate: (newData, oldData) => {
            return DepartmentApi.UpsertDeptInfoErp({
              ...newData,
              name: newData.erpName,
            }).then((res) => {
              //console.log(res);
              refreshData();
            });
          },
          // onRowDelete: (oldData) => {
          //   return DepartmentApi.DeleteDeptInfoErp(oldData.costCenter).then((res) => {
          //     console.log(res);
          //     refreshData();
          //   });
          // },
        }}
        columns={[
          { title: "연도", field: "year", cellStyle: { width: "10%" } },
          {
            title: "상위 부서",
            field: "seniorDeptCostCenter",
            lookup: dic,
            cellStyle: { width: "15%" },
          },
          { title: "부서명", field: "erpName", cellStyle: { width: "15%" } },
          {
            title: "부서 Code",
            field: "costCenter",
            cellStyle: { width: "15%" },
            editable: 'never'
          },
          {
            title: "e-mail",
            field: "departmentMailNickName",
            cellStyle: { width: "20%" },
          },
          {
            title: "상태",
            field: "status",
            cellStyle: { width: "10%" },
            initialEditValue: "Active",
          },
        ]}
        data={data}
        localization={LMATERIAL_TABLE}
      ></MaterialTable>
    </div>
  );
}

export default DeptInfoErpTableGrid;
