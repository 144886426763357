import { Grid, Typography } from "@material-ui/core";
import React, { ReactElement, useEffect } from "react";
import RegularColgroup from "src/components/RegularColgroup";
import TableInsaCard from "src/components/TableInsaCard";
import BasicUser from "src/page/card/basic/BasicUser";
import { ErrorHandler, HREmployeeApi } from "src/system/ApiService";
import { getDisplayTime } from "src/system/Helper";
import { InitPersonalData, PersonalData } from "src/system/types";
import CareerTable from "./CareerTable";
import EducationSchoolTable from "./EducationSchoolTable";
import FamilyRecordTable from "./FamilyRecordTable";

interface Props {
  employeeId: string;
}

export function MainPage({ employeeId }: Props): ReactElement {
  const [data, setData] = React.useState<PersonalData>(InitPersonalData);
  //const [state, setState] = React.useState<"edit" | "save">("edit");

  useEffect(() => {
    // API 호출
    if (employeeId !== "") {
      HREmployeeApi.GetPersonalData(employeeId)
        .then((res) => {
          setData(res);
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          alert(msg);
        });
    }
  }, [employeeId]);

  if (data.name !== "") {
    return (
      <div id="print-section">
        <Grid container spacing={2} style={{ minWidth: "1024px" }}>
          <Grid item md={12} key="기본정보">
            <BasicUser personalData={data}></BasicUser>
          </Grid>
          <Grid item md={12} key="학력_경력_병역사항">
            <Grid item xs={12} key="학력사항">
              <EducationSchoolTable
                employeeId={employeeId}
              ></EducationSchoolTable>
            </Grid>
            <Grid item xs={12} key="경력사항">
              <CareerTable employeeId={employeeId}></CareerTable>
            </Grid>
            <Grid item md={12} key="병역사항">
              <TableInsaCard
                title="병역사항"
                caption={getDisplayTime(data.serviceLastModified)}
                colgroup={<RegularColgroup cols={8}></RegularColgroup>}
              >
                <tr>
                  <th>병역구분</th>
                  <td>{data.serviceStatus}</td>
                  <th>군별</th>
                  <td>{data.serviceType}</td>
                  <th>병과</th>
                  <td>{data.serviceSpeciality}</td>
                  <th>계급</th>
                  <td>{data.serviceRank}</td>
                </tr>
                <tr>
                  <th>제대구분</th>
                  <td>{data.serviceDischargeCategory}</td>
                  <th>복무 시작일</th>
                  <td>{getDisplayTime(data.serviceStarted)}</td>
                  <th>복무 종료일</th>
                  <td>{getDisplayTime(data.serviceEnded)}</td>
                  <th>전문연구요원</th>
                </tr>
                <tr>
                  <th>비고</th>
                  <td colSpan={7}>{data.serviceRemark}</td>
                </tr>
              </TableInsaCard>
            </Grid>
            <Grid item md={12} key="장애사항">
              <TableInsaCard
                title="장애사항"
                caption={getDisplayTime(data.disabilityLastModified)}
                colgroup={<RegularColgroup cols={8}></RegularColgroup>}
              >
                <tr>
                  <th>장애여부</th>
                  <td>{data.isDisability ? "대상" : "비대상"}</td>
                  <th>장애등급</th>
                  <td>{data.disabilityRank}</td>
                  <th>장애내용</th>
                  <td>{data.disabilityContent}</td>
                  <th>판정일</th>
                  <td>{getDisplayTime(data.disabilityOnsetDate)}</td>
                </tr>
                <tr>
                  <th>비고</th>
                  <td colSpan={7}></td>
                </tr>
              </TableInsaCard>
            </Grid>
            <Grid item md={12} key="보훈사항">
              <TableInsaCard
                title="보훈사항"
                caption={getDisplayTime(data.veteransLastModified)}
                colgroup={<RegularColgroup cols={6}></RegularColgroup>}
              >
                <tr>
                  <th>보훈여부</th>
                  <td>{data.isVeterans ? "대상" : "비대상"}</td>
                  <th>보훈번호</th>
                  <td>{data.veteransNumber}</td>
                  <th>보훈관계</th>
                  <td>{data.veteransRelation}</td>
                  <th>보훈비율</th>
                  <td>
                    {data.veteransRatio !== null && `${data.veteransRatio}%`}
                  </td>
                </tr>
                <tr>
                  <th>비고</th>
                  <td colSpan={7}>{data.veteransRemark}</td>
                </tr>
              </TableInsaCard>
            </Grid>
          </Grid>
          <Grid item md={12} container spacing={1} alignContent="flex-start">
            <Grid item xs={12} key="가족사항">
              <FamilyRecordTable employeeId={employeeId}></FamilyRecordTable>
            </Grid>
            <Grid item xs={12} key="계좌정보">
              <TableInsaCard
                title="계좌정보"
                caption={getDisplayTime(data.accountLastModified)}
                colgroup={<RegularColgroup cols={2}></RegularColgroup>}
              >
                <tr>
                  <th>은행</th>
                  <td>{data.bank}</td>
                </tr>
                <tr>
                  <th>계좌정보</th>
                  <td>{data.number}</td>
                </tr>
                <tr>
                  <th>예금주</th>
                  <td>{data.holder}</td>
                </tr>
              </TableInsaCard>
            </Grid>
            {/* <Grid item xs={12} key="기본정보 변경 요청사항">
              <TableInsaCard title="기본정보 변경 요청사항" height={300}>
                <tr>
                  <th>No</th>
                  <th>신청일자</th>
                  <th>변경항목</th>
                  <th>승인상태</th>
                  <th>승인일자</th>
                </tr>
                {[1, 2].map((x, i) => (
                  <tr key={`rea-${i}`}>
                    <td className="text-center">{x}</td>
                    <td>2021.06.12</td>
                    <td>기본정보 외 3</td>
                    <td>대기</td>
                    <td>-</td>
                  </tr>
                ))}
              </TableInsaCard>
            </Grid> */}
          </Grid>
        </Grid>
      </div>
    );
  } else {
    return (
      <div>
        <Typography variant="body1">검색결과가 없습니다.</Typography>
      </div>
    );
  }
}

export default MainPage;
