import { format } from "date-fns";
import { ComboboxItem } from "src/components/TitleOneComboBox";
import { Employee } from "./types";

export const DATE_NO_DATA = "내용 없음";

export function getDateUsingNumber(item: number | undefined): string {
  if (item) {
    return getDateUsingDate(new Date(item));
  } else {
    return DATE_NO_DATA;
  }
}
export function getDateUsingDate(date: Date): string {
  return format(date, "yyyy/MM/dd");
}

export function CalculateFileSize(size: number): string {
  return size > 1048576 ? `(${(size / 1048576).toFixed(1)}MB)` : `(${(size / 1024).toFixed(1)}KB)`;
}

export const isInArray = (arr: Array<string>, value: any) => {
  return arr.findIndex((x) => x === value) !== -1;
};

export function formatBytes(bytes: number, decimals = 2): string {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function GetComboboxList(empSelectOptions: ComboboxItem[], employees: Employee[] | string[]): ComboboxItem[] {
  if (employees.length >= 1) {
    let readerKey: string[] = [];

    if (instanceOfEmployee(employees[0])) {
      const arr = employees as Employee[];
      readerKey = arr.map((x) => x.employeeId);
    } else {
      readerKey = employees as string[];
    }
    return empSelectOptions.filter((x) => readerKey.includes(x.key));
  } else {
    return [];
  }
}

export function instanceOfEmployee(object: any): object is Employee {
  return "employeeId" in object;
}

export function getDisplayTime(time: string | Date | null) {
  if (time !== null && time !== undefined) {
    var strTime = time.toString();
    if (strTime.includes("+")) {
      // Date인 경우 - CrudUrl in Grid 기능 사용 시 자동으로 Date 타입으로 바인딩되어서 구분해야함
      return getDateUsingDate(time as Date).replaceAll("/", ".");
    } else {
      // string인 경우
      if (strTime.indexOf("T") > 0) return strTime.split("T")[0].replaceAll("-", ".");
      else return strTime.replaceAll("-", ".");
    }
  } else {
    return "";
  }
}

// 생일로 만나이 구하기
export function getAgeByBirthDay(time: string) {
  var birthday = new Date(time);
  var today = new Date();
  var years = today.getFullYear() - birthday.getFullYear();
  birthday.setFullYear(today.getFullYear());
  if (today < birthday) years = years - 1;
  return years;
}
