import { Button, Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React, { ReactElement } from "react";
import { DepartmentApi, ErrorHandler } from "src/system/ApiService";
import { Department, DeptInfoErp, GridData, Team, YEARS } from "src/system/types";
import DeptInfoErpTableGrid from "./DeptInfoErpTableGrid";

export interface DState extends GridData {
  result: Department[];
  count: number;
}

export interface TState extends GridData {
  result: Team[];
  count: number;
}

export interface DeptInfoErps extends GridData {
  result: DeptInfoErp[];
  count: number;
}

function MainPage(): ReactElement {
  const [deptInfoErp, setDeptInfoErp] = React.useState<DeptInfoErps>({
    result: [],
    count: 0,
  });
  const [deptDic, setDeptDic] = React.useState<{ [key: string]: string }>();
  const [year, setYear] = React.useState<number>(2022);

  const refreshDeptInfoErp = React.useCallback(() => {
    DepartmentApi.GetDeptInfoErp(year).then((res) => {
      setDeptInfoErp(res);
      let dic: { [key: string]: string } = {};
      res.result.forEach((dept) => {
        if (!dept.costCenter.includes("C") && dept.status === "Active") {
          // 최하위 부서인 팀 코드(C)있는 경우 제외 + 활성화 팀만
          dic[dept.costCenter] = dept.name;
        }
      });
      setDeptDic({
        ...dic,
        Root: "인바디"
      });
    });
  }, [year]);

  const handleChangeYear = (event: React.ChangeEvent<{ value: unknown }>) => {
    setYear(event.target.value as number);
  };

  React.useEffect(() => {
    refreshDeptInfoErp();
  }, [refreshDeptInfoErp]);

  return (
    <div>
      <Alert severity="info">
        <AlertTitle>도움말</AlertTitle>
        <ul>
          <li>조직도를 관리할 수 있습니다.</li>
        </ul>
      </Alert>
      <hr></hr>
      {/* 연도 선택 */}
      <div style={{ maxWidth: "100%", paddingLeft: "100px" }}>
        <Grid container>
          <Grid item xs={3}>
            <InputLabel id="demo-simple-select-label">연도 선택</InputLabel>
            <Select value={year} onChange={handleChangeYear} style={{ width: "200px" }}>
              <MenuItem value={0} key={`years-all`}>
                전체
              </MenuItem>
              {YEARS.map((u, i) => (
                <MenuItem value={u} key={`years-${u}`}>
                  {u}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </div>
      <DeptInfoErpTableGrid
        data={deptInfoErp.result}
        dictionary={deptDic}
        refreshData={refreshDeptInfoErp}
      ></DeptInfoErpTableGrid>
    </div>
  );
}

export default MainPage;
