import React, { ReactElement, useEffect, useState } from "react";
import { TreeViewComponent } from "@syncfusion/ej2-react-navigations";
import {
  DepartmentApi,
  ErrorHandler,
  HREmployeeApi,
} from "src/system/ApiService";
import { DeptTreeView, EmployeeAutoComplete } from "src/system/types";
import { Grid } from "@material-ui/core";
import {
  ColumnDirective,
  ColumnsDirective,
  Filter,
  GridComponent,
  Group,
  Inject,
  Page,
  Sort,
  ToolbarItems,
  Toolbar,
  Resize,
} from "@syncfusion/ej2-react-grids";
import { InitDeptTreeView } from "src/system/types";
import { Alert, AlertTitle } from "@material-ui/lab";

function MainLayout(): ReactElement {
  const [dept, setDept] = useState<DeptTreeView>(InitDeptTreeView);
  const [empList, setEmpList] = useState<EmployeeAutoComplete[]>([]);
  const [filteredList, setFilteredList] = useState<EmployeeAutoComplete[]>([]);

  const field: Object = {
    dataSource: [dept],
    id: "id",
    text: "name",
    child: "subChild",
  };
  const style: string = "accordiontree";
  const toolbarOptions: ToolbarItems[] = ["Search"];

  var treeObj: TreeViewComponent;

  useEffect(() => {
    // 현재연도 조직도 불러오기
    DepartmentApi.GetDeptTreeView(new Date().getFullYear())
      .then((res) => {
        if (res !== undefined) {
          res.subChild?.forEach((x) => {
            if (x.name !== "지사") x.expanded = true;
          });
          setDept({ ...res, expanded: true });
        }
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        console.log(msg);
      });
    // 전체사원 리스트 조회
    HREmployeeApi.GetEmployeeList()
      .then((res) => {
        setEmpList(res);
        setFilteredList(res);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        console.log(msg);
      });
  }, []);

  // 노드 클릭 이벤트
  const nodeClicked = (args: any): void => {
    let checkedNode: any = [args.node];
    if (
      args.event.target.classList.contains("e-fullrow") ||
      args.event.key === "Enter"
    ) {
      let getNodeDetails: any = treeObj.getNode(args.node);
      if (getNodeDetails.isChecked === "true") {
        treeObj.uncheckAll(checkedNode);
      } else {
        treeObj.checkAll(checkedNode);
      }
    }

    let list: EmployeeAutoComplete[] = [];
    treeObj.checkedNodes.forEach((x) => {
      empList.forEach((emp) => {
        if (emp.teamId === x ||
          (!emp.teamId && emp.departmentId === x) ||
          (!emp.teamId && !emp.departmentId && emp.groupId === x)) {
          list.push(emp);
        }
      });
    });
    setFilteredList(list);
  };

  return (
    <div>
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            textAlign: "center",
            marginTop: "15px",
            marginBottom: "15px",
            padding: "10px",
          }}
        >
          <img src="/images/main_logo.png" width="200px" alt="InBody"></img>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "5px" }}>
          <Alert severity="info">
            <AlertTitle>도움말</AlertTitle>
            <ul>
              <li>
                {
                  "본사 내선 번호는 앞자리에 따라 구분 : 02)300-2***, 02)2182-8***,1***,4***"
                }
              </li>
              <li>{"공장 내선 국번 : 041)589-내선번호"}</li>
              <li>{"양재 내선 국번 : 02)6952-내선번호"}</li>
            </ul>
            <ul>
              <li>{"벤처센터 대표 국번 : 02)6952-8981"}</li>
              <li>{"벤처센터 내선 국번 : 070)4016-6***"}</li>
              <li>
                {"벤처센터 6F FAX : 02-6952-8982 / 7F FAX : 02-6952-8987"}
              </li>
              <li>{"LookinBody 내선 국번 : 02)2098-내선번호"}</li>
            </ul>
          </Alert>
        </Grid>
        <Grid item xs={5} md={4} lg={3} xl={2} style={{ padding: "10px" }}>
          <TreeViewComponent
            fields={field}
            cssClass={style}
            showCheckBox={true}
            ref={(treeview) => (treeObj = treeview as TreeViewComponent)}
            nodeClicked={nodeClicked}
          ></TreeViewComponent>
        </Grid>
        <Grid item xs={7} md={8} lg={9} xl={10}>
          <GridComponent
            dataSource={filteredList}
            allowPaging={true}
            allowResizing={true}
            pageSettings={{ pageSize: 20 }}
            allowFiltering={true}
            toolbar={toolbarOptions}
          >
            <ColumnsDirective>
              <ColumnDirective field="name" headerText="이름" width="50" />
              <ColumnDirective
                field="gwName"
                headerText="TEAMS 이름"
                width="70"
              />
              <ColumnDirective field="group" headerText="그룹" width="80" />
              <ColumnDirective
                field="department"
                headerText="파트"
                width="80"
              />
              <ColumnDirective field="team" headerText="팀" width="80" />
              <ColumnDirective field="jobTitle" headerText="직책" width="50" />
              <ColumnDirective
                field="jobPosition"
                headerText="직급"
                width="50"
              />
              <ColumnDirective field="email" headerText="메일" width="100" />
              <ColumnDirective field="phone" headerText="연락처" width="80" />
              <ColumnDirective field="tel" headerText="내선" width="50" />
            </ColumnsDirective>
            <Inject services={[Page, Sort, Filter, Group, Toolbar, Resize]} />
          </GridComponent>
        </Grid>
      </Grid>
    </div>
  );
}

export default MainLayout;
