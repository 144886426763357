import React, { ChangeEvent, ReactElement, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import InputPersonalData from "./InputPersonalData";
import {
  Applicant,
  Career,
  Certificate,
  DeptInfoErp,
  EducationSchool,
  FamilyMember,
  HRCategory,
  InitCareer,
  InitCertificate,
  InitEducationSchool,
  InitFamilyMember,
  InitLanguage,
  InitRecruitPersonalData,
  InitTextFieldError,
  Language,
  PersonalData,
  UserInfoErpRequest,
} from "src/system/types";
import InputEducationSchoolData from "./InputEducationSchoolData";
import InputFamilyData from "./InputFamilyData";
import InputCertificateData from "./InputCertificateData";
import InputLanguageData from "./InputLanguageData";
import InputCareerData from "./InputCareerData";
import { ErrorHandler, HREmployeeApi } from "src/system/ApiService";
import ProgressModal from "src/components/ProgressModal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    button: {
      marginRight: theme.spacing(1),
    },
    completed: {
      display: "inline-block",
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);

interface WritePageProps {
  data: any;
  applicant: Applicant;
  refresh: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

function WritePage({
  data,
  applicant,
  refresh,
  setRefresh,
}: WritePageProps): ReactElement {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{ [k: number]: boolean }>(
    {}
  );
  const [pd, setPd] = React.useState<PersonalData>(data.personalData);
  const [es, setEs] = React.useState<EducationSchool[]>(data.educationSchool);
  const [fm, setFm] = React.useState<FamilyMember[]>([]);
  const [ca, setCa] = React.useState<Career[]>(data.career);
  const [ce, setCe] = React.useState<Certificate[]>(data.certificate);
  const [la, setLa] = React.useState<Language[]>(data.language);
  const [TextFieldError, setTextFieldError] =
    React.useState(InitTextFieldError);
  const [loading, setLoading] = React.useState<boolean>(false);

  const steps = ["개인정보", "학력사항", "가족사항", "경력/외국어/자격 사항"];

  const handleValueChange = React.useCallback(
    function handleValueChange(tab: string, name: any, value: string) {
      if (tab === "personalData") {
        let newthing = { ...pd };
        if (name === "address") {
          // 주소
          var temp = value.split("/");
          newthing["address1"] = temp[0];
          newthing["zip"] = temp[1];
        } else {
          // 그 외
          newthing[name] = value;
        }
        setPd(newthing);

        if (name !== "employeeId") {
          if (value === "") {
            setTextFieldError({
              ...TextFieldError,
              [name]: { isError: true, message: "필수 입력사항입니다." },
            });
          } else {
            setTextFieldError({
              ...TextFieldError,
              [name]: { isError: false, message: "" },
            });
          }
        }
      }
    },
    [TextFieldError, pd]
  );

  const handleValueChangeIndex = React.useCallback(
    function handleValueChangeIndex(
      tab: string,
      name: any,
      value: string,
      index: number
    ) {
      if (tab === "educationSchool") {
        const newthing = [...es];
        newthing[index] = { ...newthing[index], [name]: value };
        setEs([...newthing]);
      } else if (tab === "family") {
        const newthing = [...fm];
        newthing[index] = { ...newthing[index], [name]: value };
        setFm([...newthing]);
      } else if (tab === "career") {
        const newthing = [...ca];
        newthing[index] = { ...newthing[index], [name]: value };
        setCa([...newthing]);
      } else if (tab === "certificate") {
        const newthing = [...ce];
        newthing[index] = { ...newthing[index], [name]: value };
        setCe([...newthing]);
      } else if (tab === "language") {
        const newthing = [...la];
        newthing[index] = { ...newthing[index], [name]: value };
        setLa([...newthing]);
      }
    },
    [es, fm, ca, ce, la]
  );

  useEffect(() => {
    //handleReset();
    setPd(data.personalData);
    setEs(data.educationSchool);
    setCa(data.career);
    setCe(data.certificate);
    setLa(data.language);
    // 채용정보 불러오기 했을 경우 유효성 체크 다시하도록
    let updateTFE = TextFieldError;
    for (let textFieldError in TextFieldError) {
      let str = data.personalData[textFieldError];
      if (
        textFieldError !== "gwMail" &&
        str !== null &&
        str !== "" &&
        str !== undefined
      ) {
        updateTFE[textFieldError] = { isError: false, message: "" };
      }
    }
    setTextFieldError(updateTFE);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  //#region InputPersonalData handler

  const onChangeTextPD = React.useCallback(
    function onChangeTextPD(e: ChangeEvent<HTMLInputElement>) {
      const { name, value } = e.target;
      handleValueChange("personalData", name, value);
    },
    [handleValueChange]
  );

  const onChangeSelectPD = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const { name, value } = e.target;

    if (name !== undefined) {
      if (name === "departmentId") {
        setPd({
          ...pd,
          teamId: "",
          departmentId: value as string,
        });
      } else if (name === "groupId") {
        setPd({
          ...pd,
          departmentId: "",
          teamId: "",
          groupId: value as string,
        });
      } else {
        setPd({ ...pd, [name]: value });
      }
    }
  };

  const onChangeSelect = (
    tab: string,
    e: React.ChangeEvent<{ name?: string; value: unknown }>,
    index: number
  ) => {
    const { name, value } = e.target;
    if (name !== undefined) {
      if (tab === "certificate") {
        const newthing = [...ce];
        newthing[index] = { ...newthing[index], [name]: value };
        setCe(newthing);
      } else if (tab === "language") {
        const newthing = [...la];
        newthing[index] = { ...newthing[index], [name]: value };
        setLa(newthing);
      }
    }
  };

  const onChangeValuePD = React.useCallback(
    function onChangeValuePD(name: string, value: string) {
      handleValueChange("personalData", name, value);
    },
    [handleValueChange]
  );

  const onChangeAutoCompletePD = (
    event: React.ChangeEvent<{}>,
    value: string | null,
    name: string
  ) => {
    if (name !== undefined) {
      setPd({ ...pd, [name]: value });
    }
  };

  const onChangeAutoCompleteTeam = (
    event: React.ChangeEvent<{}>,
    value: DeptInfoErp | null,
    name: string
  ) => {
    setPd({ ...pd, [name]: value });
    if (value === null) {
      setTextFieldError({
        ...TextFieldError,
        team: { isError: true, message: "필수 입력사항입니다." },
      });
    } else {
      setTextFieldError({
        ...TextFieldError,
        team: { isError: false, message: "" },
      });
    }
  };

  const onChangeAutoCompleteCategory = (
    event: React.ChangeEvent<{}>,
    value: HRCategory | null,
    name: string
  ) => {
    setPd({ ...pd, [name]: value });
    if (value === null) {
      setTextFieldError({
        ...TextFieldError,
        [name]: { isError: true, message: "필수 입력사항입니다." },
      });
    } else {
      setTextFieldError({
        ...TextFieldError,
        [name]: { isError: false, message: "" },
      });
    }
  };
  //#endregion

  //#region InputIndexModel handler
  const onChangeText = React.useCallback(
    function onChangeText(
      tab: string,
      e: ChangeEvent<HTMLInputElement>,
      index: number
    ) {
      const { name, value } = e.target;
      handleValueChangeIndex(tab, name, value, index);
    },
    [handleValueChangeIndex]
  );

  const onChangeCheck = React.useCallback(
    function onChangeCheck(
      tab: string,
      e: ChangeEvent<HTMLInputElement>,
      index: number
    ) {
      const { name, checked } = e.target;
      if (tab === "educationSchool") {
        const newthing = [...es];
        // 최종학력은 최대 1개만 선택할 수 있도록
        if (checked) {
          for (let i = 0; i < newthing.length; i++) {
            if (i === index) {
              newthing[index] = { ...newthing[index], [name]: checked };
            } else {
              newthing[i] = { ...newthing[i], [name]: false };
            }
          }
        } else {
          newthing[index] = { ...newthing[index], [name]: checked };
        }
        setEs(newthing);
        return;
      } else if (tab === "family") {
        const newthing = [...fm];
        newthing[index] = { ...newthing[index], [name]: checked };
        setFm(newthing);
      } else if (tab === "personalData") {
        const newthing = { ...pd };
        newthing["isMSAccountCreate"] = checked;
        setPd(newthing);
      }
    },
    [es, fm, pd]
  );

  const onChangeValue = React.useCallback(
    function onChangeValue(
      tab: string,
      name: string,
      value: string,
      index: number
    ) {
      handleValueChangeIndex(tab, name, value, index);
    },
    [handleValueChangeIndex]
  );

  const onChangeAutoComplete = (
    tab: string,
    event: React.ChangeEvent<{}>,
    value: string | null,
    name: string,
    index: number
  ) => {
    if (name !== undefined) {
      if (tab === "educationSchool") {
        const newthing = [...es];
        newthing[index] = { ...newthing[index], [name]: value };
        setEs([...newthing]);
      } else if (tab === "family") {
        const newthing = [...fm];
        newthing[index] = { ...newthing[index], [name]: value };
        setFm([...newthing]);
      } else if (tab === "career") {
        const newthing = [...ca];
        newthing[index] = { ...newthing[index], [name]: value };
        setCa([...newthing]);
      } else if (tab === "certificate") {
        const newthing = [...ce];
        newthing[index] = { ...newthing[index], [name]: value };
        setCe([...newthing]);
      } else if (tab === "language") {
        const newthing = [...la];
        newthing[index] = { ...newthing[index], [name]: value };
        setLa([...newthing]);
      }
    }
  };

  const onChangeFile = (
    tab: string,
    e: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.target.files !== null) {
      if (tab === "certificate") {
        const newthing = [...ce];
        newthing[index] = { ...newthing[index], file: e.target.files[0] };
        setCe([...newthing]);
      } else if (tab === "language") {
        const newthing = [...la];
        newthing[index] = { ...newthing[index], file: e.target.files[0] };
        setLa([...newthing]);
      }
    }
  };
  //#endregion

  const onAddRows = (tab: string) => {
    if (tab === "educationSchool") {
      let updateData = es;
      setEs(updateData.concat(InitEducationSchool));
    } else if (tab === "family") {
      let updateData = fm;
      setFm(updateData.concat(InitFamilyMember));
    } else if (tab === "career") {
      let updateData = ca;
      setCa(updateData.concat(InitCareer));
    } else if (tab === "certificate") {
      let updateData = ce;
      setCe(updateData.concat(InitCertificate));
    } else if (tab === "language") {
      let updateData = la;
      setLa(updateData.concat(InitLanguage));
    }
  };

  const onRemoveRows = (tab: string, index: number) => {
    if (tab === "educationSchool") {
      let updateData = es;
      updateData.splice(index, 1);
      setEs([...updateData]);
    } else if (tab === "family") {
      let updateData = fm;
      updateData.splice(index, 1);
      setFm([...updateData]);
    } else if (tab === "career") {
      let updateData = ca;
      updateData.splice(index, 1);
      setCa([...updateData]);
    } else if (tab === "certificate") {
      let updateData = ce;
      updateData.splice(index, 1);
      setCe([...updateData]);
    } else if (tab === "language") {
      let updateData = la;
      updateData.splice(index, 1);
      setLa([...updateData]);
    }
  };

  //#region Stepper 관련 함수
  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <InputPersonalData
            data={pd}
            TextFieldError={TextFieldError}
            setTextFieldError={setTextFieldError}
            onChangeText={onChangeTextPD}
            onChangeSelect={onChangeSelectPD}
            onChangeValue={onChangeValuePD}
            onChangeCheck={onChangeCheck}
            onChangeAutoComplete={onChangeAutoCompletePD}
            onChangeAutoCompleteTeam={onChangeAutoCompleteTeam}
            onChangeAutoCompleteCategory={onChangeAutoCompleteCategory}
          ></InputPersonalData>
        );
      case 1:
        return (
          <InputEducationSchoolData
            data={es}
            onAddRows={onAddRows}
            onRemoveRows={onRemoveRows}
            onChangeCheck={onChangeCheck}
            onChangeText={onChangeText}
            onChangeValue={onChangeValue}
            onChangeAutoComplete={onChangeAutoComplete}
          ></InputEducationSchoolData>
        );
      case 2:
        return (
          <InputFamilyData
            data={fm}
            onAddRows={onAddRows}
            onRemoveRows={onRemoveRows}
            onChangeCheck={onChangeCheck}
            onChangeText={onChangeText}
            onChangeValue={onChangeValue}
            onChangeAutoComplete={onChangeAutoComplete}
          ></InputFamilyData>
        );
      case 3:
        return (
          <>
            <InputCareerData
              data={ca}
              onAddRows={onAddRows}
              onRemoveRows={onRemoveRows}
              onChangeText={onChangeText}
              onChangeValue={onChangeValue}
              onChangeAutoComplete={onChangeAutoComplete}
            ></InputCareerData>
            <InputCertificateData
              data={ce}
              onAddRows={onAddRows}
              onRemoveRows={onRemoveRows}
              onChangeText={onChangeText}
              onChangeFile={onChangeFile}
              onChangeSelect={onChangeSelect}
              onChangeValue={onChangeValue}
              onChangeAutoComplete={onChangeAutoComplete}
            ></InputCertificateData>
            <InputLanguageData
              data={la}
              onAddRows={onAddRows}
              onRemoveRows={onRemoveRows}
              onChangeText={onChangeText}
              onChangeFile={onChangeFile}
              onChangeSelect={onChangeSelect}
              onChangeValue={onChangeValue}
              onChangeAutoComplete={onChangeAutoComplete}
            ></InputLanguageData>
          </>
        );
      default:
        return "Unknown step";
    }
  }

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  // 유효성 검사
  const handleComplete = () => {
    let errorCode = 0;
    switch (activeStep) {
      case 0:
        // 개인정보 유효성
        for (let textFieldError in TextFieldError) {
          let temp = TextFieldError[textFieldError];
          if (temp.isError) {
            errorCode = 10;
          }
        }
        if (pd.teamId === "" && pd.departmentId === "" && pd.groupId === "") {
          errorCode = 10;
        }
        break;
      case 1:
        // 학력사항 유효성
        let temp = es.find((x) => x.isFinal);
        if (temp === undefined) {
          errorCode = 20;
        }
        break;
      case 2:
        // 가족사항 유효성
        break;
      case 3:
        // 경력/외국어/자격 사항 유효성
        break;
    }
    // 유효성 검사 실패 시 메시지 출력
    switch (errorCode) {
      case 10:
        alert("입력값을 확인해주세요.");
        break;
      case 20:
        alert("최종학력을 선택해주세요.");
        break;
      default: {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
        break;
      }
    }
    // 모든 스탭 완료 시 저장
    if (allStepsCompleted()) {
      onAddEmployee();
    }
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
    setTextFieldError(InitTextFieldError);
    setPd(InitRecruitPersonalData);
    setEs(new Array<EducationSchool>());
    setCa(new Array<Career>());
    setCe(new Array<Certificate>());
    setLa(new Array<Language>());
    setRefresh(!refresh);
  };
  //#endregion

  const onAddEmployee = () => {
    // Loading Modal
    setLoading(true);
    // 개인정보 탭 Insert
    HREmployeeApi.UpsertPersonalData({
      ...pd,
      teamId: pd.teamId,
      departmentId: pd.departmentId,
      groupId: pd.groupId,
      workStatusId: pd.wsCategory?.id,
      workStatus: pd.wsCategory?.name,
      employmentFormId: pd.efCategory?.id,
      employmentForm: pd.efCategory?.name,
    })
      .then((res) => {
        // 학력사항 Insert
        es.forEach((x) => {
          HREmployeeApi.InsertEducationSchoolData({
            ...x,
            employeeId: pd.employeeId,
          })
            .then((res) => {
              console.log(res);
            })
            .catch((error) => {
              let msg = ErrorHandler(error);
              alert(msg);
            });
        });
        // 가족사항 insert
        fm.forEach((x) => {
          HREmployeeApi.InsertFamilyData({ ...x, employeeId: pd.employeeId })
            .then((res) => {
              console.log(res);
            })
            .catch((error) => {
              let msg = ErrorHandler(error);
              alert(msg);
            });
        });
        // 경력사항 insert
        ca.forEach((x) => {
          HREmployeeApi.InsertCareerData({ ...x, employeeId: pd.employeeId })
            .then((res) => {
              console.log(res);
            })
            .catch((error) => {
              let msg = ErrorHandler(error);
              alert(msg);
            });
        });
        // 외국어사항 insert
        la.forEach((x) => {
          if (x.evidence === "파일첨부" && x.file !== undefined) {
            // 파일 첨부
            const formData = new FormData();
            formData.append("file", x.file, x.file.name);
            HREmployeeApi.UploadFile(formData)
              .then((res) => {
                let fileId = res;
                HREmployeeApi.InsertLanguage({
                  ...x,
                  employeeId: pd.employeeId,
                  fileId: fileId,
                })
                  .then((res) => {
                    console.log(res);
                  })
                  .catch((error) => {
                    let msg = ErrorHandler(error);
                    alert(msg);
                  });
              })
              .catch((error) => {
                let msg = ErrorHandler(error);
                alert(msg);
              });
          } else {
            HREmployeeApi.InsertLanguage({ ...x, employeeId: pd.employeeId })
              .then((res) => {
                console.log(res);
              })
              .catch((error) => {
                let msg = ErrorHandler(error);
                alert(msg);
              });
          }
        });
        // 자격사항 insert
        ce.forEach((x) => {
          if (x.evidence === "파일첨부" && x.file !== undefined) {
            // 파일 첨부
            const formData = new FormData();
            formData.append("file", x.file, x.file.name);
            HREmployeeApi.UploadFile(formData)
              .then((res) => {
                let fileId = res;
                HREmployeeApi.InsertCertificateData({
                  ...x,
                  employeeId: pd.employeeId,
                  fileId: fileId,
                })
                  .then((res) => {
                    console.log(res);
                  })
                  .catch((error) => {
                    let msg = ErrorHandler(error);
                    alert(msg);
                  });
              })
              .catch((error) => {
                let msg = ErrorHandler(error);
                alert(msg);
              });
          } else {
            HREmployeeApi.InsertCertificateData({
              ...x,
              employeeId: pd.employeeId,
            })
              .then((res) => {
                console.log(res);
              })
              .catch((error) => {
                let msg = ErrorHandler(error);
                alert(msg);
              });
          }
        });
        // 등록 사원 체크
        HREmployeeApi.InsertApplicantEnrollment(applicant)
          .then((res) => {
            console.log(res);
          })
          .catch((error) => {
            let msg = ErrorHandler(error);
            alert(msg);
          });
        // 그룹웨어 v4 계성 생성 API 호출
        // if (pd.isO365) {
        //   var userInfoErp: UserInfoErpRequest = {
        //     userPrincipalName: pd.gwmail,
        //     displayName: pd.name,
        //     mobilePhone: pd.phone,
        //     companyName: "㈜인바디",
        //     costCenter: pd.inputTeam?.costCenter || "",
        //     department: pd.inputTeam?.erpName || "",
        //     personalNum: pd.employeeId,
        //     legalEntity: "IHQ",
        //     hireDate: pd.joined,
        //     teamSite: "",
        //     duties: pd.jobPosition,
        //     password: "Inbody0515@",
        //   };
        //   HREmployeeApi.InsertUserInfoErpToV4(userInfoErp)
        //     .then((res) => {
        //       alert(res);
        //     })
        //     .catch((error) => {
        //       let msg = ErrorHandler(error);
        //       alert(msg);
        //     })
        //     .finally(() => {
        //       setLoading(false);
        //     });
        // }
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton
              onClick={handleStep(index)}
              completed={completed[index]}
            >
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div style={{ textAlign: "center", marginBottom: "30px" }}>
        {allStepsCompleted() ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>
              {getStepContent(activeStep)}
            </Typography>
            <div>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.button}
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
              >
                Next
              </Button>
              {activeStep !== steps.length &&
                (completed[activeStep] ? (
                  <Typography variant="caption" className={classes.completed}>
                    Step {activeStep + 1} already completed
                  </Typography>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleComplete}
                  >
                    {completedSteps() === totalSteps() - 1
                      ? "Finish"
                      : "Complete Step"}
                  </Button>
                ))}
            </div>
          </div>
        )}
      </div>
      <ProgressModal open={loading} title="O365 계정 생성중..."></ProgressModal>
    </div>
  );
}

export default WritePage;
