import MaterialTable from "material-table";
import React, { useState, useEffect, useCallback } from "react";
import { MyStyle } from "src/Style/theme";
import { TABLEICONS, TABLE_ACTION, TABLE_HEADER } from "src/system/Constants";
import PrintIcon from "@material-ui/icons/Print";
import { Button, Typography } from "@material-ui/core";
import RequestEvidenceModal from "./RequestEvidenceModal";
import { CertificateOfEmployment } from "./MainPage";
import { Evidence } from "src/system/types";
import { ErrorHandler, EvidenceApi } from "src/system/ApiService";
import { useUserState } from "src/system/UserContext";
import { Alert, AlertTitle } from "@material-ui/lab";

function EvidenceList() {
  const [requestModal, setRequestModal] = useState<boolean>(false);
  const [data, setData] = useState<Evidence[]>([]);
  const [title, setTitle] = useState<string>("");
  const user = useUserState();
  const classes = MyStyle();

  const onLoad = useCallback(() => {
    EvidenceApi.GetEvidenceList(user.employeeId)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      });
  }, [user]);

  useEffect(() => {
    onLoad();
  }, [onLoad, requestModal]);

  const openRequestModal = (selectedTitle: string) => {
    setTitle(selectedTitle);
    setRequestModal(true);
  };

  const closeRequestModal = () => {
    setRequestModal(false);
  };

  const cancelRequest = (id: number) => {
    if (window.confirm("증빙서류 발급 요청을 취소하시겠습니까? \r\n※ 취소 시 요청이력이 삭제됩니다.")) {
      EvidenceApi.DeleteEvidence(id)
        .then((res) => {
          onLoad();
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          alert(msg);
        });
    }
  };

  const openPrintPopup = (evidence: Evidence) => {
    if (window.confirm("인쇄는 1회만 가능합니다. \r\n인쇄창을 실행하시겠습니까?")) {
      EvidenceApi.GetEvidence(evidence.id)
        .then((res) => {
          if (res.data.status === "승인완료") {
            window.open(
              `${window.location.origin}/evidence/print?id=${evidence.id}&type=${evidence.type}`,
              "팝업",
              "location=no,status=no,menubar=no,width=882,height=1247"
            );
            // 인쇄 완료 상태로 변경
            EvidenceApi.UpdateEvidence({ ...res.data, status: "인쇄완료" })
              .then((res3) => {
                onLoad();
              })
              .catch((error) => {
                let msg = ErrorHandler(error);
                alert(msg);
              });
          } else {
            alert("잘못된 접근입니다.");
          }
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          alert(msg);
        });
    }
  };

  return (
    <div className={classes.paperMainTitle}>
      <div style={{ backgroundColor: "#dddddd", textAlign: "center", height: "200px" }}>
        <div>
          <PrintIcon style={{ fontSize: 60, marginTop: "50px", marginBottom: "15px" }}></PrintIcon>
          <br></br>
          <Button variant="contained" color="primary" onClick={() => openRequestModal(CertificateOfEmployment)}>
            {CertificateOfEmployment}
          </Button>
        </div>
        <br></br>
        <Typography variant="subtitle1"></Typography>
      </div>
      <Alert severity="info">
        <AlertTitle>도움말</AlertTitle>
        <ul>
          <li>
            {" "}
            증빙서류는 <span style={{ fontWeight: "bold", color: "red" }}>인사팀의 승인 후 인쇄가 가능</span>합니다.
          </li>
          <li>
            인쇄 전{" "}
            <span style={{ fontWeight: "bold", color: "red" }}>
              브라우저의 인쇄 설정 중 배경 그래픽 인쇄 항목에 체크
            </span>
            해주세요. 그렇지 않으면 직인 출력이 되지 않습니다.<br></br>
            {`   · Chrome 브라우저 : 인쇄 창에서 "설정 더 보기" > "옵션" > "배경 그래픽" 항목을 체크해주세요.`}
            <br></br>
            {`   · Edge 브라우저 : 인쇄 창에서 "기타 설정" > "옵션" > "배경 그래픽" 항목을 체크해주세요.`}
            {/* <br></br>
            {`   · Safari 브라우저 : 인쇄 창에서 "기타 설정" > "옵션" > "배경 그래픽" 항목을 체크해주세요.`} */}
          </li>
        </ul>
      </Alert>
      <MaterialTable
        icons={TABLEICONS}
        title="증빙서류 발급 신청"
        data={data}
        columns={[
          {
            title: "신청일",
            field: "requested",
            cellStyle: { width: "10%" },
            render: (props) => {
              return <>{props.requested && props.requested.split("T")[0]}</>;
            },
          },
          {
            title: "승인일",
            field: "approved",
            cellStyle: { width: "10%" },
            render: (props) => {
              return <>{props.approved && props.approved.split("T")[0]}</>;
            },
          },
          { title: "소속", field: "team", cellStyle: { width: "10%" } },
          { title: "신청자", field: "name", cellStyle: { width: "10%" } },
          { title: "구분", field: "type", cellStyle: { width: "10%" } },
          { title: "발급용도 / 제출처", field: "usage", cellStyle: { width: "30%" } },
          { title: "진행 상태", field: "status", cellStyle: { width: "10%" } },
          {
            title: "-",
            field: "",
            cellStyle: { width: "10%" },
            render: (props) => {
              if (props.status === "승인완료")
                return (
                  <Button color="primary" variant="contained" onClick={() => openPrintPopup(props)}>
                    인쇄
                  </Button>
                );
              else if (props.status === "인쇄완료") {
                return (
                  <Button color="primary" variant="contained" disabled onClick={() => alert("이미 인쇄한 서류입니다.")}>
                    인쇄
                  </Button>
                );
              } else
                return (
                  <Button color="default" variant="contained" onClick={() => cancelRequest(props.id)}>
                    취소
                  </Button>
                );
            },
          },
        ]}
        localization={{
          body: {
            emptyDataSourceMessage: "표시할 데이터가 없습니다.",
          },
        }}
        options={{
          headerStyle: TABLE_HEADER,
          actionsCellStyle: TABLE_ACTION,
          search: true,
          pageSize: 10,
          filtering: false,
        }}
      ></MaterialTable>
      <RequestEvidenceModal title={title} open={requestModal} onClose={closeRequestModal}></RequestEvidenceModal>
    </div>
  );
}

export default EvidenceList;
