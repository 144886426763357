import { atom } from 'recoil';
import { GetAttendanceForm } from '../interface/RequestInterface';

export interface attendanceAppendProps {
  employeeId: string;
  employeeName: string;
  employeeDepartment: string;
  formId: number;
  selectedForm: GetAttendanceForm | undefined;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  vehicle: string;
  expiredAt: Date;
}

export const initAttendanceAppend: attendanceAppendProps = {
  employeeId: '',
  employeeName: '',
  employeeDepartment: '',
  formId: 0,
  selectedForm: undefined,
  startDate: '',
  startTime: '',
  endDate: '',
  endTime: '',
  vehicle: '',
  expiredAt: new Date('2100-01-01'),
}
export const attendanceAppendAtom = atom<attendanceAppendProps>({
  key: 'attendanceAppendAtom',
  default: initAttendanceAppend
})