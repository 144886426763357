import { Button, Grid, TextField } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React, { ReactElement, useState, useEffect } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import Page from "src/components/common/Page";
import KeyboardDatePicker from "src/components/KeyboardDatePicker";
import SelectCombobox from "src/components/SelectCombobox";
import SmallTextField from "src/components/SmallTextField";
import TitleMultipleComboBox from "src/components/TitleMultipleComboBox";
import { ComboboxItem } from "src/components/TitleOneComboBox";
import WarnMessage from "src/components/WarnMessage";
import PaperContainer from "src/components/WriteContainer";
import { InterviewRecordRoutes } from "src/system/types/type";
import { MyStyle } from "src/Style/theme";
import { ErrorHandler, IssueApi, EmployeeApi, IssueCategoryApi } from "src/system/ApiService";
import { GetComboboxList, isInArray } from "src/system/Helper";
import { AuthorityCategory, IssueListItem, IssueSearch, ROWS_PER_PAGE, SearchResult } from "src/system/types";
import { useUserState } from "src/system/UserContext";
import ListIssueTable from "src/ViewParts/Issue/ListIssueTable";

interface SearchHeaderProps {
  searchOption: IssueSearch;
}
function SearchHeader({ searchOption }: SearchHeaderProps): ReactElement {
  const [state, setState] = useState<IssueSearch>(searchOption);
  const [categoryOption, setCategoryOption] = useState<ComboboxItem[]>([]);
  const [empSelectOptions, setEmpSelectOptions] = useState<ComboboxItem[]>([]);

  const history = useHistory();
  const classes = MyStyle();
  useEffect(() => {
    IssueCategoryApi.GetAll()
      .then((response) => {
        let k = response.data.map((x) => ({
          key: x.id + "",
          value: x.comment,
        }));
        setCategoryOption(k);
      })
      .catch((error) => {});

    //직원 목록 불러오기
    EmployeeApi.getEmployees()
      .then(({ data }) => {
        let ret: ComboboxItem[] = [];
        data.forEach((x) => {
          ret.push({
            key: x.employeeId,
            value: `${x.name}(${x.team})`,
          });
        });
        setEmpSelectOptions(ret);
      })
      .catch(() => alert("직원 데이터를 불러오는데 실패했습니다."));
  }, []);

  const concerneds: ComboboxItem[] = GetComboboxList(empSelectOptions, state.concerneds);

  const onChangeText = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.currentTarget;

    let k = { ...state };

    if (name === "issueId") {
      if (!isNaN(parseInt(value))) {
        k.id = parseInt(value);
      } else {
        k.id = null;
      }
    } else if (name === "writer") {
      k.writer = value;
    }

    setState(k);
  };

  const onChangeSelect = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { name, value } = event.target;

    let k = { ...state };
    if (name === "category") {
      k.categoryId = parseInt(value + "");
    } else if (name === "dateOption") {
      k.dateOption = value + "";
    }
    setState(k);
  };

  const onSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    history.push({
      pathname: InterviewRecordRoutes.issue + "/list",
      search: "?page=1",
      state: {
        ...state,
        page: 1,
      },
    });
  };

  const onChangeDate = (date: Date | null, type: string) => {
    if (type === "created") {
      setState({
        ...state,
        created: date === null ? null : date.getTime(),
      });
    } else if (type === "occurred") {
      setState({
        ...state,
        occurred: date === null ? null : date.getTime(),
      });
    }
  };

  const onChangeConcerneds = (event: React.ChangeEvent<{}>, value: ComboboxItem[] | null) => {
    if (value) {
      let k = { ...state };

      k.concerneds = value.map((x) => ({
        employeeId: x.key,
        name: x.value,
        team: "",
      }));

      setState(k);
    }
  };

  return (
    <PaperContainer>
      <Grid container spacing={3} direction="column" justify="space-evenly" alignItems="flex-start">
        <Grid container item spacing={2}>
          <Grid item>
            <TextField
              type="number"
              label={"이슈번호"}
              name="issueId"
              value={state.id}
              onChange={onChangeText}
            ></TextField>
          </Grid>
          <Grid item>
            <SelectCombobox
              key="search-page-category"
              empty
              label="카테고리"
              name="category"
              value={state.categoryId + ""}
              options={categoryOption}
              onChange={onChangeSelect}
            ></SelectCombobox>
          </Grid>
          <Grid item>
            <KeyboardDatePicker
              label={"발생일"}
              style={{ marginTop: "0px" }}
              margin={"none"}
              value={null}
              onChange={(date: Date | null) => onChangeDate(date, "occurred")}
              selecteddate={state.occurred === null ? undefined : state.occurred}
            ></KeyboardDatePicker>
          </Grid>
        </Grid>
        <Grid item>
          <TitleMultipleComboBox
            label={"관련자"}
            userSelected={concerneds}
            onChange={onChangeConcerneds}
            options={empSelectOptions}
          ></TitleMultipleComboBox>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item>
            <SmallTextField
              label="작성자"
              value={state.writer ? state.writer : undefined}
              name="writer"
              onChange={onChangeText}
            ></SmallTextField>
          </Grid>
          <Grid item>
            <KeyboardDatePicker
              className={classes.DatepickerWithTextField}
              label={"작성일"}
              style={{ marginTop: "0px" }}
              margin={"none"}
              value={null}
              onChange={(date: Date | null) => onChangeDate(date, "created")}
              selecteddate={state.created === null ? undefined : state.created}
            ></KeyboardDatePicker>
          </Grid>
          <Grid item>
            <SelectCombobox
              key="search-page-dateoption"
              name="dateOption"
              value={state.dateOption}
              options={[
                { key: "prev", value: "이전" },
                { key: "after", value: "이후" },
              ]}
              onChange={onChangeSelect}
            ></SelectCombobox>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={onSubmit}>
              검색
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </PaperContainer>
  );
}

interface SearchBodyProps {
  searchOption: IssueSearch;
}
function SearchBody({ searchOption }: SearchBodyProps): ReactElement {
  const [data, setData] = useState<SearchResult<IssueListItem>>(new SearchResult());
  const history = useHistory();
  const page = searchOption.page;

  const user = useUserState();
  const isShowList = isInArray(user.authority, AuthorityCategory.LIST);

  /** searchOption 에 따라 내용 검색 */
  useEffect(() => {
    let opt = { ...searchOption, page: page };
    IssueApi.search(opt)
      .then((data) => {
        let ret = Math.floor(data.count / ROWS_PER_PAGE);
        ret = ret === 0 ? 1 : ret;
        setData({
          ...data,
          count: ret,
        });
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      });
  }, [page, searchOption]);

  const onChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    history.push({
      pathname: InterviewRecordRoutes.issue + "/list",
      search: `?page=${page}`,
      state: { ...searchOption, page: page },
    });
  };

  return (
    <>
      {!isShowList && <WarnMessage>데이터를 볼 수 있는 권한이 없습니다.</WarnMessage>}
      <ListIssueTable data={data.list}></ListIssueTable>
      <div className="d-flex justify-content-center">
        <Pagination
          count={data.count}
          defaultValue={1}
          page={data.page}
          showFirstButton
          showLastButton
          onChange={onChangePage}
        ></Pagination>
      </div>
    </>
  );
}

function SearchPage(): ReactElement {
  const { state } = useLocation<IssueSearch>();
  if (state) {
    return (
      <Page>
        <SearchHeader searchOption={state}></SearchHeader>
        <br />
        <SearchBody searchOption={state}></SearchBody>
      </Page>
    );
  } else {
    const regex = /page=([\d]+)/;
    const match = regex.exec(window.location.search);

    let page = 1;

    if (match != null && !isNaN(parseInt(match[1]))) {
      page = parseInt(match[1]);
    }

    return (
      <Redirect
        to={{
          pathname: InterviewRecordRoutes.issue + "/list",
          search: `?page=${page}`,
          state: {
            id: null,
            categoryId: 0,
            concerneds: [],
            writer: null,
            occurred: null,
            created: null,
            dateOption: "prev",
            page: page,
          },
        }}
      ></Redirect>
    );
  }
}

export default SearchPage;
