import React, { ReactElement, useEffect, useState } from "react";
import { startOfMonth, endOfMonth, addDays, getDate } from "date-fns";
import {
  Attendance,
  EmployeeAutoComplete,
  InitAttendance,
  MonthlyAttendanceStatics,
} from "src/system/types";
import TableWeek from "./TableWeek";
import { AttendanceApi, ErrorHandler } from "src/system/ApiService";
import { Grid } from "@material-ui/core";
import TableInsaCard from "src/components/TableInsaCard";
import DailyAlarmListModal from "src/components/DailyAlarmListModal";
import DailyDetailAttendanceModal from "src/components/DailyDetailAttendanceModal";
interface TableAttendanceProps {
  date: Date;
  employee: EmployeeAutoComplete;
}
const DAYS = ["일", "월", "화", "수", "목", "금", "토"];

function TableAttendance({
  date,
  employee,
}: TableAttendanceProps): ReactElement {
  const [month, setMonth] = useState<Attendance[]>();
  // const [statics, setStatics] = useState<MonthlyAttendanceStatics>();
  const [openAlarmModal, setOpenAlarmModal] = React.useState(false);

  const getMonthDays = React.useCallback((date: Date) => {
    const getDay = (date: Date) => {
      return DAYS[date.getDay()];
    };

    let start = startOfMonth(date);
    let end = endOfMonth(date);
    let startDay = start.getDate();
    let endDay = end.getDate();
    let startDayOfTheWeek = start.getDay();
    let endDayOfTheWeek = end.getDay();
    let result = [];

    for (var i = 0; i < startDayOfTheWeek; i++) {
      const date = addDays(start, (startDayOfTheWeek - i) * -1);
      result.push({
        ...InitAttendance,
        dayOfTheWeek: getDay(date),
        date,
        day: getDate(date),
        isBlur: true,
      });
    }

    for (i = startDay - 1; i < endDay; i++) {
      const date = addDays(start, i);
      result.push({
        ...InitAttendance,
        dayOfTheWeek: getDay(date),
        date,
        day: getDate(date),
        isBlur: false,
      });
    }

    for (i = 0; i < 6 - endDayOfTheWeek; i++) {
      const date = addDays(start, endDay + i);
      result.push({
        ...InitAttendance,
        dayOfTheWeek: getDay(date),
        date,
        day: getDate(date),
        isBlur: true,
      });
    }
    return result;
  }, []);

  useEffect(() => {
    // 달력
    const result = getMonthDays(date);
    // 출퇴근 이력
    // todo 통합근태신청서 DB테이블 생성하면 back-end에서 처리하도록 수정
    AttendanceApi.GetAttendanceList(employee, date)
      .then((res) => {
        res.forEach((x) => {
          const index = result.findIndex((y) => y.day === x.day && !y.isBlur);
          if (index > -1) {
            // 그룹웨어 통합근태신청서 같은 날에 여러개인 경우
            let gwStart = x.gwStart;
            let gwEnd = x.gwEnd;
            let gwOWTime = x.gwOWTime;
            let gwWorkTime = x.gwWorkTime;
            // 그룹웨어 시간에 빈 값 넣지 않도록
            if (gwStart !== "" && result[index].gwStart !== "") {
              if (result[index].gwStart < gwStart) {
                gwStart = result[index].gwStart;
              }
              if (x.category.includes("취소"))
                gwWorkTime = result[index].gwWorkTime - x.gwWorkTime;
              else gwWorkTime = result[index].gwWorkTime + x.gwWorkTime;
            }
            if (gwEnd !== "" && result[index].gwEnd !== "") {
              if (result[index].gwEnd > gwEnd) {
                gwEnd = result[index].gwEnd;
              }
            }
            if (gwOWTime !== 0 && result[index].gwOWTime !== 0) {
              if (result[index].gwOWTime > gwOWTime) {
                gwOWTime = result[index].gwOWTime;
              }
            }
            result[index] = {
              ...result[index],
              holiday: x.holiday,
              start: x.start,
              end: x.end,
              bLate: x.bLate,
              cLate: x.cLate,
              eLate: x.eLate,
              owTime: x.owTime,
              category: x.category,
              gwStart: gwStart,
              gwEnd: gwEnd,
              gwStatus: x.gwStatus,
              gwOWTime: x.gwOWTime,
              gwWorkTime: gwWorkTime,
              gwCancelStatus: x.gwCancelStatus,
            };
          }
        });
        setMonth(result);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        alert(msg);
      });
  }, [date, getMonthDays, employee]);

  const colgroup = (
    <colgroup>
      <col width="130px"></col>
      <col width="130px"></col>
      <col width="130px"></col>
      <col width="130px"></col>
      <col width="130px"></col>
      <col width="130px"></col>
      <col width="130px"></col>
    </colgroup>
  );

  const clickCloseAlarmModal = () => {
    setOpenAlarmModal(false);
  };

  const [selectedDate, setSelectedDate] = useState<Date>(date);

  const clickCellDay = (date: Date) => {
    setSelectedDate(date);
    setOpenAlarmModal(true);
  };

  return (
    <Grid container style={{ marginTop: "-20px" }} spacing={4}>
      <Grid item md={12} xs={12}>
        <TableInsaCard title={"근태 관리"} colgroup={colgroup}>
          {/* 요일 */}
          <tr>
            <th style={{ color: "red" }}>일</th>
            <th>월</th>
            <th>화</th>
            <th>수</th>
            <th>목</th>
            <th>금</th>
            <th style={{ color: "blue" }}>토</th>
          </tr>
          {/* 캘린더 */}
          {month &&
            month.map((x, index) => {
              return (
                index % 7 === 0 && (
                  <TableWeek
                    key={`week-table-${index}`}
                    month={month}
                    week={index / 7}
                    employee={employee}
                    clickCellDay={clickCellDay}
                  ></TableWeek>
                )
              );
            })}
        </TableInsaCard>
      </Grid>
      <DailyDetailAttendanceModal
        open={openAlarmModal}
        employee={employee}
        selectedDate={selectedDate}
        onClose={clickCloseAlarmModal}
      ></DailyDetailAttendanceModal>
    </Grid>
  );
}

export default TableAttendance;
